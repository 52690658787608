const Icon = ({ width = "24", height = "24", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.25 7.75H5.75V16.75L4.75 19.25H19.25L18.25 16.75V7.75Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.25 7.75L16.25 4.75H7.75L5.75 7.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 10.75V11.75C9.75 12.9926 10.7574 14 12 14C13.2426 14 14.25 12.9926 14.25 11.75V10.75"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
