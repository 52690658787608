import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorReceiptsByDate } from "../../state/selectors";
import ListingPage from "../listingPage";
import StoreLogo from "../../components/storeLogo";
import ListItem from "../../components/listItem";
import { formatCurrency } from "../../utils/formatter";
import Outline from "../../components/layouts/outline";
import routes from "../../utils/routes";
import { getReceipts, triggerInboxScan } from "../../state/actionCreators";

const ReceiptsAll = ({ receiptsByDate, getReceipts, triggerInboxScan, strs }) => {
  const refreshReceipts = async () => {
    await triggerInboxScan();
    await getReceipts();
  };

  return (
    <ListingPage
      headline={strs.receipts.title}
      items={receiptsByDate}
      backPath={routes.overview.subroutes.receipts.path}
      emptyState={<div>no items</div>}
      onRefresh={refreshReceipts}
      renderItem={(date) => (
        <Outline label={date.label} key={date.label}>
          {date.items.map((item) => (
            <ListItem
              key={item.id}
              image={<StoreLogo domain={item?.domain} />}
              text={<div className="listItem__text--heading">{item?.domain?.name || strs.unknownStore}</div>}
              rightText={formatCurrency(item.total_amount, item.currency)}
              route={`/overview/receipts/${item.id}`}
            />
          ))}
        </Outline>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    receiptsByDate: selectorReceiptsByDate(state),
    strs: selectorLanguageStrings(state, "overview"),
  };
};

const mapDispatchToProps = { getReceipts, triggerInboxScan };

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptsAll);
