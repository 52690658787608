import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { selectorAppOsPlatform, selectorLanguageStrings, selectorMyUser } from "../../state/selectors";
import "./styles.scss";
import routes from "../../utils/routes";
import { HeaderLayout } from "../../components/layouts/header";
import Outline from "../../components/layouts/outline";
import InfoBox, { InfoBoxes } from "../../components/infoBox";
import Button from "../../components/inputs/button";
import NOTIFICATION_BELL_IMAGE from "./assets/notifcation-bell.png";
import VOUCHERS_IMAGE from "./assets/vouchers.png";
import useClipboard from "react-hook-clipboard";
import Share from "../../components/icons/share";
import Message from "../../components/icons/message";
import Twitter from "./assets/icons/twitter";
import Facebook from "./assets/icons/facebook";
import Whatsapp from "./assets/icons/whatsapp";
import Linkedin from "./assets/icons/linkedin";
import { setToastMessage, setGlobalState, updateMyUser } from "../../state/actionCreators";
import NativeAppClient from "../../services/nativeAppClient";
import Hide from "../../components/hide/hide";
import className from "classnames";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import Label from "../../components/label";
import AWARD_BLACK_ICON from "../../components/icons/award-black";
import AWARD_ICON from "../../components/icons/award";
// import moment from "moment";

const ViewReferral = ({ strs, user, language, setToastMessage, isIOS, setGlobalState, updateMyUser, history }) => {
  const [, copyToClipboard] = useClipboard({ updateFrequency: 1000 }, () => {});
  const [isShareBoxOpen, setShareBoxOpen] = useState(false);
  const [isPushNotificationEnable, setIsPushNotificationEnable] = useState(false);

  const shareText = encodeURIComponent(strs.shareText({ url: user.referal?.link }));
  const progressCount = 5;

  useEffect(() => {
    if (NativeAppClient.isInNativeApp()) {
      NativeAppClient.requestPushTokenStatus()
        .then((res) => {
          if (Object.keys(res).length !== 0 && res.status !== null && res.status === "Granted") {
            setIsPushNotificationEnable(true);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, []);

  const setNotification = async () => {
    await NativeAppClient.requestPushToken();
    setIsPushNotificationEnable(true);
    await updateMyUser({
      enable_push_notifications: true,
      enable_email_notifications: true,
    });

    // setGlobalState({
    //   appPushNotificationToken: token.status,
    //   appPushNotificationTokenDate: moment().toISOString(),
    // });
  };
  return (
    <div className="viewReferral">
      <MainContentLayout center small>
        <HeaderLayout left={{ type: "arrowLeft", path: routes.overview.path }} />
        <div className="viewReferral__titleWrapper">
          <h1>{strs.heading}</h1>
          <Label color="#545454" size="base">
            {strs.subheading}
          </Label>
        </div>
        <Label color="#545454" size="sm">
          {strs.progress}
        </Label>
        <Outline
          label={""}
          background={false}
          padding={false}
          styles={{
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <div className="shareButtons shareButtons--badges">
            {Array.from(Array(progressCount)).map((ele, index) => {
              return (
                <div className="shareButtons-column" key={index}>
                  <div
                    className={className("shareButton", {
                      "shareButton--gray": index + 1 >= user.referal?.signups % progressCount,
                      "shareButton--pink": index + 1 <= user.referal?.signups % progressCount,
                    })}
                  >
                    {index + 1 <= user.referal?.signups % progressCount ? <AWARD_ICON /> : <AWARD_BLACK_ICON />}
                  </div>
                </div>
              );
            })}
          </div>
        </Outline>
        <Label color="#545454" size="sm">
          <span className="shareButtons__title--bold">{progressCount - (user.referal?.signups % progressCount)} </span>
          {strs.unlocked}
        </Label>
        <Outline
          label={""}
          background={false}
          padding={false}
          styles={{
            marginTop: 30,
            marginBottom: 0,
          }}
        >
          <div className="viewReferral__counts__wrapper">
            <div className="viewReferral__counts__button">
              <div className="viewReferral__counts__label">{strs.statsInvited}</div>
              <div className="viewReferral__counts--count">{user.referal?.clicks}</div>
            </div>
            <div className="viewReferral__counts__button">
              <div className="viewReferral__counts__label">{strs.statsSignups}</div>
              <div className="viewReferral__counts--count">{user.referal?.signups}</div>
            </div>
            <div className="viewReferral__counts__button">
              <div className="viewReferral__counts__label">{strs.giftsEarned}</div>
              <div className="viewReferral__counts--count">{Math.trunc(user.referal?.signups / progressCount)}</div>
            </div>
          </div>
        </Outline>
        <Outline
          label={strs.outlineShareLink}
          background={false}
          padding={false}
          styles={{
            marginBottom: 0,
          }}
        >
          <div className={"shareLink"}>
            <div
              className={"shareLink-input"}
              style={{
                userSelect: true,
              }}
            >
              <Hide on={"mobile"}>
                <span>{user.referal?.link}</span>
              </Hide>

              <Hide on={"desktop"}>
                <span>{user.referal?.link.replace("https://", "")}</span>
              </Hide>

              <button
                className={"shareLink-button--inline"}
                onClick={() => {
                  copyToClipboard(user.referal?.link);
                  setToastMessage(strs.copySuccess, 1500);
                }}
              >
                {strs.copyLink}
              </button>
            </div>

            <Hide on={"mobile"}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShareBoxOpen(false);
                }}
              >
                <div
                  className={className("shareDropdown", {
                    "shareDropdown--open": isShareBoxOpen,
                  })}
                >
                  <div className="shareDropdown-label">
                    <Button
                      className={"shareLink-button"}
                      onClick={() => setShareBoxOpen(!isShareBoxOpen)}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "between",
                            alignItems: "center",
                          }}
                        >
                          <Share color="#FFF" width={24} style={{ marginRight: "12px", color: "white" }} />
                          <span>{strs.shareBoxLabel}</span>
                        </div>
                      }
                      type={"pink"}
                    />
                  </div>
                  <div className="shareDropdown-items">
                    <div className="shareBox">
                      <div className="shareBox-header">{strs.shareBoxHeading}</div>
                      <div className="shareBox-content">
                        <a
                          href={`https://twitter.com/intent/tweet?text=${shareText}`}
                          target="_blank"
                          rel="nofollow noopner noreferrer"
                        >
                          <Twitter />
                        </a>
                        <a
                          href={`https://www.linkedin.com/sharing/share-offsite/?url=${user.referal?.link}`}
                          target="_blank"
                          rel="nofollow noopner noreferrer"
                        >
                          <Linkedin />
                        </a>

                        <a
                          href={`https://api.whatsapp.com/send?text=${shareText}`}
                          target="_blank"
                          rel="nofollow noopner noreferrer"
                        >
                          <Whatsapp />
                        </a>
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${user.referal?.link}`}
                          target="_blank"
                          rel="nofollow noopner noreferrer"
                        >
                          <Facebook />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </Hide>

            <Hide on={"desktop"}>
              <Button
                className={"shareLink-button"}
                label={strs.copy}
                type={"pink"}
                onClick={() => {
                  NativeAppClient.copyToClipboard(user.referal?.link);
                  setToastMessage(strs.copySuccess, 1500);
                }}
              />
            </Hide>
          </div>
        </Outline>
        <Hide on={"desktop"}>
          <Outline
            label={""}
            background={false}
            padding={false}
            styles={{
              marginTop: 10,
            }}
          >
            <div className="referalButtons">
              <div>
                <button
                  className="referalButton"
                  onClick={() => {
                    if (NativeAppClient.isInNativeApp()) {
                      NativeAppClient.triggerShareDialog({
                        text: `${strs.webshareText} ${user.referal?.link}`,
                      });
                    } else if (navigator.share) {
                      // Shot in the dark, this is only supported on a few browsers
                      navigator
                        .share({
                          title: strs.webshareTitle,
                          text: strs.webshareText,
                          url: user.referal?.link,
                        })
                        .catch((error) => setToastMessage(strs.shareError, 1500));
                    } else {
                      setToastMessage(strs.shareErrorUnknown, 1500);
                    }
                  }}
                >
                  <Share width={18} height={15} color="#000" />
                  <span>{strs.mobileShare}</span>
                </button>
              </div>
              <div>
                <button
                  className="referalButton"
                  onClick={() => {
                    // The difference in case you cannot spot it, is that ios
                    // needs an '&' instead of an '?' after the phone number.
                    // See: https://stackoverflow.com/a/19126326
                    window.location.href = isIOS
                      ? `?appOpenInUrl=sms://&body=${shareText}`
                      : `?appOpenInUrl=sms://?body=${shareText}`;
                  }}
                >
                  <Message width={20} height={20} />
                  <span>{strs.mobileSms}</span>
                </button>
              </div>
            </div>
          </Outline>
        </Hide>

        <Outline label={strs.readMore} padding={false} background={false}>
          <InfoBoxes>
            {NativeAppClient.isInNativeApp() && !isPushNotificationEnable ? (
              <InfoBox
                backgroundColor={"#e86e94"}
                image={NOTIFICATION_BELL_IMAGE}
                text={strs.turnOnNotification}
                position="above"
                onClick={() => {
                  setNotification();
                }}
                size="large"
              />
            ) : null}
            <InfoBox
              backgroundColor={"#EB6F93"}
              image={VOUCHERS_IMAGE}
              text={strs.seeGiftCodes}
              onClick={() => history.push(routes.discoveries.path)}
              position="above"
              size="large"
            />
          </InfoBoxes>
        </Outline>

        <Label color="#545454" size="xs">
          {strs.info}
        </Label>
      </MainContentLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "referral"),
  user: selectorMyUser(state),
  language: state.globalState.language,
  isIOS: selectorAppOsPlatform(state) === "ios",
});

const mapDispatchToProps = { setToastMessage, setGlobalState, updateMyUser };

export default connect(mapStateToProps, mapDispatchToProps)(ViewReferral);
