import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  selectorHasNotifications,
  selectorLanguageStrings,
  selectorPurchaseTotalSavings,
  selectorUnreadNotifications,
} from "../../../state/selectors";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import AddReceipt from "../../../components/addReceipt";
import routes from "../../../utils/routes";
import Receipts from "../../receipts";
import Refunds from "../../refunds";
import "./styles.scss";
import Bell from "../../../components/menus/loginMainMenu/assets/bell";
import Share from "../../../components/icons/share";
import TabNavigator from "../../../components/tabNavigator";

const ViewOverview = ({ hasNotifications, unreadNotifications, totalSavings, option, strs, history }) => {
  const [activeTab, setActiveTab] = useState(1);
  const TabButtonList = [
    {
      id: 1,
      label: strs.optionReceipts,
    },
    {
      id: 2,
      label: strs.optionRefunds,
    },
  ];

  useEffect(() => {
    if (activeTab) {
      history.push(activeTab === 1 ? routes.overview.subroutes.receipts.path : routes.overview.subroutes.refunds.path);
    } else {
      history.push(routes.overview.subroutes.receipts.path);
    }
  }, [activeTab]);

  return (
    <div className="viewOverviewScreen">
      <MainContentLayout center={true}>
        <div className="viewColumnLayout">
          <div className="viewColumnLayout__one">
            <div className="viewColumnLayout__one__header">{strs.optionReceipts}</div>
          </div>
          <div className="viewColumnLayout__two">
            <AddReceipt />

            {hasNotifications && (
              <Link className="roundButton bell" to={"/overview/notifications/"}>
                <Bell notification={unreadNotifications?.length > 0} />
              </Link>
            )}

            <Link className="roundButton bell" to={routes.referral.path}>
              <Share />
            </Link>
          </div>
        </div>

        <TabNavigator
          onClick={(e) => {
            setActiveTab(e.id);
          }}
          TabButtonList={TabButtonList}
          activeTab={activeTab}
        />

        {option === "receipts" && <Receipts />}
        {option === "refunds" && <Refunds />}
      </MainContentLayout>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    option: ownProps.location.pathname.match(/active|refunds|expired|receipts|giftcards/i)?.[0] || "receipts",
    totalSavings: selectorPurchaseTotalSavings(state),
    hasNotifications: selectorHasNotifications(state),
    unreadNotifications: selectorUnreadNotifications(state),
    strs: selectorLanguageStrings(state, "overview"),
  };
};

export default withRouter(connect(mapStateToProps)(ViewOverview));
