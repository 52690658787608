export const encrypt = (string, key = 23) => {
  if (!key || !Number.isInteger(key)) throw new Error("missing integer key");
  if (key > 50000000) throw new Error("key exceeds max range 50000000");
  return string
    .split("")
    .map((char) => String.fromCharCode(char.charCodeAt(0) + key))
    .join("");
};

export const decrypt = (encryptedString, key = 23) => {
  if (!encryptedString) return null;
  if (!key || !Number.isInteger(key)) throw new Error("missing integer key");
  if (key > 50000000) throw new Error("key exceeds max range 50000000");
  return encryptedString
    .split("")
    .map((char) => String.fromCharCode(char.charCodeAt(0) - key))
    .join("");
};
