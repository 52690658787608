import React from "react";
import "./styles.scss";
import classNames from "classnames";

const Outline = ({
  label,
  aside,
  children,
  padding,
  background,
  boxShadow = true,
  styles = {
    marginTop: 32,
    marginBottom: 32,
    marginLeft: 0,
    marginRight: 0,
  },
  outlineAreaStyles,
  error = false,
  border = false,
}) => {
  return (
    <div
      style={{
        ...styles,
      }}
      className={classNames({
        "outline": true,
        "outline--no-padding": padding === false,
        "outline--no-background": background === false,
      })}
    >
      {label || aside ? (
        <div className="outline__header">
          {label && <div className="outline__header--label">{label}</div>}
          {aside && <div className="outline__header--aside">{aside}</div>}
        </div>
      ) : (
        ""
      )}
      <div
        style={{ ...outlineAreaStyles }}
        className={classNames({
          "outline__area": true,
          "outline__area__box": boxShadow,
          "outline__area__border--error": error,
          "outline__area__border": border,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Outline;
