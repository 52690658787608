import "./styles.scss";
import className from "classnames";

export const ControlsLayout = ({ children, variant }) => {
  return (
    <div
      className={className("controlsLayout", {
        "controlsLayout--row": variant === "row",
      })}
    >
      {children}
    </div>
  );
};
