import React from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import iconShoppingBag from "./assets/shopping-bag.svg";
import iconReceipts from "./assets/receipts.svg";
import iconReceiptsPink from "./assets/receipts-pink.svg";
import iconShoppingBagPink from "./assets/shopping-bag-pink.svg";
import iconUser from "./assets/user.svg";
import iconUserPink from "./assets/user-pink.svg";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../state/selectors";
import "./styles.scss";

class AppModeMenu extends React.Component {
  state = { hint: false };

  handleClick = () => {
    this.setState({ hint: true });
    setTimeout(() => this.setState({ hint: false }), 2500);
  };

  render() {
    let hidden = false;
    const pathname = this.props.location.pathname;
    const match = pathname.match(/overview|profile|discover/i)?.[0];
    if (!match) hidden = true;

    const isOverview = match === "overview";
    const isProfile = match === "profile";
    const isDiscover = match === "discover";

    // skip on subroutes like /overview/active/9999
    if (isOverview && pathname.match(/(\d+)|notifications/)) hidden = true;
    // skip on subroutes like /profile/card
    if (isProfile && pathname !== "/profile") hidden = true;
    if (isDiscover && pathname !== "/discover") hidden = true;

    return (
      <div className={classNames("appModeMenu", { "appModeMenu--hidden": hidden })}>
        <div
          className={classNames("appModeMenu__hint", {
            "appModeMenu__hint--visible": this.state.hint,
          })}
        >
          Coming Soon 🙊
        </div>
        <div className="appModeMenu__items">
          <Link className="appModeMenu__item" to="/discover">
            <img alt="" draggable={false} src={isDiscover ? iconShoppingBagPink : iconShoppingBag} />
            <div
              className={classNames({
                "appModeMenu__item--label": true,
                "appModeMenu__item--label--selected": isDiscover,
              })}
            >
              {this.props.strs.shop}
            </div>
          </Link>
          <Link className="appModeMenu__item" to="/overview">
            <img
              className="appModeMenu__item--svg"
              alt=""
              draggable={false}
              src={isOverview ? iconReceiptsPink : iconReceipts}
            />
            <div
              className={classNames({
                "appModeMenu__item--label": true,
                "appModeMenu__item--label--selected": isOverview,
              })}
            >
              {this.props.strs.receipts}
            </div>
          </Link>
          <Link className="appModeMenu__item" to="/profile">
            <img alt="" draggable={false} src={isProfile ? iconUserPink : iconUser} />
            <div
              className={classNames({
                "appModeMenu__item--label": true,
                "appModeMenu__item--label--selected": isProfile,
              })}
            >
              {this.props.strs.profile}
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "appModeMenu"),
});

export default withRouter(connect(mapStateToProps, null)(AppModeMenu));
