function Search(props) {
  return (
    <svg width="14.5px" height="14.5px" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M15.25 15.25L11.5 11.5l3.75 3.75zM.75 7a6.25 6.25 0 1112.5 0A6.25 6.25 0 01.75 7z"
        stroke="#545454"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Search;
