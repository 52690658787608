const Icon = ({ width = "117", height = "39", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 117 39" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M79.0665 18.6214V20.8433H77.6738V22.3003H79.0665V27.2512C79.0665 28.9418 79.8313 29.618 81.7553 29.618C82.0934 29.618 82.4154 29.5777 82.6971 29.5294V28.0884C82.4556 28.1126 82.3027 28.1287 82.037 28.1287C81.1757 28.1287 80.7973 27.7262 80.7973 26.8084V22.3003H82.6971V20.8433H80.7973V18.6214H79.0665Z"
      fill="#222222"
    />
    <path
      d="M87.5984 29.7387C90.1584 29.7387 91.7282 28.024 91.7282 25.1823C91.7282 22.3567 90.1503 20.634 87.5984 20.634C85.0385 20.634 83.4606 22.3567 83.4606 25.1823C83.4606 28.024 85.0304 29.7387 87.5984 29.7387ZM87.5984 28.2011C86.093 28.2011 85.2478 27.0983 85.2478 25.1823C85.2478 23.2825 86.093 22.1715 87.5984 22.1715C89.0958 22.1715 89.9491 23.2825 89.9491 25.1823C89.9491 27.0902 89.0958 28.2011 87.5984 28.2011Z"
      fill="#222222"
    />
    <path
      d="M93.0713 29.5697H94.8021V24.3934C94.8021 23.1617 95.7278 22.3325 97.04 22.3325C97.3459 22.3325 97.8611 22.3889 98.006 22.4372V20.7306C97.8209 20.6823 97.4908 20.6581 97.2332 20.6581C96.0901 20.6581 95.116 21.286 94.8665 22.1474H94.7377V20.803H93.0713V29.5697Z"
      fill="#222222"
    />
    <path
      d="M102.304 22.1071C103.584 22.1071 104.421 23.0007 104.461 24.3773H100.033C100.13 23.0088 101.024 22.1071 102.304 22.1071ZM104.453 27.2029C104.131 27.8872 103.414 28.2655 102.368 28.2655C100.983 28.2655 100.09 27.2915 100.033 25.7539V25.6573H106.224V25.0535C106.224 22.3003 104.751 20.634 102.312 20.634C99.8402 20.634 98.2704 22.413 98.2704 25.2145C98.2704 28.016 99.808 29.7387 102.32 29.7387C104.324 29.7387 105.725 28.7727 106.119 27.2029H104.453Z"
      fill="#222222"
    />
    <path
      d="M67.7162 26.3323C67.8497 28.485 69.6437 29.8617 72.3137 29.8617C75.1672 29.8617 76.9528 28.4182 76.9528 26.1153C76.9528 24.3047 75.9349 23.3035 73.4651 22.7278L72.1384 22.4024C70.5698 22.0352 69.9357 21.5429 69.9357 20.6835C69.9357 19.5988 70.9203 18.8896 72.3971 18.8896C73.7989 18.8896 74.7667 19.5822 74.942 20.6919H76.7609C76.6524 18.6643 74.8669 17.2375 72.4221 17.2375C69.7938 17.2375 68.0417 18.6643 68.0417 20.8087C68.0417 22.5776 69.0346 23.6289 71.2123 24.1379L72.7642 24.5133C74.3579 24.8888 75.0588 25.4395 75.0588 26.3573C75.0588 27.4253 73.9574 28.2013 72.4555 28.2013C70.8452 28.2013 69.7271 27.4754 69.5686 26.3323H67.7162Z"
      fill="#222222"
    />
    <path
      d="M49.787 20.6581C48.5956 20.6581 47.5652 21.2538 47.0338 22.252H46.905V20.803H45.2387V32.4838H46.9694V28.2414H47.1063C47.5652 29.1672 48.5553 29.7146 49.8031 29.7146C52.0169 29.7146 53.4257 27.9677 53.4257 25.1823C53.4257 22.3969 52.0169 20.6581 49.787 20.6581ZM49.296 28.1609C47.8469 28.1609 46.9372 27.0178 46.9372 25.1904C46.9372 23.3549 47.8469 22.2118 49.304 22.2118C50.7691 22.2118 51.6466 23.3308 51.6466 25.1823C51.6466 27.0419 50.7691 28.1609 49.296 28.1609Z"
      fill="#222222"
    />
    <path
      d="M59.482 20.6581C58.2906 20.6581 57.2602 21.2538 56.7289 22.252H56.6001V20.803H54.9337V32.4838H56.6645V28.2414H56.8013C57.2602 29.1672 58.2503 29.7146 59.4981 29.7146C61.7119 29.7146 63.1207 27.9677 63.1207 25.1823C63.1207 22.3969 61.7119 20.6581 59.482 20.6581ZM58.991 28.1609C57.5419 28.1609 56.6323 27.0178 56.6323 25.1904C56.6323 23.3549 57.5419 22.2118 58.999 22.2118C60.4641 22.2118 61.3416 23.3308 61.3416 25.1823C61.3416 27.0419 60.4641 28.1609 58.991 28.1609Z"
      fill="#222222"
    />
    <path
      d="M42.1333 29.5697H44.1191L39.772 17.5296H37.7612L33.4141 29.5697H35.3331L36.4429 26.374H41.0319L42.1333 29.5697ZM38.6706 19.7157H38.8125L40.5563 24.8054H36.9185L38.6706 19.7157Z"
      fill="#222222"
    />
    <path
      d="M34.5742 8.44814V14.2563H36.6713C38.4021 14.2563 39.4043 13.1897 39.4043 11.3341C39.4043 9.50674 38.394 8.44814 36.6713 8.44814H34.5742ZM35.4758 9.26925H36.5707C37.7742 9.26925 38.4866 10.034 38.4866 11.3462C38.4866 12.6785 37.7862 13.4352 36.5707 13.4352H35.4758V9.26925Z"
      fill="#222222"
    />
    <path
      d="M42.4739 14.3409C43.7539 14.3409 44.5387 13.4835 44.5387 12.0627C44.5387 10.6499 43.7498 9.78849 42.4739 9.78849C41.1939 9.78849 40.405 10.6499 40.405 12.0627C40.405 13.4835 41.1899 14.3409 42.4739 14.3409ZM42.4739 13.5721C41.7212 13.5721 41.2985 13.0206 41.2985 12.0627C41.2985 11.1127 41.7212 10.5573 42.4739 10.5573C43.2225 10.5573 43.6492 11.1127 43.6492 12.0627C43.6492 13.0166 43.2225 13.5721 42.4739 13.5721Z"
      fill="#222222"
    />
    <path
      d="M51.2228 9.87302H50.3574L49.5766 13.2179H49.5081L48.6065 9.87302H47.7773L46.8757 13.2179H46.8113L46.0264 9.87302H45.1489L46.3565 14.2563H47.246L48.1476 11.0282H48.2161L49.1217 14.2563H50.0193L51.2228 9.87302Z"
      fill="#222222"
    />
    <path
      d="M52.2194 14.2563H53.0848V11.6924C53.0848 11.0081 53.4914 10.5814 54.1314 10.5814C54.7713 10.5814 55.0772 10.9316 55.0772 11.636V14.2563H55.9426V11.4187C55.9426 10.3762 55.4033 9.78849 54.4252 9.78849C53.7651 9.78849 53.3304 10.0823 53.117 10.5694H53.0526V9.87302H52.2194V14.2563Z"
      fill="#222222"
    />
    <path d="M57.3055 14.2563H58.1709V8.16235H57.3055V14.2563Z" fill="#222222" />
    <path
      d="M61.4256 14.3409C62.7056 14.3409 63.4905 13.4835 63.4905 12.0627C63.4905 10.6499 62.7016 9.78849 61.4256 9.78849C60.1457 9.78849 59.3567 10.6499 59.3567 12.0627C59.3567 13.4835 60.1416 14.3409 61.4256 14.3409ZM61.4256 13.5721C60.6729 13.5721 60.2503 13.0206 60.2503 12.0627C60.2503 11.1127 60.6729 10.5573 61.4256 10.5573C62.1743 10.5573 62.601 11.1127 62.601 12.0627C62.601 13.0166 62.1743 13.5721 61.4256 13.5721Z"
      fill="#222222"
    />
    <path
      d="M66.069 13.6003C65.598 13.6003 65.2559 13.3708 65.2559 12.9764C65.2559 12.59 65.5296 12.3847 66.1334 12.3444L67.2041 12.276V12.6423C67.2041 13.1857 66.721 13.6003 66.069 13.6003ZM65.8476 14.3288C66.4232 14.3288 66.9022 14.0792 67.1638 13.6405H67.2322V14.2563H68.0654V11.2617C68.0654 10.3359 67.4456 9.78849 66.3467 9.78849C65.3525 9.78849 64.6441 10.2715 64.5555 11.0242H65.3928C65.4894 10.7143 65.8235 10.5372 66.3065 10.5372C66.8982 10.5372 67.2041 10.7988 67.2041 11.2617V11.64L66.0167 11.7085C64.9742 11.7729 64.3865 12.2277 64.3865 13.0166C64.3865 13.8176 65.0023 14.3288 65.8476 14.3288Z"
      fill="#222222"
    />
    <path
      d="M71.0021 14.3288C71.6059 14.3288 72.1171 14.043 72.3787 13.564H72.4471V14.2563H73.2763V8.16235H72.4109V10.5694H72.3465C72.109 10.0863 71.6019 9.80057 71.0021 9.80057C69.8952 9.80057 69.1828 10.678 69.1828 12.0627C69.1828 13.4513 69.8872 14.3288 71.0021 14.3288ZM71.2477 10.5774C71.9722 10.5774 72.427 11.153 72.427 12.0667C72.427 12.9844 71.9762 13.552 71.2477 13.552C70.5151 13.552 70.0764 12.9925 70.0764 12.0627C70.0764 11.1409 70.5191 10.5774 71.2477 10.5774Z"
      fill="#222222"
    />
    <path
      d="M78.8881 14.3409C80.1681 14.3409 80.953 13.4835 80.953 12.0627C80.953 10.6499 80.1641 9.78849 78.8881 9.78849C77.6081 9.78849 76.8192 10.6499 76.8192 12.0627C76.8192 13.4835 77.6041 14.3409 78.8881 14.3409ZM78.8881 13.5721C78.1354 13.5721 77.7128 13.0206 77.7128 12.0627C77.7128 11.1127 78.1354 10.5573 78.8881 10.5573C79.6368 10.5573 80.0634 11.1127 80.0634 12.0627C80.0634 13.0166 79.6368 13.5721 78.8881 13.5721Z"
      fill="#222222"
    />
    <path
      d="M82.0985 14.2563H82.9639V11.6924C82.9639 11.0081 83.3705 10.5814 84.0104 10.5814C84.6504 10.5814 84.9563 10.9316 84.9563 11.636V14.2563H85.8217V11.4187C85.8217 10.3762 85.2824 9.78849 84.3043 9.78849C83.6442 9.78849 83.2094 10.0823 82.9961 10.5694H82.9317V9.87302H82.0985V14.2563Z"
      fill="#222222"
    />
    <path
      d="M89.8074 8.78222V9.89314H89.111V10.6217H89.8074V13.0971C89.8074 13.9424 90.1898 14.2805 91.1518 14.2805C91.3208 14.2805 91.4818 14.2604 91.6227 14.2362V13.5157C91.502 13.5278 91.4255 13.5359 91.2926 13.5359C90.862 13.5359 90.6728 13.3346 90.6728 12.8757V10.6217H91.6227V9.89314H90.6728V8.78222H89.8074Z"
      fill="#222222"
    />
    <path
      d="M92.7843 14.2563H93.6497V11.6964C93.6497 11.0322 94.0442 10.5855 94.7526 10.5855C95.3644 10.5855 95.6905 10.9397 95.6905 11.64V14.2563H96.5559V11.4267C96.5559 10.3842 95.9803 9.79252 95.0465 9.79252C94.3863 9.79252 93.9235 10.0863 93.7101 10.5774H93.6417V8.16235H92.7843V14.2563Z"
      fill="#222222"
    />
    <path
      d="M99.6777 10.5251C100.318 10.5251 100.736 10.9719 100.756 11.6602H98.5426C98.591 10.9759 99.0377 10.5251 99.6777 10.5251ZM100.752 13.073C100.591 13.4151 100.233 13.6043 99.7099 13.6043C99.0176 13.6043 98.5708 13.1172 98.5426 12.3485V12.3002H101.638V11.9983C101.638 10.6217 100.901 9.78849 99.6817 9.78849C98.446 9.78849 97.6612 10.678 97.6612 12.0788C97.6612 13.4795 98.4299 14.3409 99.6858 14.3409C100.688 14.3409 101.388 13.8579 101.586 13.073H100.752Z"
      fill="#222222"
    />
    <path
      d="M24.0186 19.688C24.0396 18.0578 24.9151 16.5152 26.304 15.6613C25.4278 14.41 23.9602 13.6166 22.4333 13.5688C20.8048 13.3978 19.226 14.5433 18.3961 14.5433C17.5502 14.5433 16.2724 13.5858 14.8965 13.6141C13.1031 13.672 11.4312 14.6916 10.5586 16.2596C8.68302 19.5069 10.0821 24.2794 11.8787 26.9043C12.7777 28.1896 13.8282 29.6254 15.2029 29.5745C16.5481 29.5187 17.0505 28.7167 18.6742 28.7167C20.2829 28.7167 20.7543 29.5745 22.1568 29.5421C23.6003 29.5187 24.5097 28.2511 25.3771 26.9536C26.023 26.0377 26.52 25.0255 26.8497 23.9544C25.1534 23.2369 24.0206 21.5298 24.0186 19.688Z"
      fill="#222222"
    />
    <path
      d="M21.3694 11.8425C22.1564 10.8977 22.5441 9.68334 22.4502 8.45728C21.2478 8.58356 20.1372 9.15824 19.3395 10.0668C18.5595 10.9545 18.1536 12.1475 18.2303 13.3267C19.4332 13.3391 20.6156 12.78 21.3694 11.8425Z"
      fill="#222222"
    />
    <rect
      x="0.404088"
      y="0.404088"
      width="115.569"
      height="37.9843"
      rx="4.59591"
      stroke="#222222"
      strokeWidth="0.808176"
    />
  </svg>
);

export default Icon;
