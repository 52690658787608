const Icon = ({ width = "18", height = "18", color = "#222222", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 25" fill="none" {...props}>
    <path
      d="M1 2.15761C1 1.51828 1.51702 1 2.1548 1H18.0164C18.6542 1 19.1712 1.51828 19.1712 2.1576V10.0623C19.1712 10.352 18.9369 10.5868 18.648 10.5868C18.359 10.5868 18.1248 10.352 18.1248 10.0623V2.1576C18.1248 2.0976 18.0762 2.04895 18.0164 2.04895H2.1548C2.09494 2.04895 2.04641 2.0976 2.04641 2.15761V20.9188C2.04641 20.9788 2.09494 21.0275 2.1548 21.0275H12.0244C12.3134 21.0275 12.5476 21.2623 12.5476 21.5519C12.5476 21.8416 12.3134 22.0764 12.0244 22.0764H2.1548C1.51702 22.0764 1 21.5581 1 20.9188V2.15761Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3661 20.4643C23.0058 19.6332 23.3863 18.5913 23.3863 17.4603C23.3863 14.7418 21.1879 12.538 18.476 12.538C15.7641 12.538 13.5656 14.7418 13.5656 17.4603C13.5656 20.1788 15.7641 22.3826 18.476 22.3826C19.6043 22.3826 20.6437 22.0011 21.4729 21.3597L23.9218 23.8146C24.1685 24.0618 24.5684 24.0618 24.815 23.8146C25.0617 23.5673 25.0617 23.1664 24.815 22.9192L22.3661 20.4643ZM18.476 21.1163C20.4902 21.1163 22.1231 19.4795 22.1231 17.4603C22.1231 15.4412 20.4902 13.8043 18.476 13.8043C16.4617 13.8043 14.8288 15.4412 14.8288 17.4603C14.8288 19.4795 16.4617 21.1163 18.476 21.1163Z"
      fill={color}
    />
    <path
      d="M4.94812 5.57772C4.65917 5.57772 4.42492 5.81253 4.42492 6.10219C4.42492 6.39185 4.65917 6.62667 4.94812 6.62667H14.0813C14.3703 6.62667 14.6045 6.39185 14.6045 6.10219C14.6045 5.81253 14.3703 5.57772 14.0813 5.57772H4.94812Z"
      fill={color}
    />
    <path
      d="M4.94812 10.4415C4.65917 10.4415 4.42492 10.6763 4.42492 10.966C4.42492 11.2557 4.65917 11.4905 4.94812 11.4905H11.798C12.087 11.4905 12.3212 11.2557 12.3212 10.966C12.3212 10.6763 12.087 10.4415 11.798 10.4415H4.94812Z"
      fill={color}
    />
    <path
      d="M4.94812 15.3053C4.65917 15.3053 4.42492 15.5402 4.42492 15.8298C4.42492 16.1195 4.65917 16.3543 4.94812 16.3543H8.37308C8.66204 16.3543 8.89628 16.1195 8.89628 15.8298C8.89628 15.5402 8.66204 15.3053 8.37308 15.3053H4.94812Z"
      fill={color}
    />
    <path
      d="M1 2.15761C1 1.51828 1.51702 1 2.1548 1H18.0164C18.6542 1 19.1712 1.51828 19.1712 2.1576V10.0623C19.1712 10.352 18.9369 10.5868 18.648 10.5868C18.359 10.5868 18.1248 10.352 18.1248 10.0623V2.1576C18.1248 2.0976 18.0762 2.04895 18.0164 2.04895H2.1548C2.09494 2.04895 2.04641 2.0976 2.04641 2.15761V20.9188C2.04641 20.9788 2.09494 21.0275 2.1548 21.0275H12.0244C12.3134 21.0275 12.5476 21.2623 12.5476 21.5519C12.5476 21.8416 12.3134 22.0764 12.0244 22.0764H2.1548C1.51702 22.0764 1 21.5581 1 20.9188V2.15761Z"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3661 20.4643C23.0058 19.6332 23.3863 18.5913 23.3863 17.4603C23.3863 14.7418 21.1879 12.538 18.476 12.538C15.7641 12.538 13.5656 14.7418 13.5656 17.4603C13.5656 20.1788 15.7641 22.3826 18.476 22.3826C19.6043 22.3826 20.6437 22.0011 21.4729 21.3597L23.9218 23.8146C24.1685 24.0618 24.5684 24.0618 24.815 23.8146C25.0617 23.5673 25.0617 23.1664 24.815 22.9192L22.3661 20.4643ZM18.476 21.1163C20.4902 21.1163 22.1231 19.4795 22.1231 17.4603C22.1231 15.4412 20.4902 13.8043 18.476 13.8043C16.4617 13.8043 14.8288 15.4412 14.8288 17.4603C14.8288 19.4795 16.4617 21.1163 18.476 21.1163Z"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.94812 5.57772C4.65917 5.57772 4.42492 5.81253 4.42492 6.10219C4.42492 6.39185 4.65917 6.62667 4.94812 6.62667H14.0813C14.3703 6.62667 14.6045 6.39185 14.6045 6.10219C14.6045 5.81253 14.3703 5.57772 14.0813 5.57772H4.94812Z"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.94812 10.4415C4.65917 10.4415 4.42492 10.6763 4.42492 10.966C4.42492 11.2557 4.65917 11.4905 4.94812 11.4905H11.798C12.087 11.4905 12.3212 11.2557 12.3212 10.966C12.3212 10.6763 12.087 10.4415 11.798 10.4415H4.94812Z"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.94812 15.3053C4.65917 15.3053 4.42492 15.5402 4.42492 15.8298C4.42492 16.1195 4.65917 16.3543 4.94812 16.3543H8.37308C8.66204 16.3543 8.89628 16.1195 8.89628 15.8298C8.89628 15.5402 8.66204 15.3053 8.37308 15.3053H4.94812Z"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
