import moment from "moment";
import { getSortIndex } from "../utils/sort";
import { getLanguageStrings } from "../utils/lang";
import getByMonth from "./utils/getByMonth";
import { EVENTS } from "../components/notificationItem";
import getByDate from "./utils/getByDate";
import { MOCK_NOTIFICATIONS } from "../utils/lang/mock/notifications";
import { USE_MOCK_NOTIFICATIONS } from "../utils/configs";
import NativeAppClient from "../services/nativeAppClient";

// global state
export const selectorUserToken = (state) => state.globalState?.userToken;

export const selectorUserOnBoard = (state) => {
  const welcomeGiftCard = selectorWelcomeCard(state);
  const threeDaysAgo = moment().subtract(3, "days");
  const myUser = state?.globalState?.myUser;
  const userToken = state.globalState?.userToken;
  const emailAccounts = myUser?.emailAccounts;
  const interests = myUser?.interests;
  const enableEmailNotifications = myUser?.enable_email_notifications;
  const enablePushNotifications = myUser?.enable_push_notifications;
  const createdAt = myUser ? moment(myUser.created_at) : null;

  if (myUser) {
    if (createdAt?.isAfter(threeDaysAgo)) {
      if (NativeAppClient.isInNativeApp()) {
        return !!(
          userToken &&
          ((interests && interests?.length > 0) || welcomeGiftCard) &&
          emailAccounts?.length > 0 &&
          emailAccounts &&
          enableEmailNotifications !== null &&
          enablePushNotifications !== null
        );
      } else {
        return !!(
          userToken &&
          ((interests && interests?.length > 0) || welcomeGiftCard) &&
          emailAccounts &&
          emailAccounts?.length > 0 &&
          enableEmailNotifications !== null
        );
      }
    } else {
      if (NativeAppClient.isInNativeApp()) {
        return !!(
          userToken &&
          emailAccounts?.length > 0 &&
          enableEmailNotifications !== null &&
          enablePushNotifications !== null
        );
      } else {
        return !!(userToken && emailAccounts?.length > 0 && enableEmailNotifications !== null);
      }
    }
  } else {
    return false;
  }
};

export const selectorIsAuthed = (state) => !!selectorUserToken(state);
export const selectorIsAppStateLoaded = (state) => !!state.globalState?.appStateLoaded;
export const selectorRegion = (state) => !!state?.globalState?.region;
export const selectorTrafficSource = (state) => state?.globalState?.trafficSource;
export const selectorMyUser = (state) => state?.globalState?.myUser;
export const selectorTjommiDeviceID = (state) => state?.globalState?.tjommiDeviceID;
export const selectorInterestList = (state) => state?.globalState?.interestList;
// global state- native app
export const selectorIsAppMode = (state) => !!state?.globalState?.appMode;
export const selectorAppOsPlatform = (state) => state?.globalState?.appOsPlatform;
export const selectorAppTopBarHeight = (state) => state?.globalState?.appTopBarHeight;
export const selectorAppLocale = (state) => state?.globalState?.appLocale;
export const selectorAppPushNotificationToken = (state) => state?.globalState?.appPushNotificationToken;
export const selectorAppPushNotificationTokenDate = (state) => state?.globalState?.appPushNotificationTokenDate;
export const selectorAppShouldAskPushPermission = (state) => {
  // only ask in app mode
  const isAppMode = selectorIsAppMode(state);
  if (!isAppMode) return false;
  // only ask once
  const hasAsked = !!selectorAppPushNotificationTokenDate(state);
  return !hasAsked;
};

//language
export const selectorLanguage = (state) => state?.globalState?.language;
export const selectorLanguageStrings = (state, pageId) => getLanguageStrings(selectorLanguage(state), pageId);

// Ratings entities
export const selectorRatings = (state) => Object.values(state.entities?.ratings || {});
export const selectorRatingsByStoreId = (state, id) => state.entities?.ratings?.[id];

// Discoveries entities
export const selectorDiscoveries = (state) => state.globalState?.discoveries || {};
export const selectorAnyDiscoveriesById = (state, id, type) =>
  type === "discoveries"
    ? state.globalState?.discoveries?.data.find((item) => item.id === id)
    : type === "discoveryPurchases"
    ? state.globalState?.discoveryPurchases?.data.find((item) => item.id === id)
    : state.globalState?.discoveryVouchers?.data.find((item) => item.id === id);

// DiscoveyPurchases entities
export const selectorDiscoveryPurchases = (state) => state.globalState?.discoveryPurchases || {};

// DiscoveyVouchers entities
export const selectorDiscoveryVouchers = (state) => state.globalState?.discoveryVouchers || {};

// parcels entities
const selectorParcelsArray = (state) => Object.values(state.entities?.parcels || {});
export const selectorParceldById = (state, parcelID) => state?.entities?.parcels?.[parcelID] || null;
export const selectorParcels = (state) =>
  selectorParcelsArray(state).sort((a, b) => {
    a = a.created_at;
    b = b.created_at;
    return getSortIndex(a, b);
  }) || [];
export const selectorParcelsByDate = (state) => getByDate(selectorParcels(state), ["purchase_time", "created_at"]);

// giftCards entities
export const selectorGiftCardsArray = (state) => Object.values(state.entities?.giftcards || {});
export const selectorGiftCardById = (state, giftCardId) => state?.entities?.giftcards?.[giftCardId] || null;
export const selectorGiftCards = (state) =>
  selectorGiftCardsArray(state)
    .filter((item) => item.type !== "welcome")
    .sort((a, b) => {
      a = a.received_at;
      b = b.received_at;
      return getSortIndex(a, b);
    }) || [];
export const selectorAllTypeGiftCards = (state) =>
  selectorGiftCardsArray(state).sort((a, b) => {
    a = a.received_at;
    b = b.received_at;
    return getSortIndex(a, b);
  }) || [];
export const selectorGiftCardsByDate = (state) => getByDate(selectorGiftCards(state), ["received_at", "created_at"]);
export const selectorWelcomeCard = (state) =>
  selectorGiftCardsArray(state).filter((item) => item.type === "welcome")[0];
// receipts entities
const selectorReceiptsArray = (state) => Object.values(state.entities?.receipts || {});
export const selectorReceiptById = (state, receiptId) => state?.entities?.receipts?.[receiptId] || null;
export const selectorReceipts = (state) =>
  selectorReceiptsArray(state).sort((a, b) => {
    a = a.purchase_time || a.created_at;
    b = b.purchase_time || b.created_at;
    return getSortIndex(a, b);
  }) || [];
export const selectorReceiptsByMonth = (state) => getByMonth(selectorReceipts(state), ["purchase_time", "created_at"]);
export const selectorReceiptsByDate = (state) => getByDate(selectorReceipts(state), ["purchase_time", "created_at"]);

// purchase entities
export const selectorPurchaseItems = (state, option = null) => {
  const todayTime = new Date().getTime();
  let results = Object.values(state?.entities?.purchases || {})
    .map((purchase) =>
      purchase.items.map((item) => {
        const id = item.receipt_item_id;
        const date = purchase.date;
        const currency = purchase.currency;
        const product = item.name;
        const isExpired = todayTime >= new Date(purchase.expire_date).getTime();
        const daysLeft = isExpired ? 0 : moment(purchase.expire_date).diff(moment(), "days");
        const quantity = item.quantity;
        const purchaseId = purchase.id;
        const expireDate = purchase.expire_date;
        const purchasePrice = item.purchase_price;

        // If this item has a refund, check all the "refund entries" then
        // grab the largest refund (this is a legacy thing related to stacking refunds.
        // We no longer do that, but it is still modelled that way in the purchases API
        const refund = item.has_refunds ? item.refunds[0] : null;
        const refunded = refund?.refunded_incl_fee || 0;

        const newPrice = refund ? refund.new_price : 0;
        const diff = refund?.refunded_excl_fee || 0;
        const commission = refund?.fee || 0;
        const totalSavings = refunded;

        return {
          id,
          date,
          currency,
          product,
          daysLeft,
          quantity,
          purchaseId,
          expireDate,
          purchasePrice,
          refunded,
          newPrice,
          diff,
          commission,
          totalSavings,
          isExpired,
        };
      })
    )
    .reduce((prev, cur) => prev.concat(cur), [])
    .sort((aItem, bItem) => {
      let a, b;

      // searching
      a = aItem.daysLeft > 0 ? 0 : 1;
      b = bItem.daysLeft > 0 ? 0 : 1;
      if (a === 0 && b === 0) return getSortIndex(a, b);
      if (a === 0 || b === 0) return a - b;

      // refunded
      a = !!aItem.refunded ? 0 : 1;
      b = !!bItem.refunded ? 0 : 1;
      if (a === 0 && b === 0) return getSortIndex(a, b);
      if (a === 0 || b === 0) return a - b;

      // date
      a = aItem.date;
      b = bItem.date;
      return getSortIndex(a, b);
    });

  switch (option) {
    case "expired":
      return results.filter((purchase) => purchase.daysLeft === 0);
    case "active":
      return results.filter((purchase) => purchase.daysLeft > 0);
    case "refunds":
      return results.filter((purchase) => purchase.refunded > 0);
    default:
      return results;
  }
};
export const selectorPurchaseItemsByMonth = (state, option) =>
  getByMonth(selectorPurchaseItems(state, option), ["date"]);
export const selectorPurchaseItemsByDate = (state, option) =>
  getByDate(
    selectorPurchaseItems(state, option).filter((pi) => pi.date),
    ["date"]
  );
export const selectorPurchaseTotalSavings = (state) =>
  selectorPurchaseItems(state).reduce((value, item) => value + item.totalSavings, 0);
export const selectorPurchaseItemById = (state, id) => selectorPurchaseItems(state).find((item) => item.id === id);
export const selectorPurchaseById = (state, id) => state?.entities?.purchases?.[id];

// contact list
export const selectContactList = (state) => {
  return Object.values(state?.entities?.contacts || {}).sort((b, a) => getSortIndex(a.name, b.name));
};

export const selectReceivedMedals = (state) => {
  return Object.values(state?.entities?.medals || {}).filter((m) => m.is_received);
};

// notifications
const selectorNotifications = (state) =>
  (USE_MOCK_NOTIFICATIONS ? MOCK_NOTIFICATIONS : Object.values(state?.entities?.notifications || {})).sort((a, b) =>
    getSortIndex(a.created_at, b.created_at)
  );

const eventTypeWhitelist = Object.values(EVENTS);

export const selectorNotificationsArray = (state) =>
  USE_MOCK_NOTIFICATIONS ? MOCK_NOTIFICATIONS : Object.values(state?.entities?.notifications || {});

export const selectorNotificationsFiltered = (state) =>
  selectorNotifications(state).filter((notification) => eventTypeWhitelist.includes(notification.data?.event_type));
export const selectorHasNotifications = (state) =>
  !!selectorNotificationsArray(state).filter(
    (notification) => notification?.data?.event_type !== "InitialScanCompleted"
  ).length;
export const selectorUnreadNotifications = (state) =>
  selectorNotificationsFiltered(state).filter((n) => n.seen === false);
export const selectorReadNotifications = (state) => selectorNotificationsFiltered(state).filter((n) => n.seen === true);
export const selectorNotificationsByMonth = (state) => getByMonth(selectorNotificationsFiltered(state), ["created_at"]);
export const selectorNotificationsByDay = (state) => getByDate(selectorNotificationsFiltered(state), ["created_at"]);
export const selectorNotificationsById = (state, id) => selectorNotificationsFiltered(state).find((n) => n.id === id);
export const selectorNotificationsByReceiptId = (state, id) => {
  return selectorNotificationsFiltered(state)
    .filter((notification) => notification.data.receipt_id === id)
    .sort((a, b) => getSortIndex(a.created_at, b.created_at));
};
export const selectorNotificationsByPurchaseId = (state, id) => {
  return selectorNotificationsFiltered(state)
    .filter((notification) => notification.data.purchase_id === id)
    .sort((a, b) => getSortIndex(a.created_at, b.created_at));
};
