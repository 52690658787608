import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorParcelsByDate } from "../../state/selectors";
import ListingPage from "../listingPage";
import ListItem from "../../components/listItem";
import Outline from "../../components/layouts/outline";
import routes from "../../utils/routes";
import { getParcels } from "../../state/actionCreators";

const ParcelsAll = ({ parclesByDate, getParcels, strs, strsParcel }) => {
  return (
    <ListingPage
      headline={strs.parcels.title}
      items={parclesByDate}
      backPath={routes.overview.subroutes.parcels.path}
      emptyState={<div>{strs.emptyParcels}</div>}
      onRefresh={getParcels}
      renderItem={(date) => (
        <Outline label={date.label} key={date.label}>
          {date.items.map((item) => (
            <ListItem
              key={item.id}
              image={item?.display?.logo}
              text={
                <>
                  <div className="listItem__text--heading">{item?.display?.name || strs.unknownStore}</div>
                  {item?.sender?.name && (
                    <div className="listItem__text--muted">
                      {strsParcel.parcelFrom} {item?.sender?.name}
                    </div>
                  )}
                </>
              }
              route={`/overview/parcels/${item.id}?redirect=${routes.parcelsAll.path}`}
            />
          ))}
        </Outline>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    parclesByDate: selectorParcelsByDate(state),
    strsParcel: selectorLanguageStrings(state, "parcel"),
    strs: selectorLanguageStrings(state, "overview"),
  };
};

const mapDispatchToProps = { getParcels };

export default connect(mapStateToProps, mapDispatchToProps)(ParcelsAll);
