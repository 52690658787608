import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";
import { withRouter } from "react-router-dom";

const defaultRows = 4;

class TextArea extends React.Component {
  state = {
    value: "",
    rows: this.props.initialRows || defaultRows,
  };

  handleChange = (event) => {
    if (this.props.disabled) return;
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = this.props.initialRows || defaultRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    this.setState(
      {
        value: event.target.value,
        rows: currentRows,
      },
      () => {
        if (this.props.onChange) this.props.onChange(this.state.value);
      }
    );
  };

  renderIndicator() {
    return (
      <span
        className={classNames({
          "textAreaIndicator": true,
          "textAreaIndicator--error": this.props.maxLength && this.state.value.length > this.props.maxLength,
        })}
      >
        {this.state.value.length}
        {this.props.maxLength && "/" + this.props.maxLength}
      </span>
    );
  }

  render() {
    return (
      <div className={"textArea"}>
        <div className={"textAreaInner"}>
          <textarea
            placeholder={this.props.label}
            className={"textAreaInnerInput"}
            value={this.state.value}
            onChange={this.handleChange}
            disabled={this.props.disabled}
            rows={this.state.rows}
          ></textarea>
        </div>

        {this.props.showIndicator && this.renderIndicator()}
      </div>
    );
  }
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  initialRows: PropTypes.number,
  onChange: PropTypes.func,
  showIndicator: PropTypes.bool,
  maxLength: PropTypes.number,
};

TextArea.defaultProps = {
  disabled: false,
  initialRows: defaultRows,
  showIndicator: false,
};

export default withRouter(TextArea);
