import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { selectorLanguageStrings, selectorReceipts, selectorWelcomeCard } from "../../state/selectors";
import { formatCurrency, formatDate } from "../../utils/formatter";
import routes from "../../utils/routes";
import Outline from "../../components/layouts/outline";
import ListItem from "../../components/listItem";
import StoreLogo from "../../components/storeLogo";
import InfoBox, { InfoBoxes } from "../../components/infoBox";
import SpinnerBox from "../../components/spinnerBox";
import "./styles.scss";
import IMAGE1 from "../../assets/referralImage.png";
import Hide from "../../components/hide/hide";
import WelcomeGiftCard from "../../components/welcomeGiftCard";

const Receipts = ({ strs, strsReferral, latestReceipts, allReceipts, history, latestWelcomeGiftcard }) => {
  return (
    <div>
      {latestReceipts && (
        <div className="receiptsState">
          <Outline>
            <ListItem
              image={<SpinnerBox animation={latestReceipts.length > 0} />}
              text={latestReceipts.length === 0 ? strs.emptyStateReceipts : strs.ReceiptsFoundState}
              title={latestReceipts.length === 0 ? strs.emptyStateReceiptsHeadline : strs.ReceiptsFoundStateHeadline}
            />
          </Outline>
        </div>
      )}

      {latestWelcomeGiftcard && latestWelcomeGiftcard.used_at === null ? (
        <Outline label={strs.toDoLabel} background={false} padding={false}>
          <WelcomeGiftCard data={latestWelcomeGiftcard} />
        </Outline>
      ) : null}
      {latestReceipts.length > 0 && (
        <Outline label={strs.latest}>
          {latestReceipts.map((receipt) => (
            <ListItem
              key={"receipt" + receipt.id}
              image={<StoreLogo domain={receipt?.domain} />}
              text={
                <>
                  <div className="listItem__text--heading">{receipt?.domain?.name || strs.unknownStore}</div>
                  <div className="listItem__text--muted">
                    {receipt?.purchase_time ? formatDate(receipt.purchase_time, "date-year") : strs.unknownDate}
                  </div>
                </>
              }
              rightText={formatCurrency(receipt.total_amount, receipt.currency)}
              route={`/overview/receipts/${receipt.id}`}
            />
          ))}

          {allReceipts.length > 5 && (
            <Link className="viewMoreLink" to={routes.receiptsAll.path}>
              {strs.viewMore}
            </Link>
          )}
        </Outline>
      )}

      <Hide on={"desktop"}>
        <Outline label={strs.toDoLabel} padding={false} background={false}>
          <InfoBoxes>
            <InfoBox
              backgroundColor={"#EB6F93"}
              image={IMAGE1}
              text={strsReferral.videoTitle}
              position="above"
              onClick={() => history.push(routes.referral.path)}
              size="large"
            />
          </InfoBoxes>
        </Outline>
      </Hide>
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "overview"),
  strsReferral: selectorLanguageStrings(state, "referral"),
  latestReceipts: selectorReceipts(state).slice(0, 5),
  allReceipts: selectorReceipts(state),
  latestWelcomeGiftcard: selectorWelcomeCard(state),
});

export default withRouter(connect(mapStateToProps)(Receipts));
