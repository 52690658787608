const Icon = ({ width = "24", height = "24", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g mask="url(#mask0_613_2602)">
      <path
        d="M2.5 21.5V20H4.5V10.5H2.5V9.275L12 2.625L21.5 9.275V10.5H19.5V20H21.5V21.5H2.5ZM8.3 17.75H9.8V13.5L12 16.775L14.2 13.5V17.75H15.7V11.25H14.125L12 14.45L9.875 11.25H8.3V17.75ZM18 20V8.65L12 4.45L6 8.65V20H18Z"
        fill="#222222"
      />
    </g>
  </svg>
);

export default Icon;
