// Landing page
import ViewAbout from "../views/about";
import ViewTeam from "../views/team";

import ViewLandingPage from "../views/landingpage";
import ViewMerchantPage from "../views/merchant";
import ViewFaqPage from "../views/faq";
import ViewHowworks from "../views/howitworks";
import ViewPartners from "../views/partners";

// Authentication
import ViewAdmin from "../views/admin";
import ViewSignout from "../views/signout";
import ViewOnboarding from "../views/onboarding";
import ViewWaitlist from "../views/waitlist";

// Legal
import ViewPrivacy from "../views/privacy";
import ViewTerms from "../views/terms";

// Main screens
import ViewOverview from "../views/overview";
import RefundsAll from "../views/refundsAll";
import ReceiptsAll from "../views/receiptsAll";
import ParcelsAll from "../views/parcelsAll";
import GiftCardsAll from "../views/giftCardsAll";
import Expired from "../views/expired";
import ViewNotifications from "../views/notifications";
import Discoveries from "../views/discoveries";
import Rating from "../views/rating";
import ViewReferral from "../views/referral";

// Misc

import ViewLanguage from "../views/language";
import ViewError from "../views/error";
import ViewSecurityReport from "../views/security-report";

// profile
import ViewProfile from "../views/profile";
import ViewProfileEmails from "../views/profile/emails";
import ViewProfileInfo from "../views/profile/info";
import ViewProfileInterests from "../views/profile/interests";
import ViewProfileRefunds from "../views/profile/refunds";
import ViewProfileBank from "../views/profile/bank";
import ViewProfileCard from "../views/profile/card";
import ViewProfileConfirmation from "../views/profile/confirmation";
import DeleteProfile from "../views/profile/delete";
import Support from "../views/profile/support";
import ChatSuccess from "../views/profile/support/chatSuccess";
import Chat from "../views/profile/support/chat";
import ViewDebug from "../views/debug";
import DeeplinkRedirect from "../views/deeplink";

const routes = {
  // protected routes
  receiptsAll: {
    requiresAuthorization: true,
    path: "/overview/receipts/all",
    component: ReceiptsAll,
  },
  parcelsAll: {
    requiresAuthorization: true,
    path: "/overview/parcels/all",
    component: ParcelsAll,
  },
  giftCardsAll: {
    requiresAuthorization: true,
    path: "/overview/giftcards/all",
    component: GiftCardsAll,
  },
  refundsAll: {
    requiresAuthorization: true,
    path: "/overview/refunds/all",
    component: RefundsAll,
  },
  expired: {
    requiresAuthorization: true,
    path: "/overview/refunds/expired",
    component: Expired,
  },
  overview: {
    requiresAuthorization: true,
    path: "/overview",
    component: ViewOverview,
    subroutes: {
      refunds: { path: "/overview/refunds" },
      receipts: { path: "/overview/receipts" },
      parcels: { path: "/overview/parcels" },
      giftcards: { path: "/overview/giftcards" },
    },
  },
  notifications: {
    requiresAuthorization: true,
    path: "/notifications",
    component: ViewNotifications,
  },

  // protected discover routes
  discoveries: {
    requiresAuthorization: true,
    path: "/discover",
    component: Discoveries,
    exact: true,
  },
  discover: {
    requiresAuthorization: true,
    path: "/discover/:id",
    component: Rating,
  },

  // protected support routes
  supportSuccess: {
    requiresAuthorization: true,
    path: "/support/success",
    component: ChatSuccess,
  },
  supportChat: {
    requiresAuthorization: true,
    path: "/support/chat",
    component: Chat,
  },
  support: {
    requiresAuthorization: true,
    path: "/support",
    component: Support,
  },

  // protected profile routes
  profile: {
    requiresAuthorization: true,
    path: "/profile",
    exact: true,
    component: ViewProfile,
  },
  profileEmails: {
    requiresAuthorization: true,
    path: "/profile/emails",
    component: ViewProfileEmails,
  },

  profileInformation: {
    requiresAuthorization: true,
    path: "/profile/info",
    component: ViewProfileInfo,
  },

  profileInterests: {
    requiresAuthorization: true,
    path: "/profile/interests",
    component: ViewProfileInterests,
  },

  profileRefunds: {
    requiresAuthorization: true,
    path: "/profile/refunds",
    component: ViewProfileRefunds,
  },
  profileBank: {
    requiresAuthorization: true,
    path: "/profile/bank",
    component: ViewProfileBank,
  },
  profileCard: {
    requiresAuthorization: true,
    path: "/profile/card",
    component: ViewProfileCard,
    subroutes: {
      verify: { path: "/profile/card/verify" },
    },
  },
  profileConfirmation: {
    requiresAuthorization: true,
    path: "/profile/confirmation/",
    subroutes: {
      emailDisconnected: { path: "/profile/confirmation/emailDisconnected" },
      emailConnected: { path: "/profile/confirmation/emailConnected" },
    },
    component: ViewProfileConfirmation,
  },

  profileAccountDelete: {
    requiresAuthorization: true,
    path: "/profile/delete/:screen",
    subroutes: {
      one: { path: "/profile/delete/1" },
      two: { path: "/profile/delete/2" },
      three: { path: "/profile/delete/3" },
    },
    component: DeleteProfile,
  },

  debug: {
    requiresAuthorization: false,
    path: "/debug",
    title: "Tjommi - Debug",
    component: ViewDebug,
  },

  referral: {
    requiresAuthorization: true,
    path: "/referral",
    component: ViewReferral,
  },

  // public routes
  signout: {
    path: "/signout",
    component: ViewSignout,
  },
  onboarding: {
    path: "/onboarding",
    subroutes: {
      one: { path: "/onboarding/1" },
      two: { path: "/onboarding/2" },
      three: { path: "/onboarding/3" },
      four: { path: "/onboarding/4" },
      five: { path: "/onboarding/5" },
    },
    component: ViewOnboarding,
  },
  reset: {
    path: "/reset",
    component: ViewOnboarding,
  },
  faq: {
    path: "/faq",
    title: "Tjommi - Frequently asked questions",
    component: ViewFaqPage,
  },

  howitworks: {
    path: "/howitworks",
    title: "Tjommi - How it works",
    component: ViewHowworks,
  },
  partners: {
    path: "/partners",
    title: "Tjommi - Partners",
    component: ViewPartners,
  },

  about: {
    path: "/about",
    title: "Tjommi - About us",
    component: ViewAbout,
  },
  team: {
    path: "/team",
    title: "Tjommi - The team",
    component: ViewTeam,
  },
  error: {
    path: "/error",
    title: "Tjommi - Technical Error",
    component: ViewError,
    nextRedirect: "/overview",
  },
  privacy: {
    path: "/privacy",
    title: "Tjommi - Privacy Policy",
    component: ViewPrivacy,
  },
  terms: {
    path: "/terms",
    title: "Tjommi - Terms of Use",
    component: ViewTerms,
  },
  securityReport: {
    path: "/security-report",
    title: "Tjommi - Report a security problem",
    component: ViewSecurityReport,
  },
  language: {
    path: "/language",
    title: "Tjommi - Select language",
    component: ViewLanguage,
  },
  admin: {
    path: "/admin",
    title: "Tjommi - Admin",
    component: ViewAdmin,
    nextRedirect: "/overview",
  },
  index: {
    isAppModeAllowed: false,
    path: "/",
    exact: true,
    component: ViewLandingPage,
  },
  waitlist: {
    requiresAuthorization: false,
    path: "/waitlist",
    component: ViewWaitlist,
  },
  merchant: {
    requiresAuthorization: false,
    path: "/merchant",
    component: ViewMerchantPage,
  },
  // legal: {
  //   requiresAuthorization: false,
  //   path: "/legal",
  //   component: ViewLegalPage,
  // },
  dynamicRedirect: {
    requiresAuthorization: false,
    path: "/r/:path", // Define the route with the dynamic path parameter
    component: DeeplinkRedirect,
  },
};

export default routes;
