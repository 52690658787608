const Icon = ({ width = "17", height = "18", color = "#545454", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="8.5" transform="matrix(1 0 0 -1 0 17.5)" fill="white" />
    <path
      d="M4.96342 9.15625L5.79439 8.30824L7.95064 10.4347L12.5146 5.88778L13.3583 6.7358L7.95064 12.1222L4.96342 9.15625Z"
      fill={color}
    />
  </svg>
);

export default Icon;
