import React from "react";
import { connect } from "react-redux";
import { selectorIsAppMode, selectorLanguageStrings } from "../../state/selectors";
import MainMenu from "../../components/menus/mainMenu";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import getQuery from "../../utils/getQuery";
import Footer from "../../components/layouts/footer";
import "./styles.scss";

class viewTerms extends React.Component {
  render() {
    const redirect = getQuery()?.redirect;
    return (
      <div className="viewTerms">
        {!this.props.isAppMode && <MainMenu dark={true} />}
        <MainContentLayout>
          {redirect && <HeaderLayout left={{ type: "arrowLeft", path: redirect }} />}
          <h1 className="headline">{this.props.strs.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: this.props.strs.text.join("") }} />
        </MainContentLayout>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "terms"),
  isAppMode: selectorIsAppMode(state),
});

export default connect(mapStateToProps)(viewTerms);
