import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  selectorAppOsPlatform,
  selectorLanguageStrings,
  selectorNotificationsByReceiptId,
  selectorRatingsByStoreId,
  selectorReceiptById,
  selectorReceipts,
} from "../../state/selectors";
import { formatCurrency, formatDate } from "../../utils/formatter";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import Outline from "../../components/layouts/outline";
import NotificationItem from "../../components/notificationItem";
import DetailLine from "../../components/detailLine";
import placeholder from "./assets/placeholder.svg";
import "./styles.scss";
import RatingFlatCard from "../ratings/components/ratingFlatCard";
import Spacer from "../../components/spacer";
import getQuery from "../../utils/getQuery";
import className from "classnames";
import DeletedReceiptModal from "../../components/modals/deletedReceiptModal/DeletedReceiptModal";
import routes from "../../utils/routes";
import ArrowLeft from "../../components/icons/arrowMiniLeft";
import { deleteReceipt } from "../../state/actionCreators";

class ViewReceipts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteReceiptModal: false };
  }

  render() {
    if (!this.props.receipt) return null;
    const isImage = !!this.props.receipt?.asset_url?.match(/jpg|jpeg|png/i);
    const previewUrl = isImage ? this.props.receipt.asset_url : this.props.receipt.thumbnail_url || placeholder;
    const hasImage = this.props.receipt.thumbnail_url !== null;

    return (
      <div className="viewReceipt">
        <MainContentLayout center={true}>
          <HeaderLayout
            left={
              <div className="headerLayout--leftArrow" onClick={() => this.props.history.goBack()}>
                <ArrowLeft color="black" />
              </div>
            }
            roundBackArrow={true}
          />
          <h1 className="headline">{this.props.receipt?.domain?.name || this.props.strs.receiptLabel}</h1>
          <div className="label">{formatDate(this.props.receipt.purchase_time || this.props.receipt.created_at)}</div>

          <div className="viewReceipt__content">
            <div className="viewReceipt__content__firstRow">
              <Spacer />

              {this.props.notifications.length > 0 && (
                <Outline
                  label={this.props.strs.notificationLabel}
                  styles={{
                    marginTop: 0,
                  }}
                >
                  {this.props.notifications.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      redirect={`/overview/receipts/${this.props.receipt.id}`}
                    />
                  ))}
                </Outline>
              )}

              {this.props.storeRating && (
                <Outline
                  label={this.props.strs.ratingLabel}
                  padding={false}
                  styles={{
                    marginTop: 32,
                  }}
                >
                  <RatingFlatCard rating={this.props.storeRating} />
                </Outline>
              )}

              <Outline
                label={this.props.strs.detailsLabel}
                styles={{
                  marginTop: 32,
                }}
              >
                <DetailLine
                  label={this.props.strs.dateOfPurchase}
                  value={formatDate(
                    this.props.receipt.purchase_time || this.props.receipt.created_at,
                    "date-scandinavian"
                  )}
                />
                <DetailLine
                  label={this.props.strs.total}
                  value={formatCurrency(this.props.receipt.total_amount, this.props.receipt.currency)}
                />

                {this.props.receipt.order_reference && (
                  <DetailLine
                    label={this.props.strs.orderReference}
                    value={this.props.receipt.order_reference}
                    preventValueTextOverflow={true}
                  />
                )}
              </Outline>
              {this.props.receipt.receipt_items.length > 0 && (
                <Outline
                  label={this.props.strs.products}
                  styles={{
                    marginTop: 32,
                  }}
                >
                  {this.props.receipt.receipt_items.map((item) => (
                    <DetailLine
                      onClick={() => window.open(item.product.url)}
                      key={item.id}
                      label={`${item.quantity}x ${item.product.name}`}
                      value={formatCurrency(item.purchase_price, this.props.receipt.currency)}
                    />
                  ))}
                </Outline>
              )}
            </div>
            <div className="viewReceipt__content__secondRow">
              <Spacer />
              <div className="label">{this.props.strs.receiptLabel}</div>

              <div className={"images images--fullWidth"}>
                <div className={className("images__item")}>
                  <img
                    className={className("images__image", {
                      thumbnail: !hasImage,
                    })}
                    src={previewUrl}
                    alt=""
                  />
                </div>
              </div>

              <div className="receiptActions">
                <a
                  className="receiptActions__item"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ deleteReceiptModal: true });
                  }}
                  href="#/"
                >
                  {this.props.strs.delete}
                </a>
                <a
                  className="receiptActions__item"
                  target="_blank"
                  rel="noreferrer"
                  href={(this.props.isIOS ? "appOpenInUrl=" : "") + this.props.receipt.asset_url}
                >
                  {this.props.strs.view}
                </a>
              </div>
            </div>
          </div>

          <DeletedReceiptModal
            visible={this.state.deleteReceiptModal}
            closeBtn={() => this.setState({ deleteReceiptModal: false })}
            deleteBtn={() => {
              this.props.deleteReceipt(this.props.receipt.id).then(() => {
                this.props.history.push(routes.overview.subroutes.receipts.path);
                this.setState({ deleteReceiptModal: false });
              });
            }}
          />
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.location.pathname.match(/(\d+)/)?.[0]) || null;
  const receipt = selectorReceiptById(state, id);

  return {
    redirect: getQuery()?.redirect,
    receipt: receipt,
    notifications: selectorNotificationsByReceiptId(state, id),
    storeRating: selectorRatingsByStoreId(state, receipt?.domain?.id),
    isIOS: selectorAppOsPlatform(state) === "ios",
    strs: selectorLanguageStrings(state, "receipt"),
    receipts: selectorReceipts(state),
    language: state.globalState.language,
  };
};
const mapDispatchToProps = { deleteReceipt };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewReceipts));
