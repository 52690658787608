import React from "react";
import "./styles.scss";
import classNames from "classnames";

const Button = ({
  type,
  label,
  light,
  backgroundColor,
  icon,
  leftIcon,
  rightIcon,
  textColor,
  circle,
  onClick,
  borderLine,
  disabled = false,
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (type === "label") {
    } else {
      onClick();
    }
  };
  return (
    <div
      className={classNames({
        "newButton": true,
        "newButton_label": type === "label",
        "newButton_light": light,
        "newButton_circle": circle,
        "newButton--border": borderLine,
        "newButton--disabled": disabled,
      })}
      style={{ backgroundColor: backgroundColor || "", color: textColor || "" }}
      onClick={handleClick}
    >
      {leftIcon && icon}
      {label}
      {rightIcon && icon}
    </div>
  );
};

export default Button;
