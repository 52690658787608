export const initialise = (pixelId) => {
  function init(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }

  init(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
  window.fbq("init", pixelId);
};

export const pageView = () => {
  window.fbq("track", "PageView");
};

export const trackCustomEvent = (eventName, options) => {
  window.fbq("trackCustom", eventName, options);
};

export const trackConversion = () => {
  window.fbq("track", "CompleteRegistration");
};
