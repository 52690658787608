import React, { useState } from "react";
import MainMenu from "../../components/menus/mainMenu";
import Footer from "../../components/layouts/footer";
import SectionText from "../../components/newSectionText";
import ICON_REVIWS from "../../components/icons/reviews";
import PayoutGiftcard_IMAGE from "./assets/ratingsPayoutGiftcard.png";
import Hide from "../../components/hide/hide";
import Button from "../../components/inputs/newButton";
import Reviews from "../../components/reviews";
import FlipCards from "../../components/flipscards";
import Calendar_Image from "./assets/Calendar14days.png";
import PriceGuarantee_Image from "./assets/PriceGuarantee.png";
import SIIDER1 from "./assets/slider1.png";
import SIIDER2 from "./assets/slider2.png";
import SIIDER3 from "./assets/slider3.png";
import classNames from "classnames";
import Accordion from "../../components/accordion";
import { CHROME_EXTENSION_URL, GET_APP_URL } from "../../utils/configs";
import "./styles.scss";

const ViewHowWorks = () => {
  const flipcards = [
    {
      title1: "What is a price gurantee?",
      title2: "Price guarantee",
      body1: PriceGuarantee_Image,
      body2:
        "Some stores promise a refund if the price drops after purchase. This policy usually lasts between 14 and 50 days, but many customers don’t know when or where prices drop. We do.",
      btnLabel1: "Get the app",
      btnLabel2: "Get a refund",
      backGroundColor: "#FA779D",
      label: "Price guarantee",
      firstbtnOnclick: () => {
        window.open(GET_APP_URL);
      },
      secondbtnOnclick: () => {
        window.open(GET_APP_URL);
      },
    },
    {
      title1: "What is the 14-day right to return?",
      title2: "14 day return right law",
      btnLabel1: "Get the app",
      btnLabel2: "Add Chrome extension",
      backGroundColor: "#CBC3B9",
      label: "14 days return right",
      body1: Calendar_Image,
      body2:
        "When you shop online you have the right to return your product within 14 days. If a price drops in this period, most stores prefer to refund the difference than deal with a return.",
      firstbtnOnclick: () => {
        window.open(GET_APP_URL);
      },
      secondbtnOnclick: () => {
        window.open(CHROME_EXTENSION_URL);
      },
    },
  ];
  const reviews = [
    {
      rates: 5,
      subtitle:
        "I have had the app for six months and got money back 3 times now, on purchases where I had never checked the prices myself.",
      name: "Pauli Madsen",
      title: "Money back automatically",
    },
    {
      rates: 5,
      subtitle: "Got 200kr as a voucher againnom Tjommi without doing any myself",
      name: "larsrjne45",
      title: "Easily!",
    },
    {
      rates: 5,
      subtitle: "Finally an app that secures me the best price after purchase!",
      name: "Supernicenice",
      title: "No-brainer app.",
    },
    {
      rates: 5,
      subtitle: "All receipts where found, and Tjommi got a vocuher back one time. ALl-in -all works well 🙂",
      name: "MaxBroadbrigde",
      title: "Neat little app and kind support",
    },
    {
      rates: 4,
      subtitle:
        "My claim was rejected from Babysam, but Luksusbaby came with a voucher to cover the claim.I will now shopp from Luksusbaby going forward.",
      name: "LouiseAndersen",
      title: "Easy! ",
    },
  ];
  const totalNumbersList = [
    {
      id: 1,
      title: "150k+",
      subTitle: "Total users",
    },
    {
      id: 2,
      title: "2m+",
      subTitle: "Total receipts",
    },
    {
      id: 3,
      title: "80k+",
      subTitle: "Total refunds",
    },
    {
      id: 4,
      title: "5k+",
      subTitle: "Stores",
    },
  ];

  const questionList = [
    {
      title: "Fetch receipts",
      subTitle: "we scan your inbox for receipts (nothing else)",
    },
    {
      title: "Scan market",
      subTitle: "we search scan online stores for better prices",
    },
    {
      title: "Claim refunds",
      subTitle: "we'll get you a partial refund or a voucher automatically, if something you've bought drops in price.",
    },
  ];

  const [visibleSlider, setVisibleSlider] = useState(null);
  return (
    <div className="ViewHowWorksPage">
      <MainMenu dark />
      <div className="ViewHowWorksPage-header-wrapper">
        <div className="ViewHowWorksPage-header__title">How does Tjommi actually work?</div>
        <div className="ViewHowWorksPage-header__title--sub__wrapper">
          <div className="ViewHowWorksPage-header__title ViewHowWorksPage-header__title--sub">
            — If you’ve ever shopped online then seen the price drop afterwards, you know how annoying it can be. Tjommi
            solves this problem.
          </div>
        </div>
      </div>

      {/* SECTION 1 */}

      <div className="ViewHowWorksPage-section">
        <div className="ViewHowWorksPage-section-content-accordion-wrapper">
          <div style={{ flex: 1 }}>
            <Accordion
              small
              light
              itemList={questionList}
              title="Connect your email to collect refunds automatically"
              onSectionPress={(ele) => {
                if (visibleSlider != null && visibleSlider === ele) {
                  setVisibleSlider(null);
                } else {
                  setVisibleSlider(ele);
                }
              }}
            />
          </div>

          <div className="slider">
            <div
              className={classNames({
                "slider__wrapper": true,
                "slider__wrapper--first": visibleSlider === 0 ? true : false,
                "slider__wrapper--second": visibleSlider === 1 ? true : false,
                "slider__wrapper--third": visibleSlider === 2 ? true : false,
              })}
            >
              <img
                alt=""
                src={SIIDER1}
                className={classNames({
                  "slider--img": true,
                  "slider--img--visible":
                    visibleSlider === 0 || visibleSlider === 1 || visibleSlider === 2 ? true : false,
                })}
              />
              <img
                alt=""
                src={SIIDER2}
                className={classNames({
                  "slider--img": true,
                  "slider--img--visible": visibleSlider === 1 || visibleSlider === 2 ? true : false,
                })}
              />
              <img
                alt=""
                src={SIIDER3}
                className={classNames({
                  "slider--img": true,
                  "slider--img--visible": visibleSlider === 2 ? true : false,
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 2 */}

      <div className="ViewHowWorksPage-section">
        <div className="ViewHowWorksPage-section-content-refund-wrapper">
          <div className="ViewHowWorksPage-section-content-refund-wrapper__button">
            <Button type={"label"} label="Get started" onClick={() => window.open(GET_APP_URL)} />
          </div>
          <div className="ViewHowWorksPage-section-content-refund__title">How does Tjommi get me a refund?</div>
          <div className="ViewHowWorksPage-section-content-refund__title--sub">
            Its not magic, we are using price guarantees and the 14 days return right law to try to get a refund.
          </div>
        </div>
        <div className="ViewHowWorksPage-section-content-flipCard">
          <FlipCards cards={flipcards} />
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="ViewHowWorksPage-section">
        <div className="ViewHowWorksPage-section-content-winwin__cover">
          <div className="ViewHowWorksPage-section-content-winwin__text">How does Tjommi make money?</div>
          <div className="ViewHowWorksPage-section-content-winwin__modal">
            <Hide on="mobile">
              <img alt="" src={PayoutGiftcard_IMAGE} className="ViewHowWorksPage-section-content-winwin__modal__img" />
            </Hide>
            <div className="ViewHowWorksPage-section-content-winwin__modal__cover">
              <div>
                <div className="ViewHowWorksPage-section-content-winwin__modal__text">
                  We work with a <br />
                  <span className="ViewHowWorksPage-section-content-winwin__modal__text--pink">win-win </span>
                  model.
                </div>
                <div className="ViewHowWorksPage-section-content-winwin__modal__text--sub">
                  We only make money when you make money. We take a 25% comission fee when we get you a cash refund.
                </div>
              </div>

              <div>
                <Button
                  light
                  label="Get started"
                  onClick={() => {
                    window.open(GET_APP_URL);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 5 */}

      <div className="aboutPage-section">
        <div className="aboutPage-section-content-totalNumbers-wrapper">
          {totalNumbersList.map((ele, index) => {
            return (
              <div key={ele.id} className="aboutPage-section-content-totalNumbers-wrapper--cover">
                <div className="aboutPage-section-content-totalNumbers__header">
                  <div className="aboutPage-section-content-totalNumbers__title">{ele.title}</div>
                  <div className="aboutPage-section-content-totalNumbers__title aboutPage-section-content-totalNumbers__title--sub">
                    {ele.subTitle}
                  </div>
                </div>
                <Hide on="mobile">
                  {totalNumbersList.length - 1 > index ? (
                    <hr className="aboutPage-section-content-totalNumbers__line" />
                  ) : null}
                </Hide>
              </div>
            );
          })}
        </div>
      </div>

      {/* SECTION 6 */}
      <div className="FAQPage-section">
        <div className="FAQPage-section-content__accordion FAQPage-section-content__accordion__item FAQPage-section-content__accordion__extraTop">
          <hr className="FAQPage-section-content__accordion__hr" />

          <div className="FAQPage-section-content__accordion-wrapper">
            <div>
              <Hide on="mobile">
                <ICON_REVIWS />
              </Hide>
              <Hide on="desktop">
                <ICON_REVIWS width="25" height="27" />
              </Hide>
            </div>
            <div className="FAQPage-section-content__accordion__des">
              <div className="FAQPage-section-content__accordion__des__title--medium">App store reviews</div>
            </div>
          </div>
        </div>
        <div>
          <Reviews reviews={reviews} />
        </div>
      </div>

      {/* SECTION 8 */}
      <div className="ViewHowWorksPage-section">
        <div className="ViewHowWorksPage-section-content-appstoreBanner">
          <SectionText
            specialSub={" Such a beautiful word."}
            sub={"Refund."}
            title={"Loved by 200,000  shoppers."}
            appStoreBanners
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ViewHowWorks;
