const Icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
    <path d="M24 1a23 23 0 1 0 0 46 23 23 0 0 0 0-46Zm0 0" fill="#0288d1" />
    <path
      d="M12.5 18.25h4.6V35.5h-4.6Zm2.29-2.3h-.03c-1.37 0-2.26-1.02-2.26-2.3 0-1.3.91-2.3 2.31-2.3s2.26 1 2.3 2.3c0 1.28-.9 2.3-2.32 2.3Zm21.86 8.62a6.32 6.32 0 0 0-11.5-3.62v-2.7h-4.6V35.5h4.6v-9.2a3.45 3.45 0 0 1 6.9 0v9.2h4.6V24.57Zm0 0"
      fill="#fff"
    />
  </svg>
);

export default Icon;
