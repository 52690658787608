const Icon = ({ width = "189", height = "56", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 168 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="168" height="56" rx="7" fill="#222222" />
    <path
      d="M114.137 26.8805V30.0879H112.127V32.1914H114.137V39.3383C114.137 41.7788 115.241 42.7549 118.019 42.7549C118.507 42.7549 118.972 42.6968 119.378 42.6271V40.5469C119.03 40.5818 118.809 40.605 118.426 40.605C117.182 40.605 116.636 40.024 116.636 38.6992V32.1914H119.378V30.0879H116.636V26.8805H114.137Z"
      fill="white"
    />
    <path
      d="M126.454 42.9292C130.149 42.9292 132.415 40.454 132.415 36.3517C132.415 32.2727 130.138 29.7858 126.454 29.7858C122.758 29.7858 120.481 32.2727 120.481 36.3517C120.481 40.454 122.747 42.9292 126.454 42.9292ZM126.454 40.7096C124.281 40.7096 123.06 39.1175 123.06 36.3517C123.06 33.6091 124.281 32.0054 126.454 32.0054C128.615 32.0054 129.847 33.6091 129.847 36.3517C129.847 39.1059 128.615 40.7096 126.454 40.7096Z"
      fill="white"
    />
    <path
      d="M134.354 42.6852H136.853V35.2128C136.853 33.4348 138.189 32.2378 140.084 32.2378C140.525 32.2378 141.269 32.3192 141.478 32.3889V29.9252C141.211 29.8555 140.734 29.8207 140.362 29.8207C138.712 29.8207 137.306 30.7271 136.946 31.9706H136.76V30.0298H134.354V42.6852Z"
      fill="white"
    />
    <path
      d="M147.682 31.9125C149.53 31.9125 150.738 33.2024 150.796 35.1896H144.405C144.544 33.214 145.834 31.9125 147.682 31.9125ZM150.785 39.2686C150.32 40.2564 149.286 40.8026 147.775 40.8026C145.776 40.8026 144.486 39.3964 144.405 37.1768V37.0374H153.341V36.1658C153.341 32.1914 151.215 29.7858 147.693 29.7858C144.126 29.7858 141.86 32.3541 141.86 36.3982C141.86 40.4423 144.079 42.9292 147.705 42.9292C150.599 42.9292 152.621 41.5347 153.19 39.2686H150.785Z"
      fill="white"
    />
    <path
      d="M97.7523 38.0118C97.945 41.1194 100.535 43.1068 104.389 43.1068C108.508 43.1068 111.086 41.023 111.086 37.6986C111.086 35.0849 109.617 33.6395 106.051 32.8084L104.136 32.3386C101.872 31.8086 100.956 31.098 100.956 29.8574C100.956 28.2915 102.378 27.2677 104.51 27.2677C106.533 27.2677 107.93 28.2674 108.183 29.8694H110.809C110.652 26.9425 108.075 24.8828 104.546 24.8828C100.751 24.8828 98.2221 26.9425 98.2221 30.038C98.2221 32.5916 99.6554 34.1092 102.799 34.844L105.039 35.386C107.34 35.928 108.352 36.723 108.352 38.0479C108.352 39.5897 106.762 40.7098 104.594 40.7098C102.269 40.7098 100.655 39.6619 100.426 38.0118H97.7523Z"
      fill="white"
    />
    <path
      d="M71.87 29.8207C70.1501 29.8207 68.6626 30.6806 67.8956 32.1216H67.7097V30.0298H65.3041V46.892H67.8027V40.7677H68.0002C68.6626 42.1042 70.092 42.8944 71.8933 42.8944C75.0891 42.8944 77.1228 40.3726 77.1228 36.3517C77.1228 32.3308 75.0891 29.8207 71.87 29.8207ZM71.1612 40.6515C69.0694 40.6515 67.7562 39.0013 67.7562 36.3633C67.7562 33.7137 69.0694 32.0635 71.1728 32.0635C73.2878 32.0635 74.5545 33.6789 74.5545 36.3517C74.5545 39.0362 73.2878 40.6515 71.1612 40.6515Z"
      fill="white"
    />
    <path
      d="M85.8656 29.8207C84.1456 29.8207 82.6581 30.6806 81.8911 32.1216H81.7052V30.0298H79.2996V46.892H81.7982V40.7677H81.9957C82.6581 42.1042 84.0875 42.8944 85.8888 42.8944C89.0846 42.8944 91.1183 40.3726 91.1183 36.3517C91.1183 32.3308 89.0846 29.8207 85.8656 29.8207ZM85.1567 40.6515C83.0649 40.6515 81.7517 39.0013 81.7517 36.3633C81.7517 33.7137 83.0649 32.0635 85.1683 32.0635C87.2833 32.0635 88.55 33.6789 88.55 36.3517C88.55 39.0362 87.2833 40.6515 85.1567 40.6515Z"
      fill="white"
    />
    <path
      d="M60.8213 42.6852H63.688L57.4126 25.3044H54.5098L48.2344 42.6852H51.0047L52.6067 38.072H59.2314L60.8213 42.6852ZM55.8227 28.4602H56.0274L58.5448 35.8076H53.2932L55.8227 28.4602Z"
      fill="white"
    />
    <path
      d="M49.9141 12.1958V20.5804H52.9414C55.4399 20.5804 56.8867 19.0406 56.8867 16.3619C56.8867 13.7239 55.4283 12.1958 52.9414 12.1958H49.9141ZM51.2156 13.3811H52.7961C54.5334 13.3811 55.5619 14.4851 55.5619 16.3793C55.5619 18.3026 54.5509 19.395 52.7961 19.395H51.2156V13.3811Z"
      fill="white"
    />
    <path
      d="M61.3178 20.7024C63.1656 20.7024 64.2987 19.4647 64.2987 17.4136C64.2987 15.3741 63.1598 14.1307 61.3178 14.1307C59.4701 14.1307 58.3312 15.3741 58.3312 17.4136C58.3312 19.4647 59.4643 20.7024 61.3178 20.7024ZM61.3178 19.5926C60.2313 19.5926 59.6212 18.7965 59.6212 17.4136C59.6212 16.0423 60.2313 15.2405 61.3178 15.2405C62.3986 15.2405 63.0145 16.0423 63.0145 17.4136C63.0145 18.7907 62.3986 19.5926 61.3178 19.5926Z"
      fill="white"
    />
    <path
      d="M73.9476 14.2527H72.6984L71.5711 19.0813H71.4724L70.1708 14.2527H68.9738L67.6723 19.0813H67.5793L66.4462 14.2527H65.1795L66.9227 20.5804H68.2068L69.5084 15.9203H69.6072L70.9145 20.5804H72.2103L73.9476 14.2527Z"
      fill="white"
    />
    <path
      d="M75.3863 20.5804H76.6356V16.8791C76.6356 15.8913 77.2225 15.2753 78.1463 15.2753C79.0702 15.2753 79.5118 15.7809 79.5118 16.7977V20.5804H80.7611V16.4839C80.7611 14.979 79.9825 14.1307 78.5705 14.1307C77.6176 14.1307 76.99 14.5548 76.6821 15.2579H76.5891V14.2527H75.3863V20.5804Z"
      fill="white"
    />
    <path d="M82.7285 20.5804H83.9778V11.7832H82.7285V20.5804Z" fill="white" />
    <path
      d="M88.6762 20.7024C90.524 20.7024 91.657 19.4647 91.657 17.4136C91.657 15.3741 90.5182 14.1307 88.6762 14.1307C86.8285 14.1307 85.6896 15.3741 85.6896 17.4136C85.6896 19.4647 86.8227 20.7024 88.6762 20.7024ZM88.6762 19.5926C87.5896 19.5926 86.9795 18.7965 86.9795 17.4136C86.9795 16.0423 87.5896 15.2405 88.6762 15.2405C89.757 15.2405 90.3729 16.0423 90.3729 17.4136C90.3729 18.7907 89.757 19.5926 88.6762 19.5926Z"
      fill="white"
    />
    <path
      d="M95.3793 19.6333C94.6994 19.6333 94.2055 19.3021 94.2055 18.7326C94.2055 18.1748 94.6007 17.8785 95.4722 17.8204L97.0178 17.7216V18.2503C97.0178 19.0348 96.3206 19.6333 95.3793 19.6333ZM95.0597 20.685C95.8906 20.685 96.5821 20.3247 96.9597 19.6914H97.0585V20.5804H98.2613V16.2573C98.2613 14.9209 97.3665 14.1307 95.7802 14.1307C94.345 14.1307 93.3223 14.8279 93.1945 15.9145H94.4031C94.5425 15.4671 95.0248 15.2114 95.7221 15.2114C96.5762 15.2114 97.0178 15.5891 97.0178 16.2573V16.8035L95.3037 16.9023C93.7988 16.9953 92.9505 17.6519 92.9505 18.7907C92.9505 19.947 93.8395 20.685 95.0597 20.685Z"
      fill="white"
    />
    <path
      d="M102.501 20.685C103.372 20.685 104.11 20.2724 104.488 19.581H104.587V20.5804H105.784V11.7832H104.534V15.2579H104.441C104.099 14.5606 103.366 14.1481 102.501 14.1481C100.903 14.1481 99.8743 15.4148 99.8743 17.4136C99.8743 19.4183 100.891 20.685 102.501 20.685ZM102.855 15.2695C103.901 15.2695 104.558 16.1004 104.558 17.4194C104.558 18.7442 103.907 19.5635 102.855 19.5635C101.798 19.5635 101.164 18.7559 101.164 17.4136C101.164 16.083 101.803 15.2695 102.855 15.2695Z"
      fill="white"
    />
    <path
      d="M113.885 20.7024C115.732 20.7024 116.866 19.4647 116.866 17.4136C116.866 15.3741 115.727 14.1307 113.885 14.1307C112.037 14.1307 110.898 15.3741 110.898 17.4136C110.898 19.4647 112.031 20.7024 113.885 20.7024ZM113.885 19.5926C112.798 19.5926 112.188 18.7965 112.188 17.4136C112.188 16.0423 112.798 15.2405 113.885 15.2405C114.965 15.2405 115.581 16.0423 115.581 17.4136C115.581 18.7907 114.965 19.5926 113.885 19.5926Z"
      fill="white"
    />
    <path
      d="M118.519 20.5804H119.768V16.8791C119.768 15.8913 120.355 15.2753 121.279 15.2753C122.203 15.2753 122.645 15.7809 122.645 16.7977V20.5804H123.894V16.4839C123.894 14.979 123.115 14.1307 121.703 14.1307C120.75 14.1307 120.123 14.5548 119.815 15.2579H119.722V14.2527H118.519V20.5804Z"
      fill="white"
    />
    <path
      d="M129.648 12.678V14.2817H128.642V15.3334H129.648V18.9069C129.648 20.1271 130.2 20.6152 131.588 20.6152C131.832 20.6152 132.065 20.5862 132.268 20.5513V19.5112C132.094 19.5287 131.983 19.5403 131.792 19.5403C131.17 19.5403 130.897 19.2498 130.897 18.5874V15.3334H132.268V14.2817H130.897V12.678H129.648Z"
      fill="white"
    />
    <path
      d="M133.945 20.5804H135.194V16.8849C135.194 15.9261 135.764 15.2812 136.786 15.2812C137.67 15.2812 138.14 15.7925 138.14 16.8035V20.5804H139.39V16.4956C139.39 14.9906 138.559 14.1365 137.211 14.1365C136.258 14.1365 135.589 14.5606 135.281 15.2695H135.183V11.7832H133.945V20.5804Z"
      fill="white"
    />
    <path
      d="M143.896 15.194C144.82 15.194 145.424 15.839 145.453 16.8326H142.258C142.327 15.8448 142.972 15.194 143.896 15.194ZM145.448 18.8721C145.215 19.366 144.698 19.6391 143.943 19.6391C142.943 19.6391 142.298 18.936 142.258 17.8262V17.7564H146.726V17.3207C146.726 15.3334 145.663 14.1307 143.902 14.1307C142.118 14.1307 140.985 15.4148 140.985 17.4369C140.985 19.4589 142.095 20.7024 143.908 20.7024C145.355 20.7024 146.366 20.0051 146.65 18.8721H145.448Z"
      fill="white"
    />
    <path
      d="M34.6772 28.4215C34.7075 26.0681 35.9714 23.8413 37.9764 22.6086C36.7115 20.8022 34.5929 19.6568 32.3887 19.5879C30.0378 19.3411 27.7587 20.9946 26.5607 20.9946C25.3395 20.9946 23.495 19.6124 21.5088 19.6532C18.9198 19.7369 16.5063 21.2088 15.2467 23.4722C12.5391 28.16 14.5587 35.0495 17.1524 38.8387C18.45 40.6942 19.9666 42.7669 21.951 42.6934C23.8929 42.6128 24.6182 41.4551 26.9622 41.4551C29.2845 41.4551 29.9649 42.6934 31.9896 42.6466C34.0733 42.6128 35.3862 40.7829 36.6383 38.9098C37.5707 37.5877 38.2881 36.1265 38.7641 34.5803C36.3153 33.5446 34.68 31.0803 34.6772 28.4215Z"
      fill="white"
    />
    <path
      d="M30.8528 17.0959C31.989 15.732 32.5487 13.9789 32.4132 12.209C30.6774 12.3913 29.074 13.2209 27.9226 14.5324C26.7966 15.8139 26.2106 17.5361 26.3214 19.2384C28.0578 19.2563 29.7648 18.4492 30.8528 17.0959Z"
      fill="white"
    />
  </svg>
);

export default Icon;
