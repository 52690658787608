import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorPurchaseItemsByDate } from "../../state/selectors";
import ListingPage from "../listingPage";
import Outline from "../../components/layouts/outline";
import ListItem from "../../components/listItem";
import iconDollar from "../overview/screen/assets/dollar.svg";
import routes from "../../utils/routes";
import { formatCurrency } from "../../utils/formatter";
import EmptyState from "../emptyState";
import PIGGY_BANK_IMAGE from "./assets/refunds.png";
import { getPurchases } from "../../state/actionCreators";

const RefundsAll = ({ purchases, getPurchases, strs }) => {
  return (
    <ListingPage
      backPath={routes.overview.subroutes.refunds.path}
      headline={strs.refunds.title}
      items={purchases}
      onRefresh={getPurchases}
      renderItem={(date) => (
        <Outline label={date.label} key={date.label}>
          {date.items.map((item) => (
            <ListItem
              key={item.id}
              image={iconDollar}
              text={item.product}
              rightText={formatCurrency(item.totalSavings, item.currency)}
              route={`/overview/refunds/${item.id}`}
            />
          ))}
        </Outline>
      )}
      emptyState={<EmptyState image={PIGGY_BANK_IMAGE} text={strs.refunds.refundsEmptyText} />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    purchases: selectorPurchaseItemsByDate(state, "refunds"),
    strs: selectorLanguageStrings(state, "overview"),
  };
};

const mapDispatchToProps = { getPurchases };

export default connect(mapStateToProps, mapDispatchToProps)(RefundsAll);
