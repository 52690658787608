import React from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import IconArrowRight from "./arrowRight.svg";
import "./styles.scss";

class ListItem extends React.Component {
  handleClick = () => this.props.history.push(this.props.route);

  getImage() {
    switch (typeof this.props.image) {
      case "function":
        return this.props.image();
      case "object":
        return this.props.image;
      case "string":
      default:
        return <img alt="" src={this.props.image} />;
    }
  }

  render() {
    return (
      <div
        className={classNames("listItem", {
          "listItem--clickable": this.props.route,
          "listItem--noMargin": this.props.noMargin,
        })}
        onClick={this.props.route && this.handleClick}
      >
        <div className="listItem__icon">{this.getImage()}</div>
        <div className="listItem_description">
          {this.props.title ? <div className="listItem__text listItem__text--heading">{this.props.title}</div> : null}
          <div className="listItem__text">{this.props.text}</div>
        </div>
        {this.props.rightText && <div className="listItem__right">{this.props.rightText}</div>}
        {this.props.route && this.props.showArrow && (
          <div className="listItem__arrow">
            <img alt="" src={IconArrowRight} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ListItem);
