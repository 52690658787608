export const isDateExpired = (dateString) => {
  const currentDate = new Date(); // Get the current date and time
  const inputDate = new Date(dateString); // Parse the input date string

  // Compare the input date with the current date
  return inputDate < currentDate;
};

export const findNotExpiredObject = (objects) => {
  const currentDate = new Date().getTime();

  // Iterate through the objects
  for (const obj of objects) {
    const expiresAt = new Date(obj.expires_at).getTime();

    // Check if the expiration date is in the future
    if (expiresAt > currentDate) {
      return obj; // Return the first object that is not expired
    }
  }

  return false; // Return null if all objects are expired
};
