import React from "react";
import "./styles.scss";

const RatingStars = ({ length, rating }) => {
  const percentage = (rating / length) * 100;
  const roundedPercentage = `${Math.round(percentage / 10) * 10}%`;

  return (
    <>
      {length === 5 && (
        <span className="star5-wrapper">
          <span className="star5-wrapper__stars" style={{ width: roundedPercentage, maxWidth: "100%" }}></span>
        </span>
      )}
      {length === 3 && (
        <span className="star3-wrapper">
          <span className="star3-wrapper__stars" style={{ width: roundedPercentage, maxWidth: "100%" }}></span>
        </span>
      )}
      {length === 1 && (
        <span className="star1-wrapper">
          <span className="star1-wrapper__stars" style={{ width: roundedPercentage, maxWidth: "100%" }}></span>
        </span>
      )}
    </>
  );
};

export default RatingStars;
