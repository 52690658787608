const Icon = ({ size = 15 }) => (
  <svg
    className="icon iconCancelLight"
    width={size}
    height={size}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.71 15.29C16.8974 15.4788 17.0025 15.734 17.0025 16C17.0025 16.266 16.8974 16.5212 16.71 16.71C16.5205 16.8959 16.2655 17 16 17C15.7345 17 15.4796 16.8959 15.29 16.71L9.00004 10.42L2.71004 16.71C2.52047 16.8959 2.26555 17 2.00004 17C1.73454 17 1.47962 16.8959 1.29004 16.71C1.10269 16.5212 0.997559 16.266 0.997559 16C0.997559 15.734 1.10269 15.4788 1.29004 15.29L7.58004 8.99998L1.29004 2.70998C1.10301 2.52141 0.998301 2.26642 0.998843 2.00083C0.999385 1.73524 1.10513 1.48068 1.29294 1.29287C1.48074 1.10507 1.7353 0.999324 2.00089 0.998781C2.26648 0.998239 2.52147 1.10295 2.71004 1.28998L9.00004 7.57998L15.29 1.28998C15.3831 1.19612 15.4939 1.12156 15.6159 1.07059C15.7378 1.01963 15.8687 0.993253 16.0009 0.992983C16.1331 0.992713 16.2641 1.01855 16.3862 1.06902C16.5084 1.11949 16.6195 1.19359 16.7129 1.28708C16.8064 1.38056 16.8805 1.49158 16.931 1.61378C16.9815 1.73597 17.0073 1.86693 17.007 1.99913C17.0068 2.13134 16.9804 2.26219 16.9294 2.38417C16.8785 2.50616 16.8039 2.61688 16.71 2.70998L10.42 8.99998L16.71 15.29Z"
      fill="black"
    />
  </svg>
);

export default Icon;
