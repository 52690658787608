import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { selectorLanguage } from "../../state/selectors";
import "./styles.scss";

const TopicList = ({ topicList, onSelectTopic, selectedList, borderLine, langCode, styles }) => {
  return (
    <div
      className="TopicList"
      style={{
        ...styles,
      }}
    >
      {topicList &&
        topicList.map((ele) => {
          return (
            <div
              className={classNames({
                TopicList__topic__wrapper__selected: selectedList.includes(ele.code),
                TopicList__topic__border: borderLine,
                TopicList__topic__wrapper: true,
              })}
              key={ele.code}
              onClick={() => onSelectTopic(ele.code)}
            >
              {ele.title[langCode]}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    langCode: selectorLanguage(state),
  };
};

export default connect(mapStateToProps, null)(TopicList);
