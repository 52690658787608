const icon = ({ width = "20", height = "20" }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g mask="url(#mask0_304_2891)">
      <path
        d="M5.06297 14.708L4.29297 13.938L12.939 5.29201H5.20897V4.20801H14.793V13.792H13.709V6.06201L5.06297 14.708Z"
        fill="#836AFF"
      />
    </g>
  </svg>
);

export default icon;
