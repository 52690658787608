import React from "react";
import { connect } from "react-redux";
import { deleteMyUser, setGlobalState, updateMyUser } from "../../../state/actionCreators";
import { selectorLanguageStrings } from "../../../state/selectors";
import { HeaderLayout } from "../../../components/layouts/header";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import Outline from "../../../components/layouts/outline";
import { ControlsLayout } from "../../../components/layouts/controls";
import Button from "../../../components/inputs/button";
import routes from "../../../utils/routes";
import "./styles.scss";

class ViewProfileBank extends React.Component {
  state = {
    accountNumber: this.props.accountNumber,
  };

  handleChange = (event) =>
    this.setState({
      accountNumber: event.target.value.match(/(\d*)/g).join(""),
    });

  handleClick = () => this.props.updateMyUser({ account_nr: this.state.accountNumber });

  render() {
    return (
      <div className="viewProfileBank ph-no-capture">
        <MainContentLayout center small>
          <HeaderLayout left={{ type: "arrowLeft", path: routes.profileRefunds.path }} />
          <h1>{this.props.strs.title}</h1>
          <div className="text">{this.props.strs.text}</div>
          <Outline label={this.props.strs.label}>
            <input
              className="input"
              type="text"
              placeholder={this.props.strs.label}
              value={this.state.accountNumber}
              onChange={this.handleChange}
            />
          </Outline>
          <ControlsLayout>
            <Button type="pink" label={this.props.strs.button} onClick={this.handleClick} />
          </ControlsLayout>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountNumber: state?.globalState?.myUser?.account_nr || "",
  strs: selectorLanguageStrings(state, "profileBank"),
});

const mapDispatchToProps = { updateMyUser, setGlobalState, deleteMyUser };

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfileBank);
