const Cancel = ({ width, height, color = "#222222", ...props }) => (
  <svg
    className="icon iconCancel"
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 24}
    height={height ?? 24}
    {...props}
    viewBox="0 0 24 24"
  >
    <path
      d="M19.7086 18.2892C19.8959 18.478 20.0011 18.7332 20.0011 18.9992C20.0011 19.2652 19.8959 19.5204 19.7086 19.7092C19.519 19.8951 19.2641 19.9992 18.9986 19.9992C18.7331 19.9992 18.4782 19.8951 18.2886 19.7092L11.9986 13.4192L5.70858 19.7092C5.519 19.8951 5.26408 19.9992 4.99858 19.9992C4.73307 19.9992 4.47815 19.8951 4.28858 19.7092C4.10123 19.5204 3.99609 19.2652 3.99609 18.9992C3.99609 18.7332 4.10123 18.478 4.28858 18.2892L10.5786 11.9992L4.28858 5.70919C4.10154 5.52062 3.99684 5.26563 3.99738 5.00004C3.99792 4.73444 4.10367 4.47988 4.29147 4.29208C4.47927 4.10428 4.73383 3.99853 4.99943 3.99799C5.26502 3.99745 5.52001 4.10215 5.70858 4.28919L11.9986 10.5792L18.2886 4.28919C18.3817 4.19532 18.4924 4.12077 18.6144 4.0698C18.7364 4.01883 18.8672 3.99246 18.9994 3.99219C19.1316 3.99192 19.2626 4.01776 19.3848 4.06823C19.507 4.1187 19.618 4.1928 19.7115 4.28628C19.805 4.37977 19.8791 4.49079 19.9295 4.61298C19.98 4.73518 20.0058 4.86613 20.0056 4.99834C20.0053 5.13054 19.9789 5.26139 19.928 5.38338C19.877 5.50537 19.8024 5.61609 19.7086 5.70919L13.4186 11.9992L19.7086 18.2892Z"
      fill={color}
    />
  </svg>
);

export default Cancel;
