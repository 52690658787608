import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  selectorLanguageStrings,
  selectorMyUser,
  selectorNotificationsFiltered,
  selectorUnreadNotifications,
} from "../../../state/selectors";
import Dropdown from "../../dropdown";
import routes from "../../../utils/routes";
import { APP_STORE_URL, PLAY_STORE_URL } from "../../../utils/configs";
import IconLogo from "../../icons/logo";
import ReceiptsFillIcon from "../../icons/receiptsFill";
import ReceiptsIcon from "../../icons/receipts";
import Bag from "./assets/shopping-bag";
import Bell from "./assets/bell";
import AddReceipt from "../../addReceipt";
import NotificationItem from "../../notificationItem";
import Icon from "./assets/Icon.png";
import "./styles.scss";
import Avatar from "../../userAvatarWithMedal";

class LoginMainMenu extends React.Component {
  render() {
    let hidden = false;
    const pathname = this.props.location.pathname;
    const match = pathname.match(/overview\/notifications|overview|profile|support|language|referral|discover/i)?.[0];

    if (!match) hidden = true;

    const isOverview = match === "overview";
    const isDiscover = match === "discover";
    const isNotificationItem = match === "overview/notifications" && !!pathname.match(/(\d+)/);

    if (isNotificationItem) {
      hidden = true;
    }

    // console.log("rrr", this.props.notifications);
    return (
      <div
        className={classNames("menu", {
          "menu--hidden": hidden,
        })}
      >
        <div className="left">
          <Link className="item" to={routes.index.path}>
            <IconLogo color="black" />
          </Link>

          <Link className="item" to="/discover">
            <div className="item__card">
              <div className="item__card--icon">
                <Bag color={isDiscover ? "#DF698B" : ""} />
              </div>
              <div
                className={classNames("item__card--text", {
                  "item__card--text--pink": isDiscover,
                })}
              >
                {this.props.strs.shop}
              </div>
            </div>
          </Link>

          <Link className="item" to="/overview">
            <div className="item__card">
              <div className="item__card--icon">
                {isOverview ? <ReceiptsFillIcon color={"#DF698B"} /> : <ReceiptsIcon width={19} height={19} />}
              </div>
              <div
                className={classNames("item__card--text", {
                  "item__card--text--pink": isOverview,
                })}
              >
                {this.props.strs.overview}
              </div>
            </div>
          </Link>
        </div>

        <div className="middle">
          <AddReceipt label={this.props.strs.purchase} />

          <div className="middle__right">
            <Dropdown
              label={<Bell notification={this.props.unreadNotifications?.length > 0} />}
              hideIcon={true}
              className="item"
              notification
              items={
                this.props.notifications?.length > 0
                  ? [
                      ...this.props.notifications.slice(0, 3).map((item) => {
                        return {
                          label: <NotificationItem notification={item} dropdown />,
                          nolink: true,
                        };
                      }),
                      {
                        label: <div className="notification-view">{this.props.strs.view}</div>,
                        to: "/overview/notifications",
                      },
                    ]
                  : [
                      {
                        nolink: true,
                        label: (
                          <div className="notification-noview">
                            <img src={Icon} alt="" />
                            <div>{this.props.strs.noNotification}</div>
                          </div>
                        ),
                      },
                    ]
              }
            />

            <Link className="item" to="/profile">
              <Avatar size={32} text={this.props?.user?.full_name} color={"#F7C1D1"} />
            </Link>
          </div>
        </div>

        <div className="right">
          <Dropdown
            label={this.props.strs.cta}
            hideIcon={true}
            className="item item--pink"
            items={[
              {
                label: this.props.strs.ctaAppStore,
                to: APP_STORE_URL,
                target: "_blank",
                external: true,
              },
              {
                label: this.props.strs.ctaPlayStore,
                to: PLAY_STORE_URL,
                target: "_blank",
                external: true,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "loginMainMenu"),
    user: selectorMyUser(state),
    notifications: selectorNotificationsFiltered(state),
    unreadNotifications: selectorUnreadNotifications(state),
  };
};

export default withRouter(connect(mapStateToProps)(LoginMainMenu));
