import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/connectedEmail.png";

const ThankYouModal = ({ visible, closeBtn, strs, children = null, title, text }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn}
      backgroundImage={IMAGE}
      backgroundColor={"#505EE8"}
      title={title || strs.modalTitle}
      text={text || ""}
      button={<>{children ? children : <Button type="transparent" label={strs.modalBtn} onClick={closeBtn} />}</>}
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "ThankYouModal"),
});

export default withRouter(connect(mapStateToProps)(ThankYouModal));
