import React from "react";
import "./style.scss";
import className from "classnames";
import pigCoinImage from "./assets/pigCoin.png";

const LearnMoreBox = ({
  backgroundColor,
  onClick,
  text,
  interactable = true,
  image,
  fullwidth = false,
  imageSize = false,
  title,
  size = "small",
  gradiant = false,
  styles = {},
  fontSize = "normal",
}) => {
  return (
    <div
      className={className("learnMoreBox", {
        "learnMoreBox--interactable": interactable,
        "learnMoreBox--fullwidth": fullwidth,
        "learnMoreBox--Big": size === "big",
        "learnMoreBox--gradiant": gradiant,
      })}
      style={{ backgroundColor: backgroundColor, ...styles }}
    >
      <div className="learnMoreBox__wrapper" onClick={onClick}>
        <div className={"learnMoreBox__content1"}>
          <img
            src={image || pigCoinImage}
            className={className("learnMoreBox__image", {
              learnMoreBox__ImageSmall: imageSize,
            })}
            alt=""
          />
        </div>

        <div
          className={className("learnMoreBox__content2", {
            learnMoreBox__content2__small: fontSize === "small",
          })}
        >
          <div
            className={className("learnMoreBox__content2__text", {
              learnMoreBox__content2__text__small: fontSize === "small",
            })}
          >
            {title && (
              <div
                className={className("learnMoreBox__content2__bold", {
                  learnMoreBox__content2__bold__small: fontSize === "small",
                })}
              >
                {title}
              </div>
            )}
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreBox;
