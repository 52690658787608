const icon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.334 10.334H7.66732V22.334L6.33398 25.6673H25.6673L24.334 22.334V10.334Z"
      fill={color || "none"}
      stroke={color || "#545454"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.3327 10.334L21.666 6.33398H10.3327L7.66602 10.334"
      stroke={color || "#545454"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 14.334V15.6673C13 17.3242 14.3431 18.6673 16 18.6673C17.6569 18.6673 19 17.3242 19 15.6673V14.334"
      fill={color || "none"}
      stroke={color ? "white" : "#545454"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default icon;
