function PriceIcon(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M14.25 8.75l4-4H5.75l4 4"
        stroke="#E42D61"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 14a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
        stroke="#E42D61"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PriceIcon;
