import React from "react";
import icon from "./icon.svg";
import "./styles.scss";

const SpinnerCircle = () => (
  <div className="spinnerCircle">
    <div className="spinnerCircle__wrap">
      <img alt="" draggable={false} src={icon} />
    </div>
  </div>
);

export default SpinnerCircle;
