import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/connectedEmail.png";

const ConnectedEmailModal = ({ visible, closeBtn, strs }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn}
      backgroundImage={IMAGE}
      backgroundColor={"#896EEB"}
      title={strs.emailConnected.modalTitle}
      text={strs.emailConnected.modalText}
      button={<Button label={strs.emailConnected.modalBtn} type="transparent" onClick={closeBtn} />}
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "profileConfirmation"),
});

export default withRouter(connect(mapStateToProps)(ConnectedEmailModal));
