import React from "react";
import { connect } from "react-redux";
import { setLanguage } from "../../state/actionCreators";
import { selectorLanguageStrings } from "../../state/selectors";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import Outline from "../../components/layouts/outline";
import getQuery from "../../utils/getQuery";
import iconDa from "./assets/da.png";
import iconEn from "./assets/en.png";
import iconNo from "./assets/no.png";
import "./styles.scss";
import { ControlsLayout } from "../../components/layouts/controls";
import Button from "../../components/inputs/button";

class ViewLanguage extends React.Component {
  state = {
    language: this.props.lang,
  };

  handleClick = () => {
    this.props.setLanguage(this.state.language);
    this.redirect();
  };

  redirect = () => {
    const redirect = getQuery()?.redirect;
    if (redirect) this.props.history.push(redirect);
    else this.props.history.goBack();
  };

  setLanguage = (language) => () => {
    this.setState({ language: language });
  };

  render() {
    const redirect = getQuery()?.redirect;
    return (
      <div className="viewLanguage">
        <MainContentLayout center small>
          <HeaderLayout right={{ type: "cancelRight", path: redirect }}></HeaderLayout>
          <h1>{this.props.strs.title}</h1>
          <Outline>
            <label className="radio" onClick={this.setLanguage("nb")}>
              <div className="option">
                <img alt="" src={iconNo} />
                {this.props.strs.nb}
              </div>
              <input
                type="radio"
                name="language"
                id="nb"
                readOnly
                className="radio__input"
                checked={this.state.language === "nb"}
              />
              <div className="radio__radio"></div>
            </label>
            <label className="radio" onClick={this.setLanguage("da")}>
              <div className="option">
                <img alt="" src={iconDa} />
                {this.props.strs.da}
              </div>
              <input
                type="radio"
                name="language"
                id="da"
                readOnly
                className="radio__input"
                checked={this.state.language === "da"}
              />
              <div className="radio__radio"></div>
            </label>
            <label className="radio" onClick={this.setLanguage("en")}>
              <div className="option">
                <img alt="" src={iconEn} />
                {this.props.strs.en}
              </div>
              <input
                type="radio"
                name="language"
                id="en"
                readOnly
                className="radio__input"
                checked={this.state.language === "en"}
              />
              <div className="radio__radio"></div>
            </label>
          </Outline>
          <ControlsLayout>
            <Button type="pink" label={this.props.strs.button} onClick={this.handleClick} />
          </ControlsLayout>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.globalState.language,
  strs: selectorLanguageStrings(state, "language"),
});

const mapDispatchToProps = { setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(ViewLanguage);
