import React from "react";
import classNames from "classnames";
import "./styles.scss";

const BottomSheet = ({
  active,
  setActive,
  children,
  backgroundColor = "#e86f92",
  fullHeight = false,
  header,
  zIndex,
}) => {
  return (
    <>
      <div
        onClick={() => setActive()}
        className={classNames("backdrop", {
          "backdrop--active": active,
        })}
      ></div>

      <div
        className={classNames({
          "bottomSheetWrapper": true,
          "bottomSheetWrapper--active": active,
          "bottomSheetWrapper__scroll": fullHeight,
        })}
        style={{
          zIndex: zIndex || null,
        }}
      >
        {header ? <div className="bottomSheetWrapper__header">{header}</div> : null}

        <div
          style={{
            backgroundColor,
            height: fullHeight ? "100vh" : "auto",
            borderTopLeftRadius: fullHeight ? 0 : 16,
            borderTopRightRadius: fullHeight ? 0 : 16,
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default BottomSheet;
