const Icon = ({ width = "48", height = "48", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g mask="url(#mask0_303_1061)">
      <path
        d="M11.9508 35.45V18.25H14.2008V35.45H11.9508ZM23.0008 35.45V18.25H25.3008V35.45H23.0008ZM5.55078 15V13.15L24.0008 3.09998L42.4508 13.15V15H5.55078ZM11.0508 12.75H37.0008L24.0008 5.69998L11.0508 12.75ZM5.55078 40.95V38.7H29.3508C29.3841 39.0666 29.4258 39.4333 29.4758 39.8C29.5258 40.1666 29.6174 40.55 29.7508 40.95H5.55078ZM33.8008 28.15V18.25H36.0508V27.05L33.8008 28.15ZM40.0008 47.25C37.8674 46.7166 36.1008 45.4916 34.7008 43.575C33.3008 41.6583 32.6008 39.5333 32.6008 37.2V32.45L40.0008 28.75L47.4008 32.45V37.2C47.4008 39.5333 46.7008 41.6583 45.3008 43.575C43.9008 45.4916 42.1341 46.7166 40.0008 47.25ZM38.8508 41.6L45.4508 34.95L44.3008 33.8L38.8508 39.25L36.1508 36.6L35.0008 37.75L38.8508 41.6Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Icon;
