import * as React from "react";

function Faq(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9.847 14.693H5.404A.404.404 0 015 14.29V9.847A4.847 4.847 0 019.847 5h0a4.847 4.847 0 014.846 4.847v0a4.846 4.846 0 01-4.846 4.846v0z"
        stroke="#EB6F93"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.582 14.694a4.85 4.85 0 004.57 3.23h4.444a.404.404 0 00.404-.403v-4.443a4.847 4.847 0 00-4.582-4.84"
        stroke="#EB6F93"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Faq;
