import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/winWin.png";
import { withRouter } from "react-router-dom";
import routes from "../../../utils/routes";

const winWinModal = ({ visible, closeBtn, history, strs }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn}
      backgroundImage={IMAGE}
      backgroundColor={"#EB6F93"}
      title={strs.infoBoxtitle}
      text={strs.infoBoxtext}
      button={
        <Button label={strs.addCardBtn} type="transparent" onClick={() => history.push(routes.profileCard.path)} />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "profileRefunds"),
});

export default withRouter(connect(mapStateToProps)(winWinModal));
