import React, { useEffect, useState } from "react";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import Outline from "../../components/layouts/outline";
import { ControlsLayout } from "../../components/layouts/controls";
import Button from "../../components/inputs/button";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorTrafficSource } from "../../state/selectors";
import ThankYouModal from "../../components/modals/thankYouModal";
import { enterWaitingList, setGlobalState } from "../../state/actionCreators";
import "./styles.scss";
import AuthButton from "../../components/authbutton";
import { prettyProvider } from "../../utils/misc";

const ViewWaitlistPage = ({ strs, onboardingStrs, enterWaitingList, thankYouModalStrs }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const location = useLocation();
  const [isThankYouModalVisible, setThankYouModalVisible] = useState(false);

  const emailValidation = (email) => {
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    setIsValidEmail(regEx.test(email));
  };

  useEffect(() => {
    if (email !== "") emailValidation(email);
  }, [email]);

  const onSubmit = () => {
    enterWaitingList(email, location?.state?.provider).then(() => setThankYouModalVisible(true));
  };

  return (
    <div>
      <MainContentLayout center={true} small={true}>
        <HeaderLayout left={{ type: "arrowLeft", path: "overview/receipts" }} />

        <h1 className="headline">
          {prettyProvider(location?.state?.provider)} {strs.comingSoon}
        </h1>

        <div className="label">
          {strs.waitListTitle1}
          {prettyProvider(location?.state?.provider)}
          {strs.waitListTitle2}
        </div>

        <div className="mainEmailAccount">
          <Outline label={strs.yourEmail}>
            <input
              type="email"
              className="input"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder={strs.emailPlaceHolder}
            />
          </Outline>

          <ControlsLayout>
            <Button disabled={!isValidEmail} type="pink" label={strs.btnTitle} onClick={onSubmit} />
          </ControlsLayout>
        </div>

        <ThankYouModal
          visible={isThankYouModalVisible}
          closeBtn={() => setThankYouModalVisible(false)}
          text={
            thankYouModalStrs.modalText1 +
            " " +
            prettyProvider(location?.state?.provider) +
            " " +
            thankYouModalStrs.modalText2
          }
          children={
            <>
              <AuthButton
                fixedWidth={170}
                label={onboardingStrs.signupTitleWith + " Google"}
                provider={"gmail"}
                icon={"google"}
              />
              <AuthButton
                fixedWidth={170}
                label={onboardingStrs.signupTitleWith + " Outlook"}
                provider={"outlook"}
                icon={"outlook"}
              />
            </>
          }
        />
      </MainContentLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "waitlist"),
    onboardingStrs: selectorLanguageStrings(state, "onboarding"),
    thankYouModalStrs: selectorLanguageStrings(state, "ThankYouModal"),
    trafficSource: localStorage.getItem("ref") || selectorTrafficSource(state),
  };
};

const mapDispatchToProps = { enterWaitingList, setGlobalState };

export default connect(mapStateToProps, mapDispatchToProps)(ViewWaitlistPage);
