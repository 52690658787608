import { MERGE_ENTITIES, SET_ENTITIES } from "./actions";

const reducer = (state = {}, action = {}) => {
  const nextState = { ...state };

  switch (action.type) {
    case MERGE_ENTITIES:
      for (const entity of Object.keys(action.payload)) {
        // guard against undefined values
        if (action.payload[entity] === undefined) return nextState;
        for (const record of Object.values(action.payload[entity])) {
          // initialize entity
          if (!nextState[entity]) nextState[entity] = {};

          // initialize entity record
          if (!nextState[entity][record.id]) nextState[entity][record.id] = {};

          nextState[entity][record.id] = {
            ...nextState[entity][record.id],
            ...record,
          };
        }
      }
      return nextState;

    case SET_ENTITIES:
      for (const entity of Object.keys(action.payload)) {
        // guard against undefined values
        if (action.payload[entity] === undefined) return nextState;

        // clear the entity
        nextState[entity] = {};

        for (const record of Object.values(action.payload[entity])) {
          nextState[entity][record.id] = {
            ...record,
          };
        }
      }
      return nextState;

    default:
      return state;
  }
};

export default reducer;
