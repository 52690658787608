import React from "react";
import { connect } from "react-redux";
import { clearState } from "../../state/actionCreators";
import routes from "../../utils/routes";
import ApiClient from "../../services/apiClient";
// import NativeAppClient from "../../services/nativeAppClient";
// import { deleteDeviceToken } from "../../state/actionCreators";

class ViewSignout extends React.Component {
  componentDidMount() {
    if (ApiClient.token) {
      // if (NativeAppClient.isInNativeApp()) {
      //   NativeAppClient.customerIoClearIdentify();
      // }
      ApiClient.postSignout();
    }

    this.props.clearState();
    this.props.history.push(routes.index.path);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = { clearState };

export default connect(null, mapDispatchToProps)(ViewSignout);
