import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectorIsAppMode } from "../../state/selectors";
import MainMenu from "../../components/menus/mainMenu";
import Footer from "../../components/layouts/footer";
import { HeaderLayout } from "../../components/layouts/header";
import getQuery from "../../utils/getQuery";
import { MainContentLayout } from "../../components/layouts/mainContent";
import ApiClient from "../../services/apiClient";
import "./styles.scss";

const ViewPartners = ({ isAppMode }) => {
  const [partnersList, setPartnersList] = useState([]);
  useEffect(() => {
    ApiClient.getPartners()
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPartnersList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const redirect = getQuery()?.redirect;
  return (
    <div className="ViewPartnersPage">
      {!isAppMode && <MainMenu dark={true} />}
      <MainContentLayout>
        {redirect && <HeaderLayout left={{ type: "arrowLeft", path: redirect }} />}
        <h1 className="headline">Partners</h1>
        <ul>
          {partnersList.map((ele) => {
            return <li>{ele.name}</li>;
          })}
        </ul>

        <p>
          The Tjommi partners list is subject to dynamic updates, and any newly added partners shall automatically be
          covered by your consent.
        </p>
      </MainContentLayout>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
});

export default connect(mapStateToProps)(ViewPartners);
