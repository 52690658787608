const Icon = ({ width = "24", height = "24", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.4491 20.4489H16.8931V14.88C16.8931 13.552 16.8694 11.8425 15.0436 11.8425C13.1915 11.8425 12.9081 13.2894 12.9081 14.7833V20.4485H9.35207V8.99658H12.7659V10.5616H12.8137C13.1553 9.97747 13.649 9.49693 14.2421 9.17117C14.8353 8.84541 15.5057 8.68662 16.1819 8.71172C19.7861 8.71172 20.4507 11.0824 20.4507 14.1666L20.4491 20.4489ZM5.33964 7.43116C4.93149 7.43123 4.53249 7.31028 4.19309 7.08358C3.85369 6.85689 3.58913 6.53465 3.43287 6.1576C3.27661 5.78055 3.23567 5.36563 3.31522 4.96531C3.39477 4.56499 3.59125 4.19725 3.8798 3.9086C4.16835 3.61994 4.53601 3.42333 4.9363 3.34364C5.33659 3.26394 5.75153 3.30473 6.12863 3.46085C6.50574 3.61697 6.82808 3.88141 7.0549 4.22073C7.28171 4.56005 7.40282 4.95901 7.40289 5.36716C7.40299 5.91447 7.18567 6.4394 6.79873 6.82647C6.4118 7.21355 5.88695 7.43106 5.33964 7.43116ZM7.11767 20.4489H3.55792V8.99658H7.11767V20.4489ZM22.222 0.0016303H1.77099C1.30681 -0.00360468 0.85954 0.175657 0.527462 0.500026C0.195385 0.824394 0.00566784 1.26733 0 1.73151L0 22.2681C0.00546364 22.7325 0.195055 23.1758 0.527123 23.5005C0.859191 23.8252 1.30657 24.0048 1.77099 23.9999H22.222C22.6873 24.0057 23.136 23.8266 23.4693 23.5019C23.8027 23.1772 23.9936 22.7335 24 22.2681V1.73002C23.9934 1.26493 23.8024 0.821487 23.469 0.497127C23.1356 0.172768 22.6871 -0.00598093 22.222 0.000152801"
      fill={color}
    />
  </svg>
);

export default Icon;
