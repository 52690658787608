import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uploadReceipt } from "../../state/actionCreators";
import {
  selectorAppOsPlatform,
  selectorIsAppMode,
  selectorLanguage,
  selectorLanguageStrings,
  selectorMyUser,
} from "../../state/selectors";
import { MainContentLayout } from "../../components/layouts/mainContent";
import Outline from "../../components/layouts/outline";
import DetailLine from "../../components/detailLine";
import Label from "../../components/label";
import Spacer from "../../components/spacer";
import DataSecurityModal from "../../components/modals/dataSecurityModal/DataSecurityModal";
import routes from "../../utils/routes";
import iconPig from "./assets/pig.svg";
import iconMail from "./assets/mail.svg";
import iconHelp from "./assets/support.svg";
import iconCut from "./assets/cut.svg";
import iconInsta from "./assets/insta.svg";
import iconFb from "./assets/fb.svg";
import iconExit from "./assets/exit.svg";
import iconSecurity from "./assets/security.svg";
import Avatar from "../../components/userAvatarWithMedal";
import "./styles.scss";

import Hide from "../../components/hide/hide";

const ViewProfile = ({ isIOS, fullName, isAppMode, language, strs }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="viewProfile">
      <MainContentLayout center={true} small>
        <div className="profileHeader">
          <h1 className="header-section__headline">{fullName || strs.title}</h1>
          <div className="profileHeader__badge">
            <Avatar size={40} text={fullName} color={"#F7C1D1"} />
          </div>
        </div>
        <Spacer />
        <div className="options">
          <Link to={routes.profileEmails.path} className="option" draggable={false}>
            <div className="option__icon">
              <img alt="" draggable={false} src={iconMail} />
            </div>
            <div className="option__label">{strs.option1}</div>
          </Link>

          <Link to={routes.profileRefunds.path} className="option" draggable={false}>
            <div className="option__icon">
              <img alt="" draggable={false} src={iconPig} />
            </div>
            <div className="option__label">{strs.option2}</div>
          </Link>

          <Link to={"/support"} className="option" draggable={false}>
            <div className="option__icon">
              <img alt="" draggable={false} src={iconHelp} />
            </div>
            <div className="option__label">{strs.option3}</div>
          </Link>

          <div className="option" draggable={false} onClick={() => setOpenModal(true)}>
            <div className="option__icon">
              <img alt="" draggable={false} src={iconSecurity} />
            </div>
            <div className="option__label">{strs.option5}</div>
          </div>
        </div>
        <Spacer />

        <Label>{strs.profile}</Label>
        <Outline styles={{ margin: null }}>
          <DetailLine arrowRight label={strs.personalInfo} value={""} path={routes.profileInformation.path} />
        </Outline>
        <Outline styles={{ margin: null }}>
          <DetailLine arrowRight label={strs.interests} value={""} path={routes.profileInterests.path} />
        </Outline>
        <Outline styles={{ margin: null }}>
          <DetailLine
            arrowRight
            label={strs.language}
            value={language}
            path={routes.language.path + "?redirect=" + routes.profile.path}
          />
        </Outline>
        <Spacer />
        <Label>{strs.safetyLabel}</Label>
        <Outline styles={{ margin: null }}>
          <DetailLine
            arrowRight
            label={strs.serviceAgreement}
            path={routes.terms.path + "?redirect=" + routes.profile.path}
          />
        </Outline>
        <Outline styles={{ margin: null }}>
          <DetailLine arrowRight label={strs.privacy} path={routes.privacy.path + "?redirect=" + routes.profile.path} />
        </Outline>
        <Spacer />
        <Label>{strs.aboutUs}</Label>
        <Outline styles={{ margin: null }}>
          <a
            style={{
              textDecoration: "none",
            }}
            rel="noreferrer"
            href={(isIOS ? "appOpenInUrl=" : "") + "https://www.instagram.com/tjommi/?hl=en"}
            target="_blank"
          >
            <DetailLine
              label={
                <>
                  <img alt="" draggable={false} src={iconInsta} />
                  {strs.instagram}
                </>
              }
              arrowRight={true}
            />
          </a>
        </Outline>
        <Outline styles={{ margin: null }}>
          <a
            style={{
              textDecoration: "none",
            }}
            rel="noreferrer"
            href={(isIOS ? "appOpenInUrl=" : "") + "https://www.facebook.com/DinTjommi/"}
            target="_blank"
          >
            <DetailLine
              label={
                <>
                  <img alt="" draggable={false} src={iconFb} /> {strs.facebook}
                </>
              }
              arrowRight={true}
            />
          </a>
        </Outline>

        <Spacer />

        <Outline styles={{ margin: null }}>
          <DetailLine
            label={
              <>
                <img alt="" draggable={false} src={iconCut} /> {strs.leave}
              </>
            }
            path={routes.profileAccountDelete.subroutes.one.path}
          />
          <Spacer />
          <DetailLine
            label={
              <>
                <img alt="" draggable={false} src={iconExit} /> {strs.signout}
              </>
            }
            path={routes.signout.path}
          />
        </Outline>

        <Hide on={"desktop"} nonAdmins={true}>
          <Spacer />

          <Outline label="Debug">
            <DetailLine label="Show version information" path={routes.debug.path} />
          </Outline>
        </Hide>
      </MainContentLayout>

      <DataSecurityModal closeBtn={() => setOpenModal(false)} visible={openModal} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const langCode = selectorLanguage(state);
  const user = selectorMyUser(state);

  return {
    isIOS: selectorAppOsPlatform(state) === "ios",
    fullName: user?.full_name,
    isAppMode: selectorIsAppMode(state),
    language: selectorLanguageStrings(state, "language")[langCode],
    strs: selectorLanguageStrings(state, "profile"),
  };
};

const mapDispatchToProps = { uploadReceipt };

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);
