function RightsIcon(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.75 11.75a1 1 0 011-1h10.5a1 1 0 011 1v5.5a2 2 0 01-2 2h-8.5a2 2 0 01-2-2v-5.5zM8 10.75v-.657c0-1.228-.048-2.544.675-3.537C9.249 5.767 10.307 5 12.25 5c2 0 3.25 1.5 3.25 1.5"
        stroke="#E42D61"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RightsIcon;
