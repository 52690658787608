const icon = ({ color = "white" }) => (
  <svg
    className="iconArrowRight"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5336 10.2464L15.645 6.35787C15.6448 6.35765 15.6446 6.35743 15.6443 6.3572C15.5508 6.2626 15.4985 6.13487 15.4988 6.00185C15.4991 5.86851 15.5522 5.74071 15.6464 5.64643C15.7407 5.55214 15.8685 5.49905 16.0019 5.49878C16.1349 5.49851 16.2627 5.55084 16.3573 5.64435C16.3575 5.64456 16.3577 5.64477 16.3579 5.64497L21.106 10.3931C21.1061 10.3931 21.1061 10.3932 21.1062 10.3933C21.4256 10.7136 21.605 11.1476 21.605 11.6C21.605 12.0524 21.4256 12.4863 21.1062 12.8067C21.1061 12.8068 21.1061 12.8068 21.106 12.8069L16.3599 17.553C16.3594 17.5535 16.359 17.5539 16.3585 17.5544C16.2625 17.6477 16.1339 17.7 16 17.7C15.8665 17.7 15.7384 17.6481 15.6425 17.5554C15.5496 17.4605 15.4975 17.3329 15.4975 17.2C15.4975 17.0662 15.5503 16.9379 15.6443 16.8428C15.6445 16.8426 15.6447 16.8424 15.6449 16.8422L19.5336 12.9535L20.3871 12.1H19.18H3C2.86739 12.1 2.74021 12.0473 2.64645 11.9535C2.55268 11.8598 2.5 11.7326 2.5 11.6C2.5 11.4674 2.55268 11.3402 2.64645 11.2464C2.74021 11.1527 2.86739 11.1 3 11.1H19.18H20.3871L19.5336 10.2464Z"
      fill={color}
      stroke={color}
    />
  </svg>
);

export default icon;
