import React from "react";
import AppStoreBanners from "../appStoreBanners";
import classNames from "classnames";
import "./styles.scss";

const SectionText = (props) => {
  return (
    <div className="new__sectionText">
      <p className="new__sectionText__sub">
        {props.sub}
        {props.specialSub || props.white ? (
          <span
            className={classNames({
              "new__sectionText__sub new__sectionText__sub__special": true,
              "new__sectionText__sub new__sectionText__sub--white": props.white ? true : false,
            })}
          >
            {props.specialSub}
          </span>
        ) : null}
      </p>
      <h1
        className={classNames({
          "new__sectionText__title": true,
          "new__sectionText__title--white": props.white ? true : false,
        })}
      >
        {props.title}
      </h1>
      <div className="new__sectionText__desc">{props.desc}</div>
      {props.appStoreBanners ? <AppStoreBanners big={true} /> : ""}
    </div>
  );
};

export default SectionText;
