import React, { useEffect, useState } from "react";
import {
  selectorAppOsPlatform,
  selectorLanguageStrings,
  selectorRatingsByStoreId,
  selectorAnyDiscoveriesById,
} from "../../state/selectors";
import { getRatings } from "../../state/actionCreators";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import ArrowLeft from "../../components/icons/arrowMiniLeft";
import IconPartnerBadge from "../../components/icons/partnerBadge";
import IconScoreEmptyBadge from "../../components/icons/scoreEmptyBadge";
import StoreIcon from "../ratings/assets/storeIcon";
import LearnMoreBox from "../../components/learnMoreBox";
import Outline from "../../components/layouts/outline";
import RatingStars from "../ratings/components/ratingStars";
import PriceIcon from "../ratings/assets/priceIcon";
import RightsIcon from "../ratings/assets/rightsIcon";
import CalendarIcon from "../ratings/assets/calendarIcon";
import LinkIcon from "../ratings/assets/linkIcon";
import IMAGE_COIN from "../ratings/assets/Coin.png";
import IMAGE_RATING_POOR from "./assets/Poor.png";
import IMAGE_RATING_DECENT from "./assets/Decent.png";
import IMAGE_RATING_GOOD from "./assets/Good.png";
import IMAGE_RATING_EXCELLENT from "./assets/Excellent.png";
import routes from "../../utils/routes";
import "./styles.scss";
import VIDEO_TRUSTED_TJOMMI_PARTNER from "../../assets/videos/TrustedPartners.mp4";
import { ControlsLayout } from "../../components/layouts/controls";
import Button from "../../components/inputs/button";
import VideoModal from "../../components/modals/videoModal";
import { useLocation } from "react-router-dom";

const Rating = ({ rating, history, strs, isIOS, categoriesStrs, getRatings }) => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  // Parse the query parameters
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");

  useEffect(() => {
    if (!type) {
      getRatings();
    }
  }, [getRatings]);

  const Ratings = {
    Excellent: {
      key: "Excellent",
      color: "#79C5AF",
      image: IMAGE_RATING_EXCELLENT,
      text: strs.scoreCardTextGood,
      title: strs.Excellent,
      score: (rating) => (rating?.tjommi_score ?? Math.ceil(rating?.rating?.score)) === 5 && rating.is_partner === true,
    },
    Good: {
      key: "Good",
      color: "#EB6F93",
      image: IMAGE_RATING_GOOD,
      text: strs.scoreCardTextExcellent,
      title: strs.Good,
      // Implied that this won't be a partner,
      // partners will always be scored 5, but this acts as a fallback if this is forgotten
      score: (rating) => (rating?.tjommi_score ?? Math.ceil(rating?.rating?.score)) <= 5,
    },
    Decent: {
      key: "Decent",
      color: "#896EEB",
      image: IMAGE_RATING_DECENT,
      text: strs.scoreCardTextDecent,
      title: strs.Decent,
      score: (rating) => (rating?.tjommi_score ?? Math.ceil(rating?.rating?.score)) <= 3,
    },
    Poor: {
      key: "Poor",
      color: "#EB9E6E",
      image: IMAGE_RATING_POOR,
      text: strs.scoreCardTextPoor,
      title: strs.Poor,
      score: (rating) => (rating?.tjommi_score ?? Math.ceil(rating?.rating?.score)) <= 1,
    },
    find: (rating) => {
      return [Ratings.Excellent, Ratings.Poor, Ratings.Decent, Ratings.Good].find((r) => r.score(rating));
    },
  };

  const currentTjommiScore = Ratings.find(rating);
  const scoreNumber = rating?.tjommi_score ?? Math.ceil(rating?.rating?.score) ?? 0;

  const linkHref = ({ handle, value }) => {
    // NOTE: appOpenInUrl is used in the native mobile app client to spawn an "in-app" browser on iOS.
    let prefix = isIOS ? "appOpenInUrl=" : "";

    if (handle === "support_email") return `${prefix}mailto:${value}`;
    if (handle === "support_phone") return `${prefix}tel:${value}`;

    return `${prefix}${value}`;
  };

  const renderLinkText = (link) => {
    if (link?.handle === "homepage") return `${strs.contentSectionLinksTextLink} ${rating?.name}`;
    if (link?.handle === "support_email") return `${rating?.name} ${strs.contentSectionLinksTextEmail}`;
    if (link?.handle === "support_phone") return `${rating?.name} ${strs.contentSectionLinksTextPhone}`;
    if (link?.handle === "price_match_terms_url") return `${rating?.name} ${strs.contentSectionLinksTextGuarantees}`;

    return "";
  };

  const renderGuaranteesCard = (rating) => {
    if (rating.handle === "price_match") {
      return (
        <div className="viewRating__claims__card" key={rating?.handle}>
          <PriceIcon />
          <div className="viewRating__claims__card__text">
            {rating?.value} {strs.contentSectionLinksClaimTextPriceGuarantee}
          </div>
        </div>
      );
    }

    if (rating.handle === "withdrawal_right") {
      return (
        <div className="viewRating__claims__card" key={rating?.handle}>
          <RightsIcon />
          <div className="viewRating__claims__card__text">
            {rating?.value} {strs.contentSectionLinksClaimTextWithdrawalRights}
          </div>
        </div>
      );
    }

    if (rating.handle === "open_purchase") {
      return (
        <div className="viewRating__claims__card" key={rating?.handle}>
          <CalendarIcon />
          <div className="viewRating__claims__card__text">
            {rating?.value} {strs.contentSectionLinksClaimTextOpenPurchase}
          </div>
        </div>
      );
    }
  };

  if (!rating) {
    return "";
  }

  return (
    <div className="viewRating">
      <MainContentLayout center={true}>
        <HeaderLayout
          left={
            <div
              className="headerLayout--leftArrow"
              onClick={() => {
                type
                  ? history.push(routes.discoveries.path, {
                      from: routes.discoveries.path,
                    })
                  : history.goBack();
              }}
            >
              <ArrowLeft color="black" />
            </div>
          }
          roundBackArrow={true}
        />

        <div className="viewRating__titleLayout">
          <div>
            <div className="label">{rating?.category ? categoriesStrs[rating?.category] : ""}</div>
            <h1 className="headline">{rating?.name}</h1>
          </div>
          <div className="viewRating__logo">
            <div className="viewRating__badge">
              {rating?.is_partner ? (
                <IconPartnerBadge width={24} height={24} />
              ) : scoreNumber === 0 ? (
                <IconScoreEmptyBadge />
              ) : null}
            </div>
            {rating?.logo ? <img src={rating?.logo} alt="Logo" /> : <StoreIcon width={60} height={60} />}
          </div>
        </div>

        <div className="viewRating__content">
          <div className="viewRating__content__firstRow">
            <Outline>
              <div className="viewRating__scoreCard">
                <div className="viewRating__scoreCard__header">
                  <div className="viewRating__scoreCard__header__title">{strs.title}</div>
                  <div>
                    <RatingStars rating={scoreNumber} length={5} />
                  </div>
                </div>
                <div className="viewRating__scoreCard__subTitle">{strs.scoreCardHeading}</div>
                <div
                  className="viewRating__scoreCard__label"
                  style={{
                    backgroundColor: currentTjommiScore?.color,
                  }}
                >
                  <div className="viewRating__scoreCard__label__content">
                    <div className="viewRating__scoreCard__label__content__header">
                      <div className="viewRating__scoreCard__label__content__header__title1">
                        {currentTjommiScore?.title}
                      </div>
                      <div className="viewRating__scoreCard__label__content__header__title2">
                        {currentTjommiScore?.text({ store: rating?.name })}
                      </div>
                    </div>
                    <div className="viewRating__scoreCard__label__content__icon">
                      <div>
                        <img
                          src={currentTjommiScore?.image}
                          className="viewRating__scoreCard__label__content__icon__image"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Outline>

            <Outline label={strs.contentSectionHeading1} padding={false}>
              <div className="viewRating__claims">
                {rating?.guarantees.map((rating) => renderGuaranteesCard(rating))}
              </div>
            </Outline>

            {rating?.links?.length > 0 ? (
              <Outline label={strs.contentSectionLinksHeading2} padding={false}>
                <div className="viewRating__claims">
                  {rating?.links?.map((link) => {
                    if (
                      link?.handle === "homepage" ||
                      link?.handle === "support_email" ||
                      link?.handle === "support_phone" ||
                      link?.handle === "price_match_terms_url"
                    ) {
                      return (
                        <div className="viewRating__claims__card" key={link?.handle}>
                          <a
                            href={linkHref(link)}
                            rel="noreferrer"
                            target="_blank"
                            className="viewRating__claims__card__link__text"
                          >
                            {renderLinkText(link)}
                          </a>
                          <LinkIcon />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </Outline>
            ) : null}
          </div>

          <div className="viewRating__content__secondRow">
            {rating.is_partner === true && (
              <Outline label={strs.infoCardTrustedPartnerHeading} padding={false} background={false}>
                <LearnMoreBox
                  onClick={() => setOpen(true)}
                  backgroundColor={"#79C5AF"}
                  text={strs.infoCardTrustedPartnerText}
                  image={IMAGE_COIN}
                  imageSize={"small"}
                />
              </Outline>
            )}
          </div>
        </div>
      </MainContentLayout>

      <VideoModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        videoUrl={VIDEO_TRUSTED_TJOMMI_PARTNER}
        muted={false}
        showMuteControls={true}
        showCloseControls={false}
        backgroundColor={"#79C5AF"}
      >
        <ControlsLayout>
          <Button type="transparent" label={strs.infoCardButton} onClick={() => setOpen(false)} />
        </ControlsLayout>
      </VideoModal>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const searchParams = new URLSearchParams(ownProps.location.search);
  const type = searchParams.get("type");

  const id = parseInt(ownProps.location.pathname.match(/(\d+)/)?.[0]) || null;
  const rating = type ? selectorAnyDiscoveriesById(state, id, type) : selectorRatingsByStoreId(state, id);

  return {
    strs: selectorLanguageStrings(state, "ratings"),
    categoriesStrs: selectorLanguageStrings(state, "categories"),
    isIOS: selectorAppOsPlatform(state) === "ios",
    rating: rating,
  };
};

const mapDispatchToProps = { getRatings };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rating));
