import React from "react";
import classNames from "classnames";
import "./styles.scss";

const SpinnerBox = (props) => (
  <div
    className="spinnerBox"
    style={{
      color: props?.color || "#EB6F93",
    }}
  >
    <div className="spinnerBox__wrap">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames({
          "spinnerBox--animation": props.animation,
        })}
      >
        <path
          d="M28.1923 28.1924C26.8439 29.5408 25.1838 30.5358 23.3589 31.0892C21.5341 31.6427 19.601 31.7374 17.7308 31.3652C15.8605 30.9929 14.111 30.1651 12.6372 28.9551C11.1634 27.7451 10.0108 26.1902 9.28151 24.4283C8.55224 22.6664 8.26882 20.7518 8.45635 18.8541C8.64389 16.9565 9.2966 15.1344 10.3566 13.5492C11.4167 11.9641 12.8514 10.665 14.5335 9.76686C16.2157 8.86874 18.0934 8.39939 20.0003 8.40041C21.8012 8.39687 23.5777 8.81635 25.1868 9.62506C26.7959 10.4338 28.1926 11.6091 29.2643 13.0564L25.1203 16.1604L20.0003 20.0004"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5275 24.5295C23.6051 25.4527 22.4225 26.072 21.1383 26.3044C19.8541 26.5368 18.5295 26.3711 17.342 25.8297C16.1546 25.2883 15.1609 24.3969 14.4942 23.2749C13.8275 22.153 13.5196 20.8541 13.6117 19.5523C13.7038 18.2505 14.1915 17.0079 15.0095 15.991C15.8275 14.9741 16.9368 14.2315 18.1887 13.8626C19.4405 13.4938 20.7752 13.5163 22.0139 13.9271C23.2526 14.338 24.3362 15.1176 25.1195 16.1615"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.8 10.4004L29.264 13.0564L25.12 16.1604L20 20.0004"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0001 21.6004C20.8838 21.6004 21.6001 20.884 21.6001 20.0004C21.6001 19.1167 20.8838 18.4004 20.0001 18.4004C19.1165 18.4004 18.4001 19.1167 18.4001 20.0004C18.4001 20.884 19.1165 21.6004 20.0001 21.6004Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  </div>
);

export default SpinnerBox;
