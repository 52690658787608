import React from "react";
import { connect } from "react-redux";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import { selectorLanguageStrings } from "../../../state/selectors";
import { HeaderLayout } from "../../../components/layouts/header";
import "./styles.scss";
import SupportCard from "./components/supportCard";
import Faq from "./assets/Faq";
import Chat from "./assets/Chat";

class Support extends React.Component {
  render() {
    return (
      <div className="viewSupport">
        <MainContentLayout small center>
          <HeaderLayout left={{ type: "arrowLeft", path: "/profile" }} />
          <div className="support__headline">{this.props.strs.title}</div>

          <SupportCard title={this.props.strs.chatTitle} text={this.props.strs.chatContent} path={"/support/chat"}>
            <Chat />
          </SupportCard>

          <SupportCard
            title={this.props.strs.faqTitle}
            text={this.props.strs.faqContent}
            path={"/faq?redirect=/support"}
          >
            <Faq />
          </SupportCard>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "support"),
});

export default connect(mapStateToProps)(Support);
