import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../state/selectors";
import routes from "../../../utils/routes";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/deletedAccount.png";

const DeletedAccountModal = ({ visible, strs, history }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={() => history.push(routes.signout.path)}
      backgroundImage={IMAGE}
      backgroundColor={"#EB9E6E"}
      title={strs.modalTitle}
      text={strs.modalText}
      button={<Button label={strs.modalBtn} type="transparent" onClick={() => history.push(routes.signout.path)} />}
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "profileDelete"),
});

export default withRouter(connect(mapStateToProps)(DeletedAccountModal));
