const Icon = ({ width = "17", height = "17", color = "#222222", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 115 106" fill="none" {...props}>
    <path
      d="M78.507 48.4834C77.1224 48.4834 76 49.5962 76 50.9689C76 52.3417 77.1224 53.4545 78.507 53.4545H111.33C112.714 53.4545 113.837 52.3417 113.837 50.9689C113.837 49.5962 112.714 48.4834 111.33 48.4834H78.507Z"
      fill={color}
    />
    <path
      d="M92.8451 67.3295C92.8451 68.7141 93.9579 69.8365 95.3307 69.8365C96.7034 69.8365 97.8162 68.7141 97.8162 67.3295V34.507C97.8162 33.1224 96.7034 32 95.3307 32C93.9579 32 92.8451 33.1224 92.8451 34.507V67.3295Z"
      fill={color}
    />
    <path
      d="M78.507 48.4834C77.1224 48.4834 76 49.5962 76 50.9689C76 52.3417 77.1224 53.4545 78.507 53.4545H111.33C112.714 53.4545 113.837 52.3417 113.837 50.9689C113.837 49.5962 112.714 48.4834 111.33 48.4834H78.507Z"
      stroke={color}
    />
    <path
      d="M92.8451 67.3295C92.8451 68.7141 93.9579 69.8365 95.3307 69.8365C96.7034 69.8365 97.8162 68.7141 97.8162 67.3295V34.507C97.8162 33.1224 96.7034 32 95.3307 32C93.9579 32 92.8451 33.1224 92.8451 34.507V67.3295Z"
      stroke={color}
    />
    <path
      d="M72.2582 25.9375C72.2582 38.0532 62.3759 47.875 50.1853 47.875C37.9948 47.875 28.1124 38.0532 28.1124 25.9375C28.1124 13.8218 37.9948 4 50.1853 4C62.3759 4 72.2582 13.8218 72.2582 25.9375Z"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1928 101.875H85.1808C92.9364 101.875 98.9104 95.2474 95.2886 88.4316C89.961 78.4056 77.8152 66.4375 50.1868 66.4375C22.5584 66.4375 10.4125 78.4056 5.08495 88.4316C1.46315 95.2474 7.43722 101.875 15.1928 101.875Z"
      stroke={color}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.507 48.4834C77.1224 48.4834 76 49.5962 76 50.9689C76 52.3417 77.1224 53.4545 78.507 53.4545H111.33C112.714 53.4545 113.837 52.3417 113.837 50.9689C113.837 49.5962 112.714 48.4834 111.33 48.4834H78.507Z"
      fill={color}
    />
    <path
      d="M92.8451 67.3295C92.8451 68.7141 93.9579 69.8365 95.3307 69.8365C96.7034 69.8365 97.8162 68.7141 97.8162 67.3295V34.507C97.8162 33.1224 96.7034 32 95.3307 32C93.9579 32 92.8451 33.1224 92.8451 34.507V67.3295Z"
      fill={color}
    />
    <path
      d="M78.507 48.4834C77.1224 48.4834 76 49.5962 76 50.9689C76 52.3417 77.1224 53.4545 78.507 53.4545H111.33C112.714 53.4545 113.837 52.3417 113.837 50.9689C113.837 49.5962 112.714 48.4834 111.33 48.4834H78.507Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.8451 67.3295C92.8451 68.7141 93.9579 69.8365 95.3307 69.8365C96.7034 69.8365 97.8162 68.7141 97.8162 67.3295V34.507C97.8162 33.1224 96.7034 32 95.3307 32C93.9579 32 92.8451 33.1224 92.8451 34.507V67.3295Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.2582 25.9375C72.2582 38.0532 62.3759 47.875 50.1853 47.875C37.9948 47.875 28.1124 38.0532 28.1124 25.9375C28.1124 13.8218 37.9948 4 50.1853 4C62.3759 4 72.2582 13.8218 72.2582 25.9375Z"
      stroke={color}
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1928 101.875H85.1808C92.9364 101.875 98.9104 95.2474 95.2886 88.4316C89.961 78.4056 77.8152 66.4375 50.1868 66.4375C22.5584 66.4375 10.4125 78.4056 5.08495 88.4316C1.46315 95.2474 7.43722 101.875 15.1928 101.875Z"
      stroke={color}
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
