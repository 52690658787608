import React from "react";
import "./styles.scss";
import classNames from "classnames";

const Label = ({ children, bold = false, align = false, color = "#000000", size = "base", underline }) => (
  <div
    style={{
      color: color,
    }}
    className={classNames({
      "label": true,
      "label--bold": bold ? true : false,
      "label__align--center": align === "center" ? true : false,
      "label__align--right": align === "right" ? true : false,
      "label__align--left": align === "left" ? true : false,
      "label__size--xs": size === "xs" ? true : false,
      "label__size--sm": size === "sm" ? true : false,
      "label--underline": underline,
    })}
  >
    {children}
  </div>
);

export default Label;
