import { loadStripe } from "@stripe/stripe-js/pure";
import { STRIPE_PUBLISHABLE_KEY } from "../utils/configs";

class StripeClient {
  client = null;

  async get() {
    if (!this.client) this.client = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    return this.client;
  }
}

const client = new StripeClient();

export default client;
