import moment from "moment";

const getByDate = (entities, dateProperties) => {
  return entities.reduce((res, entity) => {
    const prop = dateProperties.find((prop) => !!entity[prop]);
    if (!prop) throw new Error("getByDate- prop not found: " + prop);

    let date = moment(entity[prop]);
    const isSameYear = moment().format("YYYY") === date.format("YYYY");
    date = date.format(isSameYear ? "D. MMMM" : "D. MMMM YYYY");
    let index = res.findIndex((item) => item.label === date);
    if (index === -1) {
      res.push({ label: date, items: [] });
      index = res.length - 1;
    }
    res[index].items.push(entity);
    return res;
  }, []);
};

export default getByDate;
