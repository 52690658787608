import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { MainContentLayout } from "../../components/layouts/mainContent";
import {
  selectorIsAuthed,
  selectorLanguageStrings,
  selectorLanguage,
  selectorMyUser,
  selectorInterestList,
  selectorDiscoveries,
  // selectorDiscoveryPurchases,
  selectorDiscoveryVouchers,
} from "../../state/selectors";
import Spacer from "../../components/spacer";
import {
  getAllDiscoveries,
  getMoreDiscoveries,
  getMoreDiscoveryVouchers,
  // getMoreDiscoveryPurchases,
} from "../../state/actionCreators";
import HeaderSection from "../ratings/components/headerSection";
import routes from "../../utils/routes";
import Button from "../../components/inputs/button";
import FilterOptions from "../../components/filterOptions/index";
import { BottomSheet } from "react-spring-bottom-sheet";
import DiscoverCard from "../../components/discoverCard";
import classNames from "classnames";
import ArrowRight from "../../components/icons/arrowRight";
import vouchersImage from "../giftCard/assets/GiftcardHeader.png";
import comingSoonImageEN from "./assets/coming_soon_en.png";
import fallBackImage_EN from "./assets/fallback_en.png";
import fallBackImage_NK from "./assets/fallback_nk.png";
import fallBackImage_DK from "./assets/fallback_dk.png";
import comingSoonImageDK from "./assets/coming_soon_dk.png";
import HowGiftCodesWorkModal from "../../components/modals/howGiftCodesWorkModal/HowGiftCodesWorkModal";
import "react-spring-bottom-sheet/dist/style.css";
import "./styles.scss";

const Discoveries = ({
  strs,
  user,
  interestList,
  getAllDiscoveries,
  getMoreDiscoveries,
  getMoreDiscoveryVouchers,
  // getMoreDiscoveryPurchases,
  discoveries,
  discoveryVouchers,
  // discoveryPurchases,
  language,
}) => {
  const sheetRef = useRef();

  const [isDiscoveriesLoading, setIsDiscoveriesLoading] = useState(false);
  const [isDiscoverVouchersLoading, setIsDiscoverVouchersLoading] = useState(false);
  // const [
  //   isDiscoveryPurchasesLoading,
  //   setIsDiscoveryPurchasesLoading,
  // ] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filtersMode, setFiltersMode] = useState(false);
  const [discoverMode, setDiscoverMode] = useState(null);
  const [selectedCategory, setSelectedTopicList] = useState([...user.interests.map((ele) => ele.id)]);
  const [openModal, setOpenModal] = useState(false);
  // State to keep track of the timeout
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedSort, setSelectedSort] = useState(["relevance"]);
  const [sortList, setSortList] = useState([
    {
      id: "relevance",
      title: {
        da: "Relevans",
        en: "Relevance",
        nb: "Relevans",
      },
    },
    {
      id: "discount",
      title: {
        da: "Rabat",
        en: "Discount",
        nb: "Rabatt",
      },
    },
    {
      id: "reviews",
      title: {
        da: "Anmeldelser",
        en: "Reviews",
        nb: "Anmeldelser",
      },
    },
  ]);

  const ITEMS_PER_PAGE = 50; // Number of items to fetch per page

  useEffect(() => {
    window.addEventListener("scroll", handleScrollDiscoveriesList);
    window.addEventListener("scroll", handleScrollDiscoveyVouchersList);
    // window.addEventListener("scroll", handleScrollDiscoveyPurchasesList);
    return () => {
      window.removeEventListener("scroll", handleScrollDiscoveriesList);
      window.removeEventListener("scroll", handleScrollDiscoveyVouchersList);
      // window.removeEventListener("scroll", handleScrollDiscoveyPurchasesList);
    };
  }, []);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async (query = null) => {
    getAllDiscoveries(user.region_id, selectedSort, selectedCategory, ITEMS_PER_PAGE, 0, query);
  };

  const handleScrollDiscoveyVouchersList = (event) => {
    const { scrollLeft, clientWidth, scrollWidth } = event.currentTarget;

    if (!isDiscoverVouchersLoading && discoveryVouchers?.hasMore && scrollWidth - scrollLeft === clientWidth) {
      try {
        setIsDiscoverVouchersLoading(true);
        getMoreDiscoveryVouchers(
          user.region_id,
          selectedSort,
          selectedCategory,
          ITEMS_PER_PAGE,
          searchTerm != "" ? searchTerm : null
        );
      } catch (error) {
        throw new Error("Failed to load DiscoveryVouchers: " + error);
      } finally {
        setIsDiscoverVouchersLoading(false);
      }
    }
  };

  // const handleScrollDiscoveyPurchasesList = (event) => {
  //   const { scrollLeft, clientWidth, scrollWidth } = event.currentTarget;

  //   if (
  //     !isDiscoveryPurchasesLoading &&
  //     discoveryPurchases?.hasMore &&
  //     scrollWidth - scrollLeft === clientWidth
  //   ) {
  //     try {
  //       setIsDiscoveryPurchasesLoading(true);
  //       getMoreDiscoveryPurchases(
  //         user.region_id,
  //         selectedSort,
  //         selectedCategory,
  //         ITEMS_PER_PAGE,
  //         searchTerm != "" ? searchTerm : null
  //       );
  //     } catch (error) {
  //       throw new Error("Failed to load DiscoveryPurchases: " + error);
  //     } finally {
  //       setIsDiscoveryPurchasesLoading(false);
  //     }
  //   }
  // };

  const handleScrollDiscoveriesList = (event) => {
    const { scrollLeft, clientWidth, scrollWidth } = event.currentTarget;

    if (!isDiscoveriesLoading && discoveries?.hasMore && scrollWidth - scrollLeft === clientWidth) {
      try {
        setIsDiscoveriesLoading(true);
        getMoreDiscoveries(
          user.region_id,
          selectedSort,
          selectedCategory,
          ITEMS_PER_PAGE,
          searchTerm != "" ? searchTerm : null
        );
      } catch (error) {
        throw new Error("Failed to load discoveries: " + error);
      } finally {
        setIsDiscoveriesLoading(false);
      }
    }
  };

  const onSelectCategory = useCallback(
    (id) => {
      if (!selectedCategory.includes(id)) {
        setSelectedTopicList(selectedCategory.concat([id]));
      } else {
        let filteredArray = selectedCategory.filter((item) => item !== id);
        setSelectedTopicList(filteredArray);
      }
    },
    [selectedCategory]
  );

  const onSelectSort = useCallback(
    (id) => {
      if (!selectedSort.includes(id)) {
        setSelectedSort(id);
      } else {
        setSelectedSort([]);
      }
    },
    [selectedSort]
  );

  const filterHandeler = async () => {
    setFiltersMode(false);
    fetchAllData();
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchTerm(newSearchQuery);

    // Clear previous timeout if it exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to call the fetch function
    const newTimeout = setTimeout(() => {
      fetchAllData(newSearchQuery);
    }, 500); // Debounce time in milliseconds

    setDebounceTimeout(newTimeout);
  };

  return (
    <div className="viewDiscoveries">
      <MainContentLayout center={true}>
        <HeaderSection
          onSubmit={(value) => handleSearchChange(value)}
          filter={true}
          filterOnpress={() => {
            setFiltersMode(!filtersMode);
          }}
        />

        <div className="viewDiscoveries__vouchersMode">
          <div className="viewDiscoveries__vouchersMode__wrapper">
            <div
              onClick={() => setDiscoverMode(false)}
              className={classNames({
                "viewDiscoveries__label": true,
                "viewDiscoveries__label--selected": !discoverMode || discoverMode === null ? true : false,
              })}
            >
              {strs.myGiftCodes}
            </div>
            <div
              onClick={() => setDiscoverMode(true)}
              className={classNames({
                "viewDiscoveries__label": true,
                "viewDiscoveries__label--selected": discoverMode ? true : false,
              })}
            >
              {strs.findShops}
            </div>
          </div>
        </div>

        <Spacer />

        {discoverMode ? (
          <>
            {discoveries?.data?.length > 0 && (
              <>
                <div className="viewDiscoveries__grid--label__wrapper">
                  <div className="viewDiscoveries__grid--label">{strs.byPartners}</div>
                  <div>
                    <ArrowRight color="#757575" />
                  </div>
                </div>
                <div className="grid--horizontal" onScroll={handleScrollDiscoveriesList}>
                  {discoveries?.data?.map((discover) => (
                    <DiscoverCard
                      discover={discover}
                      key={discover?.id}
                      horizontal={true}
                      amount={true}
                      source={"discover_feed"}
                    />
                  ))}
                </div>
                <Spacer />
              </>
            )}
            {/* {discoveryPurchases?.data?.length > 0 && (
              <>
                <div className="viewDiscoveries__grid--label__wrapper">
                  <div className="viewDiscoveries__grid--label">
                    {"By previous purchases"}
                  </div>
                  <div>
                    <ArrowRight color="#757575" />
                  </div>
                </div>
                <div
                  className="grid--horizontal"
                  onScroll={handleScrollDiscoveyPurchasesList}
                >
                  {discoveryPurchases?.data?.map((discover) => (
                    <DiscoverCard
                      discover={discover}
                      key={discover?.id}
           
                      fullPic={true}
                 
                    />
                  ))}
                </div>
                <Spacer />
              </>
            )} */}

            <>
              <div className="viewDiscoveries__grid--label__wrapper">
                <div className="viewDiscoveries__grid--label">{strs.byReviews}</div>
                <div>
                  <ArrowRight color="#757575" />
                </div>
              </div>

              <div className="viewDiscoveries__fallBackCard viewDiscoveries__fallBackCard--full">
                <img
                  draggable={false}
                  alt=""
                  src={language === "en" ? comingSoonImageEN : comingSoonImageDK}
                  className="viewDiscoveries__fallBackCard--img"
                />
              </div>

              <Spacer />
            </>
          </>
        ) : (
          <div className="grid">
            {discoveryVouchers?.data?.length > 0 ? (
              discoveryVouchers?.data?.map((discover, index) => {
                return (
                  <React.Fragment key={index}>
                    <DiscoverCard discover={discover} key={discover?.id} amount={true} source={"gift_codes_feed"} />
                    {(discoveryVouchers?.data.length > 2 && index === 2) ||
                    (discoveryVouchers?.data?.length === 2 && index === 1) ? (
                      <div
                        key={"tjommi_card"}
                        className="viewDiscoveries__howtouse__card__wrapper"
                        onClick={() => setOpenModal(true)}
                      >
                        <img
                          draggable={false}
                          alt=""
                          src={vouchersImage}
                          className="viewDiscoveries__howtouse__card__img"
                        />

                        <div className="viewDiscoveries__howtouse__card__label">{strs.howtouseCard}</div>
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <div className="viewDiscoveries__fallBackCard">
                <img
                  draggable={false}
                  alt=""
                  src={language === "en" ? fallBackImage_EN : language === "da" ? fallBackImage_DK : fallBackImage_NK}
                  className="viewDiscoveries__fallBackCard--img"
                />
              </div>
            )}
          </div>
        )}

        <Spacer />
      </MainContentLayout>
      <BottomSheet
        open={filtersMode}
        ref={sheetRef}
        draggable={false}
        onDismiss={() => {
          setFiltersMode(false);
        }}
        style={{ zIndex: 200, position: "absolute" }}
      >
        <div className="viewDiscoveries__filters">
          <div className="viewDiscoveries__filters__title">{strs.filterBy}</div>
          <div className="viewDiscoveries__filters__title--secondary">{strs.sortBy}</div>
          <FilterOptions topicList={sortList} onSelectTopic={onSelectSort} selectedList={selectedSort} />
          <div className="viewDiscoveries__filters__title--secondary">{strs.category}</div>
          <FilterOptions topicList={interestList} onSelectTopic={onSelectCategory} selectedList={selectedCategory} />

          <div className="viewDiscoveries__filters_Btn_wrapper">
            <Button
              disabled={selectedCategory.length < 1}
              type="pink"
              label={strs.save}
              onClick={() => {
                filterHandeler();
              }}
              className="interestsPage_saveBtn"
            />
          </div>
        </div>
      </BottomSheet>
      <HowGiftCodesWorkModal closeBtn={() => setOpenModal(false)} visible={openModal} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "discoveries"),
  user: selectorMyUser(state),
  interestList: selectorInterestList(state),
  isAuthed: selectorIsAuthed(state) && window.location.pathname !== routes.signout.path,
  discoveries: selectorDiscoveries(state),
  // discoveryPurchases: selectorDiscoveryPurchases(state),
  discoveryVouchers: selectorDiscoveryVouchers(state),
  language: selectorLanguage(state),
});

const mapDispatchToProps = {
  getAllDiscoveries,
  getMoreDiscoveries,
  getMoreDiscoveryVouchers,
  // getMoreDiscoveryPurchases,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discoveries);
