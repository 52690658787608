import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../state/selectors";
import "./styles.scss";

class ViewError extends React.Component {
  handleButtonClick = () => this.props.history.push(this.props.nextRedirect);

  render() {
    return (
      <div className="viewError">
        <div className="section">
          <div className="section__content section__content--narrowCenter">
            <h1 className="title">{this.props.strs.title}</h1>
            <div className="text">{this.props.strs.text}</div>
            <button className="button" onClick={this.handleButtonClick}>
              {this.props.strs.buttonText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "error"),
});

export default connect(mapStateToProps)(ViewError);
