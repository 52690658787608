export const _languages = {
  da: require("./lang/da.json"),
  en: require("./lang/en.json"),
  nb: require("./lang/nb.json"),
};

export const LANGUAGES = {
  DA: "da",
  EN: "en",
  NB: "nb",
};

// TODO(26 okt. 2022) ~ Helge:  replace this shit with a i18n library that has been battle tested.
//  Known bugs:
//  - If you modify any language strings, you have to reload the page to se changes.
//  - If you call a string without any {placeholders} as a function it will throw an error that it "is not a function"
//  - If a language string is missing, either the page throws an error.
export const getLanguageStrings = (lang, pageId) => {
  // prevent mutations by getting a deep clone
  const strings = JSON.parse(JSON.stringify(_languages[lang][pageId]));
  for (const key in strings) {
    if (typeof strings[key] === "string" && strings[key].match(/\{.*\}/g)) {
      let annotatedString = strings[key];
      strings[key] = (values = {}) => {
        let string = annotatedString;
        for (const key of Object.keys(values)) {
          string = string.replaceAll(`{${key}}`, values[key]);
        }
        return string;
      };
    }
  }
  return strings;
};

const normaliseLangCode = (langCode) => {
  if (langCode === "no") return LANGUAGES.NB;
  if (langCode === "nb") return LANGUAGES.NB;

  if (![LANGUAGES.EN, LANGUAGES.NB, LANGUAGES.DA].includes(langCode)) {
    return LANGUAGES.EN;
  }

  return langCode;
};

const langCode = (code) => code?.[0] + code?.[1];

export const getLangCode = (code) => normaliseLangCode(langCode(code));

export const detectLanguage = () => {
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "";

  if (timeZone?.includes("Oslo")) return LANGUAGES.NB;
  if (timeZone?.includes("Copenhagen")) return LANGUAGES.DA;
  if (timeZone?.includes("London")) return LANGUAGES.EN;

  return getLangCode(navigator.language) ?? LANGUAGES.EN;
};

export const detectRegion = async () => {
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  // Guess region by checking timezone.
  if (timeZone?.includes("Oslo")) return "NORWAY";
  if (timeZone?.includes("Copenhagen")) return "DENMARK";
  if (timeZone?.includes("London")) return "UNITED_KINGDOM";

  // If that fails, perform IP Address Lookup
  return await fetch("https://extreme-ip-lookup.com/json/?key=75IyL1IsbaYGDfnOy5Dm")
    .then((res) => res.json())
    .then((res) => res?.country?.toUpperCase())
    .then((region) => {
      if (region === "UNITED KINGDOM") return "UNITED_KINGDOM";
      if (region === "DENMARK") return "DENMARK";
      if (region === "NORWAY") return "NORWAY";

      // Fallback to UK
      return "UNITED_KINGDOM";
    })
    .catch(function () {
      return "UNITED_KINGDOM";
    });
};
