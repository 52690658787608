const icon = (props) => (
  <svg
    width={props.width ?? 32}
    height={props.height ?? 32}
    {...props}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#EB6F93" />
    <path
      d="M21.2175 9H11.7825C11.3097 9 10.8564 9.19696 10.5221 9.54756C10.1878 9.89815 10 10.3737 10 10.8695C10 11.3653 10.1878 11.8408 10.5221 12.1914C10.8564 12.542 11.3097 12.7389 11.7825 12.7389H14.6907V21.1024C14.6907 21.6057 14.8813 22.0883 15.2206 22.4442C15.5599 22.8001 16.0201 23 16.5 23C16.9798 23 17.44 22.8001 17.7793 22.4442C18.1187 22.0883 18.3093 21.6057 18.3093 21.1024V12.739H21.2175C21.6903 12.739 22.1437 12.542 22.4779 12.1914C22.8122 11.8408 23 11.3653 23 10.8695C23 10.624 22.9539 10.3809 22.8643 10.1541C22.7747 9.92725 22.6435 9.72116 22.4779 9.54756C22.3124 9.37396 22.1159 9.23626 21.8997 9.14231C21.6834 9.04836 21.4516 9 21.2175 9Z"
      fill="white"
    />
  </svg>
);

export default icon;
