import React from "react";
import StoreIcon from "../../assets/storeIcon";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../../state/selectors";
import "./styles.scss";

const RatingFlatCard = ({ rating, history, categoriesStrs }) => {
  return (
    <div className="rating-flat-card">
      <div onClick={() => history.push(`/ratings/${rating?.id}`)}>
        <div className="heading-section">
          <div className="heading-section__icon">
            {rating?.logo ? <img src={rating?.logo} alt="Logo" /> : <StoreIcon />}
          </div>
          <div className="heading-section__content">
            <div className="heading-section__subcontent">
              <div className="heading-section__subcontent--heading">{rating?.name}</div>
            </div>
            <div className="heading-section__content--subheading">
              {rating?.category ? categoriesStrs[rating?.category] : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoriesStrs: selectorLanguageStrings(state, "categories"),
});

export default withRouter(connect(mapStateToProps, null)(RatingFlatCard));
