const Icon = ({ width = "9", height = "10", color = "#EB6F93", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9 10" fill="none" {...props}>
    <path
      d="M7.76598 0H1.23402C0.906736 0 0.592858 0.140687 0.361435 0.391111C0.130012 0.641535 0 0.981182 0 1.33534C0 1.68949 0.130012 2.02914 0.361435 2.27956C0.592858 2.52998 0.906736 2.67067 1.23402 2.67067H3.24742V8.64458C3.24742 9.00406 3.37938 9.34882 3.61429 9.60301C3.84919 9.8572 4.16779 10 4.49999 10C4.8322 10 5.1508 9.8572 5.3857 9.60301C5.62061 9.34882 5.75257 9.00406 5.75257 8.64458V2.67068H7.76598C8.09326 2.67068 8.40714 2.52999 8.63857 2.27957C8.86999 2.02915 9 1.6895 9 1.33535C9 1.15999 8.96808 0.986343 8.90607 0.824332C8.84405 0.66232 8.75316 0.515113 8.63857 0.391114C8.52398 0.267116 8.38794 0.168754 8.23822 0.101647C8.0885 0.0345394 7.92804 0 7.76598 0Z"
      fill={color}
    />
  </svg>
);

export default Icon;
