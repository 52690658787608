import React from "react";
import { withRouter } from "react-router-dom";
import {
  CUSTOMERIO_SITE_ID,
  FACEBOOK_PIXEL_ID,
  GOOGLE_AD_ID,
  GOOGLE_ADS_ID,
  GOOGLE_MEASUREMENT_ID,
  HOTJAR_HJID,
  HOTJAR_HJSV,
  IS_PRERENDER_CONTEXT,
  SNAPCHAT_PIXEL_ID,
  TIKTOK_PIXEL_ID,
} from "../../utils/configs";
import * as googleAnalytics from "./googleAnalytics4";
import * as facebookPixel from "./facebookPixel";
import * as snapchatPixel from "./snapchatPixel";
import * as tiktok from "./tiktokPixel";
import * as customerio from "./customerio";

import hotjar from "./hotjar";
import NativeAppClient from "../nativeAppClient";

export const trackEvent = (eventName, options) => {
  try {
    if (IS_PRERENDER_CONTEXT) return;
    if (GOOGLE_MEASUREMENT_ID) googleAnalytics.trackEvent(eventName, options);
    if (FACEBOOK_PIXEL_ID) facebookPixel.trackCustomEvent(eventName, options);
    if (TIKTOK_PIXEL_ID) tiktok.trackCustomEvent(eventName, options);
    if (CUSTOMERIO_SITE_ID) customerio.trackCustomEvent(eventName, options);
    if (NativeAppClient.isInNativeApp()) NativeAppClient.LogEventAF(eventName, options);
  } catch (error) {
    console.log("[Tjommi] trackEvent threw exception");
  }
};

export const trackConversion = async () => {
  try {
    if (GOOGLE_MEASUREMENT_ID && GOOGLE_ADS_ID && GOOGLE_AD_ID) googleAnalytics.trackConversion();
    if (FACEBOOK_PIXEL_ID) facebookPixel.trackConversion();
    if (SNAPCHAT_PIXEL_ID) snapchatPixel.trackConversion();
    if (TIKTOK_PIXEL_ID) tiktok.trackConversion();
    if (CUSTOMERIO_SITE_ID) customerio.trackConversion();
    if (NativeAppClient.isInNativeApp()) await NativeAppClient.LogEventAF("CompleteRegistration");
  } catch (error) {
    console.log("[Tjommi] trackConversion threw exception");
  }
};

class AnalyticsBase extends React.Component {
  componentDidMount() {
    if (IS_PRERENDER_CONTEXT) return;

    if (GOOGLE_MEASUREMENT_ID) {
      googleAnalytics.initialise(GOOGLE_MEASUREMENT_ID);
      if (GOOGLE_ADS_ID && GOOGLE_AD_ID) googleAnalytics.configAdsId(GOOGLE_ADS_ID, GOOGLE_AD_ID);
      googleAnalytics.pageView();
    }

    if (FACEBOOK_PIXEL_ID) {
      facebookPixel.initialise(FACEBOOK_PIXEL_ID);
      facebookPixel.pageView();
    }

    if (SNAPCHAT_PIXEL_ID) {
      snapchatPixel.initialise(SNAPCHAT_PIXEL_ID);
      snapchatPixel.pageView();
    }

    if (TIKTOK_PIXEL_ID) {
      tiktok.initialise(TIKTOK_PIXEL_ID);
      tiktok.pageView();
    }

    if (CUSTOMERIO_SITE_ID) {
      customerio.initialise(CUSTOMERIO_SITE_ID);
      customerio.pageView();
    }

    if (HOTJAR_HJID) hotjar(HOTJAR_HJID, HOTJAR_HJSV);
  }

  componentDidUpdate() {
    if (IS_PRERENDER_CONTEXT) return;
    if (GOOGLE_MEASUREMENT_ID) googleAnalytics.pageView();
    if (FACEBOOK_PIXEL_ID) facebookPixel.pageView();
    if (SNAPCHAT_PIXEL_ID) snapchatPixel.pageView();
    if (TIKTOK_PIXEL_ID) tiktok.pageView();
    if (CUSTOMERIO_SITE_ID) customerio.pageView();
  }

  render() {
    return null;
  }
}

export const Analytics = withRouter(AnalyticsBase);
