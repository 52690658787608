const Icon = ({ color = "white" }) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2175 10H11.7825C11.3097 10 10.8564 10.197 10.5221 10.5476C10.1878 10.8981 10 11.3737 10 11.8695C10 12.3653 10.1878 12.8408 10.5221 13.1914C10.8564 13.542 11.3097 13.7389 11.7825 13.7389H14.6907V22.1024C14.6907 22.6057 14.8813 23.0883 15.2206 23.4442C15.5599 23.8001 16.0201 24 16.5 24C16.9798 24 17.44 23.8001 17.7793 23.4442C18.1187 23.0883 18.3093 22.6057 18.3093 22.1024V13.739H21.2175C21.6903 13.739 22.1437 13.542 22.4779 13.1914C22.8122 12.8408 23 12.3653 23 11.8695C23 11.624 22.9539 11.3809 22.8643 11.1541C22.7747 10.9272 22.6435 10.7212 22.4779 10.5476C22.3124 10.374 22.1159 10.2363 21.8997 10.1423C21.6834 10.0484 21.4516 10 21.2175 10Z"
      fill={color}
    />
  </svg>
);

export default Icon;
