const Icon = ({ width = "189", height = "56", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 188 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M181.928 0H5.88093C2.63298 0 0 2.63298 0 5.88093V50.1189C0 53.3668 2.63298 55.9998 5.88093 55.9998H181.928C185.176 55.9998 187.809 53.3668 187.809 50.1189V5.88093C187.809 2.63298 185.176 0 181.928 0Z"
      fill="#222222"
    />
    <path
      d="M65.3591 39.8709C63.8734 39.8709 62.6277 39.368 61.6258 38.3623C60.62 37.3566 60.1172 36.1033 60.1172 34.5985C60.1172 33.0938 60.62 31.8404 61.6258 30.8347C62.6315 29.829 63.8772 29.3262 65.3591 29.3262C66.841 29.3262 68.1172 29.8747 69.0657 30.9757L68.1629 31.8481C67.4772 30.9947 66.5438 30.5643 65.3591 30.5643C64.2581 30.5643 63.3324 30.9338 62.5743 31.6766C61.8162 32.4195 61.4391 33.3909 61.4391 34.5947C61.4391 35.7985 61.8162 36.77 62.5743 37.5128C63.3286 38.2557 64.2581 38.6252 65.3591 38.6252C66.5705 38.6252 67.6067 38.1376 68.46 37.1585L69.3629 38.0614C68.9019 38.6176 68.3229 39.0557 67.6295 39.3795C66.9324 39.7033 66.1781 39.8671 65.3591 39.8671V39.8709Z"
      fill="white"
    />
    <path
      d="M71.8265 29.5527V32.7375L71.7693 33.6975H71.8265C72.0132 33.3699 72.3103 33.088 72.7141 32.8594C73.1179 32.6308 73.5484 32.5127 74.0093 32.5127C74.8627 32.5127 75.5179 32.7756 75.9674 33.3013C76.4169 33.827 76.6455 34.5318 76.6455 35.4156V39.6441H75.3503V35.6137C75.3503 34.3375 74.7827 33.6975 73.6436 33.6975C73.1065 33.6975 72.6722 33.9108 72.3332 34.3375C71.9941 34.7641 71.8265 35.2594 71.8265 35.8232V39.6441H70.5312V29.5527H71.8265Z"
      fill="white"
    />
    <path
      d="M79.4671 39.6465H78.1719V32.7399H79.4138V33.8675H79.4709C79.6043 33.4904 79.8709 33.1818 80.2823 32.938C80.69 32.6942 81.0862 32.5723 81.4747 32.5723C81.8633 32.5723 82.1833 32.6294 82.4461 32.7399L81.9509 33.9513C81.7833 33.8865 81.5509 33.8523 81.2614 33.8523C80.7928 33.8523 80.3738 34.0389 80.0157 34.4085C79.6538 34.778 79.4747 35.2656 79.4747 35.8675V39.6465H79.4671Z"
      fill="white"
    />
    <path
      d="M83.455 33.5612C84.1217 32.8602 84.9712 32.5098 86.0074 32.5098C87.0436 32.5098 87.8931 32.8602 88.5598 33.5612C89.2264 34.2621 89.5617 35.1383 89.5617 36.1898C89.5617 37.2412 89.2264 38.1174 88.5598 38.8183C87.8931 39.5193 87.0436 39.8697 86.0074 39.8697C84.9712 39.8697 84.1217 39.5193 83.455 38.8183C82.7884 38.1174 82.4531 37.2412 82.4531 36.1898C82.4531 35.1383 82.7884 34.2621 83.455 33.5612ZM84.4188 38.0031C84.8645 38.4602 85.3941 38.685 86.0036 38.685C86.6131 38.685 87.1426 38.4564 87.5884 38.0031C88.0341 37.5459 88.2588 36.944 88.2588 36.1936C88.2588 35.4431 88.0341 34.8374 87.5884 34.384C87.1426 33.9269 86.6131 33.7021 86.0036 33.7021C85.3941 33.7021 84.8645 33.9307 84.4188 34.384C83.9731 34.8374 83.7484 35.4431 83.7484 36.1936C83.7484 36.944 83.9731 37.5498 84.4188 38.0031Z"
      fill="white"
    />
    <path
      d="M90.6016 39.6451V32.7384H91.8435V33.6984H91.9006C92.0987 33.3708 92.3997 33.0889 92.8111 32.8603C93.2187 32.6318 93.6263 32.5137 94.0301 32.5137C95.1006 32.5137 95.8282 32.9556 96.213 33.8394C96.7768 32.9556 97.5806 32.5137 98.6244 32.5137C99.4434 32.5137 100.057 32.7727 100.472 33.2946C100.887 33.8165 101.093 34.5251 101.093 35.4165V39.6451H99.7977V35.6146C99.7977 34.9175 99.672 34.426 99.4168 34.1365C99.1615 33.847 98.7768 33.6984 98.2625 33.6984C97.7482 33.6984 97.333 33.9156 97.0015 34.346C96.6663 34.7765 96.5025 35.2718 96.5025 35.8241V39.6451H95.2073V35.6146C95.2073 34.9175 95.0816 34.426 94.8263 34.1365C94.5711 33.847 94.1863 33.6984 93.672 33.6984C93.1577 33.6984 92.7425 33.9156 92.4111 34.346C92.0758 34.7765 91.912 35.2718 91.912 35.8241V39.6451H90.6168H90.6016Z"
      fill="white"
    />
    <path
      d="M105.735 39.8697C104.691 39.8697 103.845 39.5231 103.19 38.8259C102.539 38.1326 102.211 37.2526 102.211 36.1898C102.211 35.184 102.527 34.3193 103.163 33.5955C103.8 32.8717 104.607 32.5098 105.594 32.5098C106.58 32.5098 107.438 32.845 108.055 33.5117C108.672 34.1783 108.977 35.0698 108.977 36.1898L108.961 36.4298H103.506C103.544 37.1269 103.777 37.6755 104.203 38.0793C104.63 38.4831 105.133 38.685 105.704 38.685C106.634 38.685 107.262 38.2888 107.594 37.5002L108.748 37.9802C108.523 38.5174 108.15 38.9631 107.636 39.325C107.118 39.6869 106.485 39.8659 105.731 39.8659L105.735 39.8697ZM107.594 35.3593C107.567 34.9631 107.384 34.5898 107.053 34.2317C106.718 33.8736 106.222 33.6945 105.567 33.6945C105.087 33.6945 104.672 33.8469 104.321 34.144C103.971 34.4412 103.727 34.8488 103.594 35.3555H107.598L107.594 35.3593Z"
      fill="white"
    />
    <path
      d="M124.697 29.5527L122.004 39.6441H120.678L118.507 32.9927H118.449L116.278 39.6441H114.952L112.148 29.5527H113.501L115.63 37.4879H115.687L117.874 30.9051H119.086L121.272 37.4879H121.329L123.345 29.5527H124.697Z"
      fill="white"
    />
    <path
      d="M128.063 39.8697C127.019 39.8697 126.173 39.5231 125.518 38.8259C124.867 38.1326 124.539 37.2526 124.539 36.1898C124.539 35.184 124.855 34.3193 125.491 33.5955C126.128 32.8717 126.935 32.5098 127.922 32.5098C128.909 32.5098 129.766 32.845 130.383 33.5117C131 34.1783 131.305 35.0698 131.305 36.1898L131.29 36.4298H125.834C125.872 37.1269 126.105 37.6755 126.531 38.0793C126.958 38.4831 127.461 38.685 128.032 38.685C128.962 38.685 129.59 38.2888 129.922 37.5002L131.076 37.9802C130.851 38.5174 130.478 38.9631 129.964 39.325C129.446 39.6869 128.813 39.8659 128.059 39.8659L128.063 39.8697ZM129.922 35.3593C129.895 34.9631 129.712 34.5898 129.381 34.2317C129.046 33.8736 128.55 33.6945 127.895 33.6945C127.415 33.6945 127 33.8469 126.65 34.144C126.299 34.4412 126.055 34.8488 125.922 35.3555H129.926L129.922 35.3593Z"
      fill="white"
    />
    <path
      d="M136.13 39.8689C135.604 39.8689 135.132 39.7546 134.713 39.5298C134.294 39.3051 133.981 39.0232 133.776 38.6841H133.718V39.6441H132.477V29.5527H133.772V32.7375L133.715 33.6975H133.772C133.978 33.3584 134.29 33.0765 134.709 32.8518C135.128 32.627 135.6 32.5127 136.126 32.5127C137.017 32.5127 137.791 32.8632 138.438 33.568C139.086 34.2727 139.41 35.1451 139.41 36.1889C139.41 37.2327 139.086 38.1051 138.438 38.8098C137.791 39.5146 137.021 39.8651 136.126 39.8651L136.13 39.8689ZM135.917 38.6841C136.518 38.6841 137.037 38.4556 137.467 38.0022C137.898 37.5489 138.115 36.9432 138.115 36.1927C138.115 35.4422 137.898 34.8365 137.467 34.3832C137.037 33.9299 136.518 33.7013 135.917 33.7013C135.315 33.7013 134.785 33.9261 134.358 34.3794C133.932 34.8289 133.718 35.4346 133.718 36.1965C133.718 36.9584 133.932 37.5641 134.358 38.0137C134.785 38.4632 135.303 38.6918 135.917 38.6918V38.6841Z"
      fill="white"
    />
    <path
      d="M146.352 39.8708C145.62 39.8708 144.904 39.6308 144.203 39.1508C143.502 38.6708 143.045 38.0003 142.828 37.1356L144.013 36.6556C144.154 37.2118 144.443 37.6765 144.881 38.0575C145.32 38.4384 145.811 38.6289 146.356 38.6289C146.9 38.6289 147.4 38.4803 147.8 38.1832C148.2 37.8861 148.398 37.486 148.398 36.9794C148.398 36.4156 148.2 35.9813 147.8 35.6765C147.4 35.3718 146.767 35.0861 145.902 34.8232C145.011 34.5413 144.333 34.1756 143.872 33.7299C143.411 33.2842 143.182 32.7127 143.182 32.0156C143.182 31.3184 143.468 30.6632 144.043 30.1261C144.615 29.5889 145.361 29.3223 146.283 29.3223C147.137 29.3223 147.834 29.5356 148.371 29.9623C148.908 30.3889 149.255 30.8575 149.415 31.3642L148.23 31.8556C148.146 31.5356 147.937 31.2385 147.601 30.968C147.266 30.6975 146.84 30.5604 146.314 30.5604C145.815 30.5604 145.392 30.6975 145.038 30.9756C144.683 31.2537 144.508 31.5965 144.508 32.0118C144.508 32.3889 144.672 32.7051 144.996 32.9642C145.32 33.2232 145.796 33.4556 146.428 33.6613C146.927 33.8213 147.342 33.9775 147.674 34.1261C148.009 34.2746 148.344 34.4765 148.687 34.7242C149.03 34.9718 149.289 35.2842 149.464 35.6537C149.64 36.0232 149.723 36.4537 149.723 36.9413C149.723 37.4289 149.62 37.867 149.419 38.2518C149.217 38.6365 148.95 38.9413 148.615 39.1699C148.28 39.3946 147.921 39.566 147.537 39.6841C147.152 39.8022 146.756 39.8594 146.352 39.8594V39.8708Z"
      fill="white"
    />
    <path
      d="M153.721 39.7544C152.997 39.7544 152.445 39.5639 152.064 39.1753C151.683 38.7867 151.493 38.2458 151.493 37.541V33.9182H150.281V32.7334H151.493V30.6191H152.788V32.7334H154.479V33.9182H152.788V37.442C152.788 38.1925 153.096 38.5696 153.717 38.5696C153.954 38.5696 154.148 38.5315 154.308 38.4553L154.757 39.5677C154.476 39.6896 154.129 39.7505 153.714 39.7505L153.721 39.7544Z"
      fill="white"
    />
    <path
      d="M156.244 33.5612C156.911 32.8602 157.76 32.5098 158.796 32.5098C159.833 32.5098 160.682 32.8602 161.349 33.5612C162.015 34.2621 162.351 35.1383 162.351 36.1898C162.351 37.2412 162.015 38.1174 161.349 38.8183C160.682 39.5193 159.833 39.8697 158.796 39.8697C157.76 39.8697 156.911 39.5193 156.244 38.8183C155.577 38.1174 155.242 37.2412 155.242 36.1898C155.242 35.1383 155.577 34.2621 156.244 33.5612ZM157.208 38.0031C157.654 38.4602 158.183 38.685 158.793 38.685C159.402 38.685 159.932 38.4564 160.377 38.0031C160.823 37.5459 161.048 36.944 161.048 36.1936C161.048 35.4431 160.823 34.8374 160.377 34.384C159.932 33.9269 159.402 33.7021 158.793 33.7021C158.183 33.7021 157.654 33.9307 157.208 34.384C156.762 34.8374 156.537 35.4431 156.537 36.1936C156.537 36.944 156.758 37.5498 157.208 38.0031Z"
      fill="white"
    />
    <path
      d="M164.686 39.6465H163.391V32.7399H164.633V33.8675H164.69C164.823 33.4904 165.09 33.1818 165.501 32.938C165.909 32.6942 166.309 32.5723 166.693 32.5723C167.078 32.5723 167.402 32.6294 167.665 32.7399L167.173 33.9513C167.006 33.8865 166.773 33.8523 166.484 33.8523C166.015 33.8523 165.596 34.0389 165.238 34.4085C164.876 34.778 164.697 35.2656 164.697 35.8675V39.6465H164.686Z"
      fill="white"
    />
    <path
      d="M171.211 39.8697C170.167 39.8697 169.322 39.5231 168.667 38.8259C168.015 38.1326 167.688 37.2526 167.688 36.1898C167.688 35.184 168.004 34.3193 168.64 33.5955C169.276 32.8717 170.084 32.5098 171.07 32.5098C172.057 32.5098 172.914 32.845 173.531 33.5117C174.148 34.1783 174.453 35.0698 174.453 36.1898L174.438 36.4298H168.983C169.021 37.1269 169.253 37.6755 169.68 38.0793C170.107 38.4831 170.609 38.685 171.181 38.685C172.11 38.685 172.739 38.2888 173.07 37.5002L174.225 37.9802C174 38.5174 173.627 38.9631 173.112 39.325C172.594 39.6869 171.962 39.8659 171.207 39.8659L171.211 39.8697ZM173.07 35.3593C173.044 34.9631 172.861 34.5898 172.529 34.2317C172.194 33.8736 171.699 33.6945 171.044 33.6945C170.564 33.6945 170.148 33.8469 169.798 34.144C169.447 34.4412 169.204 34.8488 169.07 35.3555H173.074L173.07 35.3593Z"
      fill="white"
    />
    <path
      d="M60.8866 23.976L63.7666 16.3379H64.8752L67.7552 23.976H66.6656L65.9304 21.9074H62.719L61.9723 23.976H60.8828H60.8866ZM63.0504 20.9893H65.5876L64.338 17.5455H64.2961L63.0466 20.9893H63.0504Z"
      fill="white"
    />
    <path
      d="M69.5089 23.9766L67.3984 18.75H68.4651L70.0003 22.8033H70.0232L71.5813 18.75H72.6251L70.4918 23.9766H69.5089Z"
      fill="white"
    />
    <path
      d="M74.802 24.1459C74.2458 24.1459 73.7849 23.9859 73.4116 23.6659C73.0382 23.3459 72.8516 22.923 72.8516 22.3973C72.8516 21.8297 73.0725 21.3802 73.5144 21.0602C73.9563 20.7364 74.4973 20.5764 75.1449 20.5764C75.7201 20.5764 76.1925 20.683 76.562 20.8964V20.7478C76.562 20.363 76.4287 20.0583 76.1658 19.8259C75.903 19.5935 75.5792 19.4792 75.1944 19.4792C74.9087 19.4792 74.6535 19.5478 74.4211 19.6811C74.1887 19.8183 74.0325 20.0011 73.9449 20.2373L73.0497 19.8526C73.1716 19.5402 73.4078 19.2506 73.7658 18.984C74.1201 18.7173 74.5925 18.584 75.1754 18.584C75.842 18.584 76.3982 18.7783 76.8401 19.1706C77.282 19.563 77.503 20.1116 77.503 20.824V23.9821H76.5658V23.2583H76.5239C76.1316 23.8563 75.5601 24.1535 74.8058 24.1535L74.802 24.1459ZM74.962 23.2506C75.3658 23.2506 75.7354 23.0983 76.0668 22.7973C76.3982 22.4964 76.562 22.1383 76.562 21.7268C76.2839 21.4983 75.8687 21.384 75.3125 21.384C74.8363 21.384 74.4782 21.4868 74.2344 21.6925C73.9944 21.8983 73.8725 22.1421 73.8725 22.4164C73.8725 22.6716 73.983 22.8735 74.2039 23.0259C74.4249 23.1744 74.6763 23.2506 74.962 23.2506Z"
      fill="white"
    />
    <path
      d="M79.6691 17.4348C79.5358 17.5681 79.372 17.6367 79.1777 17.6367C78.9834 17.6367 78.8234 17.5681 78.6863 17.4348C78.5529 17.3014 78.4844 17.1376 78.4844 16.9433C78.4844 16.749 78.5529 16.589 78.6863 16.4519C78.8196 16.3186 78.9834 16.25 79.1777 16.25C79.372 16.25 79.532 16.3186 79.6691 16.4519C79.8025 16.589 79.871 16.749 79.871 16.9433C79.871 17.1376 79.8025 17.2976 79.6691 17.4348ZM79.6691 23.9719H78.6863V18.7452H79.6691V23.9719Z"
      fill="white"
    />
    <path d="M81.9438 23.976H80.9609V16.3379H81.9438V23.976Z" fill="white" />
    <path
      d="M84.7473 24.1459C84.1912 24.1459 83.7302 23.9859 83.3569 23.6659C82.9835 23.3459 82.7969 22.923 82.7969 22.3973C82.7969 21.8297 83.0178 21.3802 83.4597 21.0602C83.9016 20.7364 84.4426 20.5764 85.0902 20.5764C85.6654 20.5764 86.1378 20.683 86.5073 20.8964V20.7478C86.5073 20.363 86.374 20.0583 86.1111 19.8259C85.8483 19.5935 85.5245 19.4792 85.1397 19.4792C84.854 19.4792 84.5988 19.5478 84.3664 19.6811C84.134 19.8183 83.9778 20.0011 83.8902 20.2373L82.995 19.8526C83.1169 19.5402 83.3531 19.2506 83.7112 18.984C84.0654 18.7173 84.5378 18.584 85.1207 18.584C85.7873 18.584 86.3435 18.7783 86.7854 19.1706C87.2273 19.563 87.4483 20.1116 87.4483 20.824V23.9821H86.5111V23.2583H86.4692C86.0769 23.8563 85.5054 24.1535 84.7512 24.1535L84.7473 24.1459ZM84.9073 23.2506C85.3112 23.2506 85.6807 23.0983 86.0121 22.7973C86.3435 22.4964 86.5073 22.1383 86.5073 21.7268C86.2292 21.4983 85.814 21.384 85.2578 21.384C84.7816 21.384 84.4235 21.4868 84.1797 21.6925C83.9397 21.8983 83.8178 22.1421 83.8178 22.4164C83.8178 22.6716 83.9283 22.8735 84.1492 23.0259C84.3702 23.1744 84.6216 23.2506 84.9073 23.2506Z"
      fill="white"
    />
    <path
      d="M91.3128 24.1474C90.9128 24.1474 90.5585 24.0636 90.2423 23.8922C89.9261 23.7207 89.6899 23.5074 89.5337 23.2522H89.4918V23.976H88.5547V16.3379H89.5375V18.7493L89.4956 19.4731H89.5375C89.6937 19.2179 89.9299 19.0045 90.2461 18.8331C90.5623 18.6617 90.9204 18.5779 91.3166 18.5779C91.9909 18.5779 92.5737 18.8445 93.0651 19.3779C93.5566 19.9112 93.8004 20.5741 93.8004 21.3626C93.8004 22.1512 93.5566 22.8141 93.0651 23.3474C92.5737 23.8807 91.9909 24.1474 91.3166 24.1474H91.3128ZM91.1528 23.2522C91.6061 23.2522 91.9985 23.0807 92.3261 22.7341C92.6537 22.3874 92.8175 21.9341 92.8175 21.3626C92.8175 20.7912 92.6537 20.3379 92.3261 19.9912C91.9985 19.6445 91.6061 19.4731 91.1528 19.4731C90.6994 19.4731 90.2994 19.6445 89.9756 19.9836C89.6518 20.3264 89.4918 20.7836 89.4918 21.3588C89.4918 21.9341 89.6518 22.395 89.9756 22.7341C90.2994 23.0769 90.6918 23.2445 91.1528 23.2445V23.2522Z"
      fill="white"
    />
    <path d="M95.6157 23.976H94.6328V16.3379H95.6157V23.976Z" fill="white" />
    <path
      d="M99.1276 24.1476C98.339 24.1476 97.6952 23.8848 97.2038 23.3591C96.7086 22.8333 96.4609 22.1667 96.4609 21.3629C96.4609 20.601 96.7009 19.9495 97.1809 19.401C97.6609 18.8524 98.2743 18.5781 99.0209 18.5781C99.7676 18.5781 100.415 18.8296 100.884 19.3362C101.352 19.8429 101.581 20.5172 101.581 21.3629L101.569 21.5457H97.44C97.4667 22.0714 97.6457 22.4867 97.9695 22.7953C98.2933 23.1 98.6705 23.2524 99.1047 23.2524C99.8095 23.2524 100.286 22.9553 100.533 22.3572L101.409 22.7191C101.238 23.1229 100.956 23.4657 100.568 23.7362C100.175 24.0105 99.6952 24.1476 99.1276 24.1476ZM100.537 20.7343C100.514 20.4372 100.377 20.1515 100.126 19.881C99.8743 19.6105 99.4971 19.4772 99.0019 19.4772C98.64 19.4772 98.3238 19.5915 98.0571 19.82C97.7905 20.0486 97.6076 20.3534 97.5086 20.7381H100.537V20.7343Z"
      fill="white"
    />
    <path
      d="M105.888 17.4348C105.755 17.5681 105.591 17.6367 105.396 17.6367C105.202 17.6367 105.042 17.5681 104.905 17.4348C104.768 17.3014 104.703 17.1376 104.703 16.9433C104.703 16.749 104.772 16.589 104.905 16.4519C105.038 16.3186 105.202 16.25 105.396 16.25C105.591 16.25 105.751 16.3186 105.888 16.4519C106.021 16.589 106.09 16.749 106.09 16.9433C106.09 17.1376 106.021 17.2976 105.888 17.4348ZM105.888 23.9719H104.905V18.7452H105.888V23.9719Z"
      fill="white"
    />
    <path
      d="M108.101 18.7514V19.4752H108.143C108.284 19.2276 108.509 19.0143 108.813 18.839C109.118 18.6638 109.446 18.5762 109.796 18.5762C110.444 18.5762 110.939 18.7743 111.278 19.1743C111.621 19.5705 111.789 20.1076 111.789 20.7743V23.9743H110.806V20.9228C110.806 19.9552 110.375 19.4714 109.514 19.4714C109.111 19.4714 108.779 19.6314 108.524 19.9552C108.269 20.279 108.139 20.6524 108.139 21.079V23.9704H107.156V18.7438H108.093L108.101 18.7514Z"
      fill="white"
    />
    <path
      d="M117.563 24.0627C117.014 24.0627 116.599 23.9179 116.31 23.6246C116.02 23.3313 115.879 22.9198 115.879 22.3865V19.6437H114.961V18.7484H115.879V17.1484H116.862V18.7484H118.142V19.6437H116.862V22.3103C116.862 22.8779 117.098 23.1637 117.567 23.1637C117.746 23.1637 117.894 23.137 118.016 23.0798L118.359 23.9218C118.146 24.0132 117.883 24.0589 117.57 24.0589L117.563 24.0627Z"
      fill="white"
    />
    <path
      d="M120.088 16.3377V18.7491L120.046 19.4729H120.088C120.229 19.2253 120.454 19.012 120.759 18.8367C121.063 18.6615 121.391 18.5739 121.742 18.5739C122.389 18.5739 122.884 18.772 123.223 19.172C123.566 19.5682 123.734 20.1053 123.734 20.772V23.972H122.751V20.9205C122.751 19.9529 122.321 19.4691 121.46 19.4691C121.056 19.4691 120.724 19.6291 120.469 19.9529C120.214 20.2767 120.084 20.6501 120.084 21.0767V23.9681H119.102V16.3301H120.084L120.088 16.3377Z"
      fill="white"
    />
    <path
      d="M127.276 24.1476C126.487 24.1476 125.844 23.8848 125.352 23.3591C124.857 22.8333 124.609 22.1667 124.609 21.3629C124.609 20.601 124.849 19.9495 125.329 19.401C125.809 18.8524 126.423 18.5781 127.169 18.5781C127.916 18.5781 128.564 18.8296 129.032 19.3362C129.501 19.8429 129.729 20.5172 129.729 21.3629L129.718 21.5457H125.588C125.615 22.0714 125.794 22.4867 126.118 22.7953C126.442 23.1 126.819 23.2524 127.253 23.2524C127.958 23.2524 128.434 22.9553 128.682 22.3572L129.558 22.7191C129.386 23.1229 129.105 23.4657 128.716 23.7362C128.324 24.0105 127.844 24.1476 127.276 24.1476ZM128.686 20.7343C128.663 20.4372 128.526 20.1515 128.274 19.881C128.023 19.6105 127.646 19.4772 127.15 19.4772C126.788 19.4772 126.472 19.5915 126.206 19.82C125.939 20.0486 125.756 20.3534 125.657 20.7381H128.686V20.7343Z"
      fill="white"
    />
    <mask
      id="mask0_3818_29816"
      style={{ madkType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="9"
      width="43"
      height="38"
    >
      <path
        d="M8.5 9.94531V43.3433C8.5 44.9166 9.78762 46.2042 11.3609 46.2042H47.6199C49.1932 46.2042 50.4808 44.9166 50.4808 43.3433V9.94531H8.5ZM34.2637 17.5796H24.7209C23.6657 17.5796 22.8123 16.7262 22.8123 15.671C22.8123 14.6158 23.6657 13.7624 24.7209 13.7624H34.2637C35.319 13.7624 36.1723 14.6158 36.1723 15.671C36.1723 16.7262 35.319 17.5796 34.2637 17.5796Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3818_29816)">
      <path d="M8.5 9.94531H50.4846V46.2042H8.5V9.94531Z" fill="#EEEEEE" />
    </g>
    <mask
      id="mask1_3818_29816"
      style={{ madkType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="9"
      width="43"
      height="38"
    >
      <path
        d="M8.5 9.94531V43.3433C8.5 44.9166 9.78762 46.2042 11.3609 46.2042H47.6199C49.1932 46.2042 50.4808 44.9166 50.4808 43.3433V9.94531H8.5ZM34.2637 17.5796H24.7209C23.6657 17.5796 22.8123 16.7262 22.8123 15.671C22.8123 14.6158 23.6657 13.7624 24.7209 13.7624H34.2637C35.319 13.7624 36.1723 14.6158 36.1723 15.671C36.1723 16.7262 35.319 17.5796 34.2637 17.5796Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_3818_29816)">
      <path
        d="M34.2795 13.7578H24.7367C23.6815 13.7578 22.8281 14.6111 22.8281 15.6664C22.8281 16.7216 23.6815 17.5749 24.7367 17.5749H34.2795C35.3348 17.5749 36.1881 16.7216 36.1881 15.6664C36.1881 14.6111 35.3348 13.7578 34.2795 13.7578Z"
        fill="white"
      />
    </g>
    <mask
      id="mask2_3818_29816"
      style={{ madkType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="9"
      width="43"
      height="38"
    >
      <path
        d="M8.5 9.94531V43.3433C8.5 44.9166 9.78762 46.2042 11.3609 46.2042H47.6199C49.1932 46.2042 50.4808 44.9166 50.4808 43.3433V9.94531H8.5ZM34.2637 17.5796H24.7209C23.6657 17.5796 22.8123 16.7262 22.8123 15.671C22.8123 14.6158 23.6657 13.7624 24.7209 13.7624H34.2637C35.319 13.7624 36.1723 14.6158 36.1723 15.671C36.1723 16.7262 35.319 17.5796 34.2637 17.5796Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_3818_29816)">
      <mask
        id="mask3_3818_29816"
        style={{ madkType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="9"
        width="43"
        height="38"
      >
        <path
          d="M8.49219 9.94336V43.3413C8.49219 44.9147 9.7798 46.2023 11.3531 46.2023H47.6121C49.1854 46.2023 50.473 44.9147 50.473 43.3413V9.94336H8.49219ZM34.2559 17.5776H24.7131C23.6579 17.5776 22.8045 16.7243 22.8045 15.6691C22.8045 14.6138 23.6579 13.7605 24.7131 13.7605H34.2559C35.3112 13.7605 36.1645 14.6138 36.1645 15.6691C36.1645 16.7243 35.3112 17.5776 34.2559 17.5776Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_3818_29816)">
        <path d="M8.49219 9.94336H50.4768V28.0728H8.49219V9.94336Z" fill="#EFEFEF" />
      </g>
      <path d="M8.5 27.8301H50.4846V28.0701H8.5V27.8301Z" fill="#EFEFEF" />
      <path d="M8.5 28.0684H50.4846V28.3084H8.5V28.0684Z" fill="#EFEFEF" />
      <path
        d="M34.2637 17.5698H24.7209C23.7076 17.5698 22.8809 16.7774 22.82 15.7793C22.8161 15.8212 22.8123 15.8593 22.8123 15.8974C22.8123 16.9527 23.6657 17.806 24.7209 17.806H34.2637C35.319 17.806 36.1723 16.9527 36.1723 15.8974C36.1723 15.8555 36.1723 15.8174 36.1647 15.7793C36.1037 16.7774 35.2771 17.5698 34.2637 17.5698ZM8.5 9.93555V10.1755H50.4846V9.93555H8.5Z"
        fill="#EFEFEF"
      />
      <path
        d="M24.7209 13.7518H34.2637C35.2771 13.7518 36.1037 14.5442 36.1647 15.5423C36.1647 15.5004 36.1723 15.4623 36.1723 15.4242C36.1723 14.369 35.319 13.5156 34.2637 13.5156H24.7209C23.6657 13.5156 22.8123 14.369 22.8123 15.4242C22.8123 15.4661 22.8123 15.5042 22.82 15.5423C22.8809 14.5442 23.7076 13.7518 24.7209 13.7518ZM47.6199 45.9574H11.3609C9.78762 45.9574 8.5 44.6698 8.5 43.0965V43.3365C8.5 44.9098 9.78762 46.1974 11.3609 46.1974H47.6199C49.1932 46.1974 50.4808 44.9098 50.4808 43.3365V43.0965C50.4808 44.6698 49.1932 45.9574 47.6199 45.9574Z"
        fill="#EFEFEF"
      />
      <path
        d="M8.5 9.93555V43.3335C8.5 44.9069 9.78762 46.1945 11.3609 46.1945H47.6199C49.1932 46.1945 50.4808 44.9069 50.4808 43.3335V9.93555H8.5ZM34.2637 17.5698H24.7209C23.6657 17.5698 22.8123 16.7165 22.8123 15.6612C22.8123 14.606 23.6657 13.7527 24.7209 13.7527H34.2637C35.319 13.7527 36.1723 14.606 36.1723 15.6612C36.1723 16.7165 35.319 17.5698 34.2637 17.5698Z"
        fill="url(#paint0_radial_3818_29816)"
      />
      <mask
        id="mask4_3818_29816"
        style={{ madkType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="11"
        y="25"
        width="37"
        height="37"
      >
        <mask
          id="mask5_3818_29816"
          style={{ madkType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="11"
          y="25"
          width="37"
          height="37"
        >
          <mask
            id="mask6_3818_29816"
            style={{ madkType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="11"
            y="25"
            width="37"
            height="37"
          >
            <mask
              id="mask7_3818_29816"
              style={{ madkType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="11"
              y="25"
              width="37"
              height="37"
            >
              <path
                d="M29.4654 61.4601C39.478 61.4601 47.5949 53.3433 47.5949 43.3306C47.5949 33.318 39.478 25.2012 29.4654 25.2012C19.4528 25.2012 11.3359 33.318 11.3359 43.3306C11.3359 53.3433 19.4528 61.4601 29.4654 61.4601Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask7_3818_29816)">
              <path
                d="M29.4654 61.4601C39.478 61.4601 47.5949 53.3433 47.5949 43.3306C47.5949 33.318 39.478 25.2012 29.4654 25.2012C19.4528 25.2012 11.3359 33.318 11.3359 43.3306C11.3359 53.3433 19.4528 61.4601 29.4654 61.4601Z"
                fill="white"
              />
            </g>
          </mask>
          <g mask="url(#mask6_3818_29816)">
            <path
              d="M29.481 61.464C39.4937 61.464 47.6105 53.3472 47.6105 43.3345C47.6105 33.3219 39.4937 25.2051 29.481 25.2051C19.4684 25.2051 11.3516 33.3219 11.3516 43.3345C11.3516 53.3472 19.4684 61.464 29.481 61.464Z"
              fill="white"
            />
          </g>
        </mask>
        <g mask="url(#mask5_3818_29816)">
          <path
            d="M29.481 61.466C39.4937 61.466 47.6105 53.3491 47.6105 43.3365C47.6105 33.3239 39.4937 25.207 29.481 25.207C19.4684 25.207 11.3516 33.3239 11.3516 43.3365C11.3516 53.3491 19.4684 61.466 29.481 61.466Z"
            fill="white"
          />
        </g>
      </mask>
      <g mask="url(#mask4_3818_29816)">
        <path
          d="M14.25 25.207V47.4584H22.3643L29.5033 35.0965H47.6327V25.207H14.25ZM14.25 25.207V47.4584H22.3643L29.5033 35.0965H47.6327V25.207H14.25Z"
          fill="#DB4437"
        />
        <path
          d="M11.3672 61.4644H28.6243L36.6357 53.453V47.4568H22.3614L11.3672 28.6035V61.4644ZM11.3672 61.4644H28.6243L36.6357 53.453V47.4568H22.3614L11.3672 28.6035V61.4644Z"
          fill="#0F9D58"
        />
        <path
          d="M29.4858 35.0918L36.6248 47.4537L28.6172 61.465H47.619V35.0918H29.4896H29.4858ZM29.4858 35.0918L36.6248 47.4537L28.6172 61.465H47.619V35.0918H29.4896H29.4858Z"
          fill="#FFCD40"
        />
        <path
          d="M29.5094 51.5774C34.0623 51.5774 37.7532 47.8865 37.7532 43.3336C37.7532 38.7807 34.0623 35.0898 29.5094 35.0898C24.9565 35.0898 21.2656 38.7807 21.2656 43.3336C21.2656 47.8865 24.9565 51.5774 29.5094 51.5774Z"
          fill="#F1F1F1"
        />
        <path
          d="M29.5083 49.9288C33.1502 49.9288 36.1026 46.9764 36.1026 43.3345C36.1026 39.6926 33.1502 36.7402 29.5083 36.7402C25.8664 36.7402 22.9141 39.6926 22.9141 43.3345C22.9141 46.9764 25.8664 49.9288 29.5083 49.9288Z"
          fill="#4285F4"
        />
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_3818_29816"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.38286 9.78031) scale(54.2932 54.2932)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default Icon;
