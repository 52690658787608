import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../state/selectors";
import "./styles.scss";

const TotalSavings = ({ strs, amount }) => (
  <div className="totalSavings">
    <div className="totalSavings--label">{strs.title}</div>
    <div className="totalSavings--amount">
      {amount ? amount : 0}
      {" kr"}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "totalSavings"),
});

export default connect(mapStateToProps)(TotalSavings);
