const Icon = ({ width = "24", height = "24", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z"
      stroke="#545454"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11.0594C10 9.92203 10.8954 9 12 9C13.1046 9 14 9.92203 14 11.0594C14 12.1968 13.1046 13.1188 12 13.1188M12 15V14.9209"
      stroke="#545454"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
