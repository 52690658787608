import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { setGlobalState } from "../../state/actionCreators";
import { selectorIsAppMode, selectorIsAuthed, selectorUserOnBoard, selectorMyUser } from "../../state/selectors";
import setPage from "../../utils/page";
import routes from "../../utils/routes";

const EnhancedRoute = (props) => {
  const {
    // route
    path,
    exact,
    component: Component,
    subroutes,
    redirect,
    requiresAuthorization,
    isAppModeAllowed,
    isAuthorizedAllowed,

    // SEO
    title,
    description,
    keywords,

    // state props
    isAppMode,
    isAuthed,
    isOnBoard,

    ...rest
  } = props;

  if (props.user && props.user.is_deleted) {
    return <Redirect to={routes.signout.path} />;
  }

  if (!!redirect) return <Redirect to={redirect} />;

  if (isAppModeAllowed === false && isAppMode) {
    return <Redirect to={isOnBoard ? routes.overview.path : routes.onboarding.path} />;
  }

  if (!isOnBoard && props.requiresAuthorization) {
    return <Redirect to={routes.onboarding.path} />;
  }

  if (isAuthorizedAllowed === false && isOnBoard) {
    return <Redirect to={routes.overview.path} />;
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={(routeProps) => {
        setPage(title, description, keywords);
        if (![routes.overview.path].includes(path)) window.scrollTo(0, 0);
        return <Component {...rest} {...routeProps} />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
  isAuthed: selectorIsAuthed(state),
  isOnBoard: selectorUserOnBoard(state),
  user: selectorMyUser(state),
});

const mapDispatchToProps = { setGlobalState };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnhancedRoute));
