import React from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconArrowRight from "../../icons/arrowRight";
import IconGoogle from "../../icons/google";
import IconIcloud from "../../icons/icloud";
import IconOutlook from "../../icons/outlook";
import IconSpinner from "../../icons/spinner";
import MedalIcon from "../../icons/medal";
import IconYahoo from "../../icons/yahoo";
import "./styles.scss";

class Button extends React.Component {
  handleClick = () => {
    if (this.props.disabled) return;
    if (this.props.onClick) this.props.onClick();
    if (this.props.to) {
      if (this.props.to.startsWith("tel:") || this.props.to.startsWith("mailto:")) {
        window.location.href = this.props.to;
      } else {
        this.props.history.push(this.props.to);
      }
    }
  };

  renderIcon() {
    if (this.props.icon === "google") return <IconGoogle />;
    if (this.props.icon === "outlook") return <IconOutlook />;
    if (this.props.icon === "icloud") return <IconIcloud />;
    if (this.props.icon === "yahoo") return <IconYahoo />;

    if (this.props.icon === "spinner") return <IconSpinner />;
    if (this.props.icon === "arrowRight") return <IconArrowRight />;
    if (this.props.icon === "medalIcon") return <MedalIcon />;

    return null;
  }

  render() {
    let iconPosition = this.props.iconPosition;
    if (iconPosition === "default" && this.props.icon === "spinner") iconPosition = "right";
    return (
      <div
        className={classNames({
          "inputButton": true,
          "inputButton--disabled": this.props.disabled,
          [`inputButton--${this.props.type}`]: true,
          [`inputButton--${this.props.size}`]: true,
          [this.props.className]: this.props.className,
        })}
        onClick={this.handleClick}
      >
        <div className="inputButton--buttonContentWrapper">
          <div
            className={classNames({
              leftIcon: this.props.icon && iconPosition !== "right",
            })}
          >
            {this.props.icon && (iconPosition === "default" || iconPosition === "left") && this.renderIcon()}
          </div>
          <div
            className={classNames({
              labelWithIcon: !!this.props.icon,
              labelFixedWidth: !!this.props.fixedWidth,
            })}
            style={{
              width: this.props.fixedWidth ?? "auto",
            }}
          >
            {this.props.label}
          </div>
          <div
            className={classNames({
              rightIcon: this.props.icon && iconPosition === "right",
            })}
          >
            {this.props.icon && iconPosition === "right" && this.renderIcon()}
          </div>
        </div>
      </div>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["default", "transparent", "warning", "danger", "disabled", "pink", "lightPink"]).isRequired,
  size: PropTypes.oneOf(["default", "small", "large"]).isRequired,
  icon: PropTypes.oneOf([
    false,
    undefined,
    "google",
    "outlook",
    "yahoo",
    "icloud",

    "spinner",
    "arrowRight",
    "medalIcon",
  ]),
  iconPosition: PropTypes.oneOf([undefined, "default", "left", "right"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

Button.defaultProps = {
  type: "default",
  size: "default",
  iconPosition: "default",
};

export default withRouter(Button);
