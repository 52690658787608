import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../state/selectors";
import GenericModal from "../../genericModal";
import Button from "../../inputs/button";
import IMAGE from "./assets/dataSecurity.png";
import { getConnectEmailUrl } from "../../inputs/buttonSigninWith";
import routes from "../../../utils/routes";
import { ControlsLayout } from "../../layouts/controls";
import { Link } from "react-router-dom";

const DataSecurityModal = ({ visible, closeBtn, strs, children = null }) => {
  const handleConnectClick = (provider) => () => {
    window.location = getConnectEmailUrl(provider, routes.profileConfirmation.subroutes.emailConnected.path);
  };

  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn}
      backgroundImage={IMAGE}
      backgroundColor={"#505EE8"}
      title={strs.dataSecurityModal.title}
      text={
        <>
          {strs.dataSecurityModal.text}{" "}
          <Link
            to={routes.privacy.path + "?redirect=/onboarding"}
            style={{
              color: "white",
              textDecoration: "underline",
            }}
          >
            {strs.dataSecurityModal.readOurPolicy}
          </Link>
        </>
      }
      button={
        <div className="buttonsWrapper">
          <ControlsLayout variant={"row"}>
            {children ? (
              children
            ) : (
              <>
                <Button icon={"google"} type="transparent" label="Gmail" onClick={handleConnectClick("google")} />
                <Button icon={"outlook"} type="transparent" label="Outlook" onClick={handleConnectClick("gmail")} />
              </>
            )}
          </ControlsLayout>
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "profileEmails"),
});

export default connect(mapStateToProps)(DataSecurityModal);
