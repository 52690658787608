const icon = () => (
  <svg
    className="iconOutlook"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0846 0.182617H6.41471C5.98148 0.182617 5.625 0.536506 5.625 0.966583V1.85768L11.0752 3.53291L16.8743 1.85768V0.966583C16.8743 0.536506 16.5178 0.182617 16.0846 0.182617Z"
      fill="#0364B8"
    />
    <path
      d="M17.8017 8.78638C17.8841 8.52931 17.9498 8.26728 17.9985 8.00185C17.9986 7.86905 17.9271 7.74599 17.8112 7.67963L17.804 7.67573L17.8017 7.67468L11.7056 4.22724C11.6794 4.21035 11.6521 4.19483 11.6242 4.18086C11.3881 4.06471 11.1104 4.06471 10.8744 4.18086C10.8464 4.19483 10.8191 4.21026 10.7928 4.22724L4.69686 7.67468L4.69456 7.67573L4.6872 7.67963C4.57143 7.74599 4.49992 7.86905 4.5 8.00185C4.54868 8.26728 4.61446 8.52931 4.69686 8.78638L11.1607 13.4795L17.8017 8.78638Z"
      fill="#0A2767"
    />
    <path
      d="M13.4983 1.85693H9.5611L8.42432 3.53216L9.5611 5.20722L13.4983 8.55743H16.8731V5.20722L13.4983 1.85693Z"
      fill="#28A8EA"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.625 1.85693H9.56225V5.20715H5.625V1.85693Z" fill="#0078D4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5 1.85693H16.8747V5.20715H13.5V1.85693Z" fill="#50D9FF" />
    <path
      d="M13.4995 8.56017L9.56226 5.20996H5.625V8.56017L9.56226 11.9105L15.6548 12.8976L13.4995 8.56017Z"
      fill="#0364B8"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5625 5.20996H13.4998V8.56017H9.5625V5.20996Z" fill="#0078D4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.625 8.55859H9.56225V11.9088H5.625V8.55859Z" fill="#064A8C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5 8.55859H16.8747V11.9088H13.5V8.55859Z" fill="#0078D4" />
    <path
      d="M11.3579 13.1506L4.72412 8.34864L5.00198 7.86341C5.00198 7.86341 11.0456 11.2806 11.1378 11.332C11.2143 11.3625 11.3002 11.3601 11.3747 11.3253C11.4607 11.2773 17.5235 7.84668 17.5235 7.84668L17.8025 8.33191L11.3579 13.1506Z"
      fill="#0A2767"
      fillOpacity="0.498039"
    />
    <path
      d="M17.8114 8.32262L17.8041 8.32709L17.8024 8.32822L11.7065 11.7757C11.4605 11.9331 11.149 11.9523 10.8853 11.8264L13.0085 14.6523L17.6511 15.6558V15.658C17.87 15.5009 17.9999 15.2484 17.9998 14.9802V8.00049C17.9999 8.13321 17.9283 8.25634 17.8126 8.32262H17.8114Z"
      fill="#1490DF"
    />
    <path
      d="M17.9993 14.9812V14.5691L12.3842 11.3931L11.7064 11.7761C11.4605 11.9335 11.149 11.9529 10.8853 11.8269L13.0085 14.6529L17.6511 15.6562V15.6585C17.87 15.5013 17.9998 15.2488 17.9998 14.9806L17.9993 14.9812Z"
      fill="black"
      fillOpacity="0.047059"
    />
    <path
      d="M17.9712 15.1948L11.8161 11.7139L11.7064 11.7758C11.4605 11.9333 11.149 11.9526 10.8853 11.8266L13.0085 14.6526L17.6511 15.6559V15.6582C17.8086 15.5448 17.9219 15.3808 17.9717 15.1942L17.9712 15.1948Z"
      fill="black"
      fillOpacity="0.098039"
    />
    <path
      d="M4.69687 8.32993V8.32432H4.6913L4.67437 8.3132C4.56522 8.24651 4.49895 8.12768 4.50001 8.00049V14.9813C4.50001 15.4401 4.88038 15.8177 5.34266 15.8177H17.1556C17.2258 15.817 17.2957 15.8077 17.3636 15.7898C17.3989 15.7837 17.433 15.7724 17.4649 15.7563C17.4769 15.7551 17.4883 15.7513 17.4986 15.7451C17.5446 15.7264 17.5881 15.7021 17.628 15.6725L17.6505 15.6558L4.69687 8.32993Z"
      fill="#28A8EA"
    />
    <path
      d="M10.1247 12.8418V3.72187C10.1234 3.31397 9.78578 2.97876 9.37489 2.97754H5.64243V7.14071L4.69685 7.67565L4.69465 7.67679L4.68736 7.68069C4.57151 7.74713 4.5 7.87018 4.5 8.00282V13.5883V13.586H9.37489C9.78578 13.5849 10.1234 13.2496 10.1247 12.8418Z"
      fill="black"
      fillOpacity="0.098039"
    />
    <path
      d="M9.56225 13.3999V4.27997C9.56103 3.87215 9.22328 3.53694 8.81247 3.53564H5.64243V7.14049L4.69685 7.67543L4.69465 7.67657L4.68736 7.68046C4.57151 7.7469 4.5 7.86996 4.5 8.0026V14.1465V14.1442H8.81247C9.22328 14.143 9.56103 13.8078 9.56225 13.3999ZM9.56225 12.2831V4.27997C9.56103 3.87215 9.22328 3.53694 8.81247 3.53564H5.64243V7.14049L4.69685 7.67543L4.69465 7.67657L4.68736 7.68046C4.57151 7.7469 4.5 7.86996 4.5 8.0026V13.0297V13.0275H8.81247C9.22328 13.0262 9.56103 12.691 9.56225 12.2831ZM8.99975 12.2831V4.27997C8.99853 3.87215 8.66086 3.53694 8.25005 3.53564H5.64243V7.14049L4.69685 7.67543L4.69465 7.67657L4.68736 7.68046C4.57151 7.7469 4.5 7.86996 4.5 8.0026V13.0297V13.0275H8.25005C8.66086 13.0262 8.99853 12.691 8.99975 12.2831Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M0.749782 3.53564H8.24907C8.66037 3.53564 8.99885 3.87158 8.99885 4.27989V11.7247C8.99885 12.133 8.66037 12.4691 8.24907 12.4691H0.749782C0.338482 12.4691 0 12.133 0 11.7247V4.27989C0 3.87158 0.338482 3.53564 0.749782 3.53564Z"
      fill="#0078D4"
    />
    <path
      d="M2.17602 6.58915C2.37525 6.16768 2.69695 5.81493 3.09958 5.57629C3.54574 5.32271 4.05375 5.19641 4.56765 5.21111C5.04358 5.20072 5.51338 5.32052 5.92542 5.55729C6.31315 5.7865 6.62545 6.12276 6.82419 6.52498C7.04068 6.96813 7.14868 7.4558 7.13919 7.94825C7.14974 8.46297 7.03855 8.97305 6.8147 9.43748C6.61155 9.85391 6.28984 10.2023 5.88942 10.4392C5.46134 10.6834 4.97404 10.8065 4.48043 10.7949C3.99418 10.8063 3.51399 10.6852 3.0923 10.4447C2.70154 10.2151 2.38547 9.87852 2.18224 9.47541C1.96435 9.03859 1.85513 8.55621 1.86388 8.06887C1.85423 7.55854 1.96116 7.05269 2.17659 6.58915H2.17602ZM3.16029 8.96671C3.26657 9.2332 3.44682 9.46445 3.68008 9.63339C3.91744 9.79844 4.202 9.88356 4.49164 9.87633C4.80025 9.88843 5.10454 9.80063 5.35842 9.62616C5.58882 9.45722 5.76432 9.22476 5.86299 8.95778C5.97394 8.66018 6.02859 8.34479 6.02442 8.02753C6.02785 7.70751 5.97647 7.38911 5.87256 7.08615C5.78093 6.8134 5.61173 6.57266 5.38542 6.39324C5.13824 6.21 4.83478 6.11691 4.52657 6.12967C4.23064 6.12203 3.93969 6.20764 3.69579 6.37423C3.45819 6.54374 3.27394 6.77693 3.16479 7.04651C2.92343 7.66373 2.92204 8.3486 3.16086 8.96671H3.16029Z"
      fill="white"
    />
  </svg>
);

export default icon;
