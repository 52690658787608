import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../state/selectors";
import { setGlobalState, setToastMessage } from "../../state/actionCreators";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import ApiClient from "../../services/apiClient";
import routes from "../../utils/routes";
import "./styles.scss";

class ViewAdmin extends React.Component {
  state = {
    pass: "",
  };

  handleChange = (event) => this.setState({ pass: event.target.value });

  handleSubmit = () => {
    ApiClient.getTestUserToken(this.state.pass)
      .then((userToken) => {
        ApiClient.token = userToken;
        this.props.setGlobalState({ userToken });
        this.props.history.push(routes.overview.path);
      })
      .catch(() => this.props.setToastMessage(this.props.globalStrs.errorAlertMessage));
  };

  render = () => {
    return (
      <div className="viewAdmin">
        <MainContentLayout center>
          <HeaderLayout
            left={{
              type: "arrowLeft",
              path: routes.onboarding.path,
              label: "Back",
            }}
          />
          <br />
          <br />
          <input
            placeholder="Admin pass"
            value={this.state.pass}
            onChange={this.handleChange}
            onBlur={this.handleSubmit}
          />
        </MainContentLayout>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  globalStrs: selectorLanguageStrings(state, "global"),
});

const mapDispatchToProps = { setGlobalState, setToastMessage };

export default connect(mapStateToProps, mapDispatchToProps)(ViewAdmin);
