const icon = ({ width = "87", height = "87", color = "#222222" }) => (
  <svg width={width} height={height} viewBox="0 0 87 87" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_304_2940)">
      <g mask="url(#mask0_304_2940)">
        <path
          d="M66.1539 15.225L54.9179 15.225C50.5679 15.225 46.882 16.7359 43.8602 19.7577C40.8413 22.7766 39.3318 26.461 39.3318 30.811L39.3318 66.1548L26.3688 53.1961L24.2852 55.2798L40.7804 71.775L57.2756 55.2798L55.1919 53.1961L42.2289 66.1548L42.2289 30.811C42.2289 27.2469 43.4672 24.2411 45.9438 21.7935C48.4233 19.3488 51.4147 18.1264 54.9179 18.1264L66.1539 18.1264L66.1539 15.225Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_304_2940"
        x="20.2852"
        y="15.225"
        width="49.8672"
        height="64.55"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_304_2940" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_304_2940" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default icon;
