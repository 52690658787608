const Icon = ({ width = "18", height = "18", color = "#222222", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 27" fill="none" {...props}>
    <path
      d="M1 2.25827C1 1.56335 1.53856 1 2.20291 1H18.7254C19.3897 1 19.9283 1.56334 19.9283 2.25827V10.8504C19.9283 11.1652 19.6843 11.4204 19.3833 11.4204C19.0823 11.4204 18.8383 11.1652 18.8383 10.8504V2.25827C18.8383 2.19304 18.7878 2.14016 18.7254 2.14016H2.20291C2.14056 2.14016 2.09001 2.19304 2.09001 2.25827V22.6509C2.09001 22.7161 2.14056 22.769 2.20291 22.769H12.4837C12.7847 22.769 13.0288 23.0242 13.0288 23.3391C13.0288 23.6539 12.7847 23.9091 12.4837 23.9091H2.20291C1.53856 23.9091 1 23.3458 1 22.6509V2.25827Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2564 22.1569C23.9227 21.2534 24.3191 20.121 24.3191 18.8916C24.3191 15.9368 22.029 13.5414 19.2041 13.5414C16.3792 13.5414 14.0892 15.9368 14.0892 18.8916C14.0892 21.8465 16.3792 24.2419 19.2041 24.2419C20.3795 24.2419 21.4622 23.8273 22.326 23.1302L24.8769 25.7984C25.1338 26.0672 25.5504 26.0672 25.8073 25.7984C26.0642 25.5297 26.0642 25.0939 25.8073 24.8252L23.2564 22.1569Z"
      fill={color}
    />
    <path
      d="M5.11263 5.97578C4.81163 5.97578 4.56763 6.23101 4.56763 6.54586C4.56763 6.86071 4.81163 7.11594 5.11263 7.11594H14.6264C14.9274 7.11594 15.1714 6.86071 15.1714 6.54586C15.1714 6.23101 14.9274 5.97578 14.6264 5.97578H5.11263Z"
      fill={color}
    />
    <path
      d="M5.11263 11.2625C4.81163 11.2625 4.56763 11.5178 4.56763 11.8326C4.56763 12.1475 4.81163 12.4027 5.11263 12.4027H12.248C12.549 12.4027 12.793 12.1475 12.793 11.8326C12.793 11.5178 12.549 11.2625 12.248 11.2625H5.11263Z"
      fill={color}
    />
    <path
      d="M5.11263 16.5493C4.81163 16.5493 4.56763 16.8045 4.56763 17.1194C4.56763 17.4342 4.81163 17.6894 5.11263 17.6894H8.68029C8.98129 17.6894 9.2253 17.4342 9.2253 17.1194C9.2253 16.8045 8.98129 16.5493 8.68029 16.5493H5.11263Z"
      fill={color}
    />
    <path
      d="M1 2.25827C1 1.56335 1.53856 1 2.20291 1H18.7254C19.3897 1 19.9283 1.56334 19.9283 2.25827V10.8504C19.9283 11.1652 19.6843 11.4204 19.3833 11.4204C19.0823 11.4204 18.8383 11.1652 18.8383 10.8504V2.25827C18.8383 2.19304 18.7878 2.14016 18.7254 2.14016H2.20291C2.14056 2.14016 2.09001 2.19304 2.09001 2.25827V22.6509C2.09001 22.7161 2.14056 22.769 2.20291 22.769H12.4837C12.7847 22.769 13.0288 23.0242 13.0288 23.3391C13.0288 23.6539 12.7847 23.9091 12.4837 23.9091H2.20291C1.53856 23.9091 1 23.3458 1 22.6509V2.25827Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2564 22.1569C23.9227 21.2534 24.3191 20.121 24.3191 18.8916C24.3191 15.9368 22.029 13.5414 19.2041 13.5414C16.3792 13.5414 14.0892 15.9368 14.0892 18.8916C14.0892 21.8465 16.3792 24.2419 19.2041 24.2419C20.3795 24.2419 21.4622 23.8273 22.326 23.1302L24.8769 25.7984C25.1338 26.0672 25.5504 26.0672 25.8073 25.7984C26.0642 25.5297 26.0642 25.0939 25.8073 24.8252L23.2564 22.1569Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.11263 5.97578C4.81163 5.97578 4.56763 6.23101 4.56763 6.54586C4.56763 6.86071 4.81163 7.11594 5.11263 7.11594H14.6264C14.9274 7.11594 15.1714 6.86071 15.1714 6.54586C15.1714 6.23101 14.9274 5.97578 14.6264 5.97578H5.11263Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.11263 11.2625C4.81163 11.2625 4.56763 11.5178 4.56763 11.8326C4.56763 12.1475 4.81163 12.4027 5.11263 12.4027H12.248C12.549 12.4027 12.793 12.1475 12.793 11.8326C12.793 11.5178 12.549 11.2625 12.248 11.2625H5.11263Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.11263 16.5493C4.81163 16.5493 4.56763 16.8045 4.56763 17.1194C4.56763 17.4342 4.81163 17.6894 5.11263 17.6894H8.68029C8.98129 17.6894 9.2253 17.4342 9.2253 17.1194C9.2253 16.8045 8.98129 16.5493 8.68029 16.5493H5.11263Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
