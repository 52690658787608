import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { connect, Provider } from "react-redux";
import store from "./state/store";
import { selectorIsAppMode, selectorIsAuthed, selectorUserToken, selectorUserOnBoard } from "./state/selectors";
import {
  clearState,
  detectAppMode,
  detectReferralCode,
  detectTrafficSource,
  getAppState,
  setLanguage,
  setTjommiDeviceID,
} from "./state/actionCreators";
import ApiClient from "./services/apiClient";
import { Analytics } from "./services/analytics";
import { ErrorBoundary } from "./services/log";
import SEO from "./utils/seo";
import EnhancedRoute from "./components/enhancedRoute";
import AppMode from "./components/appMode";
import ToastMessage from "./components/toastMessage";
import AppModeMenu from "./components/menus/appModeMenu";
import routes from "./utils/routes";
import getQuery from "./utils/getQuery";
import "./index.scss";
import LoginMainMenu from "./components/menus/loginMainMenu";
import { PostHogProvider } from "posthog-js/react";
import { POSTHOG_HOST, POSTHOG_KEY } from "../src/utils/configs";
import posthog from "posthog-js";
import NativeAppClient from "./services/nativeAppClient";
import AppUpdateModal from "./components/modals/appUpdateModal";

const routing = Object.values(routes);
const APP_STATE_REFRESH_IN_MS = 60000 * 4; // 4 minutes

class RoutingComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appVersionModal: false,
    };

    const query = getQuery();
    this.props.detectAppMode();
    this.hydrateApiClient();
    this.getAppState();
    this.props.setLanguage(query.lang);
  }

  componentDidMount() {
    posthog.capture("app_launch");
    this.props.detectTrafficSource();
    this.props.detectReferralCode();
    this.props.setTjommiDeviceID();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentDidUpdate() {
    this.hydrateApiClient();
    this.getAppState();
  }
  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  hydrateApiClient() {
    ApiClient.token = this.props.userToken;
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // this.setState({
      //   appVersionModal: true,
      // });
      // if (NativeAppClient.isInNativeApp()) {
      //   NativeAppClient.requestDebugValues().then((values) => {
      //     // alert("zdsdsd" + values.nativeAppVersion);
      //     // ApiClient.getAppVersion()
      //     //   .then((res) => {
      //     //     return res.json();
      //     //   })
      //     //   .then((data) => {
      //     //     alert("res data" + JSON.stringify(data));
      //     //     // alert("zdsgetAppVersiondsd" + data);
      //     //   })
      //     //   .catch((err) => {
      //     //     console.log("err", err);
      //     //     alert("error" + err);
      //     //   });
      //     this.setState({
      //       appVersionModal: true,
      //     });
      //   });
      // }
    }
  };

  timer = null;

  getAppState() {
    if (this.props.isAuthed) {
      this.props.getAppState();
      if (!this.timer) this.timer = setInterval(this.props.getAppState, APP_STATE_REFRESH_IN_MS);
    } else {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <Router>
        <AppUpdateModal
          visible={this.state.appVersionModal}
          // closeBtn={() => {
          //   this.setState({ appVersionModal: false });
          // }}
        />
        <AppMode />
        <Analytics />
        {this.props.isAuthed && <AppModeMenu />}
        {this.props.isAuthed && <LoginMainMenu />}

        <ToastMessage />

        <Switch>
          {routing.map((route) => (
            <EnhancedRoute key={route.path} {...SEO} {...route} routes={routes} />
          ))}
          <Redirect path="/profile/delete" to={routes.profileAccountDelete.subroutes.one.path} />
          <Redirect to={this.props.isOnBoard ? routes.overview.path : routes.index.path} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
  isAuthed: selectorIsAuthed(state) && window.location.pathname !== routes.signout.path,
  userToken: selectorUserToken(state),
  isOnBoard: selectorUserOnBoard(state),
});

const mapDispatchToProps = {
  clearState,
  getAppState,
  detectAppMode,
  setLanguage,
  detectTrafficSource,
  detectReferralCode,
  setTjommiDeviceID,
};

const Routing = connect(mapStateToProps, mapDispatchToProps)(RoutingComponent);

const options = {
  api_host: POSTHOG_HOST,
  autocapture: {
    event_allowlist: ["click"], // DOM events from this list ['click', 'change', 'submit']
    url_allowlist: ["posthog.com./docs/.*"], // strings or RegExps
    element_allowlist: ["button"], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
    css_selector_allowlist: ["[ph-autocapture]"], // List of CSS selectors
  },
};

ReactDOM.render(
  <ErrorBoundary>
    <PostHogProvider apiKey={POSTHOG_KEY} options={options}>
      <Provider store={store}>
        <Routing />
      </Provider>
    </PostHogProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
