import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { selectorLanguageStrings } from "../../state/selectors";
import iconClaimSent from "./assets/claimSent.png";
import iconCashPaidOut from "./assets/cashPaidOut.png";
import iconGiftCard from "./assets/giftCard.png";
import iconClaimRejected from "./assets/claimRejected.png";
import iconExpired from "./assets/expired.png";
import iconActiveReceipt from "./assets/activeReceipt.png";
import iconPaymentFailed from "./assets/paymentFailed.png";
import iconAuthorize from "./assets/authorize.png";
import iconBankDetails from "./assets/bankDetails.png";
import iconPaymentSuccess from "./assets/paymentSuccess.png";
import medalReceived from "./assets/medalReceived.png";
import iconCallIn from "./assets/callIn.png";
import iconClaimApprovedGiftcard from "./assets/pickUpMoney.png";
import iconDeliver from "./assets/deliver.png";
import iconDelivered from "./assets/delivered.png";
import iconParcelFound from "./assets/parcelFound.png";
import "./styles.scss";
import classNames from "classnames";
import { formatCurrency } from "../../utils/formatter";

export const EVENTS = {
  PriceDropRequested: "PriceDropRequested",
  PriceDropApproved: "PriceDropApproved",
  PriceDropRejected: "PriceDropRejected",
  ReceiptHasExpired: "ReceiptHasExpired",
  ReceiptProcessed: "ReceiptProcessed",
  MultipleReceiptsProcessed: "MultipleReceiptsProcessed",
  PaymentFailed: "PaymentFailed",
  PaymentAuthenticationRequired: "PaymentAuthenticationRequired",
  PaymentMethodRequired: "PaymentMethodRequired",
  PaymentSuccessful: "PaymentSuccessful",
  MedalReceived: "MedalReceived",
  FreeText: "FreeText",
  ClaimSent: "ClaimSent",
  ClaimApprovedGiftcard: "ClaimApprovedGiftcard",
  ClaimApprovedInvoiceReduced: "ClaimApprovedInvoiceReduced",
  ClaimApprovedMoney: "ClaimApprovedMoney",
  ClaimApprovedPartner: "ClaimApprovedPartner",
  ClaimApprovedCallRequired: "ClaimApprovedCallRequired",
  ClaimApprovedPickupRequired: "ClaimApprovedPickupRequired",
  ClaimApprovedStoreCredit: "ClaimApprovedStoreCredit",
  ClaimRejectedCompetitorGiftcard: "ClaimRejectedCompetitorGiftcard",
  ClaimRejected: "ClaimRejected",
  GiftcardReminder: "GiftcardReminder",
  GiftcardExpiryReminder: "GiftcardExpiryReminder",
  ReceiptItemReturned: "ReceiptItemReturned",
  ReceiptExpired: "ReceiptExpired",
  NoReceiptsFound: "NoReceiptsFound",
  ReceiptsRegistered: "ReceiptsRegistered",
  ParcelArrivingToday: "ParcelArrivingToday",
  ParcelDelivered: "ParcelDelivered",
  ParcelFound: "ParcelFound",
  OneOffTrustedPartner: "OneOffTrustedPartner",
};

const NotificationItem = ({ strs, notification, redirect, dropdown }) => {
  let icon = null;
  let text = null;
  const store = notification?.data?.store || "...";
  const product = notification?.data?.product || "...";
  const currency = notification?.data?.currency || "nok";
  const amount = notification?.data?.diff || notification?.data?.amount || "...";
  const amountFormatted = formatCurrency(amount, currency);

  switch (notification.data.event_type) {
    case EVENTS.PriceDropRequested:
      icon = iconClaimSent;
      text = strs.PriceDropRequested({
        product,
        store,
        amount,
      });
      break;
    case EVENTS.PriceDropApproved:
      const approvalType = notification?.data?.approval_type;
      if (approvalType === "money") {
        icon = iconCashPaidOut;
        text = strs.PriceDropApprovedMoney({ store, amount });
      } else if (approvalType === "giftcard") {
        icon = iconGiftCard;
        text = strs.PriceDropApprovedGifcard({
          product,
          store,
          amount,
        });
      } else if (approvalType === "physical") {
        icon = iconCashPaidOut;
        text = strs.PriceDropApprovedPhysical({
          store,
          amount: amountFormatted,
        });
      } else if (approvalType === "phone") {
        icon = iconCallIn;
        text = strs.ApprovedPhoneCallRequired({ store });
      } else return null;
      break;
    case EVENTS.PriceDropRejected:
      icon = iconClaimRejected;
      text = strs.PriceDropRejected({ store, product });
      break;
    case EVENTS.ReceiptHasExpired:
      icon = iconExpired;
      text = strs.ReceiptHasExpired({ product });
      break;
    case EVENTS.ReceiptProcessed:
      icon = iconActiveReceipt;
      text = strs.ReceiptProcessed({ store });
      break;
    case EVENTS.MultipleReceiptsProcessed:
      icon = iconActiveReceipt;
      text = strs.MultipleReceiptsProcessed;
      break;
    case EVENTS.PaymentFailed:
      icon = iconPaymentFailed;
      text = strs.PaymentFailed;
      break;
    case EVENTS.PaymentAuthenticationRequired:
      icon = iconAuthorize;
      text = strs.PaymentAuthenticationRequired;
      break;
    case EVENTS.PaymentMethodRequired:
      icon = iconBankDetails;
      text = strs.PaymentMethodRequired;
      break;
    case EVENTS.PaymentSuccessful:
      icon = iconPaymentSuccess;
      text = strs.PaymentSuccessful;
      break;
    case EVENTS.MedalReceived:
      icon = medalReceived;
      text = strs.MedalReceived;
      break;
    case EVENTS.FreeText:
      icon = iconClaimSent;
      text = `<b>${notification?.title}</b><br/>${notification?.data?.text}`;
      break;
    case EVENTS.ClaimApprovedGiftcard:
      icon = iconClaimApprovedGiftcard;
      text = strs.ClaimApprovedGiftcard({
        store,
        amount,
        product,
      });
      break;
    case EVENTS.ClaimSent:
      icon = iconClaimSent;
      text = strs.ClaimSent({ store, amount, product });
      break;
    case EVENTS.ClaimApprovedInvoiceReduced:
      icon = iconCashPaidOut;
      text = strs.ClaimApprovedInvoiceReduced({
        store,
        amount: amountFormatted,
        product,
      });
      break;
    case EVENTS.ClaimApprovedMoney:
      icon = iconClaimApprovedGiftcard;
      text = strs.ClaimApprovedMoney({
        store,
        amount: amountFormatted,
        product,
      });
      break;
    case EVENTS.ClaimApprovedPartner:
      icon = iconClaimApprovedGiftcard;
      text = strs.ClaimApprovedPartner({
        store,
        product,
        amount,
      });
      break;
    case EVENTS.ClaimApprovedCallRequired:
      icon = iconCallIn;
      text = strs.ClaimApprovedCallRequired({
        store,
        amount: amountFormatted,
        product,
      });
      break;
    case EVENTS.ClaimApprovedPickupRequired:
      icon = iconCashPaidOut;
      text = strs.ClaimApprovedPickupRequired({
        store,
        amount: amountFormatted,
        product,
      });
      break;
    case EVENTS.ClaimApprovedStoreCredit:
      icon = iconCashPaidOut;
      text = strs.ClaimApprovedStoreCredit({
        store,
        amount: amountFormatted,
        product,
      });
      break;

    case EVENTS.OneOffTrustedPartner:
      icon = iconClaimApprovedGiftcard;
      text = strs.OneOffTrustedPartner;
      break;
    case EVENTS.ClaimRejectedCompetitorGiftcard:
      icon = iconClaimApprovedGiftcard;
      text = strs.ClaimRejectedCompetitorGiftcard({
        store,
        // If the partner name is not defined, fallback to a generic word that refers to "someone else"
        partner: notification?.data?.partner || strs.ClaimRejectedCompetitorGiftcardPartnerFallback,
        amount,
        //  amountFormatted,
        product,
      });
      break;
    case EVENTS.ClaimRejected:
      icon = iconClaimRejected;
      text = strs.ClaimRejected({ store, product });
      break;
    case EVENTS.GiftcardReminder:
      icon = iconClaimApprovedGiftcard;
      text = strs.GiftcardReminder({ store });
      break;
    case EVENTS.GiftcardExpiryReminder:
      icon = iconClaimApprovedGiftcard;
      text = strs.GiftcardExpiryReminder;
      break;
    case EVENTS.ReceiptItemReturned:
      icon = iconExpired;
      text = strs.ReceiptItemReturned({ product });
      break;
    case EVENTS.ReceiptExpired:
      icon = iconExpired;
      text = strs.ReceiptExpired({ product });
      break;
    case EVENTS.NoReceiptsFound:
      icon = iconActiveReceipt;
      text = `<b>${notification?.data?.title}</b><br/>${notification?.data?.text}`;
      break;
    case EVENTS.ReceiptsRegistered:
      icon = iconActiveReceipt;
      text = strs.ReceiptsRegistered;
      break;
    case EVENTS.ParcelArrivingToday:
      icon = iconDeliver;
      text = `<b>${notification?.data?.title}</b><br/>${notification?.data?.text}`;
      break;
    case EVENTS.ParcelDelivered:
      icon = iconDelivered;
      text = `<b>${notification?.data?.title}</b><br/>${notification?.data?.text}`;
      break;
    case EVENTS.ParcelFound:
      icon = iconParcelFound;
      text = `<b>${notification?.data?.title}</b><br/>${notification?.data?.text}`;
      break;
    default:
      return null;
  }

  const redirectStr = redirect ? "?redirect=" + redirect : "";
  const path = `/overview/notifications/${notification.id}${redirectStr}`;
  return (
    <Link
      className={classNames("notificationItem", {
        "notificationItem__dropdown": dropdown,
        "notificationItem--seen": notification.seen,
      })}
      to={path}
      data-event-type={notification?.data?.event_type}
    >
      <div className="notificationItem__icon">
        <img alt="" draggable={false} src={icon} />
      </div>
      <div className="notificationItem__text">
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "notification"),
});

export default connect(mapStateToProps)(NotificationItem);
