const Icon = ({ width = "20", height = "16", color = "#554455", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="Vector 87"
      d="M4 8H16M1 1H19M7.5 15H12.5"
      stroke={color}
      strokeOpacity="0.270588"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
