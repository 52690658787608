import React from "react";
import "./styles.scss";
import ArrowRight from "../icons/arrowMiniRight";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Pencil from "../icons/pencil";

class DetailLine extends React.Component {
  handleClick = () => {
    const { path, onClick } = this.props;
    if (!path && !onClick) return null;
    return (event) => {
      if (path) this.props.history.push(path);
      else onClick(event);
    };
  };

  render() {
    return (
      <div
        className={classNames({
          "detailLine": true,
          "detailLine--slim": this.props.slim,
          "detailLine--clickable": this.props.path || this.props.onClick,
          "detailLine--flip": this.props.flip,
          "detailLine--no-overflow": this.props.preventValueTextOverflow || false,
          "detailLine--column": this.props.column,
        })}
        onClick={this.handleClick()}
      >
        <div className="detailLine__label">{this.props.label}</div>
        <div
          className="detailLine__value"
          style={{
            color: this.props.valueColor,
            opacity: this.props.valueColor && 1,
            fontSize: this.props.valueSize || null,
            fontWeight: this.props.valueWeight || 400,
            lineHeight: this.props.lineHeight + "px" ?? "24px",
          }}
        >
          {this.props.value}
          {this.props.arrowRight && <ArrowRight color={this.props.valueColor || "black"} />}
          {this.props.pencil && <Pencil color={this.props.valueColor || "black"} />}
        </div>
      </div>
    );
  }
}

export default withRouter(DetailLine);
