const icon = () => (
  <svg
    className="iconArrowUp"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4002 7.76863C11.6744 7.76841 11.9453 7.8308 12.1944 7.95152C12.4435 8.07223 12.6647 8.24839 12.8428 8.46783L17.8208 14.5811C17.9061 14.6801 17.9717 14.7961 18.0137 14.9222C18.0557 15.0484 18.0733 15.1821 18.0654 15.3155C18.0574 15.4489 18.0242 15.5792 17.9676 15.6989C17.9109 15.8185 17.8321 15.925 17.7358 16.012C17.6394 16.099 17.5274 16.1648 17.4065 16.2055C17.2855 16.2462 17.1581 16.261 17.0316 16.249C16.9051 16.2369 16.7823 16.1983 16.6702 16.1354C16.5582 16.0725 16.4593 15.9867 16.3793 15.8828L11.4002 9.76863L6.42101 15.8828C6.34091 15.9862 6.24197 16.0717 6.13001 16.1342C6.01804 16.1967 5.89533 16.2351 5.76909 16.2469C5.64285 16.2587 5.51563 16.2439 5.39493 16.2032C5.27423 16.1624 5.16249 16.0967 5.06629 16.0099C4.97009 15.923 4.89137 15.8168 4.83478 15.6974C4.77818 15.578 4.74485 15.4479 4.73674 15.3148C4.72863 15.1817 4.74591 15.0482 4.78757 14.9222C4.82923 14.7962 4.89441 14.6802 4.97929 14.5811L9.95843 8.46683C10.1365 8.24763 10.3576 8.0717 10.6065 7.95116C10.8554 7.83062 11.1262 7.76835 11.4002 7.76863Z"
      fill="black"
    />
  </svg>
);

export default icon;
