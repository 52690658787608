const Icon = ({ color = "white" }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none" className="icon" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.50004 5.83317L12.1667 3.1665H3.83337L6.50004 5.83317"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 9.3335C11.5 11.2665 9.933 12.8335 8 12.8335C6.067 12.8335 4.5 11.2665 4.5 9.3335C4.5 7.4005 6.067 5.8335 8 5.8335C9.933 5.8335 11.5 7.4005 11.5 9.3335Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
