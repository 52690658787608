const Icon = ({ width = "161", height = "49", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 161 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3818_30112)">
      <path
        d="M5.20312 0.664062H155.815C158.317 0.664062 160.346 2.69277 160.346 5.19531V43.0417C160.346 45.5443 158.317 47.573 155.815 47.573H5.20312C2.70058 47.573 0.671875 45.5443 0.671875 43.0417V5.19531C0.671875 2.69277 2.70058 0.664062 5.20312 0.664062Z"
        stroke="white"
      />
      <path
        d="M56.1017 34.2732C54.8307 34.2732 53.7649 33.843 52.9078 32.9826C52.0474 32.1222 51.6172 31.0499 51.6172 29.7626C51.6172 28.4752 52.0474 27.403 52.9078 26.5426C53.7682 25.6822 54.8339 25.252 56.1017 25.252C57.3695 25.252 58.4613 25.7213 59.2728 26.6631L58.5004 27.4095C57.9138 26.6794 57.1153 26.3112 56.1017 26.3112C55.1598 26.3112 54.3679 26.6273 53.7193 27.2628C53.0708 27.8983 52.7481 28.7294 52.7481 29.7593C52.7481 30.7892 53.0708 31.6203 53.7193 32.2558C54.3646 32.8913 55.1598 33.2074 56.1017 33.2074C57.1381 33.2074 58.0246 32.7903 58.7546 31.9527L59.527 32.7251C59.1327 33.2009 58.6373 33.5757 58.0442 33.8527C57.4477 34.1298 56.8024 34.2699 56.1017 34.2699V34.2732Z"
        fill="white"
      />
      <path
        d="M61.6315 25.4453V28.1699L61.5826 28.9912H61.6315C61.7912 28.7109 62.0454 28.4698 62.3909 28.2742C62.7364 28.0787 63.1047 27.9776 63.499 27.9776C64.229 27.9776 64.7896 28.2025 65.1742 28.6523C65.5588 29.102 65.7543 29.705 65.7543 30.4611V34.0787H64.6462V30.6306C64.6462 29.5388 64.1606 28.9912 63.1861 28.9912C62.7266 28.9912 62.3551 29.1737 62.065 29.5388C61.7749 29.9038 61.6315 30.3275 61.6315 30.8098V34.0787H60.5234V25.4453H61.6315Z"
        fill="white"
      />
      <path
        d="M68.1706 34.0815H67.0625V28.1727H68.125V29.1374H68.1739C68.2879 28.8147 68.5161 28.5508 68.868 28.3422C69.2168 28.1336 69.5557 28.0293 69.8881 28.0293C70.2206 28.0293 70.4943 28.0782 70.7192 28.1727L70.2955 29.2091C70.1521 29.1537 69.9533 29.1244 69.7056 29.1244C69.3048 29.1244 68.9463 29.2841 68.6399 29.6002C68.3303 29.9163 68.1771 30.3335 68.1771 30.8484V34.0815H68.1706Z"
        fill="white"
      />
      <path
        d="M71.5837 28.8741C72.1541 28.2744 72.8808 27.9746 73.7673 27.9746C74.6538 27.9746 75.3806 28.2744 75.9509 28.8741C76.5213 29.4738 76.8081 30.2234 76.8081 31.1229C76.8081 32.0224 76.5213 32.772 75.9509 33.3717C75.3806 33.9714 74.6538 34.2712 73.7673 34.2712C72.8808 34.2712 72.1541 33.9714 71.5837 33.3717C71.0134 32.772 70.7266 32.0224 70.7266 31.1229C70.7266 30.2234 71.0134 29.4738 71.5837 28.8741ZM72.4083 32.6742C72.7896 33.0653 73.2426 33.2576 73.7641 33.2576C74.2855 33.2576 74.7385 33.0621 75.1198 32.6742C75.5012 32.2832 75.6934 31.7682 75.6934 31.1262C75.6934 30.4841 75.5012 29.9659 75.1198 29.5781C74.7385 29.187 74.2855 28.9947 73.7641 28.9947C73.2426 28.9947 72.7896 29.1903 72.4083 29.5781C72.0269 29.9659 71.8347 30.4841 71.8347 31.1262C71.8347 31.7682 72.0269 32.2864 72.4083 32.6742Z"
        fill="white"
      />
      <path
        d="M77.6953 34.0796V28.1708H78.7578V28.9921H78.8067C78.9761 28.7118 79.2336 28.4706 79.5856 28.2751C79.9343 28.0795 80.283 27.9785 80.6285 27.9785C81.5443 27.9785 82.1668 28.3566 82.496 29.1127C82.9783 28.3566 83.666 27.9785 84.559 27.9785C85.2597 27.9785 85.7844 28.2001 86.1397 28.6466C86.4949 29.0931 86.6709 29.6993 86.6709 30.462V34.0796H85.5628V30.6314C85.5628 30.035 85.4553 29.6146 85.2369 29.3669C85.0185 29.1192 84.6894 28.9921 84.2494 28.9921C83.8094 28.9921 83.4542 29.1779 83.1706 29.5461C82.8838 29.9144 82.7437 30.3381 82.7437 30.8107V34.0796H81.6356V30.6314C81.6356 30.035 81.528 29.6146 81.3097 29.3669C81.0913 29.1192 80.7621 28.9921 80.3222 28.9921C79.8822 28.9921 79.5269 29.1779 79.2434 29.5461C78.9566 29.9144 78.8164 30.3381 78.8164 30.8107V34.0796H77.7084H77.6953Z"
        fill="white"
      />
      <path
        d="M90.6475 34.2712C89.7545 34.2712 89.031 33.9746 88.4704 33.3782C87.9131 32.7851 87.6328 32.0322 87.6328 31.1229C87.6328 30.2625 87.9033 29.5227 88.4476 28.9035C88.9919 28.2842 89.6828 27.9746 90.5269 27.9746C91.371 27.9746 92.1043 28.2614 92.6323 28.8318C93.1603 29.4021 93.421 30.1647 93.421 31.1229L93.4079 31.3282H88.7409C88.7735 31.9247 88.9723 32.394 89.3373 32.7394C89.7023 33.0849 90.1325 33.2576 90.6214 33.2576C91.4166 33.2576 91.9544 32.9187 92.2379 32.244L93.2254 32.6547C93.0331 33.1142 92.7138 33.4955 92.2738 33.8052C91.8305 34.1148 91.2895 34.268 90.6442 34.268L90.6475 34.2712ZM92.2379 30.4124C92.2151 30.0735 92.0587 29.7541 91.7751 29.4477C91.4883 29.1414 91.0647 28.9882 90.5041 28.9882C90.0934 28.9882 89.7382 29.1186 89.4384 29.3728C89.1385 29.627 88.9299 29.9757 88.8159 30.4092H92.2412L92.2379 30.4124Z"
        fill="white"
      />
      <path
        d="M106.868 25.4453L104.564 34.0787H103.43L101.572 28.3883H101.523L99.6657 34.0787H98.5315L96.1328 25.4453H97.2898L99.1116 32.234H99.1605L101.031 26.6023H102.068L103.938 32.234H103.987L105.711 25.4453H106.868Z"
        fill="white"
      />
      <path
        d="M109.741 34.2712C108.848 34.2712 108.125 33.9746 107.564 33.3782C107.007 32.7851 106.727 32.0322 106.727 31.1229C106.727 30.2625 106.997 29.5227 107.541 28.9035C108.086 28.2842 108.777 27.9746 109.621 27.9746C110.465 27.9746 111.198 28.2614 111.726 28.8318C112.254 29.4021 112.515 30.1647 112.515 31.1229L112.502 31.3282H107.835C107.867 31.9247 108.066 32.394 108.431 32.7394C108.796 33.0849 109.226 33.2576 109.715 33.2576C110.51 33.2576 111.048 32.9187 111.332 32.244L112.319 32.6547C112.127 33.1142 111.808 33.4955 111.368 33.8052C110.924 34.1148 110.383 34.268 109.738 34.268L109.741 34.2712ZM111.332 30.4124C111.309 30.0735 111.152 29.7541 110.869 29.4477C110.582 29.1414 110.158 28.9882 109.598 28.9882C109.187 28.9882 108.832 29.1186 108.532 29.3728C108.232 29.627 108.024 29.9757 107.91 30.4092H111.335L111.332 30.4124Z"
        fill="white"
      />
      <path
        d="M116.649 34.271C116.199 34.271 115.795 34.1732 115.437 33.9809C115.078 33.7886 114.811 33.5475 114.635 33.2574H114.586V34.0787H113.523V25.4453H114.632V28.1699L114.583 28.9912H114.632C114.808 28.7012 115.075 28.46 115.433 28.2677C115.792 28.0754 116.196 27.9776 116.646 27.9776C117.408 27.9776 118.07 28.2775 118.624 28.8804C119.178 29.4833 119.455 30.2297 119.455 31.1227C119.455 32.0157 119.178 32.762 118.624 33.3649C118.07 33.9679 117.412 34.2677 116.646 34.2677L116.649 34.271ZM116.466 33.2574C116.981 33.2574 117.425 33.0619 117.793 32.674C118.161 32.2862 118.347 31.768 118.347 31.1259C118.347 30.4839 118.161 29.9657 117.793 29.5779C117.425 29.19 116.981 28.9945 116.466 28.9945C115.951 28.9945 115.498 29.1868 115.133 29.5746C114.768 29.9592 114.586 30.4774 114.586 31.1292C114.586 31.781 114.768 32.2992 115.133 32.6838C115.498 33.0684 115.942 33.2639 116.466 33.2639V33.2574Z"
        fill="white"
      />
      <path
        d="M125.397 34.2725C124.772 34.2725 124.159 34.0672 123.559 33.6565C122.96 33.2459 122.569 32.6723 122.383 31.9325L123.396 31.5218C123.517 31.9977 123.765 32.3953 124.139 32.7212C124.514 33.0471 124.935 33.2101 125.401 33.2101C125.867 33.2101 126.294 33.0829 126.636 32.8287C126.978 32.5745 127.148 32.2323 127.148 31.7989C127.148 31.3165 126.978 30.945 126.636 30.6842C126.294 30.4235 125.753 30.1791 125.013 29.9542C124.25 29.713 123.67 29.4002 123.276 29.0188C122.881 28.6375 122.686 28.1487 122.686 27.5522C122.686 26.9558 122.93 26.3953 123.422 25.9357C123.911 25.4762 124.55 25.248 125.339 25.248C126.069 25.248 126.665 25.4306 127.125 25.7956C127.584 26.1606 127.881 26.5615 128.018 26.9949L127.004 27.4154C126.933 27.1416 126.753 26.8874 126.466 26.656C126.18 26.4246 125.815 26.3073 125.365 26.3073C124.938 26.3073 124.576 26.4246 124.273 26.6625C123.97 26.9004 123.82 27.1937 123.82 27.549C123.82 27.8716 123.96 28.1421 124.237 28.3638C124.514 28.5854 124.922 28.7842 125.463 28.9602C125.89 29.0971 126.245 29.2307 126.528 29.3578C126.815 29.4849 127.102 29.6576 127.395 29.8695C127.689 30.0813 127.91 30.3486 128.06 30.6647C128.21 30.9808 128.282 31.3491 128.282 31.7663C128.282 32.1834 128.194 32.5582 128.021 32.8874C127.848 33.2166 127.62 33.4773 127.333 33.6728C127.047 33.8651 126.74 34.0118 126.411 34.1128C126.082 34.2139 125.743 34.2627 125.397 34.2627V34.2725Z"
        fill="white"
      />
      <path
        d="M131.693 34.1728C131.074 34.1728 130.601 34.0098 130.275 33.6774C129.949 33.345 129.786 32.8822 129.786 32.2792V29.1798H128.75V28.1662H129.786V26.3574H130.894V28.1662H132.342V29.1798H130.894V32.1945C130.894 32.8365 131.158 33.1592 131.69 33.1592C131.892 33.1592 132.058 33.1266 132.195 33.0614L132.579 34.0131C132.338 34.1174 132.042 34.1695 131.686 34.1695L131.693 34.1728Z"
        fill="white"
      />
      <path
        d="M133.857 28.8741C134.427 28.2744 135.154 27.9746 136.041 27.9746C136.927 27.9746 137.654 28.2744 138.224 28.8741C138.795 29.4738 139.081 30.2234 139.081 31.1229C139.081 32.0224 138.795 32.772 138.224 33.3717C137.654 33.9714 136.927 34.2712 136.041 34.2712C135.154 34.2712 134.427 33.9714 133.857 33.3717C133.287 32.772 133 32.0224 133 31.1229C133 30.2234 133.287 29.4738 133.857 28.8741ZM134.682 32.6742C135.063 33.0653 135.516 33.2576 136.037 33.2576C136.559 33.2576 137.012 33.0621 137.393 32.6742C137.775 32.2832 137.967 31.7682 137.967 31.1262C137.967 30.4841 137.775 29.9659 137.393 29.5781C137.012 29.187 136.559 28.9947 136.037 28.9947C135.516 28.9947 135.063 29.1903 134.682 29.5781C134.3 29.9659 134.108 30.4841 134.108 31.1262C134.108 31.7682 134.297 32.2864 134.682 32.6742Z"
        fill="white"
      />
      <path
        d="M141.077 34.0815H139.969V28.1727H141.031V29.1374H141.08C141.194 28.8147 141.422 28.5508 141.774 28.3422C142.123 28.1336 142.465 28.0293 142.794 28.0293C143.124 28.0293 143.401 28.0782 143.625 28.1727L143.205 29.2091C143.062 29.1537 142.863 29.1244 142.615 29.1244C142.214 29.1244 141.856 29.2841 141.549 29.6002C141.24 29.9163 141.087 30.3335 141.087 30.8484V34.0815H141.077Z"
        fill="white"
      />
      <path
        d="M146.663 34.2712C145.77 34.2712 145.047 33.9746 144.486 33.3782C143.929 32.7851 143.648 32.0322 143.648 31.1229C143.648 30.2625 143.919 29.5227 144.463 28.9035C145.007 28.2842 145.698 27.9746 146.543 27.9746C147.387 27.9746 148.12 28.2614 148.648 28.8318C149.176 29.4021 149.437 30.1647 149.437 31.1229L149.424 31.3282H144.757C144.789 31.9247 144.988 32.394 145.353 32.7394C145.718 33.0849 146.148 33.2576 146.637 33.2576C147.432 33.2576 147.97 32.9187 148.254 32.244L149.241 32.6547C149.049 33.1142 148.729 33.4955 148.289 33.8052C147.846 34.1148 147.305 34.268 146.66 34.268L146.663 34.2712ZM148.254 30.4124C148.231 30.0735 148.074 29.7541 147.791 29.4477C147.504 29.1414 147.08 28.9882 146.52 28.9882C146.109 28.9882 145.754 29.1186 145.454 29.3728C145.154 29.627 144.946 29.9757 144.831 30.4092H148.257L148.254 30.4124Z"
        fill="white"
      />
      <path
        d="M52.2689 20.6751L54.7328 14.1406H55.6812L58.1451 20.6751H57.213L56.5839 18.9054H53.8365L53.1977 20.6751H52.2656H52.2689ZM54.1201 18.12H56.2906L55.2216 15.1738H55.1858L54.1168 18.12H54.1201Z"
        fill="white"
      />
      <path
        d="M59.6493 20.6766L57.8438 16.2051H58.7563L60.0697 19.6728H60.0893L61.4223 16.2051H62.3152L60.4901 20.6766H59.6493Z"
        fill="white"
      />
      <path
        d="M64.1765 20.8228C63.7006 20.8228 63.3063 20.6859 62.9869 20.4121C62.6675 20.1383 62.5078 19.7766 62.5078 19.3268C62.5078 18.8412 62.6968 18.4566 63.0749 18.1829C63.453 17.9058 63.9157 17.769 64.4698 17.769C64.9619 17.769 65.3661 17.8602 65.6822 18.0427V17.9156C65.6822 17.5865 65.5681 17.3257 65.3432 17.1269C65.1184 16.9281 64.8413 16.8303 64.5122 16.8303C64.2677 16.8303 64.0494 16.889 63.8506 17.0031C63.6518 17.1204 63.5181 17.2768 63.4432 17.4789L62.6773 17.1497C62.7816 16.8825 62.9836 16.6348 63.29 16.4067C63.5931 16.1785 63.9972 16.0645 64.4959 16.0645C65.0662 16.0645 65.542 16.2307 65.9201 16.5664C66.2982 16.902 66.4872 17.3714 66.4872 17.9808V20.6826H65.6854V20.0634H65.6496C65.3139 20.5751 64.825 20.8293 64.1797 20.8293L64.1765 20.8228ZM64.3134 20.0569C64.6588 20.0569 64.975 19.9265 65.2585 19.669C65.542 19.4116 65.6822 19.1052 65.6822 18.7532C65.4443 18.5577 65.089 18.4599 64.6132 18.4599C64.2058 18.4599 63.8995 18.5479 63.6909 18.7239C63.4855 18.8999 63.3813 19.1085 63.3813 19.3431C63.3813 19.5615 63.4758 19.7342 63.6648 19.8646C63.8538 19.9917 64.0689 20.0569 64.3134 20.0569Z"
        fill="white"
      />
      <path
        d="M68.3417 15.08C68.2276 15.1941 68.0875 15.2527 67.9213 15.2527C67.7551 15.2527 67.6182 15.1941 67.5009 15.08C67.3868 14.9659 67.3281 14.8258 67.3281 14.6596C67.3281 14.4933 67.3868 14.3565 67.5009 14.2391C67.6149 14.1251 67.7551 14.0664 67.9213 14.0664C68.0875 14.0664 68.2244 14.1251 68.3417 14.2391C68.4558 14.3565 68.5144 14.4933 68.5144 14.6596C68.5144 14.8258 68.4558 14.9627 68.3417 15.08ZM68.3417 20.6726H67.5009V16.2011H68.3417V20.6726Z"
        fill="white"
      />
      <path d="M70.2784 20.6751H69.4375V14.1406H70.2784V20.6751Z" fill="white" />
      <path
        d="M72.6765 20.8228C72.2006 20.8228 71.8063 20.6859 71.4869 20.4121C71.1675 20.1383 71.0078 19.7766 71.0078 19.3268C71.0078 18.8412 71.1968 18.4566 71.5749 18.1829C71.953 17.9058 72.4157 17.769 72.9698 17.769C73.4619 17.769 73.866 17.8602 74.1822 18.0427V17.9156C74.1822 17.5865 74.0681 17.3257 73.8432 17.1269C73.6184 16.9281 73.3413 16.8303 73.0122 16.8303C72.7677 16.8303 72.5494 16.889 72.3506 17.0031C72.1518 17.1204 72.0181 17.2768 71.9432 17.4789L71.1773 17.1497C71.2816 16.8825 71.4836 16.6348 71.79 16.4067C72.0931 16.1785 72.4972 16.0645 72.9959 16.0645C73.5662 16.0645 74.042 16.2307 74.4201 16.5664C74.7982 16.902 74.9872 17.3714 74.9872 17.9808V20.6826H74.1854V20.0634H74.1496C73.8139 20.5751 73.325 20.8293 72.6797 20.8293L72.6765 20.8228ZM72.8134 20.0569C73.1588 20.0569 73.475 19.9265 73.7585 19.669C74.042 19.4116 74.1822 19.1052 74.1822 18.7532C73.9443 18.5577 73.589 18.4599 73.1132 18.4599C72.7058 18.4599 72.3994 18.5479 72.1909 18.7239C71.9855 18.8999 71.8813 19.1085 71.8813 19.3431C71.8813 19.5615 71.9758 19.7342 72.1648 19.8646C72.3538 19.9917 72.5689 20.0569 72.8134 20.0569Z"
        fill="white"
      />
      <path
        d="M78.2971 20.8218C77.9549 20.8218 77.6518 20.7501 77.3813 20.6034C77.1108 20.4568 76.9087 20.2743 76.7751 20.0559H76.7392V20.6751H75.9375V14.1406H76.7784V16.2036L76.7425 16.8229H76.7784C76.912 16.6045 77.114 16.422 77.3845 16.2753C77.6551 16.1287 77.9614 16.057 78.3004 16.057C78.8772 16.057 79.3759 16.2851 79.7963 16.7414C80.2167 17.1977 80.4253 17.7648 80.4253 18.4394C80.4253 19.114 80.2167 19.6811 79.7963 20.1374C79.3759 20.5937 78.8772 20.8218 78.3004 20.8218H78.2971ZM78.1602 20.0559C78.548 20.0559 78.8837 19.9092 79.164 19.6127C79.4443 19.3161 79.5844 18.9283 79.5844 18.4394C79.5844 17.9505 79.4443 17.5627 79.164 17.2661C78.8837 16.9695 78.548 16.8229 78.1602 16.8229C77.7724 16.8229 77.4302 16.9695 77.1531 17.2596C76.8761 17.5529 76.7392 17.944 76.7392 18.4361C76.7392 18.9283 76.8761 19.3226 77.1531 19.6127C77.4302 19.906 77.7659 20.0494 78.1602 20.0494V20.0559Z"
        fill="white"
      />
      <path d="M81.9815 20.6751H81.1406V14.1406H81.9815V20.6751Z" fill="white" />
      <path
        d="M84.9845 20.8234C84.3099 20.8234 83.7591 20.5985 83.3387 20.1488C82.915 19.699 82.7031 19.1287 82.7031 18.441C82.7031 17.7892 82.9084 17.2319 83.3191 16.7626C83.7297 16.2932 84.2545 16.0586 84.8932 16.0586C85.532 16.0586 86.0861 16.2737 86.4869 16.7072C86.8878 17.1406 87.0834 17.7175 87.0834 18.441L87.0736 18.5974H83.5407C83.5635 19.0472 83.7167 19.4024 83.9937 19.6664C84.2708 19.9272 84.5934 20.0575 84.9649 20.0575C85.5679 20.0575 85.9753 19.8033 86.1871 19.2916L86.9367 19.6012C86.79 19.9467 86.5489 20.24 86.2164 20.4714C85.8808 20.7061 85.4701 20.8234 84.9845 20.8234ZM86.1904 17.9032C86.1708 17.649 86.0535 17.4046 85.8384 17.1732C85.6233 16.9418 85.3006 16.8277 84.877 16.8277C84.5673 16.8277 84.2968 16.9255 84.0687 17.1211C83.8406 17.3166 83.6841 17.5773 83.5994 17.9065H86.1904V17.9032Z"
        fill="white"
      />
      <path
        d="M90.7714 15.08C90.6573 15.1941 90.5172 15.2527 90.351 15.2527C90.1848 15.2527 90.0479 15.1941 89.9306 15.08C89.8132 14.9659 89.7578 14.8258 89.7578 14.6596C89.7578 14.4933 89.8165 14.3565 89.9306 14.2391C90.0446 14.1251 90.1848 14.0664 90.351 14.0664C90.5172 14.0664 90.6541 14.1251 90.7714 14.2391C90.8855 14.3565 90.9441 14.4933 90.9441 14.6596C90.9441 14.8258 90.8855 14.9627 90.7714 15.08ZM90.7714 20.6726H89.9306V16.2011H90.7714V20.6726Z"
        fill="white"
      />
      <path
        d="M92.6676 16.2066V16.8258H92.7035C92.8241 16.6139 93.0164 16.4314 93.2771 16.2815C93.5378 16.1316 93.8181 16.0566 94.1179 16.0566C94.672 16.0566 95.0957 16.2261 95.3857 16.5683C95.6791 16.9073 95.8225 17.3668 95.8225 17.9371V20.6748H94.9816V18.0643C94.9816 17.2364 94.6133 16.8225 93.8768 16.8225C93.5313 16.8225 93.2478 16.9594 93.0294 17.2364C92.811 17.5135 92.7002 17.8329 92.7002 18.1979V20.6715H91.8594V16.2H92.6611L92.6676 16.2066Z"
        fill="white"
      />
      <path
        d="M100.757 20.7493C100.288 20.7493 99.9327 20.6254 99.685 20.3745C99.4373 20.1235 99.3167 19.7715 99.3167 19.3153V16.9687H98.5312V16.2028H99.3167V14.834H100.158V16.2028H101.253V16.9687H100.158V19.2501C100.158 19.7357 100.36 19.9801 100.76 19.9801C100.914 19.9801 101.041 19.9573 101.145 19.9084L101.438 20.6287C101.256 20.7069 101.031 20.746 100.764 20.746L100.757 20.7493Z"
        fill="white"
      />
      <path
        d="M102.922 14.1413V16.2043L102.886 16.8235H102.922C103.043 16.6117 103.235 16.4292 103.496 16.2793C103.757 16.1293 104.037 16.0544 104.337 16.0544C104.891 16.0544 105.314 16.2239 105.604 16.5661C105.898 16.905 106.041 17.3645 106.041 17.9349V20.6725H105.2V18.062C105.2 17.2342 104.832 16.8203 104.096 16.8203C103.75 16.8203 103.467 16.9572 103.248 17.2342C103.03 17.5112 102.919 17.8306 102.919 18.1956V20.6693H102.078V14.1348H102.919L102.922 14.1413Z"
        fill="white"
      />
      <path
        d="M109.07 20.8234C108.396 20.8234 107.845 20.5985 107.425 20.1488C107.001 19.699 106.789 19.1287 106.789 18.441C106.789 17.7892 106.994 17.2319 107.405 16.7626C107.816 16.2932 108.34 16.0586 108.979 16.0586C109.618 16.0586 110.172 16.2737 110.573 16.7072C110.974 17.1406 111.169 17.7175 111.169 18.441L111.16 18.5974H107.627C107.649 19.0472 107.803 19.4024 108.08 19.6664C108.357 19.9272 108.679 20.0575 109.051 20.0575C109.654 20.0575 110.061 19.8033 110.273 19.2916L111.023 19.6012C110.876 19.9467 110.635 20.24 110.302 20.4714C109.967 20.7061 109.556 20.8234 109.07 20.8234ZM110.276 17.9032C110.257 17.649 110.139 17.4046 109.924 17.1732C109.709 16.9418 109.387 16.8277 108.963 16.8277C108.653 16.8277 108.383 16.9255 108.155 17.1211C107.926 17.3166 107.77 17.5773 107.685 17.9065H110.276V17.9032Z"
        fill="white"
      />
      <mask
        id="mask0_3818_30112"
        style={{ madkType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="8"
        width="37"
        height="32"
      >
        <path
          d="M7.45312 8.66602V37.2386C7.45312 38.5846 8.5547 39.6862 9.90072 39.6862H40.9209C42.2669 39.6862 43.3685 38.5846 43.3685 37.2386V8.66602H7.45312ZM29.4945 15.1973H21.3304C20.4276 15.1973 19.6976 14.4672 19.6976 13.5645C19.6976 12.6617 20.4276 11.9316 21.3304 11.9316H29.4945C30.3973 11.9316 31.1273 12.6617 31.1273 13.5645C31.1273 14.4672 30.3973 15.1973 29.4945 15.1973Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3818_30112)">
        <path d="M7.45312 8.66602H43.3718V39.6862H7.45312V8.66602Z" fill="#EEEEEE" />
      </g>
      <mask
        id="mask1_3818_30112"
        style={{ madkType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="8"
        width="37"
        height="32"
      >
        <path
          d="M7.45312 8.66602V37.2386C7.45312 38.5846 8.5547 39.6862 9.90072 39.6862H40.9209C42.2669 39.6862 43.3685 38.5846 43.3685 37.2386V8.66602H7.45312ZM29.4945 15.1973H21.3304C20.4276 15.1973 19.6976 14.4672 19.6976 13.5645C19.6976 12.6617 20.4276 11.9316 21.3304 11.9316H29.4945C30.3973 11.9316 31.1273 12.6617 31.1273 13.5645C31.1273 14.4672 30.3973 15.1973 29.4945 15.1973Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_3818_30112)">
        <path
          d="M29.5078 11.9297H21.3438C20.441 11.9297 19.7109 12.6597 19.7109 13.5625C19.7109 14.4653 20.441 15.1953 21.3438 15.1953H29.5078C30.4106 15.1953 31.1406 14.4653 31.1406 13.5625C31.1406 12.6597 30.4106 11.9297 29.5078 11.9297Z"
          fill="white"
        />
      </g>
      <mask
        id="mask2_3818_30112"
        style={{ madkType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="7"
        y="8"
        width="37"
        height="32"
      >
        <path
          d="M7.45312 8.66602V37.2386C7.45312 38.5846 8.5547 39.6862 9.90072 39.6862H40.9209C42.2669 39.6862 43.3685 38.5846 43.3685 37.2386V8.66602H7.45312ZM29.4945 15.1973H21.3304C20.4276 15.1973 19.6976 14.4672 19.6976 13.5645C19.6976 12.6617 20.4276 11.9316 21.3304 11.9316H29.4945C30.3973 11.9316 31.1273 12.6617 31.1273 13.5645C31.1273 14.4672 30.3973 15.1973 29.4945 15.1973Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_3818_30112)">
        <mask
          id="mask3_3818_30112"
          style={{ madkType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="7"
          y="8"
          width="37"
          height="32"
        >
          <path
            d="M7.45312 8.66797V37.2406C7.45312 38.5866 8.5547 39.6882 9.90072 39.6882H40.9209C42.2669 39.6882 43.3685 38.5866 43.3685 37.2406V8.66797H7.45312ZM29.4945 15.1992H21.3304C20.4276 15.1992 19.6976 14.4692 19.6976 13.5664C19.6976 12.6636 20.4276 11.9336 21.3304 11.9336H29.4945C30.3973 11.9336 31.1273 12.6636 31.1273 13.5664C31.1273 14.4692 30.3973 15.1992 29.4945 15.1992Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_3818_30112)">
          <path d="M7.45312 8.66797H43.3718V24.1781H7.45312V8.66797Z" fill="#EFEFEF" />
        </g>
        <path d="M7.46094 23.9688H43.3796V24.1741H7.46094V23.9688Z" fill="#EFEFEF" />
        <path d="M7.46094 24.1738H43.3796V24.3792H7.46094V24.1738Z" fill="#EFEFEF" />
        <path
          d="M29.5023 15.1934H21.3382C20.4713 15.1934 19.7641 14.5155 19.7119 13.6616C19.7087 13.6974 19.7054 13.73 19.7054 13.7626C19.7054 14.6654 20.4355 15.3954 21.3382 15.3954H29.5023C30.4051 15.3954 31.1351 14.6654 31.1351 13.7626C31.1351 13.7268 31.1351 13.6942 31.1286 13.6616C31.0764 14.5155 30.3692 15.1934 29.5023 15.1934ZM7.46094 8.66211V8.86743H43.3796V8.66211H7.46094Z"
          fill="#EFEFEF"
        />
        <path
          d="M21.3382 11.9267H29.5023C30.3692 11.9267 31.0764 12.6046 31.1286 13.4585C31.1286 13.4226 31.1351 13.39 31.1351 13.3574C31.1351 12.4546 30.4051 11.7246 29.5023 11.7246H21.3382C20.4355 11.7246 19.7054 12.4546 19.7054 13.3574C19.7054 13.3933 19.7054 13.4259 19.7119 13.4585C19.7641 12.6046 20.4713 11.9267 21.3382 11.9267ZM40.9287 39.4792H9.90853C8.56252 39.4792 7.46094 38.3776 7.46094 37.0316V37.2369C7.46094 38.5829 8.56252 39.6845 9.90853 39.6845H40.9287C42.2747 39.6845 43.3763 38.5829 43.3763 37.2369V37.0316C43.3763 38.3776 42.2747 39.4792 40.9287 39.4792Z"
          fill="#EFEFEF"
        />
        <path
          d="M7.46094 8.66211V37.2347C7.46094 38.5807 8.56252 39.6823 9.90853 39.6823H40.9287C42.2747 39.6823 43.3763 38.5807 43.3763 37.2347V8.66211H7.46094ZM29.5023 15.1934H21.3382C20.4355 15.1934 19.7054 14.4633 19.7054 13.5606C19.7054 12.6578 20.4355 11.9277 21.3382 11.9277H29.5023C30.4051 11.9277 31.1351 12.6578 31.1351 13.5606C31.1351 14.4633 30.4051 15.1934 29.5023 15.1934Z"
          fill="url(#paint0_radial_3818_30112)"
        />
        <mask
          id="mask4_3818_30112"
          style={{ madkType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="21"
          width="32"
          height="32"
        >
          <mask
            id="mask5_3818_30112"
            style={{ madkType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="9"
            y="21"
            width="32"
            height="32"
          >
            <mask
              id="mask6_3818_30112"
              style={{ madkType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="21"
              width="32"
              height="32"
            >
              <mask
                id="mask7_3818_30112"
                style={{ madkType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="9"
                y="21"
                width="32"
                height="32"
              >
                <path
                  d="M25.4085 52.7507C33.9745 52.7507 40.9186 45.8066 40.9186 37.2406C40.9186 28.6746 33.9745 21.7305 25.4085 21.7305C16.8425 21.7305 9.89844 28.6746 9.89844 37.2406C9.89844 45.8066 16.8425 52.7507 25.4085 52.7507Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask7_3818_30112)">
                <path
                  d="M25.4085 52.7507C33.9745 52.7507 40.9186 45.8066 40.9186 37.2406C40.9186 28.6746 33.9745 21.7305 25.4085 21.7305C16.8425 21.7305 9.89844 28.6746 9.89844 37.2406C9.89844 45.8066 16.8425 52.7507 25.4085 52.7507Z"
                  fill="white"
                />
              </g>
            </mask>
            <g mask="url(#mask6_3818_30112)">
              <path
                d="M25.4164 52.7507C33.9823 52.7507 40.9264 45.8066 40.9264 37.2406C40.9264 28.6746 33.9823 21.7305 25.4164 21.7305C16.8504 21.7305 9.90625 28.6746 9.90625 37.2406C9.90625 45.8066 16.8504 52.7507 25.4164 52.7507Z"
                fill="white"
              />
            </g>
          </mask>
          <g mask="url(#mask5_3818_30112)">
            <path
              d="M25.4085 52.7507C33.9745 52.7507 40.9186 45.8066 40.9186 37.2406C40.9186 28.6746 33.9745 21.7305 25.4085 21.7305C16.8425 21.7305 9.89844 28.6746 9.89844 37.2406C9.89844 45.8066 16.8425 52.7507 25.4085 52.7507Z"
              fill="white"
            />
          </g>
        </mask>
        <g mask="url(#mask4_3818_30112)">
          <path
            d="M12.3828 21.7266V40.763H19.3247L25.4323 30.1872H40.9424V21.7266H12.3828ZM12.3828 21.7266V40.763H19.3247L25.4323 30.1872H40.9424V21.7266H12.3828Z"
            fill="#DB4437"
          />
          <path
            d="M9.91406 52.7459H24.6778L31.5317 45.892V40.7621H19.3199L9.91406 24.6328V52.7459ZM9.91406 52.7459H24.6778L31.5317 45.892V40.7621H19.3199L9.91406 24.6328V52.7459Z"
            fill="#0F9D58"
          />
          <path
            d="M25.415 30.1836L31.5225 40.7594L24.6719 52.7464H40.9283V30.1836H25.4182H25.415ZM25.415 30.1836L31.5225 40.7594L24.6719 52.7464H40.9283V30.1836H25.4182H25.415Z"
            fill="#FFCD40"
          />
          <path
            d="M25.4355 44.289C29.3306 44.289 32.4882 41.1314 32.4882 37.2363C32.4882 33.3412 29.3306 30.1836 25.4355 30.1836C21.5404 30.1836 18.3828 33.3412 18.3828 37.2363C18.3828 41.1314 21.5404 44.289 25.4355 44.289Z"
            fill="#F1F1F1"
          />
          <path
            d="M25.4306 42.8787C28.5463 42.8787 31.0721 40.3529 31.0721 37.2372C31.0721 34.1215 28.5463 31.5957 25.4306 31.5957C22.3149 31.5957 19.7891 34.1215 19.7891 37.2372C19.7891 40.3529 22.3149 42.8787 25.4306 42.8787Z"
            fill="#4285F4"
          />
        </g>
      </g>
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_3818_30112"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.36072 8.5293) scale(46.4488 46.4488)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3818_30112">
        <rect width="161" height="48.3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
