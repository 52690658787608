import React from "react";
import "./styles.scss";
import classNames from "classnames";

const RadioInput = ({ checked, onClick, label, id }) => {
  return (
    <label className="radioInput" onClick={onClick}>
      <div className="radioInput_option">{label}</div>
      <input type="radio" name="gender" id={id} className="radioInput_hide" checked={checked} readOnly />
      <div
        className={classNames({
          radioInput_radio: true,
          radioInput_radio__checked: checked,
        })}
      ></div>
    </label>
  );
};

export default RadioInput;
