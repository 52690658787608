import React, { useRef } from "react";
import RatingStars from "../../views/ratings/components/ratingStars";
import ICON_ARROW_MIN_LEFT from "../../components/icons/arrowMiniLeft";
import ICON_ARROW_MIN_RIGHT from "../../components/icons/arrowMiniRight";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

const Reviews = ({ reviews }) => {
  const listRef = useRef(null);

  return (
    <div className="reviews">
      <AliceCarousel
        mouseTracking
        items={5}
        infinite
        disableDotsControls
        responsive={{
          0: { items: 1 },
          600: { items: 2 },
          1024: { items: 3 },
        }}
        controlsStrategy="alternate"
        disableButtonsControls
        buttonsDisabled={true}
        ref={listRef}
      >
        {reviews.map((ele, index) => {
          return (
            <div className="reviews__card-cover" key={index}>
              <div className="reviews__card__content">
                <RatingStars rating={ele.rates} length={5} />
                <div className="reviews__card__content__title--sub">{ele.name}</div>
                <div className="reviews__card__content__title-cover">
                  <div className="reviews__card__content__title reviews__card__content__title--bold">{ele.title}</div>
                  <div className="reviews__card__content__title">{ele.subtitle}</div>
                </div>
              </div>
            </div>
          );
        })}
      </AliceCarousel>

      <div className="reviews__buttons-cover">
        <div className="reviews__buttons" onClick={() => listRef.current?.slidePrev()}>
          <ICON_ARROW_MIN_LEFT />
        </div>
        <div className="reviews__buttons" onClick={() => listRef.current?.slideNext()}>
          <ICON_ARROW_MIN_RIGHT />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
