import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/deletedAccount.png";

const DeletedReceiptModal = ({ visible, strs, closeBtn, deleteBtn }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={() => closeBtn()}
      backgroundImage={IMAGE}
      backgroundColor={"#EB9E6E"}
      title={strs.modalTitle}
      text={strs.modalText}
      button={
        <div className="buttonsWrapper">
          <Button label={strs.actionButtonCancel} type="transparent" onClick={() => closeBtn()} />
          <Button label={strs.actionButtonDelete} type="danger" onClick={() => deleteBtn()} />
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "receiptDelete"),
});

export default withRouter(connect(mapStateToProps)(DeletedReceiptModal));
