import React from "react";
import "./styles.scss";

const EmptyState = ({ image, text }) => {
  return (
    <div className="componentEmptyState">
      <div className="componentEmptyState__imageWrapper">
        <img src={image} alt="" />
      </div>
      <p className="componentEmptyState__textStyle">{text}</p>
    </div>
  );
};

export default EmptyState;
