const icon = (props) => (
  <svg
    width={props.width ?? 21}
    height={props.height ?? 13}
    {...props}
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9683 1.85433e-06C11.0053 0.00194724 10.0602 0.260172 9.22999 0.74816C8.39979 1.23615 7.71444 1.93631 7.24431 2.77675C6.83965 2.57969 6.39557 2.47698 5.94547 2.47635C5.24003 2.47821 4.55817 2.73059 4.02152 3.18848C3.48487 3.64637 3.12832 4.28 3.01546 4.97637C2.27028 5.28838 1.63395 5.81367 1.1864 6.48623C0.738844 7.15879 0.500044 7.94861 0.5 8.75647C0.501051 9.84308 0.933576 10.8848 1.70248 11.6526C2.47138 12.4204 3.51372 12.8514 4.60033 12.8508C4.77228 12.8493 4.94397 12.8369 5.11437 12.8138H16.5074C16.5673 12.8181 16.6272 12.821 16.6872 12.8225C16.7443 12.8209 16.8014 12.818 16.8584 12.8138H17.1313V12.7935C18.0589 12.6838 18.9142 12.236 19.5351 11.5349C20.156 10.8337 20.4993 9.92811 20.5 8.98963V8.98228C20.4978 8.09098 20.1866 7.2283 19.6198 6.54266C19.053 5.85702 18.2661 5.39128 17.3945 5.22558C17.3428 3.82145 16.7483 2.49215 15.7362 1.5175C14.7241 0.54284 13.3734 -0.00115908 11.9683 1.85433e-06Z"
      fill="url(#paint0_linear_2178_10664)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2178_10664"
        x1="20.5607"
        y1="9.00002"
        x2="0.492613"
        y2="8.72322"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E82F4" />
        <stop offset="1" stopColor="#93DCF7" />
      </linearGradient>
    </defs>
  </svg>
);

export default icon;
