import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../state/selectors";
import Outline from "../../components/layouts/outline";
import Button from "../../components/inputs/button";
import Label from "../../components/label";
import "./styles.scss";
import { usePostHog } from "posthog-js/react";
import EyeOffIcon from "../../components/icons/eyeOff";
import EyeIcon from "../../components/icons/eye";

const ViewPasswordReset = ({ strs, passwordResetHandler, tjommiDeviceID }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [valid, setValid] = useState(true);
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      posthog.capture("forgotten_password_confirm_screen_view", {
        device_id: tjommiDeviceID,
      });
    }
  }, [posthog]);

  return (
    <div className="viewPasswordReset">
      <div className="viewPasswordReset__title">
        <Label align="left" color="#ffffff" size="sm">
          {strs.rule}
        </Label>
      </div>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 0,
        }}
        border={true}
        outlineAreaStyles={{
          flexDirection: "row",
          display: "flex",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
          padding: 14,
        }}
        error={!valid}
      >
        <input
          style={{
            width: "90%",
          }}
          className="viewPasswordReset__input"
          name="Password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          placeholder={strs.password}
        />
        <div
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="viewPasswordReset__passwordVisible"
        >
          <div className="viewPasswordReset__passwordVisible__wrapper">
            {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
          </div>
        </div>
      </Outline>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 15,
        }}
        error={!valid}
        border={true}
        outlineAreaStyles={{
          flexDirection: "row",
          display: "flex",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
          padding: 14,
        }}
      >
        <input
          style={{
            width: "90%",
          }}
          className="viewPasswordReset__input"
          name="confirmPassword"
          type={confirmPasswordVisible ? "text" : "password"}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          value={confirmPassword}
          placeholder={strs.confirmPassword}
        />
        <div
          onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          className="viewPasswordReset__passwordVisible"
        >
          <div className="viewPasswordReset__passwordVisible__wrapper">
            {confirmPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
          </div>
        </div>
      </Outline>
      <div className="viewPasswordReset__main_btn_wrapper">
        <Button
          type="default"
          label={strs.save}
          onClick={() => {
            if (
              password !== confirmPassword ||
              password === "" ||
              confirmPassword === "" ||
              password.length < 8 ||
              confirmPassword.length < 8
            ) {
              setValid(false);
            } else {
              posthog.capture("forgotten_password_confirm_screen_save_click", {
                device_id: tjommiDeviceID,
              });
              setValid(true);
              passwordResetHandler(password);
            }
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "passwordReset"),
});

export default withRouter(connect(mapStateToProps, null)(ViewPasswordReset));
