import React from "react";
import { connect } from "react-redux";
import { MainContentLayout } from "../../../../components/layouts/mainContent";
import { selectorLanguageStrings, selectorMyUser } from "../../../../state/selectors";
import { HeaderLayout } from "../../../../components/layouts/header";
import "./styles.scss";
import TextArea from "../../../../components/inputs/textarea";
import Button from "../../../../components/inputs/button";
import { sendSupportMessage, setToastMessage } from "../../../../state/actionCreators";
import routes from "../../../../utils/routes";
import { ControlsLayout } from "../../../../components/layouts/controls";

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  handleNext = () => () => {
    this.setState({ message: "" });
    this.props.history.push(routes.supportSuccess.path);
  };

  handleClick = () => {
    this.props
      .sendSupportMessage(this.state.message)
      .catch(() => this.props.setToastMessage("Could not send the message"))
      .finally(() => this.handleNext()());
  };

  render() {
    return (
      <div className="support">
        <MainContentLayout center small>
          <div className="main">
            <HeaderLayout left={{ type: "arrowLeft", path: routes.support.path }} />
            <div className="support__headline">{this.props.strs.title({ user: this.props?.user?.first_name })}</div>
            <div className="support__text">{this.props.strs.label}</div>
            <TextArea
              label={this.props.strs.placeholder}
              initialRows={4}
              onChange={(value) => this.setState({ message: value })}
            />
            <div className="support__message">{this.props.strs.message}</div>
          </div>

          <ControlsLayout>
            <Button
              disabled={!this.state.message}
              type="pink"
              label={this.props.strs.buttonLabel}
              onClick={this.handleClick}
            />
          </ControlsLayout>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "supportChat"),
  user: selectorMyUser(state),
});

const mapDispatchToProps = { sendSupportMessage, setToastMessage };

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
