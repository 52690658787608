const Icon = ({ width = "161", height = "49", color = "#222222", ...props }) => (
  <svg width={width} height={height} {...props} viewBox="0 0 144 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M97.832 23.1908V25.9401H96.1087V27.743H97.832V33.869C97.832 35.9608 98.7783 36.7975 101.159 36.7975C101.577 36.7975 101.976 36.7477 102.324 36.6879V34.9049C102.026 34.9348 101.836 34.9547 101.508 34.9547C100.442 34.9547 99.9736 34.4567 99.9736 33.3211V27.743H102.324V25.9401H99.9736V23.1908H97.832Z"
      fill="white"
    />
    <path
      d="M108.389 36.9469C111.557 36.9469 113.499 34.8252 113.499 31.309C113.499 27.8127 111.547 25.6811 108.389 25.6811C105.221 25.6811 103.269 27.8127 103.269 31.309C103.269 34.8252 105.211 36.9469 108.389 36.9469ZM108.389 35.0444C106.526 35.0444 105.48 33.6797 105.48 31.309C105.48 28.9582 106.526 27.5836 108.389 27.5836C110.242 27.5836 111.298 28.9582 111.298 31.309C111.298 33.6697 110.242 35.0444 108.389 35.0444Z"
      fill="white"
    />
    <path
      d="M115.161 36.7377H117.302V30.3328C117.302 28.8088 118.448 27.7828 120.072 27.7828C120.45 27.7828 121.088 27.8526 121.267 27.9123V25.8006C121.038 25.7408 120.629 25.711 120.311 25.711C118.896 25.711 117.691 26.4879 117.382 27.5537H117.223V25.8903H115.161V36.7377Z"
      fill="white"
    />
    <path
      d="M126.584 27.5039C128.168 27.5039 129.204 28.6096 129.254 30.3129H123.775C123.895 28.6195 125.001 27.5039 126.584 27.5039ZM129.244 33.8092C128.846 34.6559 127.959 35.124 126.664 35.124C124.951 35.124 123.845 33.9188 123.775 32.0162V31.8967H131.435V31.1496C131.435 27.743 129.613 25.6811 126.594 25.6811C123.536 25.6811 121.594 27.8824 121.594 31.3488C121.594 34.8153 123.497 36.9469 126.604 36.9469C129.085 36.9469 130.818 35.7516 131.306 33.8092H129.244Z"
      fill="white"
    />
    <path
      d="M83.7877 32.7319C83.9529 35.3956 86.1726 37.0991 89.4763 37.0991C93.0072 37.0991 95.2166 35.313 95.2166 32.4635C95.2166 30.2231 93.957 28.9842 90.9011 28.2719L89.2595 27.8692C87.3186 27.4149 86.5339 26.8058 86.5339 25.7424C86.5339 24.4003 87.7522 23.5227 89.5796 23.5227C91.314 23.5227 92.5117 24.3796 92.7285 25.7527H94.9791C94.8449 23.244 92.6355 21.4785 89.6105 21.4785C86.3584 21.4785 84.1903 23.244 84.1903 25.8973C84.1903 28.086 85.4189 29.3869 88.1135 30.0166L90.0338 30.4812C92.0058 30.9458 92.873 31.6272 92.873 32.7629C92.873 34.0844 91.5102 35.0445 89.6518 35.0445C87.6593 35.0445 86.2758 34.1463 86.0797 32.7319H83.7877Z"
      fill="white"
    />
    <path
      d="M61.6029 25.711C60.1287 25.711 58.8537 26.4481 58.1962 27.6832H58.0369V25.8903H55.975V40.3436H58.1166V35.0942H58.2859C58.8537 36.2397 60.0789 36.917 61.6228 36.917C64.3621 36.917 66.1052 34.7555 66.1052 31.309C66.1052 27.8625 64.3621 25.711 61.6029 25.711ZM60.9953 34.9945C59.2023 34.9945 58.0767 33.5801 58.0767 31.319C58.0767 29.0479 59.2023 27.6334 61.0052 27.6334C62.8181 27.6334 63.9039 29.018 63.9039 31.309C63.9039 33.61 62.8181 34.9945 60.9953 34.9945Z"
      fill="white"
    />
    <path
      d="M73.599 25.711C72.1248 25.711 70.8498 26.4481 70.1924 27.6832H70.033V25.8903H67.9711V40.3436H70.1127V35.0942H70.2821C70.8498 36.2397 72.075 36.917 73.619 36.917C76.3582 36.917 78.1014 34.7555 78.1014 31.309C78.1014 27.8625 76.3582 25.711 73.599 25.711ZM72.9914 34.9945C71.1985 34.9945 70.0729 33.5801 70.0729 31.319C70.0729 29.0479 71.1985 27.6334 73.0014 27.6334C74.8143 27.6334 75.9 29.018 75.9 31.309C75.9 33.61 74.8143 34.9945 72.9914 34.9945Z"
      fill="white"
    />
    <path
      d="M52.1326 36.7377H54.5897L49.2108 21.8399H46.7227L41.3438 36.7377H43.7183L45.0914 32.7835H50.7698L52.1326 36.7377ZM47.848 24.5448H48.0235L50.1813 30.8426H45.6799L47.848 24.5448Z"
      fill="white"
    />
    <path
      d="M42.7812 10.6036V17.7904H45.3761C47.5177 17.7904 48.7578 16.4706 48.7578 14.1746C48.7578 11.9135 47.5077 10.6036 45.3761 10.6036H42.7812ZM43.8969 11.6196H45.2516C46.7407 11.6196 47.6223 12.5659 47.6223 14.1896C47.6223 15.8381 46.7557 16.7744 45.2516 16.7744H43.8969V11.6196Z"
      fill="white"
    />
    <path
      d="M52.5559 17.895C54.1397 17.895 55.1109 16.8342 55.1109 15.0761C55.1109 13.3279 54.1347 12.2621 52.5559 12.2621C50.9721 12.2621 49.996 13.3279 49.996 15.0761C49.996 16.8342 50.9671 17.895 52.5559 17.895ZM52.5559 16.9438C51.6246 16.9438 51.1016 16.2614 51.1016 15.0761C51.1016 13.9007 51.6246 13.2134 52.5559 13.2134C53.4823 13.2134 54.0102 13.9007 54.0102 15.0761C54.0102 16.2564 53.4823 16.9438 52.5559 16.9438Z"
      fill="white"
    />
    <path
      d="M63.3815 12.3667H62.3107L61.3445 16.5055H61.2598L60.1442 12.3667H59.1182L58.0026 16.5055H57.9229L56.9517 12.3667H55.8659L57.3601 17.7904H58.4608L59.5764 13.7961H59.6611L60.7817 17.7904H61.8923L63.3815 12.3667Z"
      fill="white"
    />
    <path
      d="M64.6146 17.7904H65.6854V14.6179C65.6854 13.7712 66.1885 13.2433 66.9803 13.2433C67.7722 13.2433 68.1508 13.6766 68.1508 14.5481V17.7904H69.2216V14.2792C69.2216 12.9893 68.5542 12.2621 67.3439 12.2621C66.5271 12.2621 65.9892 12.6257 65.7253 13.2283H65.6456V12.3667H64.6146V17.7904Z"
      fill="white"
    />
    <path d="M70.9079 17.7904H71.9787V10.25H70.9079V17.7904Z" fill="white" />
    <path
      d="M76.006 17.895C77.5897 17.895 78.5609 16.8342 78.5609 15.0761C78.5609 13.3279 77.5848 12.2621 76.006 12.2621C74.4222 12.2621 73.446 13.3279 73.446 15.0761C73.446 16.8342 74.4172 17.895 76.006 17.895ZM76.006 16.9438C75.0746 16.9438 74.5517 16.2614 74.5517 15.0761C74.5517 13.9007 75.0746 13.2134 76.006 13.2134C76.9323 13.2134 77.4603 13.9007 77.4603 15.0761C77.4603 16.2564 76.9323 16.9438 76.006 16.9438Z"
      fill="white"
    />
    <path
      d="M81.7514 16.9786C81.1687 16.9786 80.7454 16.6947 80.7454 16.2066C80.7454 15.7285 81.084 15.4745 81.8311 15.4247L83.1559 15.34V15.7933C83.1559 16.4656 82.5583 16.9786 81.7514 16.9786ZM81.4775 17.8801C82.1897 17.8801 82.7824 17.5713 83.1061 17.0284H83.1908V17.7904H84.2217V14.085C84.2217 12.9395 83.4547 12.2621 82.0951 12.2621C80.8649 12.2621 79.9883 12.8598 79.8788 13.7911H80.9147C81.0342 13.4076 81.4476 13.1885 82.0453 13.1885C82.7774 13.1885 83.1559 13.5122 83.1559 14.085V14.5531L81.6867 14.6378C80.3967 14.7175 79.6696 15.2803 79.6696 16.2564C79.6696 17.2476 80.4316 17.8801 81.4775 17.8801Z"
      fill="white"
    />
    <path
      d="M87.8555 17.8801C88.6026 17.8801 89.2351 17.5265 89.5588 16.9338H89.6435V17.7904H90.6694V10.25H89.5986V13.2283H89.519C89.2251 12.6307 88.5976 12.2771 87.8555 12.2771C86.4859 12.2771 85.6043 13.3628 85.6043 15.0761C85.6043 16.7943 86.4759 17.8801 87.8555 17.8801ZM88.1593 13.2383C89.0558 13.2383 89.6186 13.9505 89.6186 15.0811C89.6186 16.2166 89.0608 16.9188 88.1593 16.9188C87.2528 16.9188 86.71 16.2266 86.71 15.0761C86.71 13.9355 87.2578 13.2383 88.1593 13.2383Z"
      fill="white"
    />
    <path
      d="M97.6132 17.895C99.197 17.895 100.168 16.8342 100.168 15.0761C100.168 13.3279 99.192 12.2621 97.6132 12.2621C96.0294 12.2621 95.0533 13.3279 95.0533 15.0761C95.0533 16.8342 96.0244 17.895 97.6132 17.895ZM97.6132 16.9438C96.6819 16.9438 96.1589 16.2614 96.1589 15.0761C96.1589 13.9007 96.6819 13.2134 97.6132 13.2134C98.5396 13.2134 99.0675 13.9007 99.0675 15.0761C99.0675 16.2564 98.5396 16.9438 97.6132 16.9438Z"
      fill="white"
    />
    <path
      d="M101.586 17.7904H102.656V14.6179C102.656 13.7712 103.159 13.2433 103.951 13.2433C104.743 13.2433 105.122 13.6766 105.122 14.5481V17.7904H106.193V14.2792C106.193 12.9893 105.525 12.2621 104.315 12.2621C103.498 12.2621 102.96 12.6257 102.696 13.2283H102.617V12.3667H101.586V17.7904Z"
      fill="white"
    />
    <path
      d="M111.124 11.017V12.3916H110.263V13.2931H111.124V16.3561C111.124 17.402 111.597 17.8203 112.788 17.8203C112.997 17.8203 113.196 17.7954 113.37 17.7655V16.874C113.221 16.889 113.126 16.8989 112.962 16.8989C112.429 16.8989 112.195 16.6499 112.195 16.0821V13.2931H113.37V12.3916H112.195V11.017H111.124Z"
      fill="white"
    />
    <path
      d="M114.808 17.7904H115.879V14.6229C115.879 13.8011 116.367 13.2482 117.243 13.2482C118 13.2482 118.404 13.6865 118.404 14.5531V17.7904H119.474V14.2892C119.474 12.9992 118.762 12.2671 117.607 12.2671C116.79 12.2671 116.217 12.6307 115.953 13.2383H115.869V10.25H114.808V17.7904Z"
      fill="white"
    />
    <path
      d="M123.337 13.1735C124.129 13.1735 124.647 13.7264 124.672 14.578H121.933C121.993 13.7313 122.545 13.1735 123.337 13.1735ZM124.667 16.3262C124.468 16.7495 124.025 16.9836 123.377 16.9836C122.521 16.9836 121.968 16.381 121.933 15.4297V15.3699H125.763V14.9964C125.763 13.2931 124.851 12.2621 123.342 12.2621C121.813 12.2621 120.842 13.3628 120.842 15.096C120.842 16.8292 121.793 17.895 123.347 17.895C124.587 17.895 125.454 17.2974 125.698 16.3262H124.667Z"
      fill="white"
    />
    <path
      d="M29.7255 24.5117C29.7515 22.4945 30.8348 20.5857 32.5534 19.5292C31.4692 17.9808 29.6533 16.9991 27.764 16.94C25.7489 16.7285 23.7954 18.1458 22.7685 18.1458C21.7218 18.1458 20.1408 16.961 18.4383 16.996C16.2192 17.0677 14.1505 18.3294 13.0708 20.2694C10.75 24.2876 12.4811 30.1928 14.7043 33.4407C15.8165 35.0312 17.1165 36.8077 18.8174 36.7447C20.4819 36.6757 21.1035 35.6833 23.1127 35.6833C25.1032 35.6833 25.6864 36.7447 27.4219 36.7046C29.208 36.6757 30.3333 35.1072 31.4065 33.5017C32.2057 32.3685 32.8206 31.116 33.2286 29.7907C31.1297 28.9029 29.728 26.7906 29.7255 24.5117Z"
      fill="white"
    />
    <path
      d="M26.4475 14.804C27.4214 13.6349 27.9011 12.1323 27.785 10.6152C26.2972 10.7715 24.9228 11.4826 23.9359 12.6068C22.9707 13.7052 22.4684 15.1813 22.5634 16.6404C24.0517 16.6558 25.5149 15.964 26.4475 14.804Z"
      fill="white"
    />
    <rect x="0.5" y="0.650391" width="143" height="47" rx="6.5" stroke="white" />
  </svg>
);

export default Icon;
