import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/appUpdate.png";
import { withRouter } from "react-router-dom";

const AppUpdateModal = ({ visible, closeBtn, strs }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn || null}
      backgroundImage={IMAGE}
      backgroundColor={"#6472f5"}
      title={"Update Tjommi app!"}
      text={
        "It appears you're using an older version of the Tjommi app. Enhance your experience by updating your app to the latest version."
      }
      button={
        <Button
          label={"Update App"}
          type="transparent"
          onClick={() => {
            console.log(".mkk");
          }}
        />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "overview"),
});

export default withRouter(connect(mapStateToProps)(AppUpdateModal));
