class NativeAppClient {
  static requestPushToken() {
    return this._waitForResponse("push:request", "push:response").catch(() => null);
  }
  static requestAsyncToken() {
    return this._waitForResponse("deviceToken:request", "deviceToken:response").catch(() => null);
  }
  static customerIoClearIdentify() {
    return this._waitForResponse("customerio_clearIdentify:request", "customerio_clearIdentify:response").catch(
      () => null
    );
  }

  static requestPushTokenStatus() {
    return this._waitForResponse("push-status:request", "push-status:response").catch(() => null);
  }

  static requestDebugValues() {
    return this._waitForResponse("debug:request", "debug:response").catch(() => {});
  }

  static triggerShareDialog(payload) {
    return this._waitForResponse("share:request", "share:response", payload).catch(() => {});
  }

  static copyToClipboard(text) {
    return this._waitForResponse("clipboard:request", "clipboard:response", {
      text,
    }).catch(() => {});
  }

  static openWebBrowser(url) {
    return this._waitForResponse("openbrowser:request", "openbrowser:response", {
      url,
    }).catch(() => {});
  }

  static openAppSettings() {
    return this._waitForResponse("openappsettings:request", "openappsettings:response").catch(() => null);
  }

  static customerioIdentify(id, attributes) {
    return this._waitForResponse("customerio:request", "customerio:response", {
      id,
      attributes,
    }).catch(() => {});
  }

  static async LogEventAF(event, values) {
    console.log("[Tjommi] Attempting AppsFlyer LogEvent:", event, values);

    this._waitForResponse("appsflyer:event", "appsflyer:response", {
      event: event,
      values: values || {},
    })
      .then((res) => {
        console.log("[Tjommi] AppsFlyer response", res);
      })
      .catch((err) => null);
  }

  // Sends a PostMessage to the react native webview running the webapp,
  // it waits for a response (timeout) of the expected type,
  // optionally also takes a payload (JSON)
  static _waitForResponse = (request, expectedResponse, payload, timeout = 2000) => {
    const unregister = (listener) => {
      window.removeEventListener("message", listener);
      document.removeEventListener("message", listener);
    };

    const register = (listener) => {
      window.addEventListener("message", listener);
      document.addEventListener("message", listener);
    };

    return new Promise((resolve, reject) => {
      // Handle incoming messages
      const handleResponse = (event) => {
        const { type, payload } = this._parseMessage(event);

        // Check if this is the expected response.
        if (type === expectedResponse) {
          clearTimeout(timeoutId);
          unregister(handleResponse);
          resolve(payload);
        }
      };

      // Register handles for messages coming from native webview
      register(handleResponse);

      // Send message to the native webview
      this._postMessage(request, payload);

      // If no response is given within the timeout, log it here and unregister the handlers, aka Cleanup
      const timeoutId = setTimeout(() => {
        console.warn(`[Tjommi]: Native app did not respond to request: ${request}`);
        unregister(handleResponse);
        reject();
      }, timeout);
    });
  };

  static isInNativeApp() {
    return !!window.ReactNativeWebView;
  }

  static _postMessage(type, payload) {
    if (!window.ReactNativeWebView) {
      console.warn("[Tjommi]: Trying to postMessage to Native app, while not being in a ReactNative application", {
        type,
        payload,
      });
      return;
    }

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type,
        payload,
      })
    );
  }

  static _parseMessage(event) {
    try {
      const { type, payload } = JSON.parse(event.data);
      return { type, payload };
    } catch {
      return { type: "unknown", payload: null };
    }
  }
}

export default NativeAppClient;
