import React from "react";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import PullToRefresh from "react-simple-pull-to-refresh";
import { isMobile } from "react-device-detect";

import "./style.scss";

const ListingPage = ({
  backPath,
  emptyState,
  header,
  headline,
  items,
  onRefresh = null,
  renderItem,
  roundBackArrow,
}) => {
  return (
    <div className="listingPage">
      <MainContentLayout center={true}>
        <HeaderLayout left={{ type: "arrowLeft", path: backPath }} roundBackArrow={roundBackArrow || false} />

        {headline && <h1 className="headline">{headline}</h1>}
        {header && <div className="listingPage--header">{header}</div>}

        <div className="listingPage--items">
          <PullToRefresh
            maxPullDownDistance={100}
            onRefresh={onRefresh}
            isPullable={isMobile && onRefresh !== null}
            className="tjommi--pull-to-refresh"
            pullingContent={false}
          >
            {items.length ? items.map((item) => renderItem(item)) : emptyState}
          </PullToRefresh>
        </div>
      </MainContentLayout>
    </div>
  );
};

export default ListingPage;
