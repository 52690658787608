import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorMyUser } from "../../../state/selectors";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import Outline from "../../../components/layouts/outline";
import ListItem from "../../../components/listItem";
import Button from "../../../components/inputs/button";
import DollarIcon from "../../../components/icons/dollar";
import TimeIcon from "../../../components/icons/time";
import ShopIcon from "../../../components/icons/shop";
import { withRouter } from "react-router-dom";
import routes from "../../../utils/routes";
import "./styles.scss";
import { usePostHog } from "posthog-js/react";
import NativeAppClient from "../../../services/nativeAppClient";

const ViewProfileNotification = ({ history, strs, onCancelHandler, onConfirmHandler, tjommiDeviceID, user }) => {
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      posthog.capture("communication_opt_in_screen_view", {
        device_id: tjommiDeviceID,
        user_id: user.id,
        user_name: user.full_name,
        user_email: user.email,
        user_region: user.region_id,
      });
    }
  }, [posthog]);

  return (
    <div className="ViewProfileNotificationPage">
      <MainContentLayout center={true}>
        <h1 className="headline">{strs.title}</h1>
        <Outline>
          <ListItem text={strs.priceDropSub} title={strs.priceDrop} image={<DollarIcon />} />
        </Outline>

        <Outline>
          <ListItem text={strs.timeLimitSub} title={strs.timeLimit} image={<TimeIcon />} />
        </Outline>
        {NativeAppClient.isInNativeApp() ? (
          user.enable_push_notifications === null && user.enable_email_notifications === null ? (
            <Outline>
              <ListItem text={strs.relevantSub} title={strs.relevant} image={<ShopIcon />} />
            </Outline>
          ) : null
        ) : (
          <Outline>
            <ListItem text={strs.relevantSub} title={strs.relevant} image={<ShopIcon />} />
          </Outline>
        )}

        <div className="ViewProfileNotificationPage_subtitle">
          {NativeAppClient.isInNativeApp()
            ? user.enable_push_notifications === null && user.enable_email_notifications === null
              ? strs.subtitle
              : strs.subtitlePush
            : strs.subtitleEmail}
        </div>
        <Button disabled={false} type="pink" label={strs.btnTitle} onClick={onConfirmHandler} />

        <Button disabled={false} type="transparent" label={strs.decline} onClick={onCancelHandler} />

        <div className="ViewProfileNotificationPage_infoText">
          {strs.agreement}
          <span
            className="ViewProfileNotificationPage_infoText--bold"
            onClick={() => {
              posthog.capture("communication_opt_in_screen_PP_click", {
                device_id: tjommiDeviceID,
                user_id: user.id,
                user_name: user.full_name,
                user_email: user.email,
                user_region: user.region_id,
              });
              history.push(routes.privacy.path + "?redirect=" + routes.onboarding.path);
            }}
          >
            {strs.privacyPolicy}
          </span>
          {strs.agreement2}
          <span
            className="ViewProfileNotificationPage_infoText--bold"
            onClick={() => {
              posthog.capture("communication_opt_in_screen_partners_click", {
                device_id: tjommiDeviceID,
                user_id: user.id,
                user_name: user.full_name,
                user_email: user.email,
                user_region: user.region_id,
              });
              history.push(routes.partners.path + "?redirect=" + routes.onboarding.path);
            }}
          >
            {strs.here}
          </span>
          {NativeAppClient.isInNativeApp()
            ? user.enable_push_notifications === null && user.enable_email_notifications === null
              ? strs.agreement3
              : ""
            : strs.agreement3}
        </div>
      </MainContentLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "profileNotification"),
    user: selectorMyUser(state),
  };
};

export default withRouter(connect(mapStateToProps, null)(ViewProfileNotification));
