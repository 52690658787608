import React from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import { HeaderLayout } from "../../../components/layouts/header";
import Button from "../../../components/inputs/button";
import { ControlsLayout } from "../../../components/layouts/controls";
import TextArea from "../../../components/inputs/textarea";
import { deleteMyUser, setToastMessage } from "../../../state/actionCreators";
import routes from "../../../utils/routes";
import { withRouter } from "react-router-dom";
import Outline from "../../../components/layouts/outline";
import { selectorLanguageStrings, selectorPurchaseItems, selectorReceipts } from "../../../state/selectors";
import DeletedAccountModal from "../../../components/modals/deletedAccountModal/DeletedAccountModal";

class DeleteProfile extends React.Component {
  state = {
    leaveReason: "",
  };

  handleNext = (next) => () => this.props.history.push("/profile/delete/" + next);

  onDeleteProfile() {
    this.props
      .deleteMyUser(this.state.leaveReason)
      .catch(() => this.props.setToastMessage("todo: Could not delete user message here"))
      .finally(() => this.handleNext(3)());
  }

  renderScreen1() {
    return (
      <>
        <HeaderLayout
          left={{
            type: "arrowLeft",
            path: this.props.routes.profile.path,
          }}
        />
        <h1>{this.props.strs.screen1Title}</h1>

        <TextArea
          label={this.props.strs.leaveReasonPlaceholder}
          showIndicator={true}
          initialRows={4}
          onChange={(value) => this.setState({ leaveReason: value })}
          maxLength={1200}
        />

        <ControlsLayout>
          <Button label={this.props.strs.actionButtonDelete} type="danger" onClick={this.handleNext(2)} />
          <Button label={this.props.strs.actionButtonCancel} type="transparent" to={routes.profile.path} />
        </ControlsLayout>
      </>
    );
  }

  renderScreen2() {
    return (
      <>
        <HeaderLayout
          left={{
            type: "arrowLeft",
            path: this.props.routes.profileAccountDelete.subroutes.one.path,
          }}
        />
        <h1>{this.props.strs.screen2Title}</h1>
        <div>{this.props.strs.screen2Body}</div>

        <Outline label={this.props.strs.screen2SummaryHeading}>
          <div className={"summaryCard"}>
            <div className={"summaryCardNumber"}>{this.props.activePurchasesCount}</div>
            <div className={"summaryCardText"}>{this.props.strs.screen2SummaryTextActivePurchases}</div>
          </div>
        </Outline>

        <Outline>
          <div className={"summaryCard"}>
            <div className={"summaryCardNumber"}>{this.props.receiptCount}</div>
            <div className={"summaryCardText"}>{this.props.strs.screen2SummaryTextReceipts}</div>
          </div>
        </Outline>

        <ControlsLayout>
          <Button label={this.props.strs.actionButtonDelete} type="danger" onClick={this.onDeleteProfile.bind(this)} />
          <Button label={this.props.strs.actionButtonCancel} type="transparent" to={routes.profile.path} />
        </ControlsLayout>
      </>
    );
  }

  renderScreen3() {
    return <DeletedAccountModal visible={true} />;
  }

  render() {
    return (
      <div className="viewDeleteProfile">
        <MainContentLayout center small>
          {this.props.screen === 1 && this.renderScreen1()}
          {this.props.screen === 2 && this.renderScreen2()}
          {this.props.screen === 3 && this.renderScreen3()}
        </MainContentLayout>
      </div>
    );
  }
}

// TODO(23 aug. 2021) ~ Helge: Add pageId for deleteProfile in language file
const mapStateToProps = (state, ownProps) => ({
  strs: selectorLanguageStrings(state, "profileDelete"),
  screen: parseInt(ownProps.match.params.screen) || null,
  activePurchasesCount: selectorPurchaseItems(state).filter((purchase) => purchase.isExpired === false).length,
  receiptCount: selectorReceipts(state).length,
});

const mapDispatchToProps = { deleteMyUser, setToastMessage };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteProfile));
