import React from "react";
import { connect } from "react-redux";
import { selectorIsAppMode } from "../../state/selectors";
import MainMenu from "../../components/menus/mainMenu";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import getQuery from "../../utils/getQuery";
import Footer from "../../components/layouts/footer";
import "./styles.scss";

const ViewSecurityReport = ({ isAppMode }) => {
  const redirect = getQuery()?.redirect;

  return (
    <div className="viewSecurityReport">
      {!isAppMode && <MainMenu dark={true} />}
      <div className="viewSecurityReport--wrapper">
        <MainContentLayout center small>
          {isAppMode && redirect && <HeaderLayout left={{ type: "arrowLeft", path: redirect }} />}
          <div>
            <h1 className="headline">Report a Security Incident</h1>
            <p>
              Tjommi takes security seriously. If you’ve discovered a vulnerability, we want to know about it. Please
              send reports of vulnerabilities to <a href="mailto:security@tjommi.app">security@tjommi.app</a>
            </p>
          </div>
        </MainContentLayout>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
});

export default connect(mapStateToProps)(ViewSecurityReport);
