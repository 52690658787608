import React from "react";
import { connect } from "react-redux";
import { selectorIsAppMode, selectorLanguageStrings, selectorRegion } from "../../../state/selectors";
import Button from "../button";
import ApiClient from "../../../services/apiClient";
import { APP_URL, WEB_URL, WEB_REDIRECT_URL } from "../../../utils/configs";
import routes from "../../../utils/routes";
import Store from "../../../state/store";

// TODO(21 okt. 2021) ~ Helge: Rewrite all of this garbage
// TODO(28 sep. 2022) ~ Helge: Dont use this component anymore
export const getConnectEmailUrl = (provider, redirect) => {
  const isAppMode = selectorIsAppMode(Store.getState());
  // remove first slash in app mode so e.g. "tjommi-peace-app:///signin" becomes "tjommi-peace-app://signin"
  redirect = isAppMode ? APP_URL + redirect.substring(1) : WEB_REDIRECT_URL + redirect;
  const method = provider === "google" ? "getGmailConnectUrl" : "getOutlookConnectUrl";
  let url = ApiClient[method](redirect);
  // append query flag so the native app opens it in the auth session modal
  if (isAppMode) url += "&appOpenInAuthSession";
  return url;
};

export const getSigninWithUrl = (provider, region, isAppMode, trafficSource = undefined) => {
  // remove first slash in app mode so e.g. "tjommi-peace-app:///signin" becomes "tjommi-peace-app://signin"
  let redirect = isAppMode ? APP_URL + routes.onboarding.path : WEB_URL + routes.onboarding.path;
  const registrationSource = isAppMode ? "mobile" : "web";
  let url = ApiClient.getAuthenticateUrl(provider, redirect, region, registrationSource, trafficSource);
  // append query flag so the native app opens it in the auth session modal
  if (isAppMode) url += "&appOpenInAuthSession";
  return url;
};

class ButtonSigninWith extends React.Component {
  handleClick = () => {
    window.location.href = getSigninWithUrl(this.props.type, this.props.region, this.props.isAppMode, false);
  };

  render() {
    const name = this.props.type === "google" ? "Google" : "Outlook";
    return (
      <Button icon={this.props.type} label={this.props.strs.button({ provider: name })} onClick={this.handleClick} />
    );
  }
}

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
  region: selectorRegion(state),
  strs: selectorLanguageStrings(state, "buttonSigninWith"),
});

export default connect(mapStateToProps)(ButtonSigninWith);
