import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorNotificationsByDay } from "../../state/selectors";
import routes from "../../utils/routes";
import Outline from "../../components/layouts/outline";
import NotificationItem from "../../components/notificationItem";
import "./styles.scss";
import ListingPage from "../listingPage";
import { getNotifications } from "../../state/actionCreators";

const ViewNotifications = ({ notifications, getNotifications, strs }) => {
  return (
    <ListingPage
      backPath={routes.overview.path}
      headline={strs.title}
      items={notifications}
      onRefresh={getNotifications}
      renderItem={(date) => (
        <Outline key={date.label} label={date.label}>
          {date.items.map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </Outline>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  notifications: selectorNotificationsByDay(state),
  strs: selectorLanguageStrings(state, "notifications"),
});

const mapDispatchToProps = { getNotifications };

export default connect(mapStateToProps, mapDispatchToProps)(ViewNotifications);
