import React, { useEffect } from "react";
import MainMenu from "../../components/menus/mainMenu";
import COFOUNDERS_IMAGE from "./assets/co-founders.png";
import Button from "../../components/inputs/newButton";
import Footer from "../../components/layouts/footer";
import SectionText from "../../components/newSectionText";
import SessionCard from "../../components/sectionCard";
import ICON_ADD_CIRCLE from "../../components/icons/addCircle";
import ICON_OUTWARD_ARROW from "../../components/icons/arrowOutward";
import classNames from "classnames";
import Hide from "../../components/hide/hide";
import Article1 from "./assets/news/article1.webp";
import Article2 from "./assets/news/article2.webp";
import Article3 from "./assets/news/article3.webp";
import Article4 from "./assets/news/article4.jpg";
import routes from "../../utils/routes";
import "./styles.scss";

const ViewAboutPage = () => {
  useEffect(() => {
    window.location.href = "/";
  }, []);
  const partners = [
    {
      title: "Strawberry Capital",
      description:
        "100% owned by Norwegian entrepreneur Petter Anker Stordalen which owns Nordic Choice Hotels, Ving & Hurtigruten.",
      link: "https://www.strawberrygroup.no/discover-strawberry/the-companies/strawberry-equity",
    },
    {
      title: "Nordic Makers",
      description:
        "Unicorn makers reinvesting experience and money. Founded Sitecore, Justeat and Zendesk to mention a few. ",
      link: "http://www.nordicmakers.vc/",
    },
    {
      title: "Spring Capital",
      description: "Estonian VC backing the best companies in the Baltics. Investor in Bolt and Monese.",
      link: "https://springcapital.ee/",
    },
    {
      title: "VF Venture",
      description: "The Danish growth fund backing the best companies with global ambitious from Denmark. ",
      link: "https://vf.dk/en/",
    },
  ];
  const totalNumbersList = [
    {
      id: 1,
      title: "150k+",
      subTitle: "Total users",
    },
    {
      id: 2,
      title: "2m+",
      subTitle: "Total receipts",
    },
    {
      id: 3,
      title: "80k+",
      subTitle: "Total refunds",
    },
    {
      id: 4,
      title: "5k+",
      subTitle: "Stores",
    },
  ];

  const storiesList = [
    {
      id: 1,
      title: "Milestones",
      subTitle: "2019",
      description: ["Our founder Henrik Johannessen launched Tjommi"],
    },
    {
      id: 2,
      title: "Milestones",
      subTitle: "2020",
      description: ["Moved HQ to from Norway to Copenhagen, Denmark", "Pre-seed fundraising €400+600k "],
    },
    {
      id: 3,
      title: "Milestones",
      subTitle: "2021",
      description: [
        "Tjommi get an license from Google and Outlook",
        "2,000 merchants joins the platform",
        "Launches Tjommi Score",
      ],
    },
  ];

  const latestStory = {
    title: "Milestones",
    subTitle: "2022",
    description: [
      "Seed fundraising +€1m",
      "Launches in the UK",
      "Reaches 200k users on the platform",
      "Launched Trusted Tjommi Partners, helping merchants to stand out and be transparent",
    ],
  };

  const historyCardList = [
    {
      date: "1 January 2023",
      img: Article1,
      type: "News",
      description: "Henrik goes to war against the shops: 'People have a right to their money'",
      link: "https://ekstrabladet.dk/forbrug/Teknologi/henrik-gaar-i-krig-mod-butikkerne-folk-har-ret-til-deres-penge/9566436",
    },
    {
      date: "22 December 2022",
      type: "News",
      img: Article2,
      description: "Price app Tjommi moves to London with hopes of increased growth",
      link: "https://finans.dk/erhverv/ECE14738205/prisappen-tjommi-rykker-til-london-med-haab-om-oeget-vaekst/",
    },
    {
      date: "23 July 2022",
      type: "News",
      img: Article3,
      description:
        "Prisapp with hotel billionaire Petter Stordalen in the ownership circle gets a million dollar investment",
      link: "https://finans.dk/erhverv/ECE14163044/prisapp-med-hotelmilliardaer-petter-stordalen-i-ejerkredsen-faar-millioninvestering/",
    },
    {
      date: "21 December 2021",
      type: "News",
      img: Article4,
      description:
        "Danes pay 550 million too much for Christmas presents - Every 5th Christmas gift falls in price - but you can get the money back after Christmas!",
      link: "https://via.ritzau.dk/pressemeddelelse/danskerne-betaler-550-millioner-for-meget-for-julegaverne---hver-5-julegave-falder-i-pris-men-du-kan-fa-pengene-tilbage-efter-jul?publisherId=13560836&releaseId=13639524",
    },
  ];
  return (
    <div className="aboutPage">
      <MainMenu />

      <div className="aboutPage-header-wrapper">
        <div className="aboutPage-header__title">
          Shopping <br />
          with peace of mind
        </div>

        <div className="aboutPage-header__title aboutPage-header__title--sub">
          We founded Tjommi to change the way we shop. We design services tailored to the next generation of shoppers,
          because thats who we are, and thats what we know.
        </div>
      </div>

      {/* SECTION 1 */}

      <div className="aboutPage-section">
        <div className="aboutPage-section-content-totalNumbers-wrapper">
          {totalNumbersList.map((ele, index) => {
            return (
              <div key={ele.id} className="aboutPage-section-content-totalNumbers-wrapper--cover">
                <div className="aboutPage-section-content-totalNumbers__header">
                  <div className="aboutPage-section-content-totalNumbers__title">{ele.title}</div>
                  <div className="aboutPage-section-content-totalNumbers__title aboutPage-section-content-totalNumbers__title--sub">
                    {ele.subTitle}
                  </div>
                </div>
                <Hide on="mobile">
                  {totalNumbersList.length - 1 > index ? (
                    <hr className="aboutPage-section-content-totalNumbers__line" />
                  ) : null}
                </Hide>
              </div>
            );
          })}
        </div>
      </div>

      {/* SECTION 2 */}

      <div className="aboutPage-section aboutPage-section-content-coFounders">
        <Hide on={"desktop"}>
          <div className="aboutPage-section-content-coFounders__img__title">
            Tjommi <br /> Co-founder <br /> team
          </div>
        </Hide>
        <div className="aboutPage-section-content-coFounders-wrapper">
          <div>
            <Hide on="mobile">
              <div className="aboutPage-section-content-coFounders__img__title">
                Tjommi <br /> Co-founder <br /> team
              </div>
            </Hide>
            <img
              src={COFOUNDERS_IMAGE}
              alt="Tjommi-Co_Founders"
              draggable={false}
              className="aboutPage-section-content-coFounders__img"
            />
          </div>
          <div className="aboutPage-section-content-coFounders__bottom">
            <div className="aboutPage-section-content-coFounders__bottom__title">Your ultimate shopping companion</div>
            <Hide on="mobile">
              <div className="aboutPage-section-content-coFounders__bottom__title aboutPage-section-content-coFounders__bottom__title--sub">
                As shoppers, we expect more from stores than ever before. If a price drops shortly after purchase, give
                us a partial refund or a gift card, and we’ll stay loyal. <br />
                <br />
                At its core, Tjommi is about getting your money back. But that’s only the start. With Tjommi, you can
                also collect all your receipts in on place —no more digging around in your email.
              </div>
            </Hide>
            <div className="aboutPage-section-content-coFounders__bottom__btn__cover">
              <Hide on="mobile">
                <Button
                  label={"Meet our leadership team"}
                  light
                  onClick={() => {
                    window.location.href = routes.team.path;
                  }}
                />
              </Hide>
              <Hide on="desktop">
                <Button
                  label={"Learn more"}
                  light
                  onClick={() => {
                    window.location.href = routes.team.path;
                  }}
                />
              </Hide>
            </div>
          </div>
        </div>
        <Hide on="desktop">
          <div className="aboutPage-section-content-coFounders__bottom__title aboutPage-section-content-coFounders__bottom__title--sub">
            As shoppers, we expect more from stores than ever before. If a price drops shortly after purchase, give us a
            partial refund or a gift card, and we’ll stay loyal. <br />
            <br />
            At its core, Tjommi is about getting your money back. But that’s only the start. With Tjommi, you can also
            collect all your receipts in on place —no more digging around in your email.
          </div>
        </Hide>
      </div>

      {/* SECTION 3 */}

      <div className="aboutPage-section">
        <div className="aboutPage-section-content-stories-wrapper">
          {/* TOP */}
          <div className="aboutPage-section-content-stories-wrapper__top">
            <div className="aboutPage-section-content-stories-wrapper__top__title">The Tjommi story</div>
          </div>
          {/* Middle */}
          <div className="aboutPage-section-content-stories-wrapper__middle">
            {storiesList.map((ele) => {
              return (
                <div className="aboutPage-section-content-stories-wrapper__middle__card--cover" key={ele.id}>
                  <div className="aboutPage-section-content-stories-wrapper__middle__card__title">{ele.title}</div>
                  <div className="aboutPage-section-content-stories-wrapper__middle__card__title aboutPage-section-content-stories-wrapper__middle__card__title--white">
                    {ele.subTitle}
                  </div>

                  <div className="aboutPage-section-content-stories-wrapper__middle__card__body">
                    <ul>
                      {ele.description.map((des, index) => {
                        return <li key={index}>{des}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="aboutPage-section-content-stories-wrapper__bottom">
            <div className="aboutPage-section-content-stories-wrapper__bottom__card--cover">
              <div>
                <div className="aboutPage-section-content-stories-wrapper__bottom__card__title">
                  {latestStory.title}
                </div>
                <div className="aboutPage-section-content-stories-wrapper__bottom__card__title aboutPage-section-content-stories-wrapper__bottom__card__title--sub">
                  {latestStory.subTitle}
                </div>
              </div>
              <div className="aboutPage-section-content-stories-wrapper__bottom__card__body">
                <ul>
                  {latestStory.description.map((ele, index) => {
                    return <li key={index}>{ele}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="aboutPage-section">
        <div className="aboutPage-section-content-investors-title aboutPage-section-content-investors">
          Backed by the best investors in Europe
        </div>

        <div className="aboutPage-section-content-investors aboutPage-section-content-investors-card-wrapper">
          {partners.map((ele, index) => {
            return (
              <div className="aboutPage-section-content-investors-card" key={index}>
                <div className="aboutPage-section-content-investors-card__title">{ele.title}</div>
                <div className="aboutPage-section-content-investors-card__body">
                  <div className="aboutPage-section-content-investors-card__body__link">
                    <div className="aboutPage-section-content-investors-card__body__text aboutPage-section-content-investors-card__body__text--link">
                      <a href={ele.link} target="_blank" rel="noreferrer">
                        External link <ICON_OUTWARD_ARROW />
                      </a>
                    </div>
                  </div>
                  <div className="aboutPage-section-content-investors-card__body__text">{ele.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* SECTION 5 */}
      <div className="aboutPage-section">
        <div className="aboutPage-section-content-future__title aboutPage-section-content-future">
          <span className="aboutPage-section-content-future__title--pink ">Tjommi</span>
          <br /> The past, the present, and the future
        </div>
        <div className="aboutPage-section-content-future-card__wrapper">
          {historyCardList.map((ele, index) => {
            return (
              <div
                key={index}
                className={classNames({
                  "aboutPage-section-content-future-card": true,
                  "aboutPage-section-content-future-card--pink": index === 2 ? true : false,
                  "aboutPage-section-content-future-card--black": index === 3 ? true : false,
                })}
              >
                <div className="aboutPage-section-content-future-card__img__cover">
                  <div className="aboutPage-section-content-future-card__icon">
                    <ICON_ADD_CIRCLE width="38" height="38" color="#FFFFFF" />
                  </div>
                  <img
                    src={ele.img}
                    alt="Tjommi-Future"
                    draggable={false}
                    className="aboutPage-section-content-future-card__img"
                  />
                </div>
                <div className="aboutPage-section-content-future-card__body">
                  <div className="aboutPage-section-content-future-card__body__head">
                    <div>
                      <Button
                        label={ele.type}
                        light
                        backgroundColor={"transparent"}
                        borderLine
                        textColor={index === 3 ? "white" : "#222222"}
                        onClick={() => {
                          window.location.href = ele.link;
                        }}
                      />
                    </div>
                    <div
                      className={classNames({
                        "aboutPage-section-content-future-card__body__date": true,
                        "aboutPage-section-content-future-card__body__date--white": index === 3 ? true : false,
                      })}
                    >
                      {ele.date}
                    </div>
                  </div>
                  <div
                    className={classNames({
                      "aboutPage-section-content-future-card__body__text": true,
                      "aboutPage-section-content-future-card__body__text--white": index === 3 ? true : false,
                    })}
                  >
                    {ele.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* SECTION 6 */}

      {/* need to change */}
      <div className="homeLandingPage_section">
        <div className="homeLandingPage_section_content homeLandingPage_section_content_cards_row">
          <div className="homeLandingPage_section_content_cards_row_content">
            <SessionCard newsSubscribeMode backgroundColor="#FFFFFF" />
          </div>
          <div className="homeLandingPage_section_content_cards_row_content">
            <SessionCard refundType backgroundColor="#222222" dark bottomContentPosition="end" />
          </div>
        </div>
      </div>

      {/* SECTION 7 */}
      <div className="aboutPage-section">
        <div className="aboutPage-section-content-appstoreBanner">
          <SectionText
            specialSub={" Such a beautiful word."}
            sub={"Refund."}
            title={"Loved by 200,000  shoppers."}
            appStoreBanners
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ViewAboutPage;
