import moment from "moment";
import { CLEAR_STATE } from "./actions";
import {
  selectorGiftCards,
  selectorIsAuthed,
  selectorReceipts,
  selectorAllTypeGiftCards,
  selectorDiscoveryVouchers,
  selectorDiscoveries,
} from "./selectors";
import ApiClient from "../services/apiClient";
import StripeClient from "../services/stripeClient";
import { mergeEntities, setEntities } from "./entities/actionCreators";
import { setGlobalState } from "./globalState/actionCreators";
import { IS_PRODUCTION } from "../utils/configs";
import { getLangCode } from "../utils/lang";
import { v4 as uuidv4 } from "uuid";
import getQuery from "../utils/getQuery";
import * as customerio from "../services/analytics/customerio";
import NativeAppClient from "../services/nativeAppClient";

export * from "./globalState/actionCreators";
export * from "./entities/actionCreators";

export const clearState = () => ({ type: CLEAR_STATE });

export const getAppState = () => async (dispatch, getState) => {
  if (!selectorIsAuthed(getState())) return;

  // get state and should it fail then keep trying a few times
  let values = null;
  const attempts = IS_PRODUCTION ? 3 : 1;
  for (let i = 1; i <= attempts; i++) {
    values = await Promise.all([
      ApiClient.getMyUser(),
      ApiClient.getReceipts(),
      ApiClient.getPurchases(),
      ApiClient.getNotifications(),
      ApiClient.getRatings(),
      ApiClient.getGiftCards(),
      ApiClient.getInterests(),
    ]).catch(() => null);
    if (!!values) break;
  }

  // if completely failed at getting state
  if (!values) {
    if (IS_PRODUCTION === false) {
      console.log("Could not getAppState: Probably not logged in.");
    }

    return (window.location.href = "/signout");
  }

  // got the state - now use it!
  const [myUser, receipts, purchases, notifications, ratings, giftcards, interestList] = values;
  const appStateLoaded = moment().format();

  dispatch(setEntities({ purchases, receipts, notifications, ratings, giftcards }));
  dispatch(setGlobalState({ appStateLoaded, myUser, interestList }));

  try {
    NativeAppClient.isInNativeApp()
      ? NativeAppClient.customerioIdentify(myUser?.id + "", {
          first_name: myUser.first_name,
          email: myUser.email,
        })
      : await customerio.identify(myUser);
  } catch (e) {}
};

export const getRatings = () => (dispatch) => {
  return ApiClient.getRatings()
    .then((ratings) => dispatch(mergeEntities({ ratings })))
    .catch((error) => {
      throw new Error("Failed to load ratings: " + error);
    });
};

export const getAllDiscoveries =
  (region_id, sort_by, interests, limit, offset, query = null) =>
  async (dispatch, getState) => {
    let values = null;

    values = await Promise.all([
      ApiClient.getDiscoveries(region_id, sort_by, interests, limit, offset, query),
      ApiClient.getDiscoveriesVouchers(region_id, sort_by, interests, limit, offset, query),
      // ApiClient.getDiscoveriesPurchases(region_id, sort_by, interests, limit, offset, query),
    ]).catch(() => null);

    // got the state - now use it!
    const [discoveries, discoveryVouchers] = values;

    dispatch(
      setGlobalState({
        discoveries: {
          data: discoveries,
          page: 0,
          hasMore: discoveries.length === limit,
        },
        discoveryVouchers: {
          data: discoveryVouchers,
          page: 0,
          hasMore: discoveryVouchers.length === limit,
        },
        // discoveryPurchases: {
        //   data: discoveryPurchases,
        //   page: 0,
        //   hasMore: discoveryPurchases.length === limit,
        // },
      })
    );
  };

export const getMoreDiscoveries =
  (region_id, sort_by, interests, limit, query = null) =>
  async (dispatch, getState) => {
    const page = getState().globalState?.discoveries?.page;
    const ex_discoveries = getState().globalState?.discoveries?.data;

    ApiClient.getDiscoveries(region_id, sort_by, interests, limit, (page + 1) * limit, query)
      .then((discoveries) => {
        const mergedArray = ex_discoveries.concat(
          discoveries.filter((item2) => !ex_discoveries.some((item1) => item1.id === item2.id))
        );

        dispatch(
          setGlobalState({
            discoveries: {
              data: [...mergedArray],
              page: page + 1,
              hasMore: discoveries.length === limit,
            },
          })
        );
      })
      .catch((error) => {
        throw new Error("Failed to load more Discoveries: " + error);
      });
  };

// export const getMoreDiscoveryPurchases = (
//   region_id,
//   sort_by,
//   interests,
//   limit,
//   query = null
// ) => async (dispatch, getState) => {
//   const page = getState().globalState?.discoveryPurchases?.page;
//   const ex_discoveryPurchases = getState().globalState?.discoveryPurchases
//     ?.data;

//   ApiClient.getDiscoveriesPurchases(
//     region_id,
//     sort_by,
//     interests,
//     limit,
//     (page + 1) * limit,
//     query
//   )
//     .then((discoveryPurchases) => {
//       const mergedArray = ex_discoveryPurchases.concat(
//         discoveryPurchases.filter(
//           (item2) =>
//             !ex_discoveryPurchases.some((item1) => item1.id === item2.id)
//         )
//       );
//       dispatch(
//         setGlobalState({
//           discoveryPurchases: {
//             data: [...mergedArray],
//             page: page + 1,
//             hasMore: discoveryPurchases.length === limit,
//           },
//         })
//       );
//     })
//     .catch((error) => {
//       throw new Error("Failed to load more discoveryPurchases: " + error);
//     });
// };

export const getMoreDiscoveryVouchers =
  (region_id, sort_by, interests, limit, query = null) =>
  async (dispatch, getState) => {
    const page = getState().globalState?.discoveryVouchers?.page;
    const ex_discoveryVouchers = getState().globalState?.discoveryVouchers?.data;

    ApiClient.getDiscoveriesVouchers(region_id, sort_by, interests, limit, (page + 1) * limit, query)
      .then((discoveryVouchers) => {
        const mergedArray = ex_discoveryVouchers.concat(
          discoveryVouchers.filter((item2) => !ex_discoveryVouchers.some((item1) => item1.id === item2.id))
        );
        dispatch(
          setGlobalState({
            discoveryVouchers: {
              data: [...mergedArray],
              page: page + 1,
              hasMore: discoveryVouchers.length === limit,
            },
          })
        );
      })
      .catch((error) => {
        throw new Error("Failed to load more discoveryVouchers: " + error);
      });
  };

export const getRatingsIfMissing = () => (dispatch, getState) => {
  const ratings = getState().entities?.ratings;

  if (Object.entries(ratings || {}).length) {
    return;
  }

  return ApiClient.getRatings()
    .then((ratings) => dispatch(mergeEntities({ ratings })))
    .catch((error) => {
      throw new Error("Failed to load ratings: " + error);
    });
};

export const getMyUser = () => (dispatch) => {
  return ApiClient.getMyUser()
    .then((myUser) => dispatch(setGlobalState({ myUser })))
    .catch((error) => {
      throw new Error("Failed to load my user: " + error);
    });
};

export const getReceipts = () => (dispatch) => {
  return ApiClient.getReceipts()
    .then((receipts) => dispatch(mergeEntities({ receipts })))
    .catch((error) => {
      throw new Error("Failed to load receipts: " + error);
    });
};

export const triggerInboxScan = () => () => {
  return ApiClient.triggerScan().catch((error) => {
    throw new Error("Failed to trigger scan: " + error);
  });
};

export const getParcels = () => (dispatch) => {
  return ApiClient.getParcels()
    .then((parcels) => dispatch(mergeEntities({ parcels })))
    .catch((error) => {
      throw new Error("Failed to load parcels: " + error);
    });
};

export const getPurchases = () => (dispatch) => {
  return ApiClient.getPurchases()
    .then((purchases) => dispatch(mergeEntities({ purchases })))
    .catch((error) => {
      throw new Error("Failed to load purchases: " + error);
    });
};

export const getGiftCards = () => (dispatch) => {
  return ApiClient.getGiftCards()
    .then((giftcards) => dispatch(mergeEntities({ giftcards })))
    .catch((error) => {
      throw new Error("Failed to load giftcards: " + error);
    });
};

export const getNotifications = () => (dispatch) => {
  return ApiClient.getNotifications()
    .then((notifications) => dispatch(mergeEntities({ notifications })))
    .catch((error) => {
      throw new Error("Failed to load notifications: " + error);
    });
};

export const getMedals = () => (dispatch) => {
  return ApiClient.getMedals()
    .then((medals) => dispatch(mergeEntities({ medals })))
    .catch((error) => {
      throw new Error("Failed to load medals: " + error);
    });
};

export const uploadContacts = (contacts) => (dispatch) => {
  return ApiClient.postContacts(contacts)
    .then((contacts) => dispatch(setEntities({ contacts })))
    .catch((error) => {
      throw new Error("Failed to load contacts: " + error);
    });
};

export const uploadReceipt = (file) => (dispatch) => {
  return ApiClient.postReceipt(file)
    .then((receipt) => dispatch(mergeEntities({ receipts: [receipt] })))
    .then(() => true)
    .catch((error) => {
      throw new Error("Failed to upload receipt: " + error);
    });
};

export const createMedal = (receivingUserId) => (dispatch) => {
  return ApiClient.postCreatingMedal(receivingUserId)
    .then((data) => data)
    .catch((error) => {
      throw new Error("Failed to give medal: " + error);
    });
};

export const deleteReceipt = (receiptId) => (dispatch, getState) => {
  return ApiClient.deleteReceipt(receiptId).then(() => {
    const oldReceipts = selectorReceipts(getState());
    const newReceipts = oldReceipts.filter((data) => data.id !== receiptId);
    dispatch(setEntities({ receipts: [...newReceipts] }));
  });
};

export const redeemMedalFromReferralCode = () => (dispatch, getState) => {
  const uuid = getState().globalState.code || null;

  if (!uuid) return;

  return ApiClient.redeemMedal(uuid)
    .then((data) => data)
    .catch((error) => {
      throw new Error("Failed to redeem medal: " + error);
    });
};

export const redeemReferal = () => (dispatch, getState) => {
  return ApiClient.redeemReferal();
};

export const updateMyUser = (options) => (dispatch, getState) => {
  return ApiClient.putMyUser(options)
    .then((changes) => {
      const myUser = { ...getState().globalState.myUser, ...changes };
      dispatch(setGlobalState({ myUser }));
    })
    .catch(() => alert("Change not saved."));
};

export const revokeEmailAccount = (id) => (dispatch, getState) => {
  const myUser = { ...getState().globalState.myUser };
  myUser.emailAccounts = myUser.emailAccounts.filter((emailAccount) => emailAccount.id !== id);
  dispatch(setGlobalState({ myUser }));
  return ApiClient.deleteMyEmailAccount(id);
};

export const markNotificationSeen = (id) => (dispatch, getState) => {
  if (!id) return;

  const notifications = { ...getState().entities.notifications };
  if (!notifications[id]) return; // no-op

  const notification = notifications[id];

  // No need to do anything since it has already been marked as seen.
  if (notification.seen) return;

  notification.seen = true;

  dispatch(mergeEntities({ notifications: [notification] }));
  return ApiClient.markNotificationSeen(id);
};

export const enterWaitingList = (email, provider) => (dispatch) => {
  return ApiClient.postWaitingList({ email, provider });
};

export const deleteMyUser = (reason) => (dispatch) => {
  return ApiClient.deleteMyUser(reason);
};

export const sendSupportMessage = (message) => () => {
  return ApiClient.postSupportMessage(message);
};

export const confirmCardSetup =
  (paymentMethod, clientSecret = null) =>
  async (dispatch, getState) => {
    if (!clientSecret) clientSecret = await ApiClient.getMyStripeClientSecret();
    const res = await StripeClient.get().then((stripe) =>
      stripe.confirmCardSetup(clientSecret, { payment_method: paymentMethod })
    );
    if (res.error) throw res.error;
    const paymentMethodId = res.setupIntent.payment_method;
    dispatch(
      setGlobalState({
        myUser: {
          ...getState().globalState.myUser,
          paymentMethod: await ApiClient.postMyPaymentMethod(paymentMethodId),
        },
      })
    );
  };

export const setLanguage = (langCode) => (dispatch, getState) => {
  if (!langCode) return;

  return new Promise((res, rej) => {
    const language = getLangCode(langCode);
    dispatch(setGlobalState({ language }));
    if (!selectorIsAuthed(getState())) return res();
    ApiClient.putMyUser({ locale: language }).then(() => res());
  });
};

export const detectAppMode = () => (dispatch) => {
  const query = getQuery();
  const payload = {};
  if (query.appMode !== undefined) payload.appMode = query.appMode !== "false";
  if (query.appOsPlatform) payload.appOsPlatform = query.appOsPlatform;
  if (query.appTopBarHeight) payload.appTopBarHeight = parseInt(query.appTopBarHeight);
  if (query.appLocale) payload.appLocale = query.appLocale;
  dispatch(setGlobalState(payload));
};

export const detectTrafficSource = () => (dispatch) => {
  let trafficSource = undefined;
  const query = getQuery();
  const prevTrafficSource = localStorage.getItem("ref");

  if (query.ref) {
    trafficSource = query.ref;
    console.log(`[Tjommi] Setting fresh ref from query`);
  } else if (!prevTrafficSource) {
    console.log(`[Tjommi] No previous ref set and no fresh ref in query, setting from referer`);
    let url = document.referrer;
    if (url && typeof URL === "function") {
      try {
        url = new URL(url).host;
      } catch (err) {
        // do nothing
      }
    }

    trafficSource = url;
  }

  if (trafficSource) {
    console.log(`[Tjommi] Setting ref: '${trafficSource}'`);
    dispatch(setGlobalState({ trafficSource }));
    localStorage.setItem("ref", trafficSource);
  }
};

export const detectReferralCode = () => (dispatch) => {
  const query = getQuery();
  const code = query.code || localStorage.getItem("code") || null;

  if (code) {
    console.log(`[Tjommi] Setting referral code: '${code}'`);
    dispatch(setGlobalState({ code }));
    localStorage.setItem("code", code);
  }
};

export const setToastMessage = (message, clearMs = null) => {
  return (dispatch) => {
    dispatch(setGlobalState({ toastMessage: message }));

    if (clearMs) {
      setTimeout(() => dispatch(setGlobalState({ toastMessage: null })), clearMs);
    }
  };
};

export const markGiftcardAsUsed = (giftcardId) => async (dispatch, getState) => {
  await ApiClient.markGiftcardAsUsed(giftcardId);
  const giftcards = selectorGiftCards(getState());
  const index = giftcards.findIndex((data) => data.id === giftcardId);
  if (index >= 0) {
    giftcards[index]["used_at"] = new Date();
    dispatch(setEntities({ giftcards: [...giftcards] }));
  }
};

export const deleteGiftcard = (giftcardId) => async (dispatch, getState) => {
  await ApiClient.deleteGiftcard(giftcardId);

  const giftcards = selectorGiftCards(getState()).filter((i) => i.id !== giftcardId);

  dispatch(setEntities({ giftcards }));
};

export const userRegister = (email, name, password) => (dispatch) => {
  return ApiClient.userRegister(email, name, password)
    .then((res) => res.json())
    .then((data) => {
      const { jwt, user, errors } = data;
      if (errors) {
        return errors;
      }
      ApiClient.token = jwt;
      dispatch(setGlobalState({ userToken: jwt, myUser: user }));
    })
    .catch((error) => {
      throw new Error("Failed to register user: " + error);
    });
};

export const userLogin = (email, password) => async (dispatch) => {
  return ApiClient.userLogin(email, password)
    .then(async (res) => {
      return res.json();
    })
    .then(async (data) => {
      const { jwt, user, message } = data;
      if (message) {
        return { message: message };
      }

      ApiClient.token = jwt;
      const giftcards = await ApiClient.getGiftCards();
      await dispatch(setEntities({ giftcards }));
      await dispatch(setGlobalState({ userToken: jwt, myUser: user }));
    })
    .catch((error) => {
      throw new Error("Failed to login user: " + error);
    });
};

export const userPasswordReset = (email) => (dispatch) => {
  return ApiClient.userPasswordReset(email)
    .then((res) => {
      if (res.status !== 200) {
        return false;
      } else {
        return true;
      }
    })
    .catch((error) => {
      throw new Error("Failed to reset password: " + error);
    });
};

export const userPasswordChange = (password, token) => (dispatch) => {
  return ApiClient.userPasswordUpdate(password, token)
    .then((res) => {
      if (res.status !== 200) {
        return false;
      } else {
        return true;
      }
    })

    .catch((error) => {
      throw new Error("Failed to change password: " + error);
    });
};

export const setTjommiDeviceID = () => async (dispatch) => {
  let tjommiDeviceID = localStorage.getItem("tjommiDeviceID");
  if (!tjommiDeviceID) {
    tjommiDeviceID = uuidv4();
    localStorage.setItem("tjommiDeviceID", tjommiDeviceID);
  }
  dispatch(setGlobalState({ tjommiDeviceID: tjommiDeviceID }));
};

export const claimWelcomeVoucher = (giftcardId) => (dispatch, getState) => {
  ApiClient.voucherClaim(giftcardId)
    .then((response) => {
      // Update the claimed giftcards claimed_at value
      const giftcards = selectorAllTypeGiftCards(getState()).map((giftcard) => {
        return giftcard.id === giftcardId ? { ...giftcard, claimed_at: response.claimed_at } : giftcard;
      });
      dispatch(setEntities({ giftcards }));
    })
    .catch((error) => {
      throw new Error("Failed to claim welcome voucher: " + error);
    });
};

export const claimVoucher = (voucherId, cardId) => (dispatch, getState) => {
  ApiClient.voucherClaim(cardId)
    .then((response) => {
      // Update the claimed giftcards claimed_at value
      const discoveryVouchers = selectorDiscoveryVouchers(getState());
      const voucherCards = discoveryVouchers.data.map((voucherCard) => {
        return voucherCard.id === voucherId
          ? {
              ...voucherCard,
              giftcards: voucherCard.giftcards.map((giftcard) => {
                return giftcard.id === cardId
                  ? {
                      ...giftcard,
                      claimed_at: response.claimed_at,
                    }
                  : giftcard;
              }),
            }
          : voucherCard;
      });

      const discoveries = selectorDiscoveries(getState());
      const discoveryCards = discoveries.data.map((voucherCard) => {
        return voucherCard.id === voucherId
          ? {
              ...voucherCard,
              giftcards: voucherCard.giftcards.map((giftcard) => {
                return giftcard.id === cardId
                  ? {
                      ...giftcard,
                      claimed_at: response.claimed_at,
                    }
                  : giftcard;
              }),
            }
          : voucherCard;
      });

      dispatch(
        setGlobalState({
          discoveryVouchers: {
            data: voucherCards,
            page: discoveryVouchers.page,
            hasMore: discoveryVouchers.hasMore,
          },
          discoveries: {
            data: discoveryCards,
            page: discoveries.page,
            hasMore: discoveries.hasMore,
          },
        })
      );
    })
    .catch((error) => {
      throw new Error("Failed to claim  voucher: " + error);
    });
};

export const setCustomerioToken = (deviceToken) => async (dispatch) => {
  return ApiClient.setDeviceToken(deviceToken)
    .then((res) => {})
    .catch((error) => {
      throw new Error("Failed to setCustomerioToken : " + error);
    });
};

export const deleteDeviceToken = () => async (dispatch) => {
  return ApiClient.deleteDeviceToken()
    .then((res) => {})
    .catch((error) => {
      throw new Error("Failed to delete CustomerioToken : " + error);
    });
};
