const Icon = ({ width = "48", height = "48", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g mask="url(#mask0_303_1064)">
      <path
        d="M24.0016 41.2L4.60156 17.9L10.3016 6.5H37.7016L43.4016 17.9L24.0016 41.2ZM17.8016 16.85H30.2016L26.1516 8.75H21.8516L17.8016 16.85ZM22.8516 36.35V19.15H8.55156L22.8516 36.35ZM25.1516 36.35L39.4516 19.15H25.1516V36.35ZM32.7016 16.85H40.2516L36.1516 8.75H28.6516L32.7016 16.85ZM7.80156 16.85H15.3016L19.3516 8.75H11.8516L7.80156 16.85Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Icon;
