const Icon = ({ notification }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M17.25 12V10C17.25 7.1005 14.8995 4.75 12 4.75C9.1005 4.75 6.75 7.1005 6.75 10V12L4.75 16.25H19.25L17.25 12Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16.75C9 16.75 9 19.25 12 19.25C15 19.25 15 16.75 15 16.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {notification && <ellipse cx="18.5" cy="6.5" rx="5.5" ry="5.5" fill="#EB6F93" />}
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
