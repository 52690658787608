export const MOCK_NOTIFICATIONS = [
  {
    id: "06b45372-ba5e-45fe-b037-a889ed5afcb8",
    title: "Tjommi",
    content: "Hey! You got 500 kroner back on your new Playstation 5",
    data: {
      path: "/overview/100",
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      product: "Playstation 5",
      store: "Elgiganten",
      amount: 500,
      phone_number: null,
      event_type: "PriceDropApproved",
      approval_type: "money",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "349e704d-5ca0-46e2-8da4-da4601b0ba8e",
    title: "Tjommi",
    content: "Hey! You got a 400 kroner gift card from Elgiganten",
    data: {
      path: "/overview/100",
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      product: "Playstation 5",
      store: "Elgiganten",
      amount: 400,
      phone_number: null,
      event_type: "PriceDropApproved",
      approval_type: "giftcard",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "cf264d0a-23c5-4f61-aeee-500eaa099403",
    title: "Tjommi",
    content: "Hey! You can collect 100 kroner in the closest Power store",
    data: {
      path: "/overview/100",
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      product: "Playstation 5",
      store: "Power",
      amount: 100,
      phone_number: null,
      event_type: "PriceDropApproved",
      approval_type: "physical",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "e51f6439-5b00-4e45-97bd-de40726ff8f8",
    title: "Tjommi",
    content: "Hey! You need to call JYSK to get 560 kroner back",
    data: {
      path: "/overview/100",
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      product: "Playstation 5",
      store: "JYSK",
      amount: 560,
      phone_number: "+4795965871",
      event_type: "PriceDropApproved",
      approval_type: "phone",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "cd90af91-cb02-44c5-b53b-14c5efe6c965",
    title: "Tjommi",
    content: "Hey! We have sent a claim on your El-Scooter. Fingers crossed",
    data: {
      path: "/overview/600",
      receipt_id: 40,
      receipt_item_id: 12,
      product: "El-Scooter",
      store: "JYSK",
      amount: 560,
      price_event_id: 60,
      event_type: "PriceDropRequested",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "87a2b4dd-dcc2-490c-b10f-8680d4c34cc5",
    title: "Tjommi",
    content: "Hey! Unfortunately... the claim on Hair Curler got rejected Let's get them next time",
    data: {
      path: "/overview/600",
      receipt_id: 40,
      receipt_item_id: 12,
      product: "Hair Curler",
      store: "BliVakker",
      price_event_id: 60,
      event_type: "PriceDropRejected",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "9459f8a8-a2df-401f-946d-26848a6a9b66",
    title: "Tjommi",
    content:
      "Hey! The price guarantee period is over on your purchase at Elektroimportøren, but hey you got the best price",
    data: {
      path: "/overview/600",
      receipt_id: 602345,
      store: "Elektroimportøren",
      event_type: "ReceiptHasExpired",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "6423c430-62bc-4123-9148-0eafbac19853",
    title: "Tjommi",
    content: "Hey! Something went wrong with the payment",
    data: {
      path: "/profile/card/verify/",
      receipt_id: 602345,
      event_type: "PaymentFailed",
      diff: 100,
      fee: 25,
      payment_intent_id: "18611ecf-3c80-4bea-8722-d1bdbf7c4b35",
      failure_code: "PAYMENT_FAILED_WHATEVER",
      failure_reason: "Your card was declined or something",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "b443df1a-1242-4b7b-9b77-85d53c2a9316",
    title: "Tjommi",
    content: "We found 5 receipts! We're now searching for better prices",
    data: {
      path: "/overview/",
      event_type: "MultipleReceiptsProcessed",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "7109cd3b-6945-48c7-aecb-ae50930ca475",
    title: "Tjommi",
    content: "A receipt has been scanned and added from Elkjøp",
    data: {
      event_type: "ReceiptProcessed",
      receipt_id: 58234,
      purchase_id: 438315,
      store: "Elkjøp",
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "1dc4bb3b-3ca6-4831-ab5a-faf67f1a6628",
    title: "Tjommi",
    content: "Hey! We have now charged the finders fee, check out the receipt.",
    data: {
      path: "/profile/card/verify/",
      event_type: "PaymentSuccessful",
      diff: 500,
      fee: 125,
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "e5c2aa0a-2d9c-4933-b31f-740a98f89e91",
    title: "Tjommi",
    content: "You need to authorize a payment to Tjommi",
    data: {
      path: "/profile/card/verify?payment_method_id=pi_asedfouihwef8u9wgf&client_secret=SECRETHERE",
      event_type: "PaymentAuthenticationRequired",
      client_secret: "SECRETHERE",
      payment_method_id: "e7b21030-567c-41a7-bc37-ce2da85112d1",
      price_event_id: 64,
      diff: 1000,
      fee: 250,
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "e17fa611-ee55-455c-ac12-4cb0314591be",
    title: "Tjommi",
    content: "Hey! We are missing your payment card in the app",
    data: {
      path: "/profile/card",
      event_type: "PaymentMethodRequired",
      price_event_id: 64,
      diff: 1000,
      fee: 250,
    },
    seen: true,
    created_at: "2021-01-01 10:00:00",
  },
  {
    id: "f114513a-ba3f-4986-8f27-b350b7692707",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimApprovedMoney",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "020d00e1-2c7e-4d58-b3fd-538432dd9088",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimApprovedGiftcard",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "70c4049a-b74b-4e64-a436-d331616fe2fd",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimApprovedInvoiceReduced",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "b403456d-969e-4cac-a2f3-0646d8b74527",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimApprovedCallRequired",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "467310c6-b8bd-4f5e-82a3-39eac45efe87",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimApprovedPickupRequired",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "04ac29fa-ae76-48ef-8406-fc7ac3015679",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimApprovedStoreCredit",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "6becac46-4348-4829-9f25-4fbe0d21b65e",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [
        {
          id: 10,
          region_id: 1,
          name: "Elkjøp",
          logo: "https://logo.clearbit.com/elkjop.no?size=200",
          category: "",
          is_partner: true,
          is_top_merchant: true,
          tjommi_score: 5,
        },
        {
          id: 11,
          region_id: 1,
          name: "Power",
          logo: "https://logo.clearbit.com/power.no?size=200",
          category: "",
          is_partner: true,
          is_top_merchant: true,
          tjommi_score: 5,
        },
        {
          id: 11,
          region_id: 1,
          name: "ELON",
          logo: "https://logo.clearbit.com/elon.no?size=200",
          category: "",
          is_partner: false,
          is_top_merchant: false,
          tjommi_score: 4,
        },
        {
          id: 11,
          region_id: 1,
          name: "Zalando",
          logo: "https://logo.clearbit.com/zalando.com?size=200",
          category: "",
          is_partner: false,
          is_top_merchant: false,
          tjommi_score: 3,
        },
      ],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      partner: "PARTNER STORE",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimRejectedCompetitorGiftcard",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "e7efbe68-3dc7-4c63-9c17-e104f152bfc8",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [
        {
          id: 10,
          region_id: 1,
          name: "Elkjøp",
          logo: "https://logo.clearbit.com/elkjop.no?size=200",
          category: "",
          is_partner: true,
          is_top_merchant: true,
          tjommi_score: 5,
        },
        {
          id: 11,
          region_id: 1,
          name: "Power",
          logo: "https://logo.clearbit.com/power.no?size=200",
          category: "",
          is_partner: true,
          is_top_merchant: true,
          tjommi_score: 5,
        },
        {
          id: 11,
          region_id: 1,
          name: "ELON",
          logo: "https://logo.clearbit.com/elon.no?size=200",
          category: "",
          is_partner: false,
          is_top_merchant: false,
          tjommi_score: 4,
        },
        {
          id: 11,
          region_id: 1,
          name: "Zalando",
          logo: "https://logo.clearbit.com/zalando.com?size=200",
          category: "",
          is_partner: false,
          is_top_merchant: false,
          tjommi_score: 3,
        },
      ],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ClaimRejected",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },

  {
    id: "37a2b62b-8105-4edc-854e-42304a17132e",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "GiftcardReminder",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "ad11aa61-efd1-41d0-9228-ac6ce834b005",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "GiftcardExpiryReminder",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "70984368-38b5-41b0-8df2-a8a34a4220a9",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ReceiptExpired",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "c678e986-944f-441c-971a-cbc497d36e01",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      purchase_id: 1,
      ticket_id: 1,
      receipt_id: 1,
      receipt_item_id: 1,
      price_event_id: 1,
      region_id: 1,
      domain_id: 1,
      product_id: 1,
      giftcard_id: 1,
      preferred_stores: [],
      sent_at: "2022-10-13T16:01:36.000000Z",
      closed_at: "2022-10-16T16:01:36.000000Z",
      product: "Example product",
      store: "Example Store",
      phone_number: "+4795965871",
      amount: 666,
      currency: "nok",
      event_type: "ReceiptItemReturned",
    },
    seen: true,
    created_at: "2022-10-13T16:01:36.000000Z",
  },
  {
    id: "c678e986-944f-441c-971a-cbc497d36e02",
    title: "Tjommi",
    content: "Some kind of text",
    data: {
      path: "/overview/",
      event_type: "OneOffTrustedPartner",
    },
    seen: true,
    created_at: "2022-10-23T16:01:36.000000Z",
  },
];
