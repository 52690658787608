import React from "react";
import "./styles.scss";
import className from "classnames";

const Avatar = ({ size = 48, text, color = "#F6F6F6" }) => {
  const initials = text
    ?.split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .slice(0, 2)
    .toUpperCase()
    .trim();

  return (
    <div
      title={text}
      className={className("userAvatarWithIcon", {
        "userAvatarWithIcon--avatarWrapper": true,
      })}
    >
      <div
        style={{
          background: color,
          width: size,
          height: size,
        }}
        className="userAvatarWithIcon--avatar"
      >
        <span>{initials}</span>
      </div>
    </div>
  );
};

export default Avatar;
