import computing from "./icons/icon1.svg";
import gaming_entertainment from "./icons/icon3.svg";
import clothing_accessories from "./icons/icon15.svg";
import kids_family from "./icons/icon14.svg";
import home_interior from "./icons/icon13.svg";
import do_it_yourself from "./icons/icon10.svg";
import toys_hobbies from "./icons/icon2.svg";
import garden_patio from "./icons/icon8.svg";
import phones_wearables from "./icons/icon4.svg";
import sound_vision from "./icons/icon9.svg";
import photography from "./icons/icon11.svg";
import health_beauty from "./icons/icon6.svg";
import sports_outdoor from "./icons/icon7.svg";
import motor_transport from "./icons/icon5.svg";
import unknown from "./icons/icon12.svg";
import gift_card from "./icons/icon16.svg";
import "./styles.scss";

const ICONS = {
  home_interior,
  garden_patio,
  kids_family,
  toys_hobbies,
  gaming_entertainment,
  computing,
  phones_wearables,
  sound_vision,
  photography,
  clothing_accessories,
  health_beauty,
  sports_outdoor,
  do_it_yourself,
  motor_transport,
  unknown,
  gift_card,
};

const CategoryBox = ({ category }) => {
  const icon = ICONS?.[category || "unknown"];
  return <div className="categoryBox">{icon && <img alt="" draggable={false} src={icon} />}</div>;
};

export default CategoryBox;
