import React from "react";
import { selectorIsAppMode, selectorLanguageStrings } from "../../../../state/selectors";
import { connect } from "react-redux";
import SearchIcon from "../../assets/search";
import FilterIcon from "../../../../components/icons/filter";
import "./styles.scss";

class HeaderSection extends React.Component {
  state = {
    term: "",
  };

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.term !== this.state.term) {
      this.props.onSubmit(this.state.term);
    }
  }

  handleChange = (e) => {
    this.setState({ term: e.target.value });
  };

  onKeyPressed = (e) => {
    // Clear search term on ESC
    if (e.key === "Escape") {
      this.setState({ term: "" });
    }
  };

  render() {
    return (
      <div className="header-section">
        <div className="header-section__headline">{this.props.strs.title}</div>
        <form>
          <div className="header-section__search-box">
            <div className="header-section__search-box--icon">
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder={this.props.strs.searchBarText}
              className="header-section__search-box--text"
              value={this.state.term}
              onChange={(e) => {
                this.handleChange(e);
              }}
              onKeyDown={(e) => this.onKeyPressed(e)}
            />

            {this.props.filter ? (
              <div
                className="header-section__search-box--filter"
                onClick={() => {
                  this.props.filterOnpress();
                }}
              >
                <FilterIcon />
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "discoveries"),
  isAppMode: selectorIsAppMode(state),
});

export default connect(mapStateToProps)(HeaderSection);
