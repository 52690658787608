import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  selectorInterestList,
  selectorLanguageStrings,
  selectorMyUser,
  selectorTjommiDeviceID,
} from "../../../state/selectors";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import { HeaderLayout } from "../../../components/layouts/header";
import ArrowLeft from "../../../components/icons/arrowMiniLeft";
import Button from "../../../components/inputs/button";
import TopicList from "../../../components/topicList";
import Label from "../../../components/label";
import { withRouter } from "react-router-dom";
import { updateMyUser, getGiftCards } from "../../../state/actionCreators";
import "./styles.scss";
import GiftCardImg from "./assets/giftcard.png";
import routes from "../../../utils/routes";
import classNames from "classnames";
import { usePostHog } from "posthog-js/react";

const ViewInterests = ({ history, strs, updateMyUser, user, interestList, tjommiDeviceID, getGiftCards }) => {
  const posthog = usePostHog();

  const [selectedTopicList, setSelectedTopicList] = useState([]);
  const [profilePage, setProfilePage] = useState(false);

  useEffect(() => {
    if (history.location.pathname === routes.profileInterests.path) {
      setProfilePage(true);
    } else {
    }
  }, [history]);

  useEffect(() => {
    if (posthog) {
      posthog.capture("welcome_interests_screen_view", {
        device_id: tjommiDeviceID,
        user_id: user.id,
        user_name: user.full_name,
        user_email: user.email,
        user_region: user.region_id,
      });
    }
  }, [posthog]);

  const onSelectTopic = useCallback(
    (id) => {
      if (!selectedTopicList.includes(id)) {
        setSelectedTopicList(selectedTopicList.concat([id]));
      } else {
        let filteredArray = selectedTopicList.filter((item) => item !== id);
        setSelectedTopicList(filteredArray);
      }
      posthog.capture("welcome_interests_screen_interest_click", {
        device_id: tjommiDeviceID,
        user_id: user.id,
        user_name: user.full_name,
        user_email: user.email,
        user_region: user.region_id,
        interest: { id },
      });
    },
    [selectedTopicList]
  );

  useEffect(() => {
    if (user && user.interests) {
      const preInterests = user.interests.map((ele) => {
        return ele.code;
      });
      setSelectedTopicList(preInterests || []);
    }
  }, [user]);

  const callUpdateMyData = async () => {
    await updateMyUser({
      interests: [...selectedTopicList],
    });
    if (history.location.pathname === routes.profileInterests.path) {
      history.goBack();
    }
    posthog.capture("welcome_interests_screen_save_click", {
      device_id: tjommiDeviceID,
      user_id: user.id,
      user_name: user.full_name,
      user_email: user.email,
      user_region: user.region_id,
      interest: [...selectedTopicList],
    });
    getGiftCards();
    history.push(routes.overview.path);
  };

  return (
    <div className="interestsPage">
      <MainContentLayout center={true}>
        {profilePage ? (
          <HeaderLayout
            left={
              <div
                className="headerLayout--leftArrow"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowLeft color="black" />
              </div>
            }
          />
        ) : null}
        <h1
          className={classNames({
            interestsPage_headline: true,
          })}
        >
          {profilePage ? strs.shoppingInterests : strs.headline({ name: (user && user.first_name) || "" })}
        </h1>
        {!profilePage ? (
          <>
            <h1 className="interestsPage_headline">{strs.subheadline}</h1>
            <img src={GiftCardImg} className="interestsPage_img" alt="" />
          </>
        ) : null}

        <div
          className={classNames({
            "interestsPage__subTitle": !profilePage,
            "interestsPage__subTitle--long": profilePage,
          })}
        >
          {profilePage ? (
            <Label align="center" color="#545454">
              {strs.subtitle2}
            </Label>
          ) : (
            <Label bold align="center">
              {strs.subtitle}
            </Label>
          )}
        </div>

        <div
          className={classNames({
            "interestsPage__body__wrapper": true,
            "interestsPage__body__wrapper--long": profilePage,
          })}
        >
          <Label color="#545454" align="left">
            {strs.minimumLabel}
          </Label>
          <div className="interestsPage__body__counter__wrapper">
            <div className="interestsPage__body__counter">
              {selectedTopicList?.length < 2 ? selectedTopicList?.length : 2}/{interestList?.length}
            </div>
          </div>
        </div>

        <TopicList topicList={interestList} onSelectTopic={onSelectTopic} selectedList={selectedTopicList} />

        <Button
          disabled={selectedTopicList?.length < 2}
          type="pink"
          label={strs.btnTitle}
          onClick={() => {
            callUpdateMyData();
          }}
          className="interestsPage_saveBtn"
        />
      </MainContentLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "interest"),
    user: selectorMyUser(state),
    interestList: selectorInterestList(state),
    tjommiDeviceID: selectorTjommiDeviceID(state),
  };
};

const mapDispatchToProps = {
  updateMyUser,
  getGiftCards,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewInterests));
