import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { selectorAppTopBarHeight, selectorIsAppMode } from "../../state/selectors";
import { setToastMessage } from "../../state/actionCreators";
import "./styles.scss";
import IconCancel from "../icons/cancelLight";

class ToastMessage extends React.Component {
  messageCache = null;

  handleClick = () => this.props.setToastMessage(null, null);

  render() {
    if (this.props.message) this.messageCache = this.props.message;

    return (
      <div
        className={classNames("toastMessage", { "toastMessage--visible": this.props.message })}
        style={{
          paddingTop: this.props.isAppMode ? this.props.appTopBarHeight + 10 + "px" : null,
        }}
      >
        <div className="toastMessage--inner">
          <div className="toastMessage--text">{this.messageCache}</div>
          <div className="toastMessage--icon" onClick={this.handleClick}>
            <IconCancel />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.globalState.toastMessage,
  isAppMode: selectorIsAppMode(state),
  appTopBarHeight: selectorAppTopBarHeight(state),
});

const mapDispatchToProps = { setToastMessage };

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage);
