const Icon = ({ width = "38", height = "38", color = "#1C1B1F", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 23.2L21.5 17.5L27.2 15L21.5 12.5L19 6.8L16.5 12.5L10.8 15L16.5 17.5L19 23.2ZM0 37.1V2.9C0 2.13333 0.283333 1.45833 0.85 0.875C1.41667 0.291667 2.1 0 2.9 0H35.1C35.8667 0 36.5417 0.291667 37.125 0.875C37.7083 1.45833 38 2.13333 38 2.9V27.1C38 27.8667 37.7083 28.5417 37.125 29.125C36.5417 29.7083 35.8667 30 35.1 30H7.1L0 37.1ZM2.25 31.6L6.1 27.75H35.1C35.3 27.75 35.4583 27.6917 35.575 27.575C35.6917 27.4583 35.75 27.3 35.75 27.1V2.9C35.75 2.7 35.6917 2.54167 35.575 2.425C35.4583 2.30833 35.3 2.25 35.1 2.25H2.9C2.7 2.25 2.54167 2.30833 2.425 2.425C2.30833 2.54167 2.25 2.7 2.25 2.9V31.6ZM2.25 2.9V2.25V31.6V2.9Z"
      fill={color}
    />
  </svg>
);

export default Icon;
