import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { setToastMessage, uploadReceipt } from "../../state/actionCreators";
import IconSpinner from "../icons/spinner";
import IconADDReceipts from "../icons/addReceipts";
import Add from "../menus/loginMainMenu/assets/plus";
import "./styles.scss";
import { trackEvent } from "../../services/analytics";

const MSG_ERROR = "Something went wrong. Please try again.";

class AddReceipt extends React.Component {
  state = { uploading: false };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  handleClick = () => {
    if (this.state.uploading) return;
    this.input.current.click();
  };

  handleInput = (event) => {
    this.setState({ uploading: true });

    Promise.all([...event.target.files].map((file) => this.props.uploadReceipt(file)))
      .then((res) => console.log("done"))
      .catch((error) => this.props.setToastMessage(MSG_ERROR, 3000))
      .finally(() => {
        this.input.current.value = null;
        this.setState({ uploading: false });
        trackEvent("uploaded_receipt");
      });
  };

  render() {
    return (
      <div
        className={classNames({ addReceipt: !this.props.label }, { "addReceipt--uploading": this.state.uploading })}
        onClick={this.handleClick}
      >
        {this.state.uploading && !this.props.label ? (
          <IconSpinner />
        ) : this.props.label ? (
          <div className="item">
            <div className="item__card">
              <div className="item__card--icon">{this.state.uploading ? <IconSpinner size="24px" /> : <Add />}</div>
              <div className="item__card--text">{this.props.label}</div>
            </div>
          </div>
        ) : (
          <IconADDReceipts />
        )}
        <input
          ref={this.input}
          className="addReceipt__input"
          type="file"
          onChange={this.handleInput}
          accept="image/jpeg,image/gif,image/png,application/pdf"
          capture="environment"
          multiple={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { uploadReceipt, setToastMessage };

export default connect(undefined, mapDispatchToProps)(AddReceipt);
