import React, { useState } from "react";
import { connect } from "react-redux";
import { deleteMyUser, setGlobalState, updateMyUser } from "../../../state/actionCreators";
import { selectorLanguageStrings } from "../../../state/selectors";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import { HeaderLayout } from "../../../components/layouts/header";
import Outline from "../../../components/layouts/outline";
import DetailLine from "../../../components/detailLine";
import Spacer from "../../../components/spacer";
import InfoBox, { InfoBoxes } from "../../../components/infoBox";
import WinWinModal from "../../../components/modals/winWinModal";
import routes from "../../../utils/routes";
import PIGGYBANK_IMAGE from "./assets/winWin.png";
import "./styles.scss";

const ViewProfileRefunds = ({ strs, paymentMethod, accountNumber }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="viewProfileRefunds ph-no-capture">
      <MainContentLayout center>
        <HeaderLayout left={{ type: "arrowLeft", path: routes.profile.path }} />
        <h1>{strs.title}</h1>

        <Outline label={strs.readMoreLabel} padding={false} background={false}>
          <InfoBoxes>
            <InfoBox
              backgroundColor={"#EB6F93"}
              image={PIGGYBANK_IMAGE}
              text={strs.infoBoxtitle}
              onClick={() => setModalOpen(true)}
            />
          </InfoBoxes>
        </Outline>

        <Outline label={strs.paymentLabel}>
          <DetailLine
            slim
            flip
            label={strs.paymentLineLabel}
            value={paymentMethod || strs.paymentAdd}
            valueColor={!paymentMethod && "#E54472"}
            path={routes.profileCard.path}
          />
        </Outline>

        <div className="note">{strs.paymentNote}</div>
        <Spacer />

        <Outline label={strs.accountLabel}>
          <DetailLine
            slim
            flip
            label={strs.accountLineLabel}
            value={accountNumber || strs.accountAdd}
            valueColor={!accountNumber && "#E54472"}
            path={routes.profileBank.path}
          />
        </Outline>
        <div className="note">{strs.accountNote}</div>
      </MainContentLayout>

      <WinWinModal closeBtn={() => setModalOpen(false)} visible={modalOpen} />
    </div>
  );
};

const mapStateToProps = (state) => {
  let paymentMethod = state?.globalState?.myUser?.paymentMethod?.last4 || null;
  if (paymentMethod) paymentMethod = "XXXX" + paymentMethod;
  let accountNumber = state?.globalState?.myUser?.account_nr || null;
  if (accountNumber) accountNumber = "XXXX-" + accountNumber.substring(accountNumber.length - 4);
  return {
    paymentMethod,
    accountNumber,
    strs: selectorLanguageStrings(state, "profileRefunds"),
  };
};

const mapDispatchToProps = { updateMyUser, setGlobalState, deleteMyUser };

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfileRefunds);
