import React, { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import ApiClient from "../../services/apiClient";
import ICON_EXPAND_MORE from "../../components/icons/expandMore";
import ICON_BOY_FACE from "../../components/icons/boyFace";
import ICON_WOMEN_FACE from "../../components/icons/womenFace";
import ICON_PLUSE from "../../components/icons/plus";
import ICON_DIVERSITY from "../../components/icons/diversity";
import ICON_WORKLOAD from "../../components/icons/assuredWorkload";
import ICON_CARD_MEMBER from "../../components/icons/cardMembership";
import ICON_HANDSHAKE from "../../components/icons/handshake";
import ICON_DIAMOND from "../../components/icons/diamond";
import ICON_STORE_FRONT from "../../components/icons/storefront";
// import IMAGE_SECTION5 from "./assets/claus_nielsen.png";
import Button from "../../components/inputs/newButton";
import SessionCard from "../../components/sectionCard";
import Hide from "../../components/hide/hide";
import RatingCard from "../../views/ratings/components/ratingCard";

import MainMenu from "../../components/menus/mainMenu";
import "./styles.scss";

const ViewMerchantPage = () => {
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    getMerchantPartners();
  }, []);

  const getMerchantPartners = async () => {
    await ApiClient.getMerchantPartners()
      .then((partners) => setRatings([...partners]))
      .catch((error) => {
        throw new Error("Failed to load partners: " + error);
      });
  };

  return (
    <div className="ViewMerchantPage">
      <MainMenu dark />
      <div className="ViewMerchantPage_main_wrapper">
        <div className="ViewMerchantPage_hero_image">
          <div className="ViewMerchantPage_hero_image_title">
            Use refunds to build <span className="ViewMerchantPage_hero_image_title_light">trust and loyalty </span>
            with customers
          </div>
          <div className="ViewMerchantPage_hero_image_sub_title">
            Learn more about Trusted Tjommi Partners
            <ICON_EXPAND_MORE />
          </div>
        </div>

        {/* SECTION 1 */}

        <div className="ViewMerchantPage_section ViewMerchantPage_section_1_row">
          <div className="ViewMerchantPage_section_1_row_2">
            <div className="ViewMerchantPage_section_1_row_2_col_1">
              <div>
                <ICON_HANDSHAKE />
              </div>

              <div className="ViewMerchantPage_section_title_small_extra_white ViewMerchantPage_section_title_small_extra">
                Trusted Tjommi Partners
              </div>
            </div>
            <div className="ViewMerchantPage_section_1_row_2_col_2">
              <div className="ViewMerchantPage_section_title_medium">
                Offer voucher refunds if prices drop, keep more customers
              </div>
              <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_1_row_2_col_2_des">
                Create a great experience when your customer has claim to a price guarantee refund. Refund with vouchers
                to keep the cash, and keep the customer. Win, win.
              </div>
            </div>
          </div>
        </div>

        {/* SECTION 2 */}

        <div className="ViewMerchantPage_section ViewMerchantPage_section_2">
          <div className="ViewMerchantPage_section_2_row">
            <div className="ViewMerchantPage_section_2_row_grid_container">
              <div className="ViewMerchantPage_section_2_grid">
                {ratings.map((ele) => {
                  return (
                    <RatingCard
                      rating={{
                        name: ele.name,
                        tjommi_score: ele.tjommi_score,
                        is_partner: ele.is_partner,
                        logo: ele.logo,
                      }}
                      key={ele.id}
                    />
                  );
                })}
              </div>
              <hr className="ViewMerchantPage_hr ViewMerchantPage_section_2_hr" />

              <div className="ViewMerchantPage_section_2_row_2">
                <div>
                  <ICON_STORE_FRONT />
                </div>
                <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
                  Some of our partners
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION 3 */}

        <div className="ViewMerchantPage_section">
          <div className="ViewMerchantPage_section_3_row_1">
            <div className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_3_row_1_col_1">
              Revenue optimization of existing and potential customer portfolio.
            </div>
            <div>
              <Button
                label="Learn More"
                backgroundColor="#fbaa77"
                textColor="#000000"
                onClick={() => {
                  window.location = "mailto:benjamin@tjommi.app";
                }}
              />
            </div>
          </div>

          <div className="ViewMerchantPage_section_3_row_2 ViewMerchantPage_section_3_row_2_topSpace">
            <div className="ViewMerchantPage_section_3_row_2_col_1">
              <ICON_WORKLOAD />
              <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
                <span className="ViewMerchantPage_section_title_small_green">Assure</span> shoppers and build trust
              </div>
            </div>
            <div className="ViewMerchantPage_section_3_row_2_col_2">
              <Hide on={"mobile"}>
                <div className="ViewMerchantPage_section_title_large">1</div>
              </Hide>
              <div className="ViewMerchantPage_section_3_row_2_col_2_textWrapper">
                <div className="ViewMerchantPage_section_title_normal ViewMerchantPage_section_3_row_2_col_2_textWrapper_col_1">
                  Become a trusted Tjommi Partner by offering consistent, timely refunds, and inspire confidence in
                  shoppers.
                </div>

                <div className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_3_row_2_col_2_textWrapper_col_2 ViewMerchantPage_section_title_small_extra_orange">
                  “I always check the Tjommi Score to make sure I buy from the most price friendly store”
                  <br />
                  <br />
                  Joakim
                </div>
              </div>
            </div>
          </div>
          <div className="ViewMerchantPage_section_3_row_2 ViewMerchantPage_section_3_row_2_topSpace">
            <div className="ViewMerchantPage_section_3_row_2_col_1">
              <ICON_CARD_MEMBER />
              <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
                <span className="ViewMerchantPage_section_title_small_green">Access </span>
                engaged shoppers primed to purchase
              </div>
            </div>
            <div className="ViewMerchantPage_section_3_row_2_col_2">
              <Hide on={"mobile"}>
                <div className="ViewMerchantPage_section_title_large">2</div>
              </Hide>
              <div className="ViewMerchantPage_section_3_row_2_col_2_textWrapper">
                <div className="ViewMerchantPage_section_title_normal ViewMerchantPage_section_3_row_2_col_2_textWrapper_col_1">
                  Tjommi users loves a good deal. Tap into our users and cover a refund if another store rejects a
                  claim.
                </div>

                <div className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_3_row_2_col_2_textWrapper_col_2 ViewMerchantPage_section_title_small_extra_orange">
                  “I have completely changed the stores that im shopping from due to Tjommi”
                  <br />
                  <br />
                  Louise
                </div>
              </div>
            </div>
          </div>

          <div className="ViewMerchantPage_section_3_row_2 ViewMerchantPage_section_3_row_2_topSpace">
            <div className="ViewMerchantPage_section_3_row_2_col_1">
              <ICON_DIAMOND />
              <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
                <span className="ViewMerchantPage_section_title_small_green">Retain </span>
                your most valuable shoppers
              </div>
            </div>
            <div className="ViewMerchantPage_section_3_row_2_col_2">
              <Hide on={"mobile"}>
                <div className="ViewMerchantPage_section_title_large">3</div>
              </Hide>
              <div className="ViewMerchantPage_section_3_row_2_col_2_textWrapper">
                <div className="ViewMerchantPage_section_title_normal ViewMerchantPage_section_3_row_2_col_2_textWrapper_col_1">
                  Customers return to stores with good refund policies, or where they can get a gift card.
                </div>

                <div className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_3_row_2_col_2_textWrapper_col_2 ViewMerchantPage_section_title_small_extra_orange">
                  “I will always come back to a store I can trust and get a refund if the prices drops"
                  <br />
                  <br />
                  Benjamin
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION 4 */}

        <div className="ViewMerchantPage_section ViewMerchantPage_section_4">
          <div className="ViewMerchantPage_section_4_row_1">
            <div className="ViewMerchantPage_section_4_row_1_col_1 ViewMerchantPage_section_title_large_xl">
              <Hide on={"mobile"}>
                <span className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_title_small_extra_rigtSpace">
                  1.
                </span>{" "}
              </Hide>
              74%
            </div>
            <div className="ViewMerchantPage_section_4_row_1_col_2 ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
              of shoppers say they{" "}
              <span className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_green">
                use price comparison
              </span>{" "}
              search engines to find and compare products
            </div>
          </div>

          <div className="ViewMerchantPage_section_4_row_1">
            <div className="ViewMerchantPage_section_4_row_1_col_1 ViewMerchantPage_section_title_large_xl">
              <Hide on={"mobile"}>
                <span className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_title_small_extra_rigtSpace">
                  2.
                </span>{" "}
              </Hide>
              50%
            </div>
            <div className="ViewMerchantPage_section_4_row_1_col_2 ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
              of shoppers see{" "}
              <span className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_green">
                price as the top factor
              </span>{" "}
              in deciding which website to buy from
            </div>
          </div>

          <div className="ViewMerchantPage_section_4_row_1">
            <div className="ViewMerchantPage_section_4_row_1_col_1 ViewMerchantPage_section_title_large_xl">
              <Hide on={"mobile"}>
                <span className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_title_small_extra_rigtSpace">
                  3.
                </span>{" "}
              </Hide>
              73%
            </div>
            <div className="ViewMerchantPage_section_4_row_1_col_2 ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
              A whopping 73% of respondents said they were willing to{" "}
              <span className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_green">
                pay more if they were assured transparency
              </span>
            </div>
          </div>
          <div className="ViewMerchantPage_section_4_row_2">
            <div className="ViewMerchantPage_section_title_small_extra">
              1. Prisync.com - “Price Comparison Engines: Commonly Used Services in Europe”
            </div>
            <div className="ViewMerchantPage_section_title_small_extra">2. KPMG - Global Online Consumer Report</div>
            <div className="ViewMerchantPage_section_title_small_extra">
              3. McKinsey - Four ways to achieve pricing excellence in retail marketplaces
            </div>
          </div>
        </div>
        {/* SECTION 5 */}

        {/* <div className="ViewMerchantPage_section">
          <div className="ViewMerchantPage_section_5_row_1">
            <img
              alt="Tjommi Merchant"
              src={IMAGE_SECTION5}
              className="ViewMerchantPage_section_5_row_1_img"
            />
          </div>
          <div className="ViewMerchantPage_section_5_row_2">
            <div className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_title_small_extra_white ViewMerchantPage_section_5_row_2_col_1">
              Tjommi has really solved our retention engine. We’re accessing new
              customers at lower cost, and better yet, they keep coming back.
            </div>
            <div className="ViewMerchantPage_section_5_row_2_col_2">
              <div className="ViewMerchantPage_section_title_small_extra ViewMerchantPage_section_title_small_extra_white">
                Claus Nielsen
              </div>
              <div className="ViewMerchantPage_section_title_small_extra">
                CMO
              </div>
              <div className="ViewMerchantPage_section_title_small_extra">
                Bestseller Group
              </div>
            </div>
          </div>
        </div> */}

        {/* SECTION 6 */}

        <div className="ViewMerchantPage_section">
          <div className="ViewMerchantPage_section_title_large ViewMerchantPage_section_title_large_grey ViewMerchantPage_section_6_row_1">
            Acquire and retain customers
          </div>
          <div className="ViewMerchantPage_section_6_row_2">
            <div className="ViewMerchantPage_section_title_large ViewMerchantPage_section_6_row_2_col">
              at a lower cost
            </div>
          </div>
          <div className="ViewMerchantPage_section_6_row_3">
            <div>
              <hr className="ViewMerchantPage_hr" />

              <div className="ViewMerchantPage_section_6_row_3_col">
                <ICON_DIVERSITY />
              </div>
              <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
                Keep customers engaged
              </div>
              <div className="ViewMerchantPage_section_title_small">
                Let your customers know that your prices are the best in the market once your price matching guarantee
                has expired.
              </div>
            </div>
            <div>
              <hr className="ViewMerchantPage_hr" />

              <div className="ViewMerchantPage_section_6_row_3_col">
                <ICON_WOMEN_FACE />
                <ICON_BOY_FACE />
                <ICON_PLUSE width="48" height="48" color={"white"} />
              </div>

              <div className="ViewMerchantPage_section_title_small ViewMerchantPage_section_title_small_white">
                Reach new customers easily
              </div>
              <div className="ViewMerchantPage_section_title_small">
                Tjommi users prefer to shop with trusted partners. Become a Tjommi Partner to attract new customers.
              </div>
            </div>
          </div>
        </div>

        {/* SECTION 7 */}
        <div className="ViewMerchantPage_section">
          <div className="ViewMerchantPage_section_content_sessionCard">
            <div className="homeLandingPage_section_content_cards_row_content">
              <SessionCard newsSubscribeMode backgroundColor="#FFFFFF" />
            </div>
            <div className="homeLandingPage_section_content_cards_row_content">
              <SessionCard refundType backgroundColor="#CBC3B9" dark bottomContentPosition="end" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewMerchantPage;
