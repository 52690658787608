import React from "react";
import LOGO from "./assets/logo_dark.png";
import TJOMMI_WORD_LOGO from "./assets/tjommi-logo.png";
import ICON_ARROW_OUTWARD from "./assets/arrow_outward.png";
import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL, TIKTOK_URL } from "../../../utils/configs";
import { withRouter } from "react-router-dom";
import AppStoreBanners from "../../appStoreBanners";
import routes from "../../../utils/routes";
import "./styles.scss";

const Footer = () => {
  return (
    <div className="footerPage">
      <div className="footerPage_section">
        <div className="footerPage_section_left">
          <div className="footerPage_appIcon">
            <img src={LOGO} alt="TjommiLogo" className="footerPage_appIcon_img" />
          </div>
        </div>
        <div className="footerPage_section_wrapper">
          <div className="footerPage_section_middle">
            <div className="footerPage_section">
              <div className="footerPage_section_middle_getApp">
                <p className="footerPage_section_middle_getApp_title">Get the app</p>

                <AppStoreBanners outlineLight={true} />
              </div>
            </div>
          </div>
          <div className="footerPage_section_right">
            <div className="footerPage_section_middle_getApp">
              <p className="footerPage_section_middle_getApp_title">Join us here.</p>
              <div className="footerPage_section_social_link_wrapper">
                <div
                  className="footerPage_section_social_link footerPage_section_middle_getApp_title
                footerPage_section_middle_getApp_title_social"
                >
                  <a
                    href={LINKEDIN_URL}
                    className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title_social"
                  >
                    Linkedin
                  </a>
                  <div className="footerPage_section_social_link_img">
                    <img src={ICON_ARROW_OUTWARD} alt="TjommiLogo" className="footerPage_section_social_link_img" />
                  </div>
                </div>
                <div
                  className="footerPage_section_social_link footerPage_section_middle_getApp_title
                footerPage_section_middle_getApp_title_social"
                >
                  <a
                    href={FACEBOOK_URL}
                    className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title_social"
                  >
                    Facebook
                  </a>
                  <div
                    className="
                  footerPage_section_middle_getApp_title
                  footerPage_section_social_link_img"
                  >
                    <img src={ICON_ARROW_OUTWARD} alt="TjommiLogo" className="footerPage_section_social_link_img" />
                  </div>
                </div>
                <div
                  className="footerPage_section_social_link footerPage_section_middle_getApp_title
                footerPage_section_middle_getApp_title_social"
                >
                  <a
                    href={TIKTOK_URL}
                    className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title_social"
                  >
                    Tiktok
                  </a>
                  <div
                    className="
                  footerPage_section_middle_getApp_title
                  footerPage_section_social_link_img"
                  >
                    <img src={ICON_ARROW_OUTWARD} alt="TjommiLogo" className="footerPage_section_social_link_img" />
                  </div>
                </div>
                <div
                  className="footerPage_section_social_link footerPage_section_middle_getApp_title
                footerPage_section_middle_getApp_title_social"
                >
                  <a
                    href={INSTAGRAM_URL}
                    className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title_social"
                  >
                    Instagram
                  </a>
                  <div
                    className="
                  footerPage_section_middle_getApp_title
                  footerPage_section_social_link_img"
                  >
                    <img src={ICON_ARROW_OUTWARD} alt="TjommiLogo" className="footerPage_section_social_link_img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerPage_section_bottom">
        <div className="footerPage_section_bottom_wrapper">
          <div className="footerPage_section_bottom_links_wrapper">
            <div className="footerPage_section_bottom_left">
              <div className="footerPage_section_bottom__divider__wrapper">
                <hr className="footerPage_section_bottom__divider" />
              </div>
              <div className="footerPage_section_bottom_title">Reach us here</div>
              <div className="footerPage_section_bottom_title_sub">hello@tjommi.app</div>
            </div>
            <div className="footerPage_section_bottom_middle">
              <div className="footerPage_section_bottom__divider__wrapper">
                <hr className="footerPage_section_bottom__divider" />
              </div>
              <div className="footerPage_section_bottom_title">Useful links</div>
              {/* <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a href={routes.about.path} className="footerPage_section_bottom__links">
                  About Tjommi
                </a>
              </div> */}
              <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a href={routes.faq.path} className="footerPage_section_bottom__links">
                  FAQ
                </a>
              </div>
              <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a href="mailto:henrik@tjommi.app" className="footerPage_section_bottom__links">
                  Press
                </a>
              </div>
              <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a href={routes.privacy.path} className="footerPage_section_bottom__links">
                  Privacy Policy
                </a>
              </div>
              <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a href={routes.terms.path} className="footerPage_section_bottom__links">
                  Terms and conditions
                </a>
              </div>
              <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a href={routes.securityReport.path} className="footerPage_section_bottom__links">
                  Report security issue
                </a>
              </div>
              {/* <div className="footerPage_section_bottom_title footerPage_section_bottom_title_sub">
                <a
                  href="/files/call_for_egm_dec_2022.pdf"
                  className="footerPage_section_bottom__links"
                >
                  Investor Relations
                </a>
              </div> */}
            </div>
          </div>
          <div className="footerPage_section_bottom_right">
            <div className="footerPage_section_bottom_logo_wrapper">
              <img src={TJOMMI_WORD_LOGO} alt="TjommiWord" className="footerPage_section_bottom_logo" />
            </div>
            <div className="footerPage_section_bottom_title_sub footerPage_section_bottom_title_sub_small">
              Company registered in Denmark
              <br /> Company Registration no. 44201844
              <br />
              Copyright © 2023 Tjommi.
              <br />
              <br />
              All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Footer);
