const Icon = ({ width = "40", height = "40", color = "#828282", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g mask="url(#mask0_303_966)">
      <path
        d="M19.999 25.083L10.582 15.625L12.082 14.167L19.999 22.083L27.916 14.167L29.416 15.667L19.999 25.083Z"
        fill={color}
      />
    </g>
  </svg>
);

export default Icon;
