import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../state/selectors";
import Outline from "../layouts/outline";
import Button from "../inputs/button";
import Label from "../label";
import "./styles.scss";
import { usePostHog } from "posthog-js/react";

const ViewForgotPassword = ({
  strs,
  onforgotPasswordHandler,
  tjommiDeviceID,
}) => {
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(true);
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      posthog.capture("forgotten_password_screen_view", {
        device_id: tjommiDeviceID,
      });
    }
  }, [posthog]);

  return (
    <div className="viewForgotPassword">
      <div className="viewForgotPassword__title">
        <Label align="left" color="#ffffff" bold size="sm">
          {strs.title}
        </Label>
      </div>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 0,
        }}
        error={!valid}
        border={true}
        outlineAreaStyles={{
          padding: 14,
        }}
      >
        <input
          className="viewForgotPassword__input"
          name="Email"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={strs.email}
        />
      </Outline>
      <div className="viewForgotPassword__main_btn_wrapper">
        <Button
          type="default"
          label={strs.send}
          onClick={() => {
            if (email === "") {
              setValid(false);
            } else {
              setValid(true);
              posthog.capture("forgotten_password_screen_send_click", {
                device_id: tjommiDeviceID,
              });
              onforgotPasswordHandler(email);
            }
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "forgotPassword"),
});

export default withRouter(connect(mapStateToProps, null)(ViewForgotPassword));
