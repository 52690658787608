const icon = () => (
  <svg
    className="iconArrowDown"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 16.2314C11.7113 16.2316 11.426 16.1692 11.1638 16.0485C10.9016 15.9278 10.6688 15.7516 10.4813 15.5322L5.24128 9.4189C5.15148 9.31991 5.08244 9.20392 5.03823 9.07779C4.99402 8.95166 4.97554 8.81794 4.98389 8.68455C4.99223 8.55115 5.02724 8.42079 5.08683 8.30115C5.14642 8.18152 5.22939 8.07504 5.33083 7.98802C5.43228 7.90099 5.55014 7.83519 5.67744 7.79449C5.80475 7.7538 5.93893 7.73903 6.07204 7.75108C6.20515 7.76312 6.3345 7.80173 6.45244 7.86461C6.57037 7.9275 6.67451 8.01338 6.75868 8.1172L11.9999 14.2314L17.2411 8.1172C17.3254 8.01381 17.4296 7.92834 17.5474 7.86581C17.6653 7.80329 17.7944 7.76498 17.9273 7.75314C18.0602 7.74131 18.1941 7.75618 18.3212 7.79688C18.4482 7.83759 18.5658 7.90331 18.6671 7.99017C18.7684 8.07702 18.8512 8.18327 18.9108 8.30264C18.9704 8.42201 19.0055 8.55209 19.014 8.68523C19.0225 8.81837 19.0043 8.95187 18.9605 9.07787C18.9166 9.20387 18.848 9.31982 18.7587 9.4189L13.5175 15.5332C13.3301 15.7524 13.0973 15.9283 12.8353 16.0489C12.5733 16.1694 12.2883 16.2317 11.9999 16.2314Z"
      fill="#1F1F1F"
    />
  </svg>
);

export default icon;
