import React from "react";
import StoreIcon from "../../assets/storeIcon";
import RatingStars from "../ratingStars";
import IconPartnerBadge from "../../../../components/icons/partnerBadge";
import { withRouter } from "react-router-dom";
import IconScoreEmptyBadge from "../../../../components/icons/scoreEmptyBadge";
import "./styles.scss";

const RatingCard = ({ rating, history }) => {
  return (
    <div
      className="rating-card"
      onClick={() => (rating?.id ? history.push(`/ratings/${rating?.id}`) : null)}
      style={{ cursor: rating?.id ? "pointer" : "default" }}
    >
      <div className="rating-card__badge">
        {rating?.is_partner ? <IconPartnerBadge /> : rating?.tjommi_score === 0 ? <IconScoreEmptyBadge /> : null}
      </div>
      <div className="heading-section">
        <div className="heading-section__icon">
          {rating?.logo ? <img src={rating?.logo} alt="Logo" /> : <StoreIcon width={130} height={136} />}
        </div>

        <div className="heading-section__subcontent">
          <div className="heading-section__subcontent--ratings">
            <RatingStars rating={rating?.tjommi_score} length={5} />
          </div>
          <div className="heading-section__subcontent--heading">{rating?.name}</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RatingCard);
