import React from "react";
import PinkCategoryBox from "../categoryBox";

const StoreLogo = (props) => {
  return props.domain?.logo ? (
    <img
      src={props.domain?.logo}
      alt="Logo"
      style={{
        width: "48px",
        height: "48px",
        borderRadius: "8px",
      }}
    />
  ) : (
    <PinkCategoryBox category={props.domain?.category} />
  );
};

export default StoreLogo;
