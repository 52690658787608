import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../../../state/selectors";
import "./styles.scss";
import Arrow from "../../assets/Arrow";
import { Link } from "react-router-dom";

class SupportCard extends React.Component {
  render() {
    return (
      <div className="support-card">
        <Link className="card" to={this.props.path} draggable={false}>
          <div className="card__header">
            {this.props.children}
            <div className="card__header--text">{this.props.title}</div>
          </div>
          <Arrow />
        </Link>
        <div className="support-card__text">{this.props.text}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "support"),
});

export default connect(mapStateToProps)(SupportCard);
