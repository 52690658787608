import React, { useState } from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorMyUser } from "../../../state/selectors";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import { HeaderLayout } from "../../../components/layouts/header";
import ArrowLeft from "../../../components/icons/arrowMiniLeft";
import { ControlsLayout } from "../../../components/layouts/controls";
import Button from "../../../components/inputs/button";
import Outline from "../../../components/layouts/outline";
import { withRouter } from "react-router-dom";
import DateInput from "../../../components/inputs/dateInput";
import RadioInput from "../../../components/inputs/radioInput";
import { updateMyUser } from "../../../state/actionCreators";
import QuestionCircleIcon from "../../../components/icons/questionCircle";
import IconClose from "../../../components/icons/cancel";
import "./styles.scss";

const ViewProfileInfo = ({ history, strs, updateMyUser, user, lang }) => {
  const genderList = [
    {
      code: "male",
      title: {
        en: "Male",
        da: "Mand",
        nb: "Mann",
      },
    },
    {
      code: "female",
      title: {
        en: "Female",
        da: "Kvinde",
        nb: "Kvinne",
      },
    },
    {
      code: "unspecified",
      title: {
        en: "Unspecified",
        da: "Uspecificeret",
        nb: "Uspesifisert",
      },
    },
    {
      code: "other",
      title: {
        en: "Other",
        da: "Andet",
        nb: "Andre",
      },
    },
  ];

  const [selectedGender, setSelectedGender] = useState(user.gender || "unspecified");
  const [birthDay, setBirthDay] = useState(user.birthday || "");
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [alertVisible, setAlertVisible] = useState(false);

  const callUpdateMyData = async () => {
    await updateMyUser({
      birthday: birthDay,
      first_name: firstName,
      last_name: lastName,
      gender: selectedGender,
    });
    history.goBack();
  };

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
    } else {
      setBirthDay(e.target.value);
    }
  };
  const infoHandler = () => {
    setAlertVisible(!alertVisible);
    setTimeout(() => {
      setAlertVisible(false);
    }, 4000);
  };
  return (
    <div className="profileInformationPage">
      {alertVisible && (
        <div className="profileInformationPage_alertMessageWrapper">
          <div className="profileInformationPage_alertMessageWrapper--mainview">
            <div className="profileInformationPage_alertMessageWrapper--body">
              <div className="profileInformationPage_alertMessageWrapper--body_text">{strs.alertMsg}</div>
              <div
                className="profileInformationPage_alertMessageWrapper--body--pointer"
                onClick={() => setAlertVisible(false)}
              >
                <IconClose />
              </div>
            </div>
          </div>
        </div>
      )}
      <MainContentLayout center={true}>
        <HeaderLayout
          left={
            <div
              className="headerLayout--leftArrow"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowLeft color="black" />
            </div>
          }
        />
        <h1 className="headline">{strs.title}</h1>

        <Outline label={strs.firstName}>
          <input
            className="input"
            onChange={handleChange}
            name="firstName"
            value={firstName}
            placeholder={strs.firstName}
          />
        </Outline>

        <Outline label={strs.lastName}>
          <input
            className="input"
            name="lastName"
            onChange={handleChange}
            value={lastName}
            placeholder={strs.lastName}
          />
        </Outline>

        <Outline
          label={
            <div className="profileInformationPage_info_cover" onClick={infoHandler}>
              {strs.birthday}
              <QuestionCircleIcon />
            </div>
          }
        >
          <DateInput handleChange={handleChange} value={birthDay} name="birthday" />
        </Outline>

        <Outline
          label={
            <div className="profileInformationPage_info_cover" onClick={infoHandler}>
              {strs.gender}
              <QuestionCircleIcon />
            </div>
          }
        >
          {genderList.map((ele) => {
            return (
              <RadioInput
                key={ele.code}
                checked={selectedGender === ele.code}
                onClick={(e) => {
                  setSelectedGender(e.target.id);
                }}
                label={ele.title[lang]}
                id={ele.code}
              />
            );
          })}
        </Outline>

        <ControlsLayout>
          <Button disabled={false} type="pink" label={strs.btnTitle} onClick={() => callUpdateMyData()} />
        </ControlsLayout>
      </MainContentLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "profileInformation"),
    user: selectorMyUser(state),
    lang: state.globalState.language,
  };
};

const mapDispatchToProps = {
  updateMyUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewProfileInfo));
