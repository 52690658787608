const Icon = ({ width = "30", height = "30", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 29.5C13 29.5 11.125 29.118 9.375 28.354C7.625 27.59 6.09033 26.5553 4.771 25.25C3.45167 23.9447 2.41 22.417 1.646 20.667C0.882 18.917 0.5 17.028 0.5 15C0.5 13 0.882 11.118 1.646 9.354C2.41 7.59 3.44467 6.05533 4.75 4.75C6.05533 3.44467 7.583 2.41 9.333 1.646C11.083 0.882 12.972 0.5 15 0.5C17 0.5 18.882 0.882 20.646 1.646C22.41 2.41 23.9447 3.44467 25.25 4.75C26.5553 6.05533 27.59 7.59 28.354 9.354C29.118 11.118 29.5 13 29.5 15C29.5 17 29.118 18.875 28.354 20.625C27.59 22.375 26.5553 23.9097 25.25 25.229C23.9447 26.5483 22.41 27.59 20.646 28.354C18.882 29.118 17 29.5 15 29.5ZM15 28.5C18.75 28.5 21.9373 27.1873 24.562 24.562C27.1873 21.9373 28.5 18.75 28.5 15C28.5 11.25 27.1943 8.06267 24.583 5.438C21.9723 2.81267 18.778 1.5 15 1.5C11.25 1.5 8.06267 2.80567 5.438 5.417C2.81267 8.02767 1.5 11.222 1.5 15C1.5 18.75 2.81267 21.9373 5.438 24.562C8.06267 27.1873 11.25 28.5 15 28.5Z"
      fill={color}
    />
    <path d="M15.542 15.542H22.25V14.542H15.542H14.542H7.75V15.542H14.542H15.542Z" fill={color} />
  </svg>
);

export default Icon;
