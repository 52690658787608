const Icon = ({ color = "white" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2324 5.01563C15.4367 5.01578 15.636 5.07848 15.8036 5.1953C15.9711 5.31212 16.0989 5.47745 16.1697 5.66906C16.2405 5.86067 16.2509 6.06936 16.1996 6.26706C16.1482 6.46477 16.0375 6.642 15.8824 6.77493L9.76863 12.0156L15.8828 17.2563C16.0774 17.4307 16.196 17.6744 16.2132 17.9351C16.2304 18.1959 16.1448 18.453 15.9748 18.6515C15.8049 18.8499 15.5639 18.974 15.3036 18.9971C15.0434 19.0201 14.7844 18.9404 14.5821 18.7749L8.46783 13.5342C8.24889 13.3465 8.07314 13.1136 7.95261 12.8516C7.83208 12.5895 7.76963 12.3045 7.76953 12.0161C7.76944 11.7277 7.8317 11.4426 7.95206 11.1805C8.07241 10.9184 8.24802 10.6854 8.46683 10.4975L14.5821 5.25633C14.763 5.10074 14.9938 5.01531 15.2324 5.01563Z"
      fill={color}
    />
  </svg>
);

export default Icon;
