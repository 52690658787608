let identified = false;

export const initialise = (id) => {
  const code = `var _cio = _cio || [];
    (function() {
      var a,b,c;a=function(f){return function(){_cio.push([f].
      concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
        "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
      var t = document.createElement('script'),
              s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id    = 'cio-tracker';
      t.setAttribute('data-site-id', '${id}');
      t.setAttribute('data-auto-track-page', 'false')
      t.src = 'https://assets.customer.io/assets/track-eu.js';
      s.parentNode.insertBefore(t, s);
    })();`;
  const elm = document.createElement("script");
  elm.appendChild(document.createTextNode(code));
  document.body.appendChild(elm);
};

export const pageView = () => {
  window._cio.page();
};

export const identify = async (user) => {
  if (identified) return;
  if (!user) return;

  try {
    window._cio?.identify({
      id: user.id,
      first_name: user.first_name,
      email: user.email,
    });
    console.log("[Tjommi] CustomerIO identified");
    identified = true;
  } catch (e) {
    console.log("[Tjommi] CustomerIO identify failed", e);
  }
};

export const trackCustomEvent = (eventName, options) => {
  window._cio.track(eventName, options);
};

export const trackConversion = () => {
  window._cio.track("CompleteRegistration");
};

export const newsSubscribe = (email) => {
  window._cio?.identify({
    email,
    newsletter_signup: true,
  });
};
