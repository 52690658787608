import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { revokeEmailAccount, setToastMessage, updateMyUser } from "../../../state/actionCreators";
import { selectorIsAppMode, selectorLanguageStrings } from "../../../state/selectors";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import { HeaderLayout } from "../../../components/layouts/header";
import { ControlsLayout } from "../../../components/layouts/controls";
import { getConnectEmailUrl } from "../../../components/inputs/buttonSigninWith";
import Button from "../../../components/inputs/button";
import Label from "../../../components/label";
import Outline from "../../../components/layouts/outline";
import DetailLine from "../../../components/detailLine";
import InfoBox, { InfoBoxes } from "../../../components/infoBox";
import DataSecurityModal from "../../../components/modals/dataSecurityModal/DataSecurityModal";
import EMAIL_MODAL_IMAGE from "./assets/connectEmail.png";
import DATA_SECURITY_MODAL_IMAGE from "./assets/dataSecurity.png";
import ConnectEmailProfileModal from "../../../components/modals/connectEmailProfileModal/ConnectEmailProfileModal";
import routes from "../../../utils/routes";
import getQuery from "../../../utils/getQuery";
import "./styles.scss";
import GenericModal from "../../../components/genericModal";

const ViewProfileEmailAccounts = ({
  setToastMessage,
  strsErrorMessage,
  revokeEmailAccount,
  history,
  strs,
  connectedEmailAccounts,
  mainEmailAccount,
}) => {
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [disconnectEmailAccount, setDisconnectEmailAccount] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const query = getQuery();

  useEffect(() => {
    if (query?.status === "error") {
      setToastMessage(strsErrorMessage);
    }
  }, [query, setToastMessage, strsErrorMessage]);

  const handleConnectClick = (provider) => () => {
    window.location = getConnectEmailUrl(provider, routes.profileConfirmation.subroutes.emailConnected.path);
  };

  const handleDisconnectClick = (emailAccount) => () => setDisconnectEmailAccount(emailAccount);

  const removeEmailAccount = async () => {
    revokeEmailAccount(disconnectEmailAccount.id);
    setDisconnectEmailAccount(null);
    history.push(routes.profileConfirmation.subroutes.emailDisconnected.path);
  };

  const renderEmailAccounts = (emailAccounts, withDisconnect = false) => {
    return emailAccounts.map((emailAccount, index) => (
      <Outline key={index}>
        <DetailLine
          slim={true}
          label={emailAccount.email}
          value={withDisconnect && strs.disconnectLabel}
          onClick={withDisconnect && handleDisconnectClick(emailAccount)}
        />
      </Outline>
    ));
  };

  return (
    <div className="viewProfileEmailAccounts">
      <MainContentLayout center small>
        <HeaderLayout
          left={{ type: "arrowLeft", path: routes.profile.path }}
          right={
            connectedEmailAccounts.length ? (
              <Link onClick={() => setShowDisconnect(!showDisconnect)} to="#">
                Manage
              </Link>
            ) : undefined
          }
        />
        <h1>{strs.title}</h1>

        <Outline label={strs.readMore} padding={false} background={false}>
          <InfoBoxes>
            <InfoBox
              backgroundColor={"#505EE8"}
              image={DATA_SECURITY_MODAL_IMAGE}
              text={strs.dataSecurityModal.title}
              onClick={() => setOpenModal("open_modal_1")}
              contentPosition
            />
            <InfoBox
              backgroundColor={"#896EEB"}
              image={EMAIL_MODAL_IMAGE}
              text={strs.connectEmailModal.modalTitle}
              onClick={() => setOpenModal("open_modal_2")}
              contentPosition
            />
          </InfoBoxes>
        </Outline>

        {mainEmailAccount && (
          <div className="mainEmailAccount">
            <Label>{strs.labelMainAccount}</Label>
            {renderEmailAccounts([mainEmailAccount], false)}
          </div>
        )}

        {!!connectedEmailAccounts.length && (
          <div className="connectedEmailAccounts">
            <Label>{strs.labelConnectedAccounts}</Label>
            {renderEmailAccounts(connectedEmailAccounts, showDisconnect)}
          </div>
        )}

        <ControlsLayout>
          <Button type="pink" label={strs.buttonConnectGmail} onClick={handleConnectClick("google")} />
          <Button type="pink" label={strs.buttonConnectOutlook} onClick={handleConnectClick("gmail")} />
        </ControlsLayout>
      </MainContentLayout>

      <GenericModal
        isVisible={!!disconnectEmailAccount}
        backgroundColor={"#896EEB"}
        backgroundImage={DATA_SECURITY_MODAL_IMAGE}
        title={strs.disconnectTitle}
        text={
          <div dangerouslySetInnerHTML={{ __html: strs.disconnectMessage({ email: disconnectEmailAccount?.email }) }} />
        }
        closeBtn={() => setDisconnectEmailAccount(false)}
        btnText={strs.addEmailBtn}
        button={
          <div className="buttonsWrapper">
            <Button
              label={strs.disconnectCancelButton}
              type="transparent"
              onClick={() => setDisconnectEmailAccount(false)}
            />
            <Button label={strs.disconnectConfirmButton} type="danger" onClick={removeEmailAccount} />
          </div>
        }
      />

      <DataSecurityModal closeBtn={() => setOpenModal(false)} visible={openModal === "open_modal_1"} />
      <ConnectEmailProfileModal closeBtn={() => setOpenModal(false)} visible={openModal === "open_modal_2"} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const connectedEmailAccounts = [...(state?.globalState?.myUser?.emailAccounts || [])];
  const mainEmailAccount = connectedEmailAccounts.splice(0, 1)?.[0];
  return {
    mainEmailAccount,
    connectedEmailAccounts,
    isAppMode: selectorIsAppMode(state),
    strs: selectorLanguageStrings(state, "profileEmails"),
    strsErrorMessage: selectorLanguageStrings(state, "signin").errorMessage,
  };
};

const mapDispatchToProps = {
  updateMyUser,
  revokeEmailAccount,
  setToastMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfileEmailAccounts);
