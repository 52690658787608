import Button from "../inputs/button";
import React from "react";
import { getConnectEmailUrl } from "../inputs/buttonSigninWith";
import { detectRegion } from "../../utils/lang";
import {
  selectorIsAppMode,
  selectorTrafficSource,
  selectorTjommiDeviceID,
  selectorMyUser,
} from "../../state/selectors";
import { setGlobalState } from "../../state/globalState/actionCreators";
import { setToastMessage } from "../../state/actionCreators";
import { connect } from "react-redux";
import routes from "../../utils/routes";
import { usePostHog } from "posthog-js/react";

const AuthButton = ({ label, provider, icon, children = null, fixedWidth = 160, tjommiDeviceID, user }) => {
  const posthog = usePostHog();

  const handleSignInWithClick = async (provider = null) => {
    const region = await detectRegion();

    setGlobalState({ region });

    if (!provider) {
      provider = localStorage.getItem("provider");
    }

    if (provider === "google") {
      posthog?.capture("connect_email_screen_google_click", {
        device_id: tjommiDeviceID,
        user_id: user.id,
        user_name: user.full_name,
        user_email: user.email,
        user_region: user.region_id,
      });
    } else if (provider === "outlook") {
      posthog?.capture("connect_email_screen_outlook_click", {
        device_id: tjommiDeviceID,
        user_id: user.id,
        user_name: user.full_name,
        user_email: user.email,
        user_region: user.region_id,
      });
    }

    localStorage.setItem("provider", provider);

    window.location = getConnectEmailUrl(provider, routes.onboarding.path);
  };

  return children ? (
    <div onClick={() => handleSignInWithClick(provider)}>{children}</div>
  ) : (
    <Button
      type="transparent"
      label={label}
      onClick={() => handleSignInWithClick(provider)}
      icon={icon}
      fixedWidth={fixedWidth}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAppMode: selectorIsAppMode(state),
    trafficSource: localStorage.getItem("ref") || selectorTrafficSource(state),
    tjommiDeviceID: selectorTjommiDeviceID(state),
    user: selectorMyUser(state),
  };
};

const mapDispatchToProps = { setGlobalState, setToastMessage };

export default connect(mapStateToProps, mapDispatchToProps)(AuthButton);
