import * as React from "react";

function Arrow(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.75 8.75l3.5 3.25-3.5 3.25"
        stroke="#757575"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Arrow;
