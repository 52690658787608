const Icon = ({ width = "36", height = "36" }) => (
  <svg width={width} height={height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_678_1579)">
      <path
        d="M90.116 0H9.88402C4.42523 0 0 4.42523 0 9.88402V90.116C0 95.5748 4.42523 100 9.88402 100H90.116C95.5748 100 100 95.5748 100 90.116V9.88402C100 4.42523 95.5748 0 90.116 0Z"
        fill="#FA779D"
      />
      <path
        d="M67.5492 25.1001H32.3492C28.6765 25.1001 25.6992 28.0774 25.6992 31.7501C25.6992 35.4228 28.6765 38.4001 32.3492 38.4001H43.1992V68.1501C43.1992 71.878 46.2213 74.9001 49.9492 74.9001C53.6771 74.9001 56.6992 71.878 56.6992 68.1501V38.4001H67.5492C71.2219 38.4001 74.1992 35.4228 74.1992 31.7501C74.1992 28.0774 71.2219 25.1001 67.5492 25.1001Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_678_1579">
        <rect width="100" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
