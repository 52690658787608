import React, { useEffect, useRef, useState } from "react";

import "./styles.scss";
import { Portal } from "react-portal";
import Modal from "../../../components/modal";
import AudioOff from "../../icons/audio-off";
import AudioOn from "../../icons/audio-on";
import Cancel from "../../icons/cancel";

const VideoModal = ({
  isOpen,
  children,
  videoUrl,
  onClose,
  muted = false,
  showMuteControls = true,
  showCloseControls = true,
  backgroundColor = "#ed7a9c",
}) => {
  const defaultMuted = muted;

  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(defaultMuted);

  const handleEscape = (event) => {
    if (event.key === "Escape" && onClose) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  });

  useEffect(() => {
    if (isOpen) {
      videoRef.current.play();
    } else {
      if (videoRef.current.play) {
        // Pause and restart
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isOpen]);

  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };

  return (
    <Portal>
      <div className="videoModalHolder">
        <Modal visible={isOpen}>
          <div className="videoModal" style={{ backgroundColor: backgroundColor }}>
            <div className="videoModalWrapper">
              <div className="videoModalWrapper__content">
                {showCloseControls && (
                  <div className="floatingButton floatingButton--left" onClick={onClose}>
                    <Cancel />
                  </div>
                )}
                {showMuteControls && (
                  <div className="floatingButton floatingButton--right" onClick={toggleMute}>
                    {isMuted ? <AudioOff /> : <AudioOn />}
                  </div>
                )}
                <video
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  ref={videoRef}
                  autoPlay
                  playsInline
                  loop
                  muted={defaultMuted}
                >
                  <source type="video/mp4" src={videoUrl} />
                </video>

                {children}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Portal>
  );
};

export default VideoModal;
