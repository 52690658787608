import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BUGSNAG_KEY, IS_PRODUCTION } from "../../utils/configs";

if (IS_PRODUCTION)
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ["production"],
  });

export const log = (error, payload) => {
  const handle = !payload ? undefined : (event) => event.addMetadata("payload", payload);
  if (IS_PRODUCTION) return Bugsnag.notify(error, handle);
};

export const ErrorBoundary = IS_PRODUCTION
  ? Bugsnag.getPlugin("react").createErrorBoundary(React)
  : (props) => props.children;
