import React from "react";
import { connect } from "react-redux";
import { selectorGiftCardsByDate, selectorLanguageStrings } from "../../state/selectors";
import ListingPage from "../listingPage";
import StoreLogo from "../../components/storeLogo";
import ListItem from "../../components/listItem";
import { formatCurrency, formatDate } from "../../utils/formatter";
import Outline from "../../components/layouts/outline";
import routes from "../../utils/routes";
import { getGiftCards } from "../../state/actionCreators";

const GiftCardsAll = ({ giftCardsByDate, getGiftCards, strs }) => {
  return (
    <ListingPage
      headline={strs.giftCrads.title}
      items={giftCardsByDate}
      backPath={routes.overview.subroutes.giftcards.path}
      emptyState={<div>no items</div>}
      onRefresh={getGiftCards}
      roundBackArrow={true}
      renderItem={(date) => (
        <Outline label={date.label} key={date.label}>
          {date.items.map((item) => {
            return (
              <ListItem
                key={item.id}
                image={<StoreLogo domain={item?.domain || { category: "gift_card" }} />}
                text={
                  <>
                    <div className="listItem__text--heading">{item?.issuer || strs.unknownStore}</div>
                    <div className="listItem__text--muted">
                      {item?.received_at ? formatDate(item.received_at, "date-year") : strs.unknownDate}
                    </div>
                  </>
                }
                rightText={formatCurrency(item.amount, item.currency)}
                route={`/overview/giftcards/${item.id}?redirect=${routes.giftCardsAll.path}`}
              />
            );
          })}
        </Outline>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    giftCardsByDate: selectorGiftCardsByDate(state),
    strs: selectorLanguageStrings(state, "overview"),
  };
};

const mapDispatchToProps = { getGiftCards };

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardsAll);
