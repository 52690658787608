import React from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorMyUser, selectorTjommiDeviceID } from "../../state/selectors";
import { formatCurrency, getExpireDaysAndHours, createQueryString } from "../../utils/formatter";
import { claimWelcomeVoucher, setToastMessage } from "../../state/actionCreators";
import NativeAppClient from "../../services/nativeAppClient";
import useClipboard from "react-hook-clipboard";
import { usePostHog } from "posthog-js/react";

const WelcomeGiftCard = ({
  claimWelcomeVoucher,
  strs,
  user,
  setToastMessage,
  tjommiDeviceID,
  data: { id, amount, currency, expires_at, claimed_at, code, domain, type },
}) => {
  const [, copyToClipboard] = useClipboard({ updateFrequency: 1000 }, () => {});
  const posthog = usePostHog();

  return (
    <div className="welcomeGiftCard">
      <div className="welcomeGiftCard__col__1">
        <div className="welcomeGiftCard__title">{strs.title}</div>
        {claimed_at ? (
          <div
            className="welcomeGiftCard__code__wrapper"
            onClick={() => {
              NativeAppClient.isInNativeApp() ? NativeAppClient.copyToClipboard(code) : copyToClipboard(code);
              setToastMessage(strs.copySuccess, 1500);
            }}
          >
            <div className="welcomeGiftCard__code">{code}</div>
            <div className="welcomeGiftCard__code__copy">{strs.copyCode}</div>
          </div>
        ) : null}

        <div className="welcomeGiftCard__btn__wrapper" style={{ marginTop: 45 }}>
          <div
            className="welcomeGiftCard__activate"
            onClick={() => {
              if (claimed_at) {
                posthog?.capture("welcome_voucher_redeem_click", {
                  device_id: tjommiDeviceID,
                  user_id: user.id,
                  user_name: user.full_name,
                  user_email: user.email,
                  user_region: user.region_id,
                });

                const url = createQueryString(domain.url, "receipts_feed", type, domain.name);
                NativeAppClient.isInNativeApp() ? NativeAppClient.openWebBrowser(url) : window.open(url, "_blank");
              } else {
                claimWelcomeVoucher(id);
                posthog?.capture("welcome_voucher_activate_click", {
                  device_id: tjommiDeviceID,
                  user_id: user.id,
                  user_name: user.full_name,
                  user_email: user.email,
                  user_region: user.region_id,
                });
              }
            }}
          >
            <span className="welcomeGiftCard__activate__title">{!claimed_at ? strs.active : strs.redeem}</span>
          </div>
          <span className="welcomeGiftCard__expire">
            {strs.expire} {getExpireDaysAndHours(expires_at, strs.hours)}
          </span>
        </div>
      </div>
      <div className="welcomeGiftCard__col__2">
        <div>
          <img src={domain.logo || ""} className="welcomeGiftCard__logo" alt="" />
        </div>
        <div className="welcomeGiftCard__price">{formatCurrency(amount, currency)}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "welcomeGiftCard"),
  user: selectorMyUser(state),
  tjommiDeviceID: selectorTjommiDeviceID(state),
});

const mapDispatchToProps = {
  claimWelcomeVoucher,
  setToastMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeGiftCard);
