export const initialise = (pixelId) => {
  function init(e, t, n) {
    if (e.snaptr) return;
    var a = (e.snaptr = function () {
      a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
    });
    a.queue = [];
    var s = "script";
    var r = t.createElement(s);
    r.async = !0;
    r.src = n;
    var u = t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r, u);
  }

  init(window, document, "https://sc-static.net/scevent.min.js");
  window.snaptr("init", pixelId);
};

export const pageView = () => {
  window.snaptr("track", "PAGE_VIEW");
};

export const trackCustomEvent = (eventName, options) => {
  window.snaptr("track", "CUSTOM_EVENT_1", options);
};

export const trackConversion = () => {
  window.snaptr("track", "SIGN_UP");
};
