const Icon = ({ width = "30", height = "30", color = "#222222", ...props }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      stroke="#000"
      strokeWidth="1.5"
      d="M12.25 18.25c3.5 0 7.25-1.75 7.25-6.25s-3.75-6.25-7.25-6.25S5 7.5 5 12c0 1.03.2 1.92.54 2.67.22.47.34.99.24 1.5l-.26 1.4a1 1 0 0 0 1.17 1.16l3.2-.6c.26-.05.51-.04.77 0 .53.08 1.06.12 1.59.12Z"
    />
    <path
      stroke="#000"
      d="M9.75 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM12.75 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM15.75 12a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
);

export default Icon;
