import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorPurchaseItems, selectorPurchaseTotalSavings } from "../../state/selectors";
import routes from "../../utils/routes";
import InfoBox, { InfoBoxes } from "../../components/infoBox";
import IMAGE1 from "./assets/refunds.png";
import IMAGE2 from "./assets/expiredNoResult.png";
import Outline from "../../components/layouts/outline";
import ListItem from "../../components/listItem";
import SpinnerBox from "../../components/spinnerBox";
import TotalSavings from "../../components/totalSavings";
import { Link, withRouter } from "react-router-dom";
import DetailLine from "../../components/detailLine";
import { formatDate } from "../../utils/formatter";

const Refunds = ({ strs, activePurchases, history, totalSavings }) => {
  return (
    <div>
      <div className="emptyState">
        <Outline>
          <ListItem
            image={<SpinnerBox animation={activePurchases.length > 0} />}
            text={strs.emptyRefundText}
            title={strs.emptyRefundHeadLine}
          />
        </Outline>
      </div>

      <Outline label={strs.optionActive}>
        {Boolean(activePurchases.length === 0) ? (
          <DetailLine label={""} value={strs.emptyRefundListLabel} column={true} />
        ) : (
          activePurchases.map((purchase) => (
            <ListItem
              key={purchase.id}
              image={<SpinnerBox animation={true} />}
              text={purchase?.product}
              rightText={formatDate(purchase.expireDate, "days-left")}
              route={`/overview/refunds/${purchase.id}`}
            />
          ))
        )}

        {activePurchases.length > 5 && (
          <Link className="viewMoreLink" to={routes.refundsAll.path}>
            {strs.viewMore}
          </Link>
        )}
      </Outline>

      {totalSavings > 0 && (
        <Outline padding={false} styles={{ maxWidth: "410px" }}>
          <TotalSavings amount={Math.round(totalSavings * 100) / 100} />
        </Outline>
      )}

      <Outline label={strs.overview} padding={false} background={false}>
        <InfoBoxes>
          <InfoBox
            backgroundColor={"#EB6F93"}
            image={IMAGE1}
            text={strs.yourRefunds}
            onClick={() => history.push(routes.refundsAll.path)}
            position="above"
            size="large"
          />
          <InfoBox
            backgroundColor={"#EB6F93"}
            image={IMAGE2}
            text={strs.expiredProducts}
            onClick={() => history.push(routes.expired.path)}
            position="above"
            size="large"
          />
        </InfoBoxes>
      </Outline>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activePurchases: selectorPurchaseItems(state, "active").slice(0, 5),
    totalSavings: selectorPurchaseTotalSavings(state),
    strs: selectorLanguageStrings(state, "overview"),
  };
};
export default withRouter(connect(mapStateToProps)(Refunds));
