import { log } from "../services/log";
import { IS_PRODUCTION, LOCAL_STORAGE_KEY } from "../utils/configs";
import { decrypt, encrypt } from "../utils/cypher";

export const persistState = (state) => {
  try {
    let str = JSON.stringify(state);
    if (IS_PRODUCTION) str = encrypt(str);
    localStorage.setItem(LOCAL_STORAGE_KEY, str);
  } catch (e) {}
};

export const parseState = () => {
  let parsed = undefined;
  try {
    let str = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (IS_PRODUCTION) str = decrypt(str);
    parsed = JSON.parse(str);
    if (parsed === null) parsed = undefined;
  } catch (error) {
    log(error);
  }
  return parsed;
};
