import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "../../components/inputs/button";
import routes from "../../utils/routes";
import getQuery from "../../utils/getQuery";
import { log } from "../../services/log";
import ApiClient from "../../services/apiClient";
import moment from "moment";
import {
  userLogin,
  userPasswordReset,
  userPasswordChange,
  userRegister,
  updateMyUser,
} from "../../state/actionCreators";
import {
  selectorLanguage,
  selectorLanguageStrings,
  selectorMyUser,
  selectorGiftCardsArray,
  selectorTjommiDeviceID,
} from "../../state/selectors";
import IconInformationCircle from "../../components/icons/informationCircle";
import IconClose from "../../components/icons/cancel";
import IconArrowRight from "../../components/icons/arrowRight";
import DataSecurityModal from "../../components/modals/dataSecurityModal/DataSecurityModal";
import "./styles.scss";
import AuthButton from "../../components/authbutton";
import HERO from "../landingpage/assets/tjommi-hero.png";
import IconLogo from "../../components/icons/logo";
import BottomSheet from "../../components/bottomSheet";
import ViewProfileNotification from "../profile/notification";
import ViewInterests from "../profile/interests";
import ViewSignup from "../../components/signup";
import ViewSignin from "../../components/signin";
import ViewForgotPassword from "../../components/forgotPassword";
import ViewPasswordReset from "../../components/passwordReset";
import Label from "../../components/label";
import ICON_CHECK_CIRCLE from "../../components/icons/checkCircle";
import ArrowLeftIcon from "../../components/icons/arrowMiniLeft";
import { usePostHog } from "posthog-js/react";
import classnames from "classnames";
import NativeAppClient from "../../services/nativeAppClient";
import * as customerio from "../../services/analytics/customerio";

const ViewOnboarding = ({
  strs,
  history,
  user,
  userRegister,
  userLogin,
  userPasswordReset,
  userPasswordChange,
  tjommiDeviceID,
  updateMyUser,
  giftCards,
}) => {
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(null);
  const [isSecurityModalVisible, setIsSecurityModalVisible] = useState(false);
  const [emailAccess, setEmailAccess] = useState(false);
  const [step, setStep] = useState(null);
  const posthog = usePostHog();

  const query = getQuery();

  useEffect(() => {
    setTimeout(() => {
      if (alertVisible) {
        setAlertVisible(null);
      }
    }, 3000);
  }, [alertVisible]);

  useEffect(() => {
    if (posthog) {
      posthog.identify(tjommiDeviceID);
      posthog.capture("start_screen_view", {
        device_id: tjommiDeviceID,
      });
    }
  }, [posthog]);

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        if (user.is_deleted) {
          history.push(routes.signout.path);
        } else {
          try {
            if (NativeAppClient.isInNativeApp()) {
              await NativeAppClient.customerioIdentify(user?.id + "", {
                first_name: user.first_name,
                email: user.email,
              });
            } else {
              await customerio.identify(user);
            }

            await ApiClient.redeemReferal();

            checkSignupFlowStep(user);
          } catch (e) {
            console.log("[Tjommi] Redeem failed", e);
          }
        }
      }
    };

    fetchData(); // Call the async function
  }, [user, giftCards]);

  useEffect(() => {
    if (query && query.token) {
      setStep(4);
      setBottomSheetVisible(true);
    }

    if (!query.status) {
    } else if (query.status === "fail") {
      // Native app failure
      if (query?.type === "native_app") {
        log(new Error("sign in failed due to native app"), {
          windowLocationSearch: window.location.search,
          query,
        });
      }

      setAlertVisible("permissionError");
    } else if (query.status === "ok" && query.jwt) {
      // Success
      if (user.interests && user.interests.length === 0) {
        setStep(4);
        setBottomSheetVisible(true);
        return;
      } else {
        history.push(routes.overview.path);
        return;
      }
    }
  }, []);

  const checkSignupFlowStep = (user) => {
    const threeDaysAgo = moment().subtract(3, "days");
    const createdAt = moment(user.created_at);

    if (
      NativeAppClient.isInNativeApp()
        ? user.enable_push_notifications === null || user.enable_email_notifications === null
        : user.enable_email_notifications === null
    ) {
      setStep(3);
      setBottomSheetVisible(true);
      return;
    } else if (user.emailAccounts === null || (user.emailAccounts && user.emailAccounts.length === 0)) {
      setBottomSheetVisible(false);
      setEmailAccess(true);
      posthog?.capture("connect_email_screen_view", {
        device_id: tjommiDeviceID,
        user_id: user.id,
        user_name: user.full_name,
        user_email: user.email,
        user_region: user.region_id,
      });
      return;
    } else if (
      createdAt.isAfter(threeDaysAgo) &&
      ((user.interests && user.interests.length === 0) || (giftCards && giftCards[0]?.type !== "welcome"))
    ) {
      setStep(5);
      setBottomSheetVisible(true);
      return;
    } else {
      history.push(routes.overview.path);
      return;
    }
  };

  const signUpMAndLoginModalOpen = (signUp) => {
    if (signUp) {
      posthog.capture("start_screen_signup_click", {
        device_id: tjommiDeviceID,
      });
      setStep(0);
    } else {
      posthog.capture("start_screen_login_click", {
        device_id: tjommiDeviceID,
      });
      setStep(1);
    }
    setBottomSheetVisible(true);
  };

  return (
    <div className="onboardingWrapper">
      <img src={HERO} alt="Tjommi" draggable={false} className="onboardingWrapper_hero_image" />

      <div className="onboardingWrapper__inset">
        <div
          className={classnames({
            onboardingWrapper__inset__backArrow: true,
            onboardingWrapper__inset__backArrow__visible: bottomSheetVisible,
          })}
          onClick={() => {
            setBottomSheetVisible(false);
            setStep(null);
          }}
        >
          <ArrowLeftIcon />
        </div>

        <IconLogo color={"white"} width={180} heigh={57} />

        {alertVisible != null ? (
          <div
            className={classnames({
              alertMessageWrapper: true,
              alertMessageWrapper__center: alertVisible === "permissionError" ? false : true,
            })}
          >
            <div className="alertMessageWrapper--mainview">
              <div className="alertMessageWrapper--header">
                <div>
                  {alertVisible === "permissionError" ? (
                    <IconInformationCircle stroke="#00000080" />
                  ) : alertVisible === "icloudAlert" || alertVisible === "yahooAlert" ? (
                    <div className="alertMessageWrapper--title">
                      {strs.authComingSoon({
                        auth: alertVisible === "icloudAlert" ? "iCloud" : "Yahoo",
                      })}
                    </div>
                  ) : null}
                  {alertVisible === "wrongPassword" ||
                  alertVisible === "passwordResetWrongAlert" ||
                  alertVisible === "passwordResetSuccessAlert" ||
                  alertVisible === "emailInvalid" ||
                  alertVisible === "passwordRuleAlert" ||
                  alertVisible === "userAlreadyExisitingAlert" ||
                  alertVisible === "tokenExpiredAlert" ? (
                    <div className="alertMessageWrapper__wrongPasswrd_cover">
                      <IconInformationCircle stroke="#00000080" />
                      <div className="alertMessageWrapper--title">
                        {alertVisible === "wrongPassword"
                          ? strs.wrongPasswordAlert
                          : alertVisible === "passwordResetWrongAlert"
                          ? strs.passwordResetWrongAlert
                          : alertVisible === "passwordResetSuccessAlert"
                          ? strs.passwordResetSuccessAlert
                          : alertVisible === "emailInvalid"
                          ? strs.emailInvalidAlert
                          : alertVisible === "passwordRuleAlert"
                          ? strs.passwordRuleAlert
                          : alertVisible === "userAlreadyExisitingAlert"
                          ? strs.userAlreadyExisitingAlert
                          : alertVisible === "tokenExpiredAlert"
                          ? strs.tokenExpiredAlert
                          : ""}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="alertMessageWrapper__CloseIcon" onClick={() => setAlertVisible(null)}>
                  <IconClose width="14px" height="14px" />
                </div>
              </div>

              <div className="alertMessageWrapper--title">
                {alertVisible === "permissionError" ? strs.permissionTitle1 : null}
              </div>
              <div className="alertMessageWrapper--body">
                {alertVisible === "permissionError" ? (
                  <>
                    {strs.permissionTitle2}
                    <span>{strs.permissionTitle3}</span>
                    {strs.permissionTitle4}
                    <span> {strs.permissionTitle5}</span>
                    {strs.permissionTitle6}
                  </>
                ) : alertVisible === "icloudAlert" || alertVisible === "yahooAlert" ? (
                  <>
                    {strs.authComingSoonDes({
                      auth: alertVisible === "icloudAlert" ? "iCloud" : "Yahoo",
                    })}
                  </>
                ) : null}
              </div>

              {alertVisible === "permissionError" ? (
                <div className="alertMessageWrapper--header alertMessageWrapper--footer">
                  <div
                    className="pointer"
                    onClick={() => {
                      setAlertVisible(null);
                      setIsSecurityModalVisible(true);
                    }}
                  >
                    {strs.learnMore}
                  </div>

                  <AuthButton>
                    <div style={{ display: "flex" }} className="pointer">
                      <p className="alertMessageWrapper--footer--text">{strs.tryAgain}</p>
                      <IconArrowRight color="black" />
                    </div>
                  </AuthButton>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {emailAccess ? (
          <>
            <div className="onboardingWrapper__emailAcess">
              <div className="onboardingWrapper__emailAcess--title">{strs.emailAccessTitle}</div>

              <div className="onboardingWrapper__emailAcess--subtitle">
                <ICON_CHECK_CIRCLE />
                {strs.emailAccessPoint1}
              </div>
              <div className="onboardingWrapper__emailAcess--subtitle">
                <ICON_CHECK_CIRCLE />
                {strs.emailAccessPoint2}
              </div>
              <div className="onboardingWrapper__emailAcess--subtitle">
                <ICON_CHECK_CIRCLE />
                {strs.emailAccessPoint3}
              </div>
            </div>

            <div className="onboardingWrapper__emailAcess__bottom__container">
              <Label align="center" color="#ffffff" size="xs">
                {strs.emailAccesssubtitle1} <span className="ViewSignup__agreements__text">{strs.only}</span>{" "}
                {strs.emailAccesssubtitle2}
              </Label>
              <AuthButton label={strs.continue + " Google"} provider={"google"} icon={"google"} fixedWidth={170} />
              <AuthButton label={strs.continue + " Outlook"} provider={"outlook"} icon={"outlook"} fixedWidth={170} />
              <Button
                type="transparent"
                label={strs.continue + " iCloud"}
                onClick={() => {
                  posthog.capture("connect_email_screen_icloud_click", {
                    device_id: tjommiDeviceID,
                    user_id: user.id,
                    user_name: user.full_name,
                    user_email: user.email,
                    user_region: user.region_id,
                  });
                  setAlertVisible("icloudAlert");
                }}
                icon={"icloud"}
                fixedWidth={170}
              />
              <Button
                type="transparent"
                label={strs.continue + " Yahoo"}
                onClick={() => {
                  posthog.capture("connect_email_screen_yahoo_click", {
                    device_id: tjommiDeviceID,
                    user_id: user.id,
                    user_name: user.full_name,
                    user_email: user.email,
                    user_region: user.region_id,
                  });
                  setAlertVisible("yahooAlert");
                }}
                icon={"yahoo"}
                fixedWidth={170}
              />
              <div>
                <Label align="center" color="#ffffff" size="xs">
                  {strs.emailAccessInfo1}{" "}
                  <span
                    className="ViewSignup__agreements__text"
                    onClick={() => {
                      posthog.capture("connect_email_screen_TC_click", {
                        device_id: tjommiDeviceID,
                        user_id: user.id,
                        user_name: user.full_name,
                        user_email: user.email,
                        user_region: user.region_id,
                      });
                      history.push(routes.terms.path + "?redirect=" + routes.onboarding.path);
                    }}
                  >
                    {strs.terms}
                  </span>
                  {strs.emailAccessInfo2}
                </Label>
              </div>
            </div>
          </>
        ) : (
          <>
            {!bottomSheetVisible ? <div className="headerWrapper--text">{strs.title}</div> : null}

            {!user && !bottomSheetVisible ? (
              <div className="buttonsWrapper">
                <div className="buttonsWrapper__buttons">
                  {
                    <>
                      <Button type="default" label={strs.signup} onClick={() => signUpMAndLoginModalOpen(true)} />
                      <Button type="transparent" label={strs.login} onClick={() => signUpMAndLoginModalOpen(false)} />
                    </>
                  }
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>

      <BottomSheet
        backgroundColor={step === 0 || step === 1 || step === 2 || step === 4 ? "#e86f92" : "#f6f6f6"}
        active={bottomSheetVisible}
        setActive={() => setBottomSheetVisible(false)}
        fullHeight={step === 3 || step === 5 ? true : false}
        header={
          step === 0
            ? strs.signup
            : step === 1
            ? strs.login
            : step === 2
            ? strs.forgotPassword
            : step === 4
            ? strs.passwordRest
            : ""
        }
      >
        {step === 0 ? (
          <div className="onboardingWrapper__signUpandLoginBtn__Wrapper">
            <ViewSignup
              onSignupHandler={(email, name, password) => {
                userRegister(email, name, password).then((res) => {
                  if (res) {
                    setAlertVisible("userAlreadyExisitingAlert");
                  }
                });
              }}
              setAlertMsg={(alertMsg) => {
                setAlertVisible(alertMsg);
              }}
              tjommiDeviceID={tjommiDeviceID}
            />
          </div>
        ) : step === 1 ? (
          <div className="onboardingWrapper__signUpandLoginBtn__Wrapper">
            <ViewSignin
              onSignInHandler={async (email, password) => {
                await userLogin(email, password).then((res) => {
                  if (res && res.message) {
                    setAlertVisible("wrongPassword");
                  }
                });
              }}
              forgotPasswordHandler={() => {
                setStep(2);
              }}
              tjommiDeviceID={tjommiDeviceID}
            />
          </div>
        ) : step === 2 ? (
          <div className="onboardingWrapper__signUpandLoginBtn__Wrapper">
            <ViewForgotPassword
              onforgotPasswordHandler={(email) => {
                userPasswordReset(email)
                  .then((res) => {
                    setBottomSheetVisible(false);
                    if (res === false) {
                      setAlertVisible("passwordResetWrongAlert");
                    } else {
                      setAlertVisible("passwordResetSuccessAlert");
                    }
                  })
                  .catch((error) => {});
              }}
              tjommiDeviceID={tjommiDeviceID}
            />
          </div>
        ) : step === 3 ? (
          <ViewProfileNotification
            tjommiDeviceID={tjommiDeviceID}
            onConfirmHandler={async () => {
              posthog.capture("communication_opt_in_screen_confirm_click", {
                device_id: tjommiDeviceID,
                user_id: user.id,
                user_name: user.full_name,
                user_email: user.email,
                user_region: user.region_id,
              });

              if (NativeAppClient.isInNativeApp()) {
                await NativeAppClient.requestPushToken();

                await updateMyUser({
                  enable_push_notifications: true,
                  enable_email_notifications: true,
                });

                setBottomSheetVisible(false);
              } else {
                await updateMyUser({
                  enable_push_notifications: false,
                  enable_email_notifications: true,
                });
                setBottomSheetVisible(false);
              }
            }}
            onCancelHandler={async () => {
              await updateMyUser({
                enable_push_notifications: false,
                enable_email_notifications: true,
              });
              posthog.capture("communication_opt_in_screen_reject_click", {
                device_id: tjommiDeviceID,
                user_id: user.id,
                user_name: user.full_name,
                user_email: user.email,
                user_region: user.region_id,
              });
              setBottomSheetVisible(false);
            }}
          />
        ) : step === 4 ? (
          <div className="onboardingWrapper__signUpandLoginBtn__Wrapper">
            <ViewPasswordReset
              passwordResetHandler={(password) => {
                userPasswordChange(password, query.token)
                  .then((res) => {
                    if (res) {
                      setBottomSheetVisible(false);
                      // TODO(23 Jun 2023) ~ Helge: LOGIN THE USER WHEN THEY CHANGE THEIR PASSWORD!
                    } else {
                      setAlertVisible("tokenExpiredAlert");
                    }
                  })
                  .catch((error) => {});
              }}
              tjommiDeviceID={tjommiDeviceID}
            />
          </div>
        ) : step === 5 ? (
          <ViewInterests />
        ) : null}
      </BottomSheet>
      <DataSecurityModal
        closeBtn={() => setIsSecurityModalVisible(false)}
        visible={isSecurityModalVisible}
        children={
          <>
            <Button type="default" label={strs.signup} onClick={() => signUpMAndLoginModalOpen(strs.signupTitle)} />
            <Button type="transparent" label={strs.login} onClick={() => signUpMAndLoginModalOpen(strs.loginTitle)} />
          </>
        }
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    language: selectorLanguage(state),
    screen: parseInt(ownProps.match.params.screen) || null,
    strs: selectorLanguageStrings(state, "onboarding"),
    user: selectorMyUser(state),
    tjommiDeviceID: selectorTjommiDeviceID(state),
    giftCards: selectorGiftCardsArray(state),
  };
};

const mapDispatchToProps = {
  userRegister,
  userLogin,
  userPasswordReset,
  userPasswordChange,
  updateMyUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewOnboarding));
