import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../inputs/button";
import GenericModal from "../../genericModal";
import IMAGE from "./assets/connectEmailProfileModal.png";
import { getConnectEmailUrl } from "../../inputs/buttonSigninWith";
import routes from "../../../utils/routes";
import { ControlsLayout } from "../../layouts/controls";

const ConnectEmailProfileModal = ({ visible, closeBtn, strs }) => {
  const handleConnectClick = (provider) => () => {
    window.location = getConnectEmailUrl(provider, routes.profileConfirmation.subroutes.emailConnected.path);
  };

  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn}
      backgroundImage={IMAGE}
      backgroundColor={"#896EEB"}
      title={strs.connectModal.title}
      text={
        <div>
          <div className="textItem">
            <h1 className="textItem__numberStyle">1</h1>
            <div className="textItem__content">{strs.connectModal.text1}</div>
          </div>
          <div className="textItem">
            <h1 className="textItem__numberStyle">2</h1>
            <div className="textItem__content">{strs.connectModal.text2}</div>
          </div>
          <div className="textItem">
            <h1 className="textItem__numberStyle">3</h1>
            <div className="textItem__content">{strs.connectModal.text3}</div>
          </div>
        </div>
      }
      button={
        <ControlsLayout variant={"row"}>
          <Button icon={"google"} type="transparent" label="Gmail" onClick={handleConnectClick("google")} />
          <Button icon={"outlook"} type="transparent" label="Outlook" onClick={handleConnectClick("gmail")} />
        </ControlsLayout>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "overview"),
});

export default connect(mapStateToProps)(ConnectEmailProfileModal);
