import React from "react";
import Star from "../ratingStars";

const RatingItem = (props) => {
  return (
    <div className="row">
      {props.valueList &&
        props.valueList.map((item, index) => {
          return (
            <div key={index} className="row__item">
              <div className="row__item--icon">
                <img src={item.src} alt="Tjommi" draggable={item.draggable} />
              </div>
              <div className="row__item--content">
                <p>{item.title}</p>
                <Star value={item.value} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default RatingItem;
