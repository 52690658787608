import React from "react";
import StoreIcon from "./assets/storeIcon";
import IconLogo from "../../components/icons/tjommiT";
import Trustpilot from "./assets/trustpilot.png";
import { selectorLanguageStrings, selectorTjommiDeviceID, selectorMyUser } from "../../state/selectors";
import "./styles.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getExpireDaysAndHours, createQueryString } from "../../utils/formatter";
import getCurrencySymbol from "../../state/utils/getCurrencySymbol";
import { findNotExpiredObject } from "../../state/utils/isDateExpired";
import classNames from "classnames";
import { claimVoucher, setToastMessage } from "../../state/actionCreators";
import NativeAppClient from "../../services/nativeAppClient";
import useClipboard from "react-hook-clipboard";
import { usePostHog } from "posthog-js/react";
import ApiClient from "../../services/apiClient";

const DiscoverCard = ({
  discover,
  strs,
  horizontal = false,
  claimVoucher,
  setToastMessage,
  source,
  tjommiDeviceID,
  user,
}) => {
  const posthog = usePostHog();
  const [, copyToClipboard] = useClipboard({ updateFrequency: 1000 }, () => {});

  const giftCard = discover?.giftcards?.length > 0 ? findNotExpiredObject(discover?.giftcards) : null;

  return (
    <div
      className={classNames({
        "discovery-card": true,
        "discovery-card--full": horizontal,
      })}
    >
      <div className="discovery-card_cover">
        {giftCard && (
          <div className="discovery-card_cover--amount">
            {giftCard?.amount}
            {getCurrencySymbol(giftCard?.currency)}
          </div>
        )}
        {giftCard === false && discover?.giftcards?.length > 0 && (
          <div className="discovery-card_cover--amount">
            {discover?.giftcards?.[0]?.amount}
            {getCurrencySymbol(discover?.giftcards?.[0]?.currency)}
          </div>
        )}
        <div
          className={classNames({
            "discovery-card_cover--image__wrapper": true,
            "discovery-card_cover--image__wrapper--full": horizontal,
          })}
          onClick={() => {
            if (giftCard && !giftCard?.claimed_at) {
              return null;
            } else {
              const url = createQueryString(discover.host, source, giftCard?.type || "discover", discover.name);
              ApiClient.logMerchantClick(discover?.id);

              NativeAppClient.isInNativeApp() ? NativeAppClient.openWebBrowser(url) : window.open(url, "_blank");

              if (giftCard === null) {
                posthog?.capture("merchant_screen_click_no_giftcode", {
                  device_id: tjommiDeviceID,
                  user_id: user.id,
                  user_name: user.full_name,
                  user_email: user.email,
                  user_region: user.region_id,
                  merchant_name: discover?.name,
                  affiliate: false,
                });
              } else if (giftCard === false) {
                posthog?.capture("merchant_screen_click_expired", {
                  device_id: tjommiDeviceID,
                  user_id: user.id,
                  user_name: user.full_name,
                  user_email: user.email,
                  user_region: user.region_id,
                  merchant_name: discover?.name,
                });
              } else if (giftCard?.claimed_at) {
                posthog?.capture("merchant_screen_click_redeem", {
                  device_id: tjommiDeviceID,
                  user_id: user.id,
                  user_name: user.full_name,
                  user_email: user.email,
                  user_region: user.region_id,
                  merchant_name: discover?.name,
                  giftcode_type: giftCard?.type,
                });
              }
            }
          }}
        >
          {discover?.shop_cover_image_url || discover?.logo ? (
            <img
              src={discover?.shop_cover_image_url || discover?.logo}
              alt="Logo"
              className={"discovery-card_cover--image"}
            />
          ) : (
            <StoreIcon width={130} height={136} />
          )}
        </div>

        <div
          className={classNames({
            "discovery-card_cover__subcontent": true,
            "discovery-card_cover__subcontent--nopadding": giftCard === false ? true : false,
          })}
        >
          {giftCard ? (
            <>
              {giftCard?.claimed_at ? (
                <div className="discovery-card__codeWrapper">
                  <div
                    className="discovery-card__codeWrapper--content"
                    onClick={() => {
                      NativeAppClient.isInNativeApp()
                        ? NativeAppClient.copyToClipboard(giftCard?.code)
                        : copyToClipboard(giftCard?.code);
                      setToastMessage(strs.copySuccess, 1500);
                    }}
                  >
                    <div className="discovery-card__codeWrapper--code">{giftCard?.code}</div>
                    <div className="discovery-card__codeWrapper--copy">{strs.copyCode}</div>
                  </div>
                  <div className="discovery-card__codeWrapper__expiresWrapper">
                    <div>
                      <div className="discovery-card--subtext discovery-card--subtext--small">{strs.expire}</div>
                      <div className="discovery-card--subtext discovery-card--subtext--small discovery-card--subtext--bold">
                        {getExpireDaysAndHours(giftCard?.expires_at, strs.hours)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="discovery-card__claimCard"
                  onClick={() => {
                    claimVoucher(discover.id, giftCard.id);
                    posthog?.capture("merchant_screen_click_activate", {
                      device_id: tjommiDeviceID,
                      user_id: user.id,
                      user_name: user.full_name,
                      user_email: user.email,
                      user_region: user.region_id,
                      giftcode_type: giftCard?.type,
                      merchant_name: discover?.name,
                    });
                  }}
                >
                  <div className="discovery-card__claimCard--label">{strs.claimCard}</div>
                </div>
              )}
            </>
          ) : giftCard === false ? (
            <div className="discovery-card--subtext">{strs.expired}</div>
          ) : null}

          <div className="discovery-card_cover__subcontent--ratings__wrapper">
            <div className="discovery-card_cover__subcontent--ratings__wrapper--tjommi_score">
              <IconLogo />
              <div className="discovery-card--subtext">{discover?.tjommi_score}/5</div>
            </div>

            <div className="discovery-card_cover__subcontent--ratings__wrapper--trustpilot">
              <img
                src={Trustpilot}
                alt="Logo"
                className="discovery-card_cover__subcontent--ratings__wrapper--trustpilot--image"
              />
              <span className="discovery-card--subtext discovery-card--subtext--bold">
                &nbsp;{discover?.trustpilot_score}
              </span>
              <span className="discovery-card--subtext">{strs.outOf}&nbsp;5</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "discoveries"),
  tjommiDeviceID: selectorTjommiDeviceID(state),
  user: selectorMyUser(state),
});

const mapDispatchToProps = {
  claimVoucher,
  setToastMessage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscoverCard));
