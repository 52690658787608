import React from "react";
import { APP_STORE_URL, CHROME_EXTENSION_URL, PLAY_STORE_URL } from "../../utils/configs";
import AppStore from "./appStore";
import PlayStore from "./playStore";
import ChromeExtention from "./chromeExtension";
import AppStoreOutline from "./appStoreOutline";
import PlayStoreOutline from "./playStoreOutline";
import AppStoreOutlineLight from "./appStoreOutlineLight";
import PlayStoreOutlineLight from "./playStoreOutlineLight";
import ChromeExtentionOutlineLight from "./chromeExtentionOutlineLight";

import classnames from "classnames";
import "./styles.scss";

const AppStoreBanners = (props) => (
  <div
    className={classnames({
      "new__appStoreBanners": true,
      "new__appStoreBanners--aligned": props.aligned,
    })}
  >
    <a target="_blank" rel="noreferrer" href={APP_STORE_URL}>
      {props.outline ? (
        <AppStoreOutline
          className={classnames({
            new__appStoreBanners__appStore: true,
            new__appStoreBanners__appStore__big: props.big,
          })}
        />
      ) : props.outlineLight ? (
        <AppStoreOutlineLight
          className={classnames({
            new__appStoreBanners__appStore: true,
            new__appStoreBanners__appStore__big: props.big,
          })}
        />
      ) : (
        <AppStore
          className={classnames({
            new__appStoreBanners__appStore: true,
            new__appStoreBanners__appStore__big: props.big,
          })}
        />
      )}
    </a>
    <a target="_blank" rel="noreferrer" href={PLAY_STORE_URL}>
      {props.outline ? (
        <PlayStoreOutline
          className={classnames({
            new__appStoreBanners__playStore: true,
            new__appStoreBanners__playStore__big: props.big,
          })}
        />
      ) : props.outlineLight ? (
        <PlayStoreOutlineLight
          className={classnames({
            new__appStoreBanners__playStore: true,
            new__appStoreBanners__playStore__big: props.big,
          })}
        />
      ) : (
        <PlayStore
          className={classnames({
            new__appStoreBanners__playStore: true,
            new__appStoreBanners__playStore__big: props.big,
          })}
        />
      )}
    </a>
    <a target="_blank" rel="noreferrer" href={CHROME_EXTENSION_URL}>
      {props.outline ? null : props.outlineLight ? (
        <ChromeExtentionOutlineLight
          className={classnames({
            new__appStoreBanners__chromeExtention: true,
            new__appStoreBanners__chromeExtention__big: props.big,
          })}
        />
      ) : (
        <ChromeExtention
          className={classnames({
            new__appStoreBanners__chromeExtention: true,
            new__appStoreBanners__chromeExtention__big: props.big,
          })}
        />
      )}
    </a>
  </div>
);

export default AppStoreBanners;
