export const rescue = (callback, fallback = null) => {
  try {
    return callback();
  } catch (error) {
    return fallback;
  }
};

export const prettyProvider = (provider) => {
  if (provider === "icloud") return "iCloud";
  if (provider === "yahoo") return "Yahoo";
  if (provider === "outlook") return "Outloook";
  if (provider === "gmail") return "Gmail";
  if (provider === "google") return "Gmail";
};
