const WEB_URL =
  (process.env.REACT_APP_VERCEL_URL ? `https://${process.env.REACT_APP_VERCEL_URL}` : null) ||
  process.env.REACT_APP_WEB_URL;

if (WEB_URL.charAt(WEB_URL.length - 1) === "/") throw new Error(`REACT_APP_WEB_URL must not end in a slash /`);

module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  IS_PRODUCTION: process.env.REACT_APP_ENV === "production",
  API_URL: process.env.REACT_APP_API_URL,
  WEB_URL,
  WEB_REDIRECT_URL: process.env.REACT_APP_WEB_REDIRECT_URL,
  APP_URL: process.env.REACT_APP_APP_URL,
  LOCAL_STORAGE_KEY: process.env.REACT_APP_LOCAL_STORAGE_KEY,
  GOOGLE_MEASUREMENT_ID: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
  GOOGLE_ADS_ID: process.env.REACT_APP_GOOGLE_ADS_ID,
  GOOGLE_AD_ID: process.env.REACT_APP_GOOGLE_AD_ID,
  HOTJAR_HJSV: process.env.REACT_APP_HOTJAR_HJSV,
  HOTJAR_HJID: process.env.REACT_APP_HOTJAR_HJID,
  FACEBOOK_PIXEL_ID: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
  SNAPCHAT_PIXEL_ID: process.env.REACT_APP_SNAPCHAT_PIXEL_ID,
  TIKTOK_PIXEL_ID: process.env.REACT_APP_TIKTOK_PIXEL_ID,
  CUSTOMERIO_SITE_ID: process.env.REACT_APP_CUSTOMERIO_SITE_ID,
  IS_PRERENDER_CONTEXT: navigator.userAgent === "PRERENDER_CONTEXT",
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  IS_STRIPE_PUBLISHABLE_KEY_LIVE: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY.includes("live"),
  TEST_USER_PHONE: process.env.REACT_APP_TEST_USER_PHONE,
  BUGSNAG_KEY: process.env.REACT_APP_BUGSNAG_KEY,
  APP_STORE_URL: "https://apps.apple.com/dk/app/tjommi/id1551135091",
  PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=app.tjommi.peace.app",
  CHROME_EXTENSION_URL: "https://chrome.google.com/webstore/detail/tjommi/lobihiomioflmmkpncddcmgkojoepblk?hl=en",
  POSTHOG_KEY: process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  POSTHOG_HOST: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  // Dev flags
  USE_MOCK_NOTIFICATIONS: JSON.parse(process.env.REACT_APP_MOCK_NOTIFICATIONS),

  // Social Links
  INSTAGRAM_URL: "https://www.instagram.com/tjommi/",
  FACEBOOK_URL: "https://www.facebook.com/DinTjommi/",
  LINKEDIN_URL: "https://www.linkedin.com/company/tjommi/",
  TWITTER_URL: "https://twitter.com/tjommiapp",
  YOUTUBE_URL: "https://www.linkedin.com/company/tjommi/",
  SPOTIFY_URL: "https://www.linkedin.com/company/tjommi/",
  MEDIUM_URL: "https://www.linkedin.com/company/tjommi/",
  TIKTOK_URL: "https://www.tiktok.com/@tjommi.app",

  // APP LINK
  GET_APP_URL: "https://tjommi.onelink.me/U7SB/getappbutton",
};
