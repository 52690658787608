import React from "react";
import "./styles.scss";

let currentScreenIndex = 0;

// slide to a screen wrapped by ScreensLayout and currently mounted in dom
export const slideToScreen = (nextScreenIndex) => {
  // direction
  if (currentScreenIndex === nextScreenIndex) return;
  const slide = currentScreenIndex - nextScreenIndex < 0 ? slideRight : slideLeft;

  const screensLayoutElm = document.getElementById("screenTransitionLayouts");

  // when sliding to a screen thats more than one screen away in either direction
  const slideScreens = Math.abs(currentScreenIndex - nextScreenIndex);
  if (slideScreens > 1) {
    // move inbetween screens to littleLeft position
    if (slide === slideRight) {
      for (let i = currentScreenIndex; i < nextScreenIndex; i++) {
        screensLayoutElm.childNodes[i].className = "screenTransitionLayout littleLeft";
      }
    } else {
      // move inbetween screens to right position
      for (let i = currentScreenIndex - 1; i > nextScreenIndex; i--) {
        screensLayoutElm.childNodes[i].className = "screenTransitionLayout right";
      }
    }
  }

  // commit change
  const currentScreen = screensLayoutElm.childNodes[currentScreenIndex];
  const nextScreen = screensLayoutElm.childNodes[nextScreenIndex];
  slide(currentScreen, nextScreen);
  currentScreen.classList.add("visible");
  nextScreen.classList.add("visible");
  currentScreenIndex = nextScreenIndex;
};

const slideRight = (currentScreen, nextScreen) => {
  currentScreen.className = "screenTransitionLayout littleLeft slow";
  nextScreen.className = "screenTransitionLayout fast";
};

const slideLeft = (currentScreen, nextScreen) => {
  currentScreen.className = "screenTransitionLayout right fast";
  nextScreen.className = "screenTransitionLayout fast";
};

// wraps multiple ScreensLayouts
export const ScreenTransitionLayout = ({ component: Component, ...rest }) => (
  <div className="screenTransitionLayout right">{<Component {...rest} />}</div>
);

// adds screen transition when wrapped by screenTransitionLayout
export class ScreenTransitionLayouts extends React.Component {
  componentDidMount() {
    // adjust the first screen to center by overwritting the right class name that screens has by default
    document.getElementById("screenTransitionLayouts").childNodes[0].className = "screenTransitionLayout visible";
    currentScreenIndex = 0;
  }

  getScreens() {
    return document.getElementById("screenTransitionLayouts").childNodes;
  }

  render() {
    return (
      <div className="screenTransitionLayouts" id="screenTransitionLayouts">
        {this.props.children}
      </div>
    );
  }
}
