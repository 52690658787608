import React from "react";
import MainMenu from "../../components/menus/mainMenu";
import AppStoreBanners from "../../components/appStoreBanners";
import HERO from "./assets/tjommi-hero.png";
import GIFTCARD from "./assets/giftcard_noti.png";
import PRIVACY from "./assets/privacy.png";
import APP_VIDEO from "./assets/App2.mp4";
import ICON_ARROW_CIRCLE_LEFT from "./assets/arrow_circle_right.png";
import ICON_PRIVACY_KEY from "./assets/privacy-key.png";
import ICON_LOGO from "../../components/icons/logoBadge";
import FRAME_IMAGE from "./assets/frame.png";
import ICON_HOTEL_CLASS from "../../components/icons/hotelCalss";
import ICON_TRUSTPILOT from "./assets/rating/trustpilotIcon.svg";
import ICON_FACEBOOK from "./assets/rating/facebookIcon.svg";
import ICON_APPSTORE from "./assets/rating/appStoreIcon.svg";
import ICON_PLAYSTORE from "./assets/rating/googlePlayIcon.svg";
import ICON_KEY from "./assets/key.png";
import Button from "../../components/inputs/newButton";
import SessionCard from "../../components/sectionCard";
import CARD1_IMG from "./assets/card1.png";
import CARD2_IMG from "./assets/card2.png";
import CARD3_IMG from "./assets/card3.png";
import CARD4_IMG from "./assets/card4.png";
import Footer from "../../components/layouts/footer";
import SectionText from "../../components/newSectionText";
import Hide from "../../components/hide/hide";
import FlipCards from "../../components/flipscards";
import RatingItem from "../../components/ratingItem";
import { withRouter } from "react-router-dom";
import routes from "../../utils/routes";
import { CHROME_EXTENSION_URL, GET_APP_URL } from "../../utils/configs";
import { selectorIsAppMode } from "../../state/selectors";
import { connect } from "react-redux";
import "./styles.scss";

class ViewNewLandingPage extends React.Component {
  state = {
    menuDark: false,
    CardFlip: null,
  };

  componentDidMount() {
    if (this.props.isAppMode) {
      this.props.history.push(routes.onboarding.path);
    }
  }

  render() {
    const flipcards = [
      {
        title1: "Never pay more than you need to with Tjommi",
        title2: "Never pay more than you need with Tjommi",
        body1: CARD1_IMG,
        body2:
          "Set your price checking on auto-pilot with Tjommi. We’ve made the whole process automated, so you don't need to every worry about getting the best price",
        btnLabel1: "Get the app",
        btnLabel2: "Get a refund",
        backGroundColor: "#FA779D",
        label: "Refunds",
        firstbtnOnclick: () => window.open(GET_APP_URL),
        secondbtnOnclick: () => window.open(GET_APP_URL),
      },
      {
        title1: "Shop Smarter with our Trusted Tjommi Stores",
        title2: "Get more out of your shopping with the Tjommi Score",
        btnLabel1: "Get the app",
        btnLabel2: "Add Chrome extension",
        backGroundColor: "#CBC3B9",
        label: "Tjommi score",
        body1: CARD2_IMG,
        body2:
          "No more worrying if you can trust this store if the price drops after the purchase. Tjommi gives you the insight, so that you never have not overpay ever again.",
        firstbtnOnclick: () => {
          window.open(GET_APP_URL);
        },
        secondbtnOnclick: () => {
          window.open(CHROME_EXTENSION_URL);
        },
      },
      {
        title1: "All your Receipts in one place",
        title2: "All your Receipts in one place",
        btnLabel1: "See how it works",
        btnLabel2: "See how easy it can be",
        backGroundColor: "#836AFF",
        label: "Receipts",
        body1: CARD3_IMG,
        body2: "Get a complete overview of all your receipts in one app. No more digging around in your email inbox",
        firstbtnOnclick: () => {
          this.props.history.push(routes.howitworks.path);
        },
        secondbtnOnclick: () => {
          this.props.history.push(routes.howitworks.path);
        },
      },
      {
        title1: "Apply your Vouchers with our Chrome extension",
        title2: "All your Vouchers and easy access with Tjommi chrome extension",
        btnLabel1: "Add Chrome extension",
        btnLabel2: "Add Chrome extension",
        backGroundColor: "#4ADAB0",
        label: "Vouchers",
        body1: CARD4_IMG,
        body2:
          "Be in control of all your vouchers, and never miss a expired date. Tjommi gives you a complete overview, and make it easy to use with Chrome Extension.",
        firstbtnOnclick: () => {
          window.open(CHROME_EXTENSION_URL);
        },
        secondbtnOnclick: () => {
          window.open(CHROME_EXTENSION_URL);
        },
      },
    ];
    return (
      <div className="homeLandingPage">
        <MainMenu />

        <div>
          <div className="homeLandingPage_hero_image_wrapper">
            <div className="homeLandingPage_hero_image_wrapper_content">
              <Hide on="mobile">
                <div className="homeLandingPage_hero_image_wrapper_content-notification">
                  <div className="homeLandingPage_hero_image_wrapper_content-notification__header">
                    <div className="homeLandingPage_hero_image_wrapper_content-notification__header--cover">
                      <ICON_LOGO />
                      <span className="homeLandingPage_hero_image_wrapper_content-notification__header--text">
                        Tjommi
                      </span>
                    </div>
                    <div className="homeLandingPage_hero_image_wrapper_content-notification__header--text">now</div>
                  </div>
                  <div className="homeLandingPage_hero_image_wrapper_content-notification__body">
                    <div className="homeLandingPage_hero_image_wrapper_content-notification__body__title">
                      Refund accepted
                    </div>
                    <div className="homeLandingPage_hero_image_wrapper_content-notification__body__title--sub">
                      Zalando accepted the claim, and the refund is on its way to you 🤝
                    </div>
                  </div>
                </div>
              </Hide>
              <div className="homeLandingPage_hero_image_title">Price drop?</div>
              <div className="homeLandingPage_hero_image_title">Get a refund</div>

              <AppStoreBanners outlineLight={true} aligned />
            </div>

            <img src={HERO} alt="Tjommi" draggable={false} className="homeLandingPage_hero_image" />
          </div>

          {/* SECTION (1) */}

          <div className="homeLandingPage_section__bestPrice">
            <div className="homeLandingPage_section_content flex-column">
              <div className="homeLandingPage_section_content_btn_label_wrapper">
                <Button type="label" label="Refund" />
              </div>
              <div className="homeLandingPage_section_content_title_lg">Always get the best price with Tjommi</div>

              <div className="homeLandingPage_section_content_description homeLandingPage_section_content_description_best_price">
                We’ll get you a partial refund or a voucher automatically, if something you’ve bought drops in price.
              </div>

              <div className="homeLandingPage_space">
                <Button label="Get Tjommi" onClick={() => window.open(GET_APP_URL)} />
              </div>
            </div>
          </div>

          {/*  SECTION (2) */}

          <div className="homeLandingPage_section">
            <div className="homeLandingPage_section_content homeLandingPage_section_content_flex-row homeLandingPage_alignItem_start homeLandingPage_section_content_video">
              <div className="homeLandingPage_section_2_col_1">
                <div className="homeLandingPage_video_section">
                  <div className="homeLandingPage_video_section--desktop">
                    <div className="homeLandingPage_video_section--desktop__mobile-wrapper">
                      <div className="homeLandingPage_video_section--wrapper">
                        <img src={FRAME_IMAGE} alt="" className="homeLandingPage_video_section--frame" />
                        <video loop autoPlay playsInline muted>
                          <source type="video/mp4" src={APP_VIDEO} />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="homeLandingPage_section_2_col_2">
                <div className="flex-column flex">
                  <div className="homeLandingPage_section_content_btn_label_wrapper homeLandingPage_section_content_btn_label_wrapper_shop">
                    <Button type="label" label="Refund" />
                  </div>

                  <div className="homeLandingPage_section_content_title_xl homeLandingPage_section_content_title_xl_shop">
                    Shop with <br />
                    <span className="homeLandingPage_text-pink">confidence</span>
                  </div>
                  <div
                    className="
                    homeLandingPage_section_content__confidence__description
                  homeLandingPage_section_content__confidence__description__wrapper"
                  >
                    10% of everything you buy drops in price after purchase. Using your receipts, we search for a better
                    price and try to get a refund on your behalf — so you can buy what you want, without hesitation.
                  </div>
                  <Hide on={"mobile"}>
                    <div className="homeLandingPage_section_content_description_small">
                      Download the Tjommi <br />
                      app and connect your <br />
                      email to get started.
                    </div>
                    <div className="homeLandingPage_button_wrapper">
                      <Button label="Download app" onClick={() => window.open(GET_APP_URL)} />
                    </div>
                  </Hide>
                </div>
              </div>
            </div>
          </div>

          {/*  SECTION (3) */}

          <div className="homeLandingPage_section">
            <div className="homeLandingPage_section_content_full flex-column">
              <div className="homeLandingPage_section_content_title_xl_space homeLandingPage_section_content_btn_label_wrapper_giftcard">
                <Button type="label" label="Voucher" />
              </div>
              <Hide on={"mobile"}>
                <div className="homeLandingPage_section_content_title_xl homeLandingPage_section_content_title_xl_space  homeLandingPage_section_content_title_xl_shop">
                  Easy access to your vouchers
                </div>
              </Hide>
              <div className="homeLandingPage_section_content_giftcard_main_wrapper">
                <div className="homeLandingPage_section_content_giftcard_col">
                  <div className="homeLandingPage_section_content_giftcard_img_wrapper">
                    <img
                      className="homeLandingPage_section_content_giftcard_img"
                      src={GIFTCARD}
                      alt="TjommiGiftCard"
                      draggable={false}
                    />
                    <div className="homeLandingPage_section_content_giftcard_img_underline"></div>
                  </div>
                </div>
                <div className="homeLandingPage_section_content_giftcard_col homeLandingPage_section_content_giftcard_col_textarea">
                  <Hide on={"desktop"}>
                    <div className="homeLandingPage_section_content_title_xl homeLandingPage_section_content_title_xl_space  homeLandingPage_section_content_title_xl_shop homeLandingPage_section_content_title_xl_giftcard">
                      Easy access to your vouchers
                    </div>
                  </Hide>
                  <div className="homeLandingPage_section_content_description homeLandingPage_section_content_description_space homeLandingPage_section_content_description_alignment homeLandingPage_section_content_description_alignment_giftcard">
                    Keep all of your vouchers in one place with the Tjommi Chrome extension, and always get the best
                    price.
                  </div>
                  <div className="homeLandingPage_button_wrapper">
                    <div className="homeLandingPage_button_small"></div>
                    <Button
                      label="Add to chrome"
                      onClick={() => {
                        window.open(CHROME_EXTENSION_URL);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="homeLandingPage_section__accordion">
                <hr className="FAQPage-section-content__accordion__hr" />

                <div className="FAQPage-section-content__accordion-wrapper">
                  <div>
                    <Hide on="desktop">
                      <ICON_HOTEL_CLASS width="25" height="27" />
                    </Hide>
                  </div>
                  <div className="FAQPage-section-content__accordion__des">
                    <div className="FAQPage-section-content__accordion__des__title--medium">
                      The shoppers have spoken
                    </div>
                  </div>
                </div>

                <div className="homeLandingPage_section_reviews">
                  <div className="homeLandingPage_section_reviews__cover">
                    <RatingItem
                      valueList={[
                        {
                          src: ICON_TRUSTPILOT,
                          draggable: false,
                          value: 5,
                          title: "Trustpilot",
                        },
                        {
                          src: ICON_FACEBOOK,
                          draggable: false,
                          value: 5,
                          title: "Facebook",
                        },
                        {
                          src: ICON_APPSTORE,
                          draggable: false,
                          value: 5,
                          title: "Appstore",
                        },
                        {
                          src: ICON_PLAYSTORE,
                          draggable: false,
                          value: 5,
                          title: "Google Play",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SECTION (5) */}

          <div className="homeLandingPage_section__oneApp">
            <div className="homeLandingPage_section__oneApp__wrapper">
              <div className="homeLandingPage_section__oneApp__btn__wrapper">
                <Button type="label" label="Shopping essentials" />
              </div>
              <div className="homeLandingPage_section_content_title_xl homeLandingPage_section_content_title_xl_topSpace">
                One app, all your shopping needs
              </div>

              <div className="homeLandingPage_section_content_description homeLandingPage_section_content_description_topSpace">
                Tjommi means ‘buddy’ in Norwegian. From refunds and vouchers, to all receipt info in one place, we’ve
                got your back.
              </div>

              <div className="homeLandingPage_section_content_cardSet">
                <FlipCards cards={flipcards} fluid />
              </div>
            </div>
          </div>

          {/*  SECTION (6) */}

          <div className="homeLandingPage_section">
            <div className="homeLandingPage_section_content_full flex-column">
              <div className="homeLandingPage_section_content_privacy_description_cover">
                <div className="homeLandingPage_section_6">
                  <div className="homeLandingPage_section_privacy_key_wrapper">
                    <Hide on="desktop">
                      <div className="homeLandingPage_section_privacy_key_wrapper_img_cover">
                        <img
                          src={ICON_KEY}
                          alt="TjommiKey"
                          draggable={false}
                          className="homeLandingPage_section_privacy_key_wrapper_img"
                        />
                      </div>
                    </Hide>
                    <div className="homeLandingPage_section_content_title_xl homeLandingPage_section_content_title_xl_space homeLandingPage_section_content_title_xl_privacy">
                      Privacy
                      <br />
                      matters
                    </div>
                  </div>

                  <div className="homeLandingPage_section_content_privacy_img_wrapper">
                    <img
                      src={PRIVACY}
                      alt="TjommiPrivacy"
                      draggable={false}
                      className="homeLandingPage_section_content_privacy_img"
                    />
                  </div>
                </div>
                <div className="homeLandingPage_section_6 homeLandingPage_section_content_privacy_wrapper">
                  <Hide on="mobile">
                    <div className="homeLandingPage_section_privacy_key_wrapper_img_cover">
                      <img
                        src={ICON_PRIVACY_KEY}
                        alt="TjommiKey"
                        draggable={false}
                        className="homeLandingPage_section_privacy_key_wrapper_img"
                      />
                    </div>
                  </Hide>
                  <div className="homeLandingPage_section_content_description homeLandingPage_section_content_description_privacy_space">
                    We’re careful with your data
                    <br />
                    <br />
                    Your data is secure with us. We only use our email access to fetch receipts and when claiming a
                    refund.
                    <br />
                    <br /> Need Proof? We also have a license from Google and Outlook to deliver this service, and of
                    course, we are fully GDPR compliant.
                  </div>
                  <Hide on="desktop">
                    <hr className="homeLandingPage_section_content_privacy__hr" />
                  </Hide>
                  <div
                    className="homeLandingPage_section_content_privacy_link"
                    onClick={() => this.props.history.push(routes.privacy.path)}
                  >
                    Learn more about privacy
                    <div className="homeLandingPage_left_arrow__wrapper">
                      <img
                        src={ICON_ARROW_CIRCLE_LEFT}
                        alt="ICON_ARROW_CIRCLE_LEFT"
                        draggable={false}
                        className="homeLandingPage_left_arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  SECTION (7) */}
          <div className="homeLandingPage_section">
            <div className="homeLandingPage_section_content homeLandingPage_section_content_cards_row">
              <div className="homeLandingPage_section_content_cards_row_content">
                <SessionCard newsSubscribeMode backgroundColor="#FFFFFF" />
              </div>
              <div className="homeLandingPage_section_content_cards_row_content">
                <SessionCard refundType backgroundColor="#222222" dark bottomContentPosition="end" />
              </div>
            </div>
          </div>

          <div className="homeLandingPage_section">
            <div className="homeLandingPage_section_appstoreBanner">
              <SectionText
                specialSub={" Such a beautiful word."}
                sub={"Refund."}
                title={"Loved by 200,000  shoppers."}
                appStoreBanners
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
});

export default withRouter(connect(mapStateToProps, null)(ViewNewLandingPage));
