const icon = (props) => (
  <svg
    width={props.width ?? 21}
    height={props.height ?? 18}
    {...props}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 4.40267H4.31438L6.53548 10.1285L8.78546 4.40267H12.499L6.90709 17.9577H3.16943L4.70023 14.3658L0.500118 4.40267H0.5ZM16.8235 8.97781H12.664L16.3558 0.0410156L20.5 0.0411942L16.8235 8.97781ZM13.7536 9.83314C15.0298 9.83314 16.0644 10.8756 16.0644 12.1614C16.0644 13.4473 15.0298 14.4898 13.7536 14.4898C12.4775 14.4898 11.4432 13.4473 11.4432 12.1614C11.4432 10.8756 12.4777 9.83314 13.7536 9.83314Z"
      fill="#5F01D1"
    />
  </svg>
);

export default icon;
