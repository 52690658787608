import moment from "moment";
import numeral from "numeral";
import "moment/locale/da";
import "moment/locale/nb";
import store from "../state/store";
import { LANGUAGES } from "./lang";

// TODO(24 aug. 2021) ~ Helge: Replace with a library
export const formatDate = (date, format = null) => {
  const language = store.getState().globalState.language;
  moment.locale(language);

  if (typeof date === "string") {
    const isUTC = date.match(/Z|\+/gi);
    // ensure UTC format
    if (!isUTC) date += ".000000Z";
    // convert UTC to local
    date = moment.utc(date).local().format();
  }

  let diff = moment().startOf("day").diff(moment(date).startOf("day"), "days");
  if (format == null) {
    if (diff === 0) {
      diff = moment().diff(moment(date), "minutes");
      if (diff < 5) format = "now";
      else if (diff > 50) format = "time";
      else format = "min";
    } else {
      if (diff < 15) format = "days";
      else {
        format = "date";
        diff = moment().diff(moment(date), "years");
        if (diff > 0) format = "date-year";
      }
    }
  }

  const diffInDays = Math.abs(diff);

  switch (format) {
    case "now":
      return "Now";
    case "time":
      return moment(date).format("HH:mm");
    case "min":
      return diff + "m";
    case "days":
      return diff + "d";

    case "days-left": {
      // xd left
      switch (language) {
        case LANGUAGES.DA:
          return `${diffInDays}d tilbage`;
        case LANGUAGES.NB:
          return `${diffInDays}d igjen`;
        case LANGUAGES.EN:
          return `${diffInDays}d left`;
        default: {
          console.warn(
            `days-left format not available for ${language}, check formatter.js and add it, defaulting to english`
          );
          return `${diffInDays}d left`;
        }
      }
    }

    case "expires": {
      // Expired
      if (diff >= 0) {
        switch (language) {
          case LANGUAGES.DA:
            return "Udløbet";
          case LANGUAGES.NB:
            return "Utløpt";
          case LANGUAGES.EN:
          default:
            return "Expired";
        }
      }

      // x days left
      switch (language) {
        case LANGUAGES.DA:
          return `${diffInDays} dager tilbage`;
        case LANGUAGES.NB:
          return `${diffInDays} dager igjen`;
        case LANGUAGES.EN:
        default:
          return `${diffInDays} days left`;
      }
    }

    case "date-scandinavian":
      return moment(date).format("DD.MM.YYYY");
    case "date-year":
      return moment(date).format("DD MMM YYYY");
    case "timestamp":
      return moment(date).format("DD/MM/YYYY H:mm");
    case "timestamp-long":
      switch (language) {
        case LANGUAGES.DA:
        case LANGUAGES.NB:
          return moment(date).format("dddd, DD MMMM [kl] H:mm.");
        case LANGUAGES.EN:
        default:
          return moment(date).format("dddd, DD MMMM [at] H:mm.");
      }

    default:
    case "date":
      return moment(date).format("DD MMM");
  }
};

export const currencyForRegion = (region) => {
  if (region === "NORWAY") return "nok";
  if (region === "DENMARK") return "dkk";
  if (region === "SWEDEN") return "sek";
  if (region === "UNITED_KINGDOM") return "gbp";
};

export const formatCurrency = (number, currency) => {
  const globalState = store.getState().globalState;

  // Space as the thousand separator, dot as decimal separator, show 2 decimals.
  // Example: 12345.67 -> 12 345.67
  const displayNumber = numeral(number).format("0,0.00").replace(",", " ");
  const assumedCurrency = currency?.toLowerCase() ?? currencyForRegion(globalState?.myUser?.region_code);

  // The currency "symbol" for NOK, DKK, SEK is "kr ", all lowercased.
  if (assumedCurrency === "nok") return "kr " + displayNumber;
  if (assumedCurrency === "dkk") return "kr " + displayNumber;
  if (assumedCurrency === "sek") return "kr " + displayNumber;

  // Note: No space between symbol and number
  if (assumedCurrency === "eur") return "€" + displayNumber;
  if (assumedCurrency === "gbp") return "£" + displayNumber;
  if (assumedCurrency === "usd") return "$" + displayNumber;

  // Fallback to plain number.
  return displayNumber;
};
export const getExpireDaysAndHours = (date, hrString) => {
  const language = store.getState().globalState.language;
  moment.locale(language);

  const now = moment();
  const targetDate = moment(date);

  const duration = moment.duration(targetDate.diff(now));

  if (duration.asMilliseconds() < 0) {
    return null;
  }

  const days = duration.days();
  const hours = duration.hours();

  const formattedResult = `${days} d ${hours} ${hrString}`;
  return formattedResult;
};

export const createQueryString = (host, utmSource, utmCampaign, utmContent) => {
  let mappedCampaign;

  switch (utmCampaign) {
    case "ca":
      mappedCampaign = "pricedrop_acquisition";
      break;
    case "lr":
      mappedCampaign = "pricedrop_retention";
      break;
    case "welcome":
      mappedCampaign = "welcome";
      break;
    default:
      mappedCampaign = utmCampaign; // Use the original value if no mapping is found
  }
  // Check if the host includes "http://" or "https://"
  if (!host.startsWith("http://") && !host.startsWith("https://")) {
    host = "https://" + host; // Add "https://" if missing
  }

  const queryString = `${host}?utm_medium=app&utm_source=${encodeURIComponent(
    utmSource
  )}&utm_campaign=${encodeURIComponent(mappedCampaign)}&utm_content=${encodeURIComponent(utmContent)}`;
  return queryString;
};
