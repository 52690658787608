import React, { useEffect, useState } from "react";
import routes from "../../utils/routes";
import NativeAppClient from "../../services/nativeAppClient";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import Outline from "../../components/layouts/outline";
import DetailLine from "../../components/detailLine";

const ViewDebug = () => {
  const [debugValues, setDebugValues] = useState({});

  useEffect(() => {
    NativeAppClient.requestDebugValues().then((values) => {
      setDebugValues(values);
    });
  }, []);

  return (
    <MainContentLayout center small>
      <HeaderLayout left={{ type: "arrowLeft", path: routes.profile.path }} />
      <h1>Debug</h1>
      {debugValues ? (
        <Outline label="App information">
          {Object.entries(debugValues).map(([key, value]) => (
            <DetailLine label={key} value={value.toString()} />
          ))}
        </Outline>
      ) : (
        <Outline label="Error">
          <div>Values could not be loaded...</div>
        </Outline>
      )}
    </MainContentLayout>
  );
};

export default ViewDebug;
