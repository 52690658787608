const StoreIcon = (props) => (
  <svg width={props.width ?? 21} height={props.height ?? 18.75} viewBox="0 0 22 19" fill="none" {...props}>
    <path
      d="M20 0H2C1.175 0 .5.675.5 1.5v12.75c0 .825.675 1.5 1.5 1.5h1.65l-2.1 1.65c-.3.225-.375.75-.15 1.05.15.225.375.3.6.3.15 0 .3-.075.45-.15l3.525-2.85h9.975l3.525 2.85c.15.15.3.15.45.15.225 0 .45-.075.6-.3.225-.3.225-.825-.15-1.05l-2.025-1.65H20c.825 0 1.5-.675 1.5-1.5V1.5C21.5.675 20.825 0 20 0zm0 14.25H2v-3.075c1.05-.15 1.725-.675 2.4-1.125.675-.45 1.275-.9 2.25-1.05 1.5-.225 2.55.3 3.675.975 1.125.6 2.475 1.35 4.275 1.35h.15c1.725-.075 3.525-.75 5.25-2.1v5.025zm0-7.05c-1.8 1.65-3.525 2.55-5.25 2.55-1.5.075-2.55-.525-3.675-1.125-1.35-.75-2.7-1.425-4.65-1.125-1.35.225-2.175.825-2.925 1.35-.525.375-.975.675-1.5.825V1.5h18v5.7z"
      fill="#EB6F93"
    />
  </svg>
);

export default StoreIcon;
