import React, { useState, useEffect } from "react";
import Outline from "../layouts/outline";
import Button from "../inputs/button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../state/selectors";
import Label from "../label";
import "./styles.scss";
import routes from "../../utils/routes";
import { usePostHog } from "posthog-js/react";
import { validateEmail } from "../../utils/validations";
import EyeOffIcon from "../../components/icons/eyeOff";
import EyeIcon from "../../components/icons/eye";

const ViewSignup = ({
  onSignupHandler,
  strs,
  history,
  tjommiDeviceID,
  setAlertMsg,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [nameValid, setNameValid] = useState(true);
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      posthog.capture("signup_screen_view", {
        device_id: tjommiDeviceID,
      });
    }
  }, [posthog]);

  const linkhandler = (path) => {
    history.push(path);
  };

  const checkAllFieldsRequired = () => {
    if (password.length < 8) {
      setPasswordValid(false);
      setAlertMsg("passwordRuleAlert");
    } else {
      setPasswordValid(true);
    }
    if (!validateEmail(email)) {
      setEmailValid(false);
      setAlertMsg("emailInvalid");
    } else {
      setEmailValid(true);
    }

    if (name.length < 4) {
      setNameValid(false);
    } else {
      setNameValid(true);
    }

    if (name.length >= 4 && password.length >= 8 && validateEmail(email)) {
      posthog.capture("signup_screen_signup_click", {
        device_id: tjommiDeviceID,
      });
      onSignupHandler(email, name, password);
    }
  };
  return (
    <>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 0,
        }}
        error={!emailValid}
        border={true}
        outlineAreaStyles={{
          padding: 14,
        }}
      >
        <input
          className="ViewSignup__input"
          name="Email"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={strs.email}
        />
      </Outline>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 17,
        }}
        error={!nameValid}
        border={true}
        outlineAreaStyles={{
          padding: 14,
        }}
      >
        <input
          className="ViewSignup__input"
          name="Name"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          placeholder={strs.name}
        />
      </Outline>
      <div className="ViewSignup__rules">
        <Label align="left" color="#ffffff" size="xs">
          {strs.rules}
        </Label>
      </div>
      <Outline
        error={!passwordValid}
        boxShadow={false}
        styles={{
          marginTop: 3,
        }}
        border={true}
        outlineAreaStyles={{
          flexDirection: "row",
          display: "flex",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
          padding: 14,
        }}
      >
        <input
          style={{
            width: "90%",
          }}
          className="ViewSignup__input"
          name="Password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          placeholder={strs.password}
        />
        <div
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="ViewSignup__passwordVisible"
        >
          <div className="ViewSignup__passwordVisible__wrapper">
            {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
          </div>
        </div>
      </Outline>
      <div className="ViewSignup__agreements">
        <Label align="left" color="#ffffff" size="xs">
          {strs.agreement}
          <span
            className="ViewSignup__agreements__text"
            onClick={() => {
              linkhandler(routes.terms.path);
              posthog.capture("signup_screen_TC_click", {
                device_id: tjommiDeviceID,
              });
            }}
          >
            {strs.terms}
          </span>
          {strs.and}
          <span
            className="ViewSignup__agreements__text"
            onClick={() => {
              linkhandler(routes.privacy.path);
              posthog.capture("signup_screen_PP_click", {
                device_id: tjommiDeviceID,
              });
            }}
          >
            {strs.privacy}
          </span>
        </Label>
      </div>

      <Button
        type="default"
        label={strs.title}
        onClick={() => {
          checkAllFieldsRequired();
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "signUp"),
  };
};

export default withRouter(connect(mapStateToProps, null)(ViewSignup));
