import "./hide.scss";
import className from "classnames";
import PropTypes from "prop-types";
import { selectorMyUser } from "../../state/selectors";
import { connect } from "react-redux";

const Hide = ({ myUser, on, nonAdmins = false, children }) => {
  if (
    nonAdmins === true &&
    myUser?.emailAccounts?.map((e) => e.email).some((email) => email.includes("tjommi.app")) === false
  ) {
    return null;
  }

  return (
    <div
      className={className("hide", {
        "hide--on-mobile": on === "mobile",
        "hide--on-desktop": on === "desktop",
      })}
    >
      {children}
    </div>
  );
};

Hide.propTypes = {
  on: PropTypes.oneOf(["mobile", "desktop"]).isRequired,
};

const mapStateToProps = (state) => ({
  myUser: selectorMyUser(state),
});

export default connect(mapStateToProps)(Hide);
