import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { selectorLanguage } from "../../state/selectors";
import "./styles.scss";

const FilterOptions = ({ topicList, onSelectTopic, selectedList, langCode, horizontal }) => {
  return (
    <div
      className={classNames({
        "FilterOptions--horizontal": horizontal,
        "FilterOptions": true,
      })}
    >
      {topicList &&
        topicList.map((ele) => {
          return (
            <div
              className={classNames({
                "FilterOptions__topic__wrapper__selected": selectedList.includes(ele.id),
                "FilterOptions__topic__wrapper": true,
                "FilterOptions__topic__wrapper--horizontalPadding": horizontal,
              })}
              key={ele.id}
              onClick={() => onSelectTopic(ele.id)}
            >
              {ele.title[langCode]}
            </div>
          );
        })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    langCode: selectorLanguage(state),
  };
};

export default connect(mapStateToProps, null)(FilterOptions);
