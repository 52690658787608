import React from "react";
import classNames from "classnames";
import "./styles.scss";

// TODO(26 okt. 2021) ~ Helge: when clicking the overlay, modal should close, no matter what.
const Modal = ({ children, visible, onClose }) => {
  return (
    <div className={classNames("modal", { "modal--visible": visible })}>
      <div className="modal__overlay" onClick={onClose} />
      <div className="modal__contentArea">{children}</div>
    </div>
  );
};

export default Modal;
