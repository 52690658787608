const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "NOK":
    case "SEK":
    case "DKK":
      return "kr";
    case "GBP":
      return "£";
    case "EURO":
      return "€";
    case "USD":
      return "$";
    default:
      return "kr";
  }
};

export default getCurrencySymbol;
