import React from "react";
import DismissIcon from "../../components/icons/cancelMedium";
import "./style.scss";
import className from "classnames";

const InfoBox = ({
  backgroundColor,
  position = "bottom-center",
  size = "small",
  image,
  onClick,
  onDismiss,
  text,
  dismissable,
  dismissed,
  fullwidth = false,
  padding = true,
  interactable = true,
}) => {
  return (
    <div
      className={className("infoBox", {
        "infoBox--above": position === "above",
        "infoBox--bottom-right": position === "bottom-right",
        "infoBox--bottom-left": position === "bottom-left",
        "infoBox--bottom-center": position === "bottom-center",
        "infoBox--large": size === "large",
        "infoBox--small": size === "small",
        "infoBox--dismissed": dismissed,
        "infoBox--fullwidth": fullwidth,
        "infoBox--interactable": interactable,
      })}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="infoBox__wrapper" onClick={onClick}>
        <img src={image} className="infoBox__image" alt=" " />
        <div
          className={className("infoBox__content", {
            "infoBox--noPadding": !padding,
          })}
        >
          {text}
        </div>
      </div>

      {dismissable && (
        <div
          className="infoBox__dismiss"
          onClick={(e) => {
            e.stopPropagation();
            if (onDismiss) onDismiss();
          }}
        >
          <DismissIcon />
        </div>
      )}
    </div>
  );
};

const InfoBoxes = ({ children, ...forwardedProps }) => {
  return (
    <div className="infoBox-grid" {...forwardedProps}>
      {children}
    </div>
  );
};

export { InfoBoxes };
export default InfoBox;
