import React from "react";
import { connect } from "react-redux";
import { selectorAppOsPlatform, selectorAppTopBarHeight, selectorIsAppMode } from "../../state/selectors";
import "./styles.scss";

class AppMode extends React.Component {
  handleContectMenu = (event) => {
    event.preventDefault();
  };

  render() {
    const body = document.querySelector("body");
    if (this.props.isAppMode) {
      body.classList.add("appMode");
      window.addEventListener("contextmenu", this.handleContectMenu, false);
    } else {
      body.classList.remove("appMode");
      window.removeEventListener("contextmenu", this.handleContectMenu, false);
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
  osPlatform: selectorAppOsPlatform(state),
  topBarHeight: selectorAppTopBarHeight(state),
});

export default connect(mapStateToProps)(AppMode);
