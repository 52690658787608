import React, { useCallback, useEffect } from "react";
import CloseIcon from "../icons/cancel";
import ArrowBack from "../icons/arrowMiniLeft";
import Modal from "../modal";
import "./styles.scss";
import { Portal } from "react-portal";

const GenericModal = ({ backgroundColor, backgroundImage, button, closeBtn, isVisible, text, title }) => {
  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        closeBtn();
      }
    },
    [closeBtn]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <Portal>
      <div className="genericModalHolder">
        <Modal visible={isVisible}>
          <div className="genericModal" style={{ backgroundColor }}>
            <div className="genericModalWrapper">
              <div className="genericModalWrapper__content">
                {closeBtn && (
                  <div className="genericModalWrapper__content--closeIconBtn" onClick={closeBtn}>
                    <CloseIcon color={"#FFF"} />
                  </div>
                )}
                <div className="genericModalWrapper__content--arrowBackBtn" onClick={closeBtn}>
                  <ArrowBack />
                </div>
                <h1 className="genericModalWrapper__content--title">{title}</h1>
                <div className="genericModalWrapper__content--textStyle">{text}</div>
                <div className="buttonStyles">{button}</div>
              </div>
              <div className="genericModalWrapper__imageWrapper">
                <img src={backgroundImage} alt="" />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Portal>
  );
};

export default GenericModal;
