const Icon = ({ width = "48", height = "48", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g mask="url(#mask0_303_1058)">
      <path
        d="M7.25 25.45V30.1C7.25 30.2667 7.31667 30.4167 7.45 30.55C7.58333 30.6833 7.73333 30.75 7.9 30.75H40.1C40.2667 30.75 40.4167 30.6833 40.55 30.55C40.6833 30.4167 40.75 30.2667 40.75 30.1V25.45H7.25ZM7.9 5H40.1C40.9 5 41.5833 5.28333 42.15 5.85C42.7167 6.41667 43 7.1 43 7.9V30.1C43 30.9 42.7167 31.5833 42.15 32.15C41.5833 32.7167 40.9 33 40.1 33H30.4V42.25L24 39.05L17.6 42.25V33H7.9C7.1 33 6.41667 32.7167 5.85 32.15C5.28333 31.5833 5 30.9 5 30.1V7.9C5 7.1 5.28333 6.41667 5.85 5.85C6.41667 5.28333 7.1 5 7.9 5ZM7.25 20.75H40.75V7.9C40.75 7.73333 40.6833 7.58333 40.55 7.45C40.4167 7.31667 40.2667 7.25 40.1 7.25H7.9C7.73333 7.25 7.58333 7.31667 7.45 7.45C7.31667 7.58333 7.25 7.73333 7.25 7.9V20.75ZM7.25 30.1V7.25H7.9C7.73333 7.25 7.58333 7.31667 7.45 7.45C7.31667 7.58333 7.25 7.73333 7.25 7.9V30.1C7.25 30.2667 7.31667 30.4167 7.45 30.55C7.58333 30.6833 7.73333 30.75 7.9 30.75H7.25V30.1Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Icon;
