import React from "react";
import { withRouter } from "react-router-dom";
import {
  ScreenTransitionLayout,
  ScreenTransitionLayouts,
  slideToScreen,
} from "../../components/layouts/screenTransition";
import ViewOverviewScreen from "./screen";
import ViewReceipt from "../receipt";
import ViewPurchase from "../purchase";
import ViewNotifications from "../notifications";
import ViewNotification from "../notification";
import "./styles.scss";
// import moment from "moment";
// import NativeAppClient from "../../services/nativeAppClient";
import { setGlobalState } from "../../state/actionCreators";
import { connect } from "react-redux";

class ViewOverview extends React.Component {
  componentDidMount() {
    this.slideToScreen();
    // this.checkToken();
  }

  componentDidUpdate() {
    this.slideToScreen();
  }

  // checkToken() {
  // if (NativeAppClient.isInNativeApp()) {
  //   const deviceToken = NativeAppClient.requestAsyncToken();
  //   if (deviceToken != null) {
  //     this.props.setGlobalState({
  //       appPushNotificationToken: deviceToken,
  //       appPushNotificationTokenDate: moment().toISOString(),
  //     });
  //   }
  // }
  // }

  slideToScreen() {
    const pathname = this.props.location.pathname;
    const entity = pathname.match(/active|refunds|expired|receipts|notifications|parcels/i)?.[0];
    const hasId = !!pathname.match(/(\d+)/);
    switch (entity) {
      case "active":
      case "refunds":
      case "expired":
        return hasId ? slideToScreen(1) : slideToScreen(0);
      case "receipts":
        return hasId ? slideToScreen(2) : slideToScreen(0);
      case "notifications":
        return hasId ? slideToScreen(4) : slideToScreen(3);
      default:
        return slideToScreen(0);
    }
  }

  render() {
    return (
      <div className="viewOverview">
        <ScreenTransitionLayouts>
          <ScreenTransitionLayout component={ViewOverviewScreen} />
          <ScreenTransitionLayout component={ViewPurchase} />
          <ScreenTransitionLayout component={ViewReceipt} />
          <ScreenTransitionLayout component={ViewNotifications} />
          <ScreenTransitionLayout component={ViewNotification} />
        </ScreenTransitionLayouts>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setGlobalState,
};

export default withRouter(connect(null, mapDispatchToProps)(ViewOverview));
