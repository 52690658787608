import React, { useRef, useState } from "react";
import ICON_ADD_CIRCLE from "../../components/icons/addCircle";
import Button from "../../components/inputs/newButton";
import classNames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import "./styles.scss";

const FlipCards = ({ cards, fluid }) => {
  const [flipedCard, setFlipedCard] = useState([]);
  const [visibleCards, setVisibleCards] = useState([]);
  const ref = useRef();

  const setFlipedCardIndex = (index) => {
    if (flipedCard.length > 0) {
      if (flipedCard.includes(index)) {
        const newArray = flipedCard.filter((e) => e !== index);

        setFlipedCard([...newArray]);
      } else {
        setFlipedCard([...flipedCard, index]);
      }
    } else {
      setFlipedCard([index]);
    }
  };

  const VisibilitySensorChange = (isVisible, index) => {
    if (isVisible) {
      setVisibleCards([...visibleCards, index]);
    } else {
      const tmpVisbleCards = visibleCards.filter((ele) => ele !== index);
      setVisibleCards([...tmpVisbleCards]);
    }
  };

  return (
    <div className="flipCards">
      <div className="flipCards__wrapper" ref={ref}>
        {cards.map((ele, index) => {
          return (
            <VisibilitySensor onChange={(e) => VisibilitySensorChange(e, index)} partialVisibility={true} key={index}>
              <div
                id={"filpCard-" + index}
                key={index}
                className={classNames({
                  flipCards__card__appear: true,
                  flipCards__card__appear__inview: visibleCards.includes(index) ? true : false,
                })}
              >
                <div className="flipCards__wrapper__header">
                  <Button type="label" label={ele.label} />
                  <div
                    onClick={() => {
                      setFlipedCardIndex(index);
                    }}
                  >
                    <ICON_ADD_CIRCLE className="flipCards__pointer" />
                  </div>
                </div>

                <div className="flipCards__card__cover" style={{ backgroundColor: ele.backGroundColor }}>
                  <div
                    className={classNames({
                      flipCards__card__body: true,
                      flipCards__card__body_show: flipedCard.length === 0 || !flipedCard.includes(index) ? true : false,
                      flipCards__card__body_hide: flipedCard.length > 0 && flipedCard.includes(index) ? true : false,
                    })}
                  >
                    <div className="flipCards__card__title">{ele.title1}</div>

                    <div className="flipCards__card__img__cover">
                      <img
                        src={ele.body1}
                        className={classNames({
                          "flipCards__card__img": true,
                          "flipCards__card__img--fluid": fluid ? true : false,
                        })}
                        alt="tjommy cards"
                      />
                    </div>

                    <div style={{ display: "flex" }}>
                      <Button label={ele.btnLabel1} onClick={() => ele.firstbtnOnclick()} />
                    </div>
                  </div>

                  <div
                    className={classNames({
                      flipCards__card__body: true,
                      flipCards__card__body_show: flipedCard.length > 0 && flipedCard.includes(index) ? true : false,
                      flipCards__card__body_hide: !flipedCard.includes(index) ? true : false,
                    })}
                  >
                    <div className="flipCards__card__title flipCards__card__title--sub flipCards__card__title--bold">
                      {ele.title2}
                    </div>
                    <div className="flipCards__card__title flipCards__card__title--sub">{ele.body2}</div>

                    <div style={{ display: "flex" }}>
                      <Button label={ele.btnLabel2} onClick={() => ele.secondbtnOnclick()} />
                    </div>
                  </div>
                </div>
              </div>
            </VisibilitySensor>
          );
        })}
      </div>
    </div>
  );
};

export default FlipCards;
