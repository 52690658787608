const Icon = ({ width = "48", height = "48", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g mask="url(#mask0_3873_29946)">
      <path
        d="M32.2031 30.6008L40.2031 23.7008L43.4031 24.0008L34.7531 31.3508L37.3531 42.6008L34.6031 40.9508L32.2031 30.6008ZM28.0031 17.5508L25.4031 11.4508L26.6031 8.55078L30.5031 17.7508L28.0031 17.5508ZM13.5531 37.2008L21.0031 32.7008L28.4531 37.2508L26.4531 28.8008L33.0031 23.1008L24.4031 22.3508L21.0031 14.4008L17.6031 22.3008L9.00313 23.0508L15.5531 28.7508L13.5531 37.2008ZM10.1531 41.9008L13.0031 29.6008L3.45312 21.3008L16.0531 20.2008L21.0031 8.55078L25.9531 20.2008L38.5531 21.3008L29.0031 29.6008L31.8531 41.9008L21.0031 35.4008L10.1531 41.9008Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

export default Icon;
