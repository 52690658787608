const Icon = ({ width = "18", height = "18", color = "#222222", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 104 102" fill="none" {...props}>
    <path
      d="M1 6.48604C1 3.45619 3.47739 1 6.53341 1H82.5368C85.5929 1 88.0702 3.45618 88.0702 6.48604V43.9476C88.0702 45.3203 86.9478 46.4331 85.5632 46.4331C84.1786 46.4331 83.0562 45.3203 83.0562 43.9476V6.48604C83.0562 6.20165 82.8237 5.97111 82.5368 5.97111H6.53341C6.24657 5.97111 6.01403 6.20165 6.01403 6.48604V95.3978C6.01403 95.6822 6.24657 95.9128 6.53341 95.9128H53.8252C55.2098 95.9128 56.3323 97.0256 56.3323 98.3983C56.3323 99.771 55.2098 100.884 53.8252 100.884H6.53341C3.47739 100.884 1 98.4277 1 95.3978V6.48604Z"
      fill={color}
    />
    <path
      d="M19.9181 22.6944C18.5335 22.6944 17.4111 23.8072 17.4111 25.18C17.4111 26.5527 18.5335 27.6655 19.9181 27.6655H63.6814C65.066 27.6655 66.1884 26.5527 66.1884 25.18C66.1884 23.8072 65.066 22.6944 63.6814 22.6944H19.9181Z"
      fill={color}
    />
    <path
      d="M19.9181 45.7446C18.5335 45.7446 17.4111 46.8575 17.4111 48.2302C17.4111 49.6029 18.5335 50.7158 19.9181 50.7158H52.7406C54.1252 50.7158 55.2476 49.6029 55.2476 48.2302C55.2476 46.8575 54.1252 45.7446 52.7406 45.7446H19.9181Z"
      fill={color}
    />
    <path
      d="M19.9181 68.7949C18.5335 68.7949 17.4111 69.9077 17.4111 71.2804C17.4111 72.6532 18.5335 73.766 19.9181 73.766H36.3293C37.7139 73.766 38.8364 72.6532 38.8364 71.2804C38.8364 69.9077 37.7139 68.7949 36.3293 68.7949H19.9181Z"
      fill={color}
    />
    <path
      d="M67.6775 73.766C66.2929 73.766 65.1705 74.8788 65.1705 76.2515C65.1705 77.6243 66.2929 78.7371 67.6775 78.7371H100.5C101.885 78.7371 103.007 77.6243 103.007 76.2515C103.007 74.8788 101.885 73.766 100.5 73.766H67.6775Z"
      fill={color}
    />
    <path
      d="M82.0156 92.6121C82.0156 93.9967 83.1284 95.1191 84.5012 95.1191C85.8739 95.1191 86.9867 93.9967 86.9867 92.6121V59.7896C86.9867 58.405 85.8739 57.2826 84.5012 57.2826C83.1284 57.2826 82.0156 58.405 82.0156 59.7896V92.6121Z"
      fill={color}
    />
    <path
      d="M1 6.48604C1 3.45619 3.47739 1 6.53341 1H82.5368C85.5929 1 88.0702 3.45618 88.0702 6.48604V43.9476C88.0702 45.3203 86.9478 46.4331 85.5632 46.4331C84.1786 46.4331 83.0562 45.3203 83.0562 43.9476V6.48604C83.0562 6.20165 82.8237 5.97111 82.5368 5.97111H6.53341C6.24657 5.97111 6.01403 6.20165 6.01403 6.48604V95.3978C6.01403 95.6822 6.24657 95.9128 6.53341 95.9128H53.8252C55.2098 95.9128 56.3323 97.0256 56.3323 98.3983C56.3323 99.771 55.2098 100.884 53.8252 100.884H6.53341C3.47739 100.884 1 98.4277 1 95.3978V6.48604Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9181 22.6944C18.5335 22.6944 17.4111 23.8072 17.4111 25.18C17.4111 26.5527 18.5335 27.6655 19.9181 27.6655H63.6814C65.066 27.6655 66.1884 26.5527 66.1884 25.18C66.1884 23.8072 65.066 22.6944 63.6814 22.6944H19.9181Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9181 45.7446C18.5335 45.7446 17.4111 46.8575 17.4111 48.2302C17.4111 49.6029 18.5335 50.7158 19.9181 50.7158H52.7406C54.1252 50.7158 55.2476 49.6029 55.2476 48.2302C55.2476 46.8575 54.1252 45.7446 52.7406 45.7446H19.9181Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9181 68.7949C18.5335 68.7949 17.4111 69.9077 17.4111 71.2804C17.4111 72.6532 18.5335 73.766 19.9181 73.766H36.3293C37.7139 73.766 38.8364 72.6532 38.8364 71.2804C38.8364 69.9077 37.7139 68.7949 36.3293 68.7949H19.9181Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.6775 73.766C66.2929 73.766 65.1705 74.8788 65.1705 76.2515C65.1705 77.6243 66.2929 78.7371 67.6775 78.7371H100.5C101.885 78.7371 103.007 77.6243 103.007 76.2515C103.007 74.8788 101.885 73.766 100.5 73.766H67.6775Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.0156 92.6121C82.0156 93.9967 83.1284 95.1191 84.5012 95.1191C85.8739 95.1191 86.9867 93.9967 86.9867 92.6121V59.7896C86.9867 58.405 85.8739 57.2826 84.5012 57.2826C83.1284 57.2826 82.0156 58.405 82.0156 59.7896V92.6121Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
