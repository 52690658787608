let initialized = false;
const ERR_MSG = "google analytics must be initialized";

export const initialise = (measurementId) => {
  const head = document.getElementsByTagName("head")[0];
  const elm = document.createElement("script");
  elm.setAttribute("async", "");
  elm.type = "text/javascript";
  elm.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  head.appendChild(elm);
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  window.gtag("config", measurementId, { send_page_view: false });
  initialized = true;
};

export const pageView = (pathname = undefined, options = {}) => {
  if (!initialized) throw new Error(ERR_MSG);
  const page_path = pathname || window.location.pathname + window.location.search;
  window.gtag("event", "page_view", { page_path, ...options });
};

export const trackEvent = (eventName, options = {}) => {
  if (!initialized) throw new Error(ERR_MSG);
  if (eventName === "page_view") throw new Error("page_view eventName is reserved and should not be used");
  window.gtag("event", eventName, options);
};

let googleAdIdValue = null;
export const configAdsId = (googleAdsId, googleAdId) => {
  googleAdIdValue = googleAdId;
  window.gtag("config", googleAdsId, { send_page_view: false });
};

export const trackConversion = () => {
  window.gtag("event", "conversion", { send_to: googleAdIdValue });
  window.gtag("event", "sign_up");
};
