import { API_URL, IS_PRODUCTION, TEST_USER_PHONE } from "../utils/configs";

/**
 * returns json on ok and if http response status is 401 unauthorized
 * it redirects the user to /signout, if outside of 200 range it redirects the user to /error.
 * Ideal for protected routes.
 * @param {*} res
 */
const handleAuthorized = async (res) => {
  if (res.status < 200 || 299 < res.status) {
    if (res.status === 401 && IS_PRODUCTION) {
      window.location.href = "/signout";
      return alert("Something went wrong- Sign in again.");
    }

    if (!window.location.href.match(/\/error/gi) && IS_PRODUCTION) return (window.location.href = "/error");
    else return;
  }

  const isJson = !!res.headers.get("content-type")?.match(/application\/json/gi) || false;
  const method = isJson ? "json" : "text";
  return await res[method]();
};

class ApiClient {
  token = null;

  _options() {
    if (!this.token) throw new Error("Token not set in ApiClient");
    return {
      headers: {
        "Authorization": `Bearer ${this.token}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    };
  }

  async getTestUserToken(verificationCode) {
    const challenge = await fetch(`${API_URL}/v2/auth/sms?auth[phone]=${TEST_USER_PHONE}&fake=1`)
      .then((res) => res.json())
      .then((res) => (this.challenge = res.challenge));

    return fetch(`${API_URL}/v2/auth/sms`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auth: {
          verification_code: verificationCode,
          uuid: challenge.uuid,
          phone: challenge.phone,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => (this.token = res.user.jwt_token));
  }

  getMyStripeClientSecret() {
    return fetch(`${API_URL}/v2/my/payment-methods/setup-intent`, this._options())
      .then(handleAuthorized)
      .then((res) => res.client_secret);
  }

  postMyPaymentMethod(paymentMethodId) {
    return fetch(`${API_URL}/v2/my/payment-methods`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({ payment_method_id: paymentMethodId }),
    }).then(handleAuthorized);
  }

  getMyUser() {
    return Promise.all([
      fetch(`${API_URL}/v2/my/user`, this._options()).then(handleAuthorized),
      fetch(`${API_URL}/v2/my/email-accounts`, this._options()).then(handleAuthorized),
      fetch(`${API_URL}/v2/my/payment-methods`, this._options())
        .then(handleAuthorized)
        .then((res) => (Array.isArray(res) ? null : res)),
    ]).then((values) => {
      const myUser = values[0];
      delete myUser.stripe_intent_client_secret;
      return {
        ...myUser,
        emailAccounts: values[1],
        paymentMethod: values[2],
      };
    });
  }

  putMyUser(payload) {
    return fetch(`${API_URL}/v2/my/user`, {
      method: "PUT",
      ...this._options(),
      body: JSON.stringify({
        user: {
          first_name: payload.first_name,
          last_name: payload.last_name,
          account_nr: payload.account_nr,
          accepts_newsletters: payload.accepts_newsletters,
          locale: payload.locale,
          interests: payload.interests,
          gender: payload.gender,
          birthday: payload.birthday,
          enable_push_notifications: payload.enable_push_notifications,
          enable_email_notifications: payload.enable_email_notifications,
        },
      }),
    })
      .then(handleAuthorized)
      .catch((err) => {
        console.log("err in", err);
      });
  }

  async deleteMyUser(reason) {
    if (reason) {
      await fetch(`${API_URL}/v2/my/leave-reason`, {
        method: "POST",
        body: JSON.stringify({
          message: reason,
        }),
        ...this._options(),
      }).then(handleAuthorized);
    }

    await fetch(`${API_URL}/v2/my/user/`, {
      method: "DELETE",
      ...this._options(),
    });
  }

  postSupportMessage(message) {
    return fetch(`${API_URL}/v2/my/support`, {
      method: "POST",
      body: JSON.stringify({
        message: message,
      }),
      ...this._options(),
    }).then(handleAuthorized);
  }

  postWaitingList(payload) {
    return fetch(`${API_URL}/v2/public/waitlist`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  deleteMyCard(cardId) {
    return fetch(`${API_URL}/v2/my/stripe-cards/${cardId}`, {
      method: "DELETE",
      ...this._options(),
    }).then(handleAuthorized);
  }

  deleteMyEmailAccount(id) {
    return fetch(`${API_URL}/v2/gdpr/email/${id}`, {
      method: "DELETE",
      ...this._options(),
    }).then(handleAuthorized);
  }

  postMyPushNotificationToken(token) {
    return fetch(`${API_URL}/v2/expo/subscribe`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({ expo_token: token }),
    }).then(handleAuthorized);
  }

  getPurchases() {
    return fetch(`${API_URL}/v2/my/purchases`, this._options()).then(handleAuthorized);
  }

  getNotifications() {
    return fetch(`${API_URL}/v2/my/notifications`, this._options()).then(handleAuthorized);
  }

  markNotificationSeen(id) {
    return fetch(`${API_URL}/v2/my/notifications/${id}/seen`, {
      method: "POST",
      ...this._options(),
    }).then(handleAuthorized);
  }

  getMedals() {
    return fetch(`${API_URL}/v2/my/medals`, this._options()).then(handleAuthorized);
  }

  async postCreatingMedal(receivingUserId = null) {
    return await fetch(`${API_URL}/v2/my/medals`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({
        to_user_id: receivingUserId,
      }),
    }).then(handleAuthorized);
  }

  async redeemMedal(uuid) {
    return await fetch(`${API_URL}/v2/my/medals/redeem`, {
      method: "PUT",
      ...this._options(),
      body: JSON.stringify({
        uuid: uuid,
      }),
    }).then(handleAuthorized);
  }

  async redeemReferal() {
    return await fetch(`${API_URL}/v2/my/referal/redeem`, {
      method: "GET",
      ...this._options(),
    }).then(handleAuthorized);
  }

  postContacts(contacts) {
    return fetch(`${API_URL}/v2/my/contacts`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({ contacts: contacts }),
    }).then(handleAuthorized);
  }

  getContacts() {
    return fetch(`${API_URL}/v2/my/contacts`, this._options()).then(handleAuthorized);
  }

  getGmailConnectUrl(redirectUrl) {
    return `${API_URL}/gmail?redirectUrl=${redirectUrl}&jwt=${this.token}`;
  }

  getOutlookConnectUrl(redirectUrl) {
    return `${API_URL}/outlook?redirectUrl=${redirectUrl}&jwt=${this.token}`;
  }

  getAuthenticateUrl(provider, redirectUrl, regionCode, registrationSource, trafficSource, force) {
    const trafficSourceString = trafficSource ? `&traffic_source=${trafficSource}` : "";
    const forceString = force ? "&force" : "";
    return `${API_URL}/authenticate/${provider}?redirect_url=${redirectUrl}&region_code=${regionCode}&registration_source=${registrationSource}${forceString}${trafficSourceString}`;
  }

  getRatings() {
    return fetch(`${API_URL}/v2/public/scoreboard`).then(handleAuthorized);
  }

  getDiscoveries(region_id, sort_by, interests, limit, offset, query = null) {
    let apiUrl = `${API_URL}/v2/discover/partners?interests=${interests}&region_id=${region_id}&sort_by=${sort_by}&limit=${limit}&offset=${offset}`;
    if (query !== null) {
      apiUrl += `&query=${query}`;
    }
    return fetch(apiUrl, this._options()).then(handleAuthorized);
  }

  getDiscoveriesVouchers(region_id, sort_by, interests, limit, offset, query = null) {
    let apiUrl = `${API_URL}/v2/discover/vouchers?interests=${interests}&region_id=${region_id}&sort_by=${sort_by}&limit=${limit}&offset=${offset}`;
    if (query !== null) {
      apiUrl += `&query=${query}`;
    }
    return fetch(apiUrl, this._options()).then(handleAuthorized);
  }

  // getDiscoveriesPurchases(
  //   region_id,
  //   sort_by,
  //   interests,
  //   limit,
  //   offset,
  //   query = null
  // ) {
  //   let apiUrl = `${API_URL}/v2/discover/previous-purchases?interests=${interests}&region_id=${region_id}&sort_by=${sort_by}&limit=${limit}&offset=${offset}`;
  //   if (query !== null) {
  //     apiUrl += `&query=${query}`;
  //   }
  //   return fetch(apiUrl, this._options()).then(handleAuthorized);
  // }

  getMerchantPartners() {
    return fetch(`${API_URL}/v2/public/scoreboard?partners_only=1`).then(handleAuthorized);
  }

  getRating(id) {
    return fetch(`${API_URL}/v2/public/scoreboard/${id}`).then(handleAuthorized);
  }

  getReceipt(id) {
    return fetch(`${API_URL}/v2/my/receipts/${id}`, this._options()).then(handleAuthorized);
  }

  getReceipts() {
    return fetch(`${API_URL}/v2/my/receipts`, this._options()).then(handleAuthorized);
  }

  triggerScan() {
    return fetch(`${API_URL}/v2/my/receipts/scan`, {
      method: "POST",
      ...this._options(),
    }).then(handleAuthorized);
  }

  async deleteReceipt(id) {
    return fetch(`${API_URL}/v2/my/receipts/${id}`, {
      method: "DELETE",
      ...this._options(),
    }).then(handleAuthorized);
  }

  async getGiftCards() {
    return fetch(`${API_URL}/v2/my/giftcards`, this._options()).then(handleAuthorized);
  }

  async markGiftcardAsUsed(id) {
    return fetch(`${API_URL}/v2/my/giftcards/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ used_at: new Date() }),
      ...this._options(),
    }).then(handleAuthorized);
  }

  async deleteGiftcard(id) {
    return fetch(`${API_URL}/v2/my/giftcards/${id}`, {
      method: "DELETE",
      ...this._options(),
    }).then(handleAuthorized);
  }

  async voucherClaim(id) {
    return fetch(`${API_URL}/v2/my/giftcards/${id}/claim`, {
      method: "POST",
      ...this._options(),
    }).then(handleAuthorized);
  }
  async postReceipt(fileBuffer) {
    let extension = fileBuffer.name.match(/(?:.(?!\.))+$/)?.[0].replace(".", "");
    if (!extension) throw new Error("extension is required");

    // Get pre-signed url from backend
    const { key, url } = await fetch(`${API_URL}/v2/signed-storage-url`, {
      method: "POST",
      ...this._options(),
    }).then(handleAuthorized);

    // Put file to AWS S3
    const result = await fetch(url, { method: "PUT", body: fileBuffer });
    if (!result.ok) {
      throw new Error("Failed to upload the file: " + result.statusText);
    }

    // Post receipt to backend
    const receipt = await fetch(`${API_URL}/v2/my/receipts`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({ s3: { key, type: "file", extension } }),
    }).then(handleAuthorized);

    return this.getReceipt(receipt.id);
  }

  async getParcels() {
    return fetch(`${API_URL}/v2/my/parcels`, this._options()).then(handleAuthorized);
  }

  async getParcel(id) {
    return fetch(`${API_URL}/v2/my/parcels/${id}`, this._options()).then(handleAuthorized);
  }

  postSignout() {
    return fetch(`${API_URL}/v2/logout`, {
      method: "POST",
      ...this._options(),
    });
  }

  async getInterests() {
    return fetch(`${API_URL}/v2/my/interests`, {
      ...this._options(),
    }).then(handleAuthorized);
  }

  async userRegister(email, name, password) {
    return fetch(`${API_URL}/v2/auth/register`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email, name, password }),
    });
  }

  async userLogin(email, password) {
    return fetch(`${API_URL}/v2/auth/login`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email, password }),
    });
  }

  async userPasswordReset(email) {
    return fetch(`${API_URL}/v2/auth/password/reset`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email }),
    });
  }

  async userPasswordUpdate(password, token) {
    return fetch(`${API_URL}/v2/auth/password/reset/change`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ password, token }),
    });
  }

  logMerchantClick(domain_id) {
    return fetch(`${API_URL}/v2/discover/merchant/click`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({ domain_id }),
    }).then(handleAuthorized);
  }

  setDeviceToken(deviceToken) {
    return fetch(`${API_URL}/v2/customerio/token`, {
      method: "POST",
      ...this._options(),
      body: JSON.stringify({ token: deviceToken }),
    }).then(handleAuthorized);
  }
  deleteDeviceToken() {
    return fetch(`${API_URL}/v2/customerio/`, {
      method: "DELETE",
      ...this._options(),
    }).then(handleAuthorized);
  }

  async getPartners() {
    return fetch(`${API_URL}/v2/public/partners`, {
      method: "GET",
    });
  }
  async getAppVersion() {
    return fetch(`${API_URL}/v2/public/app/version`, {
      method: "GET",
    });
  }
}

export default new ApiClient();
