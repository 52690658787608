const Icon = ({ width = "16", height = "18", color = "#222222", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.5 17.5001V6.2501L8 0.600098L15.5 6.2501V17.5001H0.5ZM2 7.0001V15.3501C2.85 14.6668 3.76667 14.1461 4.75 13.7881C5.73333 13.4294 6.80833 13.2501 7.975 13.2501C9.10833 13.2501 10.1877 13.4334 11.213 13.8001C12.2377 14.1668 13.1667 14.6751 14 15.3251V7.0001L8 2.4751L2 7.0001ZM8 11.7501C7.23333 11.7501 6.58333 11.4834 6.05 10.9501C5.51667 10.4168 5.25 9.76676 5.25 9.0001C5.25 8.23343 5.51667 7.58343 6.05 7.0501C6.58333 6.51676 7.23333 6.2501 8 6.2501C8.76667 6.2501 9.41667 6.51676 9.95 7.0501C10.4833 7.58343 10.75 8.23343 10.75 9.0001C10.75 9.76676 10.4833 10.4168 9.95 10.9501C9.41667 11.4834 8.76667 11.7501 8 11.7501ZM8 10.2501C8.35 10.2501 8.64567 10.1291 8.887 9.8871C9.129 9.64577 9.25 9.3501 9.25 9.0001C9.25 8.6501 9.129 8.35443 8.887 8.1131C8.64567 7.8711 8.35 7.7501 8 7.7501C7.65 7.7501 7.35433 7.8711 7.113 8.1131C6.871 8.35443 6.75 8.6501 6.75 9.0001C6.75 9.3501 6.871 9.64577 7.113 9.8871C7.35433 10.1291 7.65 10.2501 8 10.2501ZM3.675 16.0001H12.275C11.6417 15.5834 10.9667 15.2708 10.25 15.0621C9.53333 14.8541 8.775 14.7501 7.975 14.7501C7.175 14.7501 6.41667 14.8541 5.7 15.0621C4.98333 15.2708 4.30833 15.5834 3.675 16.0001Z"
      fill={color}
    />
  </svg>
);

export default Icon;
