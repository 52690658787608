import React, { useState } from "react";
import "./styles.scss";
import classNames from "classnames";
import MONEY_PIGGY from "./assets/money-piggy.png";
import Button from "../../components/inputs/newButton";
import { validateEmail } from "../../utils/validations";
import { GET_APP_URL } from "../../utils/configs";
import EmailInput from "../../components/inputs/emailInput";
import { newsSubscribe } from "../../services/analytics/customerio";

const SectionCard = ({
  title,
  backgroundColor,
  dark,
  bottomContent,
  bottomContentPosition,
  refundType,
  newsSubscribeMode,
}) => {
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState(false);

  return (
    <div className="sectionCard sectionCard_full_width" style={{ backgroundColor }}>
      <div className="sectionCard_main_wrapper">
        <div className="sectionCard_title_wrapper">
          <div
            className={classNames({
              sectionCard_title: true,
              sectionCard_title_white: dark,
            })}
          >
            {refundType
              ? "Why pay more?"
              : newsSubscribeMode && subscribe
              ? "Thanks, you will  be hearing from us"
              : newsSubscribeMode && !subscribe
              ? "News about refunds? It happens. Get the newsletter."
              : title}
          </div>
          {refundType ? (
            <div
              className={classNames({
                sectionCard_title: true,
                sectionCard_title_white: dark,
              })}
            >
              Get a refund
            </div>
          ) : null}
        </div>

        <div
          className={classNames({
            sectionCard_bottom: true,
            sectionCard_bottom_end: bottomContentPosition === "end",
          })}
        >
          {refundType ? (
            <Button label="Get Tjommi" light onClick={() => window.open(GET_APP_URL)} />
          ) : newsSubscribeMode ? (
            subscribe ? null : (
              <div className="homeLandingPage_email_input">
                <EmailInput
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <Button
                  label="Submit"
                  disabled={!validateEmail(email)}
                  onClick={() => {
                    newsSubscribe(email);
                    setSubscribe(true);
                    setEmail("");
                  }}
                />
              </div>
            )
          ) : (
            bottomContent
          )}
        </div>
        {refundType && <img src={MONEY_PIGGY} alt="tjommi refund" className="sectionCard_img" />}
      </div>
    </div>
  );
};

export default SectionCard;
