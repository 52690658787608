import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../state/selectors";
import Outline from "../../components/layouts/outline";
import Button from "../../components/inputs/button";
import Label from "../../components/label";
import "./styles.scss";
import EyeOffIcon from "../../components/icons/eyeOff";
import EyeIcon from "../../components/icons/eye";
import { usePostHog } from "posthog-js/react";

const ViewSignin = ({
  onSignInHandler,
  strs,
  forgotPasswordHandler,
  tjommiDeviceID,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    if (posthog) {
      posthog.capture("login_screen_view", {
        device_id: tjommiDeviceID,
      });
    }
  }, [posthog]);

  return (
    <>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 0,
        }}
        error={!emailValid}
        border={true}
        outlineAreaStyles={{
          padding: 14,
        }}
      >
        <input
          className="viewSignin__input"
          name="Email"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={strs.email}
        />
      </Outline>
      <Outline
        boxShadow={false}
        styles={{
          marginTop: 17,
        }}
        border={true}
        error={!passwordValid}
        outlineAreaStyles={{
          flexDirection: "row",
          display: "flex",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
          padding: 14,
        }}
      >
        <input
          style={{
            width: "90%",
          }}
          className="viewSignin__input"
          name="Password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          placeholder={strs.password}
        />
        <div
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="viewSignin__passwordVisible"
        >
          {" "}
          <div className="ViewSignup__passwordVisible__wrapper">
            {passwordVisible ? <EyeOffIcon /> : <EyeIcon />}
          </div>
        </div>
      </Outline>
      <div className="viewPasswordReset__main_btn_wrapper">
        <Button
          type="default"
          label={strs.continue}
          onClick={() => {
            if (password === "" && email === "") {
              setPasswordValid(false);
              setEmailValid(false);
            } else {
              setPasswordValid(true);
              setEmailValid(true);
            }
            if (password === "" || password.length < 8) {
              setPasswordValid(false);
            }

            if (email === "") {
              setEmailValid(false);
            }

            if (email !== "" && password !== "" && password.length >= 8) {
              posthog.capture("login_screen_signup_click", {
                device_id: tjommiDeviceID,
              });
              onSignInHandler(email, password);
            }
          }}
        />

        <div
          className="viewSignin__forgotPassword"
          onClick={() => {
            posthog.capture("login_screen_forgot_password_click", {
              device_id: tjommiDeviceID,
            });
            forgotPasswordHandler();
          }}
        >
          <Label align="center" color="#ffffff" bold size="sm">
            {strs.forgotPassword}
          </Label>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "signin"),
});

export default withRouter(connect(mapStateToProps, null)(ViewSignin));
