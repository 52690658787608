import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings } from "../../../state/selectors";
import GenericModal from "../../genericModal";
import Button from "../../inputs/button";
import IMAGE from "./assets/giftVouchers.png";
import { withRouter } from "react-router-dom";

const HowGiftCodesWorkModal = ({ visible, closeBtn, strs }) => {
  return (
    <GenericModal
      isVisible={visible}
      closeBtn={closeBtn}
      backgroundColor={"#79C5AF"}
      title={strs.howtouseCard}
      text={
        <>
          <div style={{ marginLeft: 70, marginRight: 70 }}>
            <img src={IMAGE} alt="" className="genericModalWrapper__content--img" />
          </div>
          {strs.howtouseCardModalDes}
        </>
      }
      button={<Button label={strs.howtouseCardBack} type="transparent" onClick={closeBtn} />}
    />
  );
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "discoveries"),
});

export default withRouter(connect(mapStateToProps)(HowGiftCodesWorkModal));
