import "./spinner.scss";

const Icon = ({ size }) => (
  <svg
    className="iconSpinner"
    height={size || "80px"}
    width={size || "80px"}
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 44 44"
  >
    <g>
      <g>
        <path d="M40.687,20.738h-0.231l-5.859,0.014l-0.284,0.004c-0.899,0.071-1.609,0.826-1.607,1.745c0,0.966,0.783,1.747,1.749,1.751    h6.275c0.902-0.079,1.61-0.836,1.608-1.758C42.336,21.56,41.609,20.795,40.687,20.738z M32.197,15.295l0.004-0.004l4.212-4.203    l0.222-0.226c0.582-0.689,0.543-1.727-0.107-2.377c-0.661-0.658-1.718-0.686-2.412-0.075l-0.161,0.164l-4.133,4.15l-0.198,0.2    c-0.585,0.687-0.554,1.719,0.097,2.37C30.404,15.977,31.513,15.977,32.197,15.295z M22.504,2.684    c-0.936,0-1.703,0.729-1.761,1.648l0.001,0.232l0.017,5.851v0.283c0.075,0.901,0.83,1.609,1.748,1.609    c0.968,0,1.752-0.783,1.752-1.748v-0.008l0.003-5.944V4.29C24.184,3.388,23.425,2.684,22.504,2.684z M15.291,12.807l-4.206-4.203    l-0.226-0.225c-0.692-0.579-1.729-0.544-2.38,0.107c-0.662,0.661-0.687,1.72-0.077,2.409l0.163,0.164l4.155,4.125l0.201,0.2    c0.69,0.586,1.725,0.551,2.374-0.096c0.685-0.687,0.685-1.791,0.002-2.474L15.291,12.807z M10.693,24.233    c0.902-0.07,1.611-0.825,1.611-1.744c-0.002-0.969-0.784-1.748-1.75-1.751H4.277c-0.901,0.079-1.608,0.836-1.606,1.755    c0,0.937,0.728,1.7,1.65,1.758h0.231l5.859-0.019H10.693z M12.81,29.692l-0.005,0.007l-4.21,4.204l-0.223,0.222    c-0.58,0.692-0.544,1.729,0.107,2.377c0.663,0.66,1.718,0.688,2.412,0.079l0.163-0.165l4.132-4.15l0.199-0.199    c0.585-0.688,0.553-1.724-0.097-2.371C14.601,29.013,13.495,29.013,12.81,29.692z M24.245,34.293    c-0.072-0.9-0.829-1.609-1.747-1.609c-0.969,0-1.752,0.783-1.752,1.748v0.007l-0.003,5.945l0.001,0.317    c0.08,0.898,0.837,1.605,1.76,1.605c0.936,0,1.7-0.73,1.76-1.648v-0.232l-0.019-5.851V34.293z M36.442,33.932l-4.156-4.125    l-0.199-0.2c-0.689-0.586-1.725-0.554-2.375,0.097c-0.684,0.683-0.685,1.79-0.001,2.474l0.006,0.007l4.205,4.204l0.226,0.22    c0.694,0.583,1.73,0.547,2.38-0.103c0.662-0.664,0.687-1.719,0.079-2.41L36.442,33.932z"></path>
      </g>
    </g>
  </svg>
);

export default Icon;
