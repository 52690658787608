import moment from "moment";
import { SET_GLOBAL_STATE } from "./actions";
import { detectLanguage } from "../../utils/lang";

const INITIAL_STATE = {
  initialized: moment().format(),
  language: detectLanguage(),
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_GLOBAL_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
