const Icon = (props) => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M48.6162 24C48.6162 10.746 37.8702 0 24.6162 0C11.3622 0 0.616211 10.746 0.616211 24C0.616211 35.98 9.39221 45.908 20.8662 47.708V30.938H14.7722V23.998H20.8662V18.714C20.8662 12.7 24.4502 9.376 29.9322 9.376C32.5562 9.376 35.3042 9.846 35.3042 9.846V15.752H32.2762C29.2942 15.752 28.3642 17.602 28.3642 19.5V24H35.0202L33.9562 30.94H28.3642V47.71C39.8402 45.908 48.6162 35.978 48.6162 24Z"
      fill="#3B5998"
    />
  </svg>
);

export default Icon;
