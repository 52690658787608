import React from "react";
import IconStar from "../icons/star";

const Star = ({ value, color }) => {
  const stars = Array.from({ length: 5 });

  return (
    <div className="rating__star">
      {stars.map((star, index) => {
        if (value <= index) {
          color = "none";
        } else {
          color = "#EBC96E";
        }
        return <IconStar key={index} filColor={color} />;
      })}
    </div>
  );
};

export default Star;
