const Icon = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="24" cy="24" r="24" fill="#1DA1F2" />
    <path
      d="M37.9452 15.6205C36.8969 16.0667 35.7864 16.3612 34.6493 16.4946C35.8465 15.799 36.7428 14.7082 37.1728 13.4235C36.0633 14.0495 34.8337 14.5051 33.5247 14.7589C32.6611 13.8661 31.5167 13.274 30.2693 13.0744C29.0218 12.8749 27.7411 13.0791 26.6261 13.6554C25.511 14.2316 24.624 15.1477 24.1027 16.2613C23.5815 17.3748 23.4551 18.6237 23.7433 19.8138C18.9717 19.5961 14.7448 17.3799 11.9133 14.0325C11.3986 14.8782 11.1301 15.8427 11.1363 16.8239C11.1363 18.7525 12.1513 20.4476 13.689 21.4435C12.7777 21.4155 11.8865 21.1773 11.0897 20.7488V20.8164C11.0891 22.0981 11.5473 23.3404 12.3864 24.3327C13.2255 25.325 14.3939 26.0061 15.6933 26.2604C14.8513 26.4786 13.9695 26.5113 13.1127 26.3563C13.4815 27.4593 14.1973 28.4236 15.1602 29.1144C16.1231 29.8053 17.2851 30.1883 18.484 30.2101C16.4534 31.7507 13.9462 32.5868 11.365 32.5842C10.91 32.5842 10.4562 32.5582 10 32.5086C12.6317 34.1376 15.6916 35.0023 18.8165 35C29.3783 35 35.1475 26.5458 35.1475 19.2273C35.1475 18.9905 35.1475 18.7536 35.13 18.5168C36.257 17.7328 37.2291 16.7594 38 15.6431L37.9452 15.6205Z"
      fill="white"
    />
  </svg>
);

export default Icon;
