const Icon = (props) => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.8164 0C11.6832 0 1.00288 10.7616 0.998049 23.9892C0.996102 28.522 2.25462 32.937 4.63779 36.7571L5.20435 37.6646L2.79851 46.5172L11.8098 44.1356L12.68 44.6553C16.3347 46.8417 20.5248 47.9978 24.7981 48H24.8072C37.9302 48 48.6106 37.2373 48.6159 24.0086C48.6183 17.5982 46.1443 11.57 41.6489 7.03554C37.1535 2.50106 31.1762 0.00270385 24.8164 0Z"
      fill="url(#paint0_linear_6923_97689)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6473 11.9205C17.1108 10.7194 16.5464 10.6951 16.0367 10.674C15.6193 10.6562 15.1418 10.6572 14.6648 10.6572C14.1873 10.6572 13.4121 10.8379 12.7565 11.5598C12.1003 12.2818 10.251 14.0269 10.251 17.5766C10.251 21.1263 12.816 24.556 13.1733 25.0378C13.5312 25.5191 18.1248 33.036 25.4004 35.9276C31.4463 38.3308 32.6765 37.8528 33.9894 37.7327C35.3017 37.6127 38.224 35.9876 38.8201 34.3031C39.4167 32.6185 39.4167 31.1752 39.238 30.8734C39.0588 30.5722 38.5819 30.3921 37.8656 30.0314C37.1499 29.6707 33.631 27.925 32.9748 27.6844C32.3187 27.4437 31.8417 27.3237 31.3642 28.0456C30.8873 28.7676 29.5165 30.3921 29.0985 30.8734C28.6811 31.3552 28.2637 31.4153 27.5475 31.0546C26.8318 30.6928 24.5264 29.9319 21.7918 27.474C19.664 25.5618 18.2278 23.2002 17.8099 22.4783C17.3925 21.7563 17.7653 21.3659 18.1242 21.0062C18.4456 20.6829 18.84 20.1637 19.1983 19.7424C19.5551 19.3212 19.6748 19.0205 19.913 18.5397C20.1517 18.0579 20.0326 17.6366 19.8534 17.2759C19.6748 16.9152 18.2836 13.3471 17.6473 11.9205"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_6923_97689"
        x1="24.3223"
        y1="2.88052"
        x2="24.5676"
        y2="43.8992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#57D163" />
        <stop offset="1" stopColor="#23B33A" />
      </linearGradient>
    </defs>
  </svg>
);

export default Icon;
