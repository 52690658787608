const Icon = ({ color = "white" }) => (
  <svg
    className="iconArrowMiniRight"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.76757 18.9844C8.5633 18.9842 8.36398 18.9215 8.19642 18.8047C8.02885 18.6879 7.90108 18.5225 7.83029 18.3309C7.75949 18.1393 7.74908 17.9306 7.80044 17.7329C7.8518 17.5352 7.96247 17.358 8.11757 17.2251L14.2314 11.9844L8.11717 6.74367C7.92259 6.56925 7.80401 6.3256 7.78681 6.06485C7.76962 5.80411 7.85517 5.54699 8.02515 5.34853C8.19514 5.15006 8.43606 5.02602 8.69635 5.00295C8.95664 4.97987 9.21563 5.0596 9.41787 5.22507L15.5322 10.4658C15.7511 10.6535 15.9269 10.8864 16.0474 11.1484C16.1679 11.4105 16.2304 11.6955 16.2305 11.9839C16.2306 12.2723 16.1683 12.5574 16.0479 12.8195C15.9276 13.0816 15.752 13.3146 15.5332 13.5025L9.41787 18.7437C9.23696 18.8993 9.00618 18.9847 8.76757 18.9844Z"
      fill={color}
    />
  </svg>
);

export default Icon;
