function LinkIcon(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" color="blue" {...props}>
      <path
        d="M17.25 15.25v-8.5h-8.5M17 7L6.75 17.25"
        stroke="#E54472"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        id="styled-element"
      />
    </svg>
  );
}

export default LinkIcon;
