import React from "react";
import { connect } from "react-redux";
import { selectorAppTopBarHeight, selectorIsAppMode } from "../../../state/selectors";
import classNames from "classnames";
import "./styles.scss";

const MainContentLayoutComp = ({ isAppMode, topBarHeight, center, small, children }) => {
  if (topBarHeight) topBarHeight = topBarHeight + 10 + "px";
  return (
    <div
      className={classNames({
        "mainContentLayout": true,
        "mainContentLayout--center": !!center,
        "mainContentLayout--small": !!small,
      })}
      style={{ paddingTop: isAppMode && topBarHeight }}
    >
      {children}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAppMode: selectorIsAppMode(state),
  topBarHeight: selectorAppTopBarHeight(state),
});

export const MainContentLayout = connect(mapStateToProps)(MainContentLayoutComp);
