import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { parseState, persistState } from "./persist";
import { CLEAR_STATE } from "./actions";
import { IS_PRODUCTION } from "../utils/configs";
import globalState from "./globalState/reducer";
import entities from "./entities/reducer";

const appReducer = combineReducers({
  globalState,
  entities,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    const nextState = { globalState: globalState(undefined) };
    nextState.globalState.appMode = state.globalState?.appMode;
    nextState.globalState.appOsPlatform = state.globalState?.appOsPlatform;
    nextState.globalState.appTopBarHeight = state.globalState?.appTopBarHeight;
    nextState.globalState.appLocale = state.globalState?.appLocale;
    nextState.globalState.language = state.globalState?.language;
    nextState.globalState.userCountryCode = state.globalState?.userCountryCode;
    nextState.globalState.userPhoneNumber = state.globalState?.userPhoneNumber;
    state = nextState;
  }

  return appReducer(state, action);
};

const initialState = parseState();
const composeEnhancers = IS_PRODUCTION ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
store.subscribe(() => persistState(store.getState()));

export default store;
