import React from "react";
import classNames from "classnames";
import ArrowDown from "../icons/arrowDown";
import ArrowUp from "../icons/arrowUp";
import enhanceWithClickOutside from "react-click-outside";
import "./styles.scss";
import { Link } from "react-router-dom";

class Dropdown extends React.Component {
  state = {
    visible: false,
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  handleToggleOff = () => this.setState({ visible: false });

  handleSelect = (item) => () => {
    this.setState({ visible: false }, () => {
      this.props.onSelect(item);
    });
  };

  handleClickOutside = () => {
    this.handleToggleOff();
  };

  // TODO(30 sep. 2021) ~ Helge: Rewrite this to take a "type" instead of random props
  renderItem(item, index) {
    if (item.external) {
      return (
        <a key={index} className="dropdown__item" href={item.to} target={item.target}>
          {item.label}
        </a>
      );
    }

    if (item.nolink) {
      return (
        <div key={index} className="dropdown__item" onClick={this.props.onSelect ? this.handleSelect(item) : null}>
          {item.label}
        </div>
      );
    }

    return (
      <Link
        key={index}
        className="dropdown__item"
        onClick={this.props.onSelect ? this.handleSelect(item) : null}
        to={item.to}
      >
        {item.label}
      </Link>
    );
  }

  render() {
    const item = this.props.activeItem ? this.props.items.find(this.props.activeItem) : { label: this.props.label };
    return (
      <div
        className={classNames("dropdown", this.props.className, { "dropdown--visible": this.state.visible })}
        onClick={this.handleToggle}
      >
        <div className="dropdown__label">
          {item.label}
          {!this.props.hideIcon && (
            <div className="dropdown__label--icon">{this.state.visible ? <ArrowUp /> : <ArrowDown />}</div>
          )}
        </div>
        <div className={classNames("dropdown__box", { "dropdown__box--notification": this.props.notification })}>
          <div className="dropdown__boxInner">
            {this.props.items.map((item, index) => this.renderItem(item, index))}
          </div>
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(Dropdown);
