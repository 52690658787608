import React from "react";
import Footer from "../../components/layouts/footer";
import ICON_REVIWS from "../../components/icons/reviews";
import Accordion from "../../components/accordion";
import MainMenu from "../../components/menus/mainMenu";
import SessionCard from "../../components/sectionCard";
import SectionText from "../../components/newSectionText";
import Hide from "../../components/hide/hide";
import Reviews from "../../components/reviews";
import "./styles.scss";

const ViewFAQPage = () => {
  const reviews = [
    {
      rates: 5,
      subtitle: "Veldig smart - anbefales!!!",
      name: "Epicfika",
      title: "Veldig kult!!",
    },
    {
      rates: 4,
      subtitle:
        "Fikk nettop penger rett inn på konto etter at joggeskoene jeg kjøpte på nett gikk ned 298,- i pris👏👏👏Helt genialt",
      name: "hysterisk bra",
      title: "Genialt",
    },
    {
      rates: 5,
      subtitle: "Genialt konsept! Gleder meg til neste gang et av kjøpene mine synker i pris!",
      name: "Jorgenhaha",
      title: "Genialt",
    },
    {
      rates: 5,
      subtitle: "Veldig smart - anbefales!!!",
      name: "Epicfika",
      title: "Veldig kult!!",
    },
    {
      rates: 4,
      subtitle:
        "Fikk nettop penger rett inn på konto etter at joggeskoene jeg kjøpte på nett gikk ned 298,- i pris👏👏👏Helt genialt",
      name: "hysterisk bra",
      title: "Genialt",
    },
    {
      rates: 5,
      subtitle: "Genialt konsept! Gleder meg til neste gang et av kjøpene mine synker i pris!",
      name: "Jorgenhaha",
      title: "Genialt",
    },
  ];
  const questionList = [
    {
      title: "What is a price guarantee?",
      subTitle:
        "A price guarantee is a promise offered by many online stores. With this, they promise to pay back the difference if the price falls after your purchase. The promise period is usually limited to between 30 - 60 days.",
    },
    {
      title: `Which stores offer price guarantees?`,
      subTitle:
        "Most stores offer some sort of guarantee. There is a 14 day ‘right to return’ law, which you can use to get a refund, but some stores are better at honouring their policies than others.",
    },
    {
      title: `What happens when Tjommi finds a better price?`,
      subTitle: `We send an email from your account to the store, including the price information and asking them to refund the difference. You don’t need to do anything. If the claim is to a trusted Tjommi partner, we’ll handle the refund and send you a gift card.`,
    },
    {
      title: `When will I get my money back?`,
      subTitle: `If and when a store approves a refund claim, we’ll work quickly to get the money into your account. This usually takes between 3 -6 days, but can be specific to each store. You can see which stores are best at honouring refunds by checking their Tjommi score.`,
    },
    {
      title: "How long does it take to get a refund?",
      subTitle:
        "This can depend on the store and its customer services department, but usually between 3 - 6 days. If it’s a voucher from a trusted Tjommi partner, it should arrive immediately upon approval. You can see which stores are best at honouring refunds by checking their Tjommi score.",
    },
    {
      title: "What happens if a store rejects a claim?",
      subTitle: `If a store rejects a claim, you won’t get a refund, and that store will receive a lower Tjommi score. It’s possible that another store might step in and cover the difference with a voucher for you.`,
    },
    {
      title: "Which email account should I sign up with?",
      subTitle:
        "Sign up with the email account you use most often when shopping online. We are only looking for receipts, and we need receipts to deliver your our service. We don’t read your other emails.",
    },
    {
      title: "What if I don’t want to give Tjommi email access?",
      subTitle:
        "If you don't feel comfortable with us having access to your everyday inbox, consider creating  a new email account dedicated to shopping and sign up with that.",
    },
    {
      title: "I’ve just signed up. Why can’t I see any receipts, parcels, or vouchers?",
      subTitle:
        "It can take up to 30 minutes for Tjommi to get familiar with your inbox. If we still don't find any receipts, it might be because we don't have your store in our database. Feel free to suggest a new store by sending an email to hello@tjommi.app",
    },
    {
      title: "What is a Trusted Tjommi Partner?",
      subTitle:
        "These are stores who do the best job of honouring their refund policies, either with cash or vouchers.",
    },
    {
      title: "Why did I receive a voucher from a different store?",
      subTitle:
        "If a store rejects a claim, we give other stores the chance to cover the difference with a gift card, so that you never lose out.",
    },
    {
      title: "How does Tjommi make money?",
      subTitle:
        "Our standard fee is a 25% commission on cash refunds. Additionally, we connect brands with the right customers through relevant recommendations. This experience is sponsored by our partners.",
    },
    {
      title: "How does the Tjommi Chrome extension work?",
      subTitle:
        "Go to https://chrome.google.com/webstore/category/extensions and add Tjommi. Et voila, you’ll have all of your Tjommi vouchers at hand and ready to use when shopping online.",
    },
    {
      title: "Why am I missing receipts?",
      subTitle:
        "Tjommi searches for receipts once a day from the past 12 months. If new receipts aren’t showing up, please email us at hello@tjommi.app, remembering to include your Tjommi-connected email address, the store name, and purchase date.",
    },
    {
      title: "What if I find a better price myself?",
      subTitle:
        "We scan stores daily and rarely miss a price drop. Sometimes a price will drop but with no eligible refund. If you think we’ve missed something, email us at hello@tjommi.app",
    },
    {
      title: "How does Tjommi handle my emails?",
      subTitle:
        "Your data is secure with us. We only use our access to fetch receipts and when claiming a refund. Need Proof? We also have a license from Google and Outlook to deliver this service, and of course, we are fully GDPR compliant",
    },
    {
      title: "What happens to my data if I delete my account?",
      subTitle: "We will delete all of the information we hold about you and your account.",
    },
    {
      title: "What happens if Tjommi claims a refund and I have returned the products?",
      subTitle: "The store will know and will not process the refund or vouchers.",
    },
    {
      title: "Where is Tjommi available?",
      subTitle:
        "Tjommi is live in the UK, Denmark, and Norway. We’re expanding soon, so join our mailing list for first dibs on refunds.(Button) Join mailing list",
    },
    {
      title: "Why can’t I sign in with iCloud or Yahoo?",
      subTitle:
        "We’re working on adding iCloud and Yahoo, but for now you can only sign in with Gmail and Outlook. We are aiming on adding iCloud and Yahoo in 2023.",
    },
  ];
  return (
    <div className="FAQPage">
      <MainMenu />
      <div className="FAQPage-header-wrapper">
        <div className="FAQPage-header__title">How can we help?</div>
        <div className="FAQPage-header__title--sub">
          Our most frequent questions are answered here. If you can’t find what you need, email us at{" "}
          <span className="FAQPage-header__title--sub--purple">hello@tjommi.app</span>
        </div>
      </div>
      {/* SECTION 1 */}
      <div className="FAQPage-section">
        <div className="FAQPage-section-content__questions">
          <Accordion itemList={questionList} title="" />
        </div>

        <div className="FAQPage-section-content__questions__bottom__separator">
          {/* <hr className="FAQPage-section-content__questions__hr" /> */}
          {/* <div className="FAQPage-section-content__questions__Button">
            <Button label="View all" onClick={() => null} />
          </div> */}
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="FAQPage-section">
        <div className="FAQPage-section-content__accordion FAQPage-section-content__accordion__item FAQPage-section-content__accordion__extraTop">
          <hr className="FAQPage-section-content__accordion__hr" />

          <div className="FAQPage-section-content__accordion-wrapper">
            <div>
              <Hide on="mobile">
                <ICON_REVIWS />
              </Hide>
              <Hide on="desktop">
                <ICON_REVIWS width="25" height="27" />
              </Hide>
            </div>
            <div className="FAQPage-section-content__accordion__des">
              <div className="FAQPage-section-content__accordion__des__title--medium">App store reviews</div>
            </div>
          </div>
        </div>

        <Reviews reviews={reviews} />
      </div>

      {/* SECTION 4 */}
      <div className="FAQPage-section">
        <div className="FAQPage-section-content__sessionCards">
          <div className="FAQPage-section-content__sessionCards-wrapper">
            <SessionCard newsSubscribeMode backgroundColor="#FFFFFF" />
          </div>
          <div className="FAQPage-section-content__sessionCards-wrapper">
            <SessionCard refundType backgroundColor="#222222" dark bottomContentPosition="end" />
          </div>
        </div>
      </div>
      {/* SECTION 5 */}
      <div className="FAQPage-section">
        <div className="FAQPage-section-content__appstoreBanner">
          <SectionText
            specialSub={" Such a beautiful word."}
            sub={"Refund."}
            title={"Loved by 200,000  shoppers."}
            appStoreBanners
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewFAQPage;
