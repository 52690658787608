import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings, selectorNotificationsById, selectorPurchaseById } from "../../state/selectors";
import { markNotificationSeen } from "../../state/actionCreators";
import { HeaderLayout } from "../../components/layouts/header";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { EVENTS } from "../../components/notificationItem";
import Button from "../../components/inputs/button";
import getQuery from "../../utils/getQuery";
import illustrationPaymentAuthenticationRequired from "./assets/payment-authentication-required.png";
import illustrationPaymentFailed from "./assets/payment-failed.png";
import illustrationPaymentMethodRequired from "./assets/payment-method-required.png";
import illustrationPaymentSuccessful from "./assets/payment-successful.png";
import illustrationPriceDropApprovedGiftcard from "./assets/price-drop-approved-giftcard.png";
import illustrationPriceDropApprovedPhone from "./assets/price-drop-approved-phone-call.png";
import illustrationPriceDropApproved from "./assets/price-drop-approved.png";
import illustrationPriceDropRejected from "./assets/price-drop-rejected.png";
import illustrationPriceDropRequested from "./assets/price-drop-requested.png";
import illustrationReceiptHasExpired from "./assets/receipt-has-expired.png";
import illustrationReceiptsRegistered from "./assets/receipt-registered.png";
import illustrationPriceDropRejectedGiftCard from "./assets/price-drop-rejected-giftcard.png";
import illustrationMedalReceived from "./assets/medal-received.png";
import illustrationReceiptItemReturned from "./assets/receipt-returned.png";
import illustrationNoReceiptFound from "./assets/no-receipts-found.png";
import illustrationParcelDeliver from "./assets/parcel-deliver.png";
import illustrationParcelFound from "./assets/parcel-found.png";
import RatingCard from "../../views/ratings/components/ratingCard";
import "./styles.scss";
import className from "classnames";
import { formatCurrency } from "../../utils/formatter";
import { ControlsLayout } from "../../components/layouts/controls";
import routes from "../../utils/routes";
import Hide from "../../components/hide/hide";

const Colors = {
  pink: "pink",
  purple: "purple",
  orange: "orange",
  blue: "blue",
  green: "green",
};

class ViewNotification extends React.Component {
  getContent() {
    const notification = this.props.notification;

    const strs = this.props.strs;
    let headline, text, color, illustration, route, label;
    const currency = notification?.data?.currency || "nok";
    const product = notification?.data?.product || "...";
    const store = notification?.data?.store || "...";
    const partner = notification?.data?.partner || strs.ClaimRejectedCompetitorGiftcardPartnerFallback;
    const amount = notification?.data?.diff || notification?.data?.amount || "...";
    const amountFormatted = formatCurrency(amount, currency);
    const purchaseItemId = this.props.purchase?.items?.[0]?.receipt_item_id;
    if (purchaseItemId) route = "/overview/active/" + purchaseItemId;

    label = this.props.strs.view;

    switch (notification.data.event_type) {
      case EVENTS.PriceDropRequested:
        headline = strs.PriceDropRequestedHeadline;
        text = strs.PriceDropRequestedText({ product, store, amount });
        color = Colors.purple;
        illustration = illustrationPriceDropRequested;

        if (this.props.notification?.data?.receipt_item_id) {
          route = "/overview/refunds/" + this.props.notification?.data?.receipt_item_id;
        } else {
          route = this.props.notification.path;
        }
        break;
      case EVENTS.PriceDropApproved:
        const approvalType = notification?.data?.approval_type;
        color = Colors.pink;
        route = "/overview";
        if (approvalType === "money") {
          headline = strs.PriceDropApprovedMoneyHeadline;
          text = strs.PriceDropApprovedMoneyText({
            store,
            product,
            amount: amountFormatted,
          });
          illustration = illustrationPriceDropApproved;
        } else if (approvalType === "giftcard") {
          color = Colors.green;
          headline = strs.PriceDropApprovedGiftcardHeadline;
          text = strs.PriceDropApprovedGiftcardText({
            store,
            product,
            amount: amountFormatted,
          });
          illustration = illustrationPriceDropApprovedGiftcard;
        } else if (approvalType === "physical") {
          headline = strs.PriceDropApprovedPhysicalHeadline;
          text = strs.PriceDropApprovedPhysicalText({
            store,
            product,
            amount: amountFormatted,
          });
          illustration = illustrationPriceDropApproved;
        } else if (approvalType === "phone") {
          headline = strs.PriceDropApprovedPhoneHeadline;
          text = strs.PriceDropApprovedPhoneText({
            store,
            product,
            amount: amountFormatted,
          });
          illustration = illustrationPriceDropApprovedPhone;
          if (notification.data.phone_number) {
            route = "tel:" + notification.data.phone_number;
          }
        } else return null;
        break;
      case EVENTS.PriceDropRejected:
        headline = strs.PriceDropRejectedHeadline;
        text = strs.PriceDropRejectedText({ store, product });
        color = Colors.orange;
        illustration = illustrationPriceDropRejected;
        route = "/overview";
        break;
      case EVENTS.ReceiptHasExpired:
        headline = strs.ReceiptHasExpiredHeadline;
        text = strs.ReceiptHasExpiredText({ store });
        color = Colors.orange;
        illustration = illustrationReceiptHasExpired;
        route = `/overview/receipts/${this.props.notification.data.receipt_id}`;
        break;
      case EVENTS.ReceiptProcessed:
        headline = strs.ReceiptProcessedHeadline({ store });
        text = strs.ReceiptProcessedText;
        color = Colors.purple;
        illustration = illustrationReceiptsRegistered;
        route = `/overview/receipts/${this.props.notification.data.receipt_id}`;
        label = strs.ViewReceipt;
        break;
      case EVENTS.MultipleReceiptsProcessed:
        headline = strs.MultipleReceiptsProcessedHeadline;
        text = strs.MultipleReceiptsProcessedText;
        color = Colors.purple;
        illustration = illustrationReceiptsRegistered;
        route = "/overview";
        break;
      case EVENTS.PaymentFailed:
        headline = strs.PaymentFailedHeadline;
        text = strs.PaymentFailedText;
        color = Colors.orange;
        illustration = illustrationPaymentFailed;
        route = "/profile/card/edit";
        break;
      case EVENTS.PaymentAuthenticationRequired:
        headline = strs.PaymentAuthenticationRequiredHeadline;
        text = strs.PaymentAuthenticationRequiredText;
        color = Colors.blue;
        illustration = illustrationPaymentAuthenticationRequired;
        route = this.props.notification.data.path;
        label = strs.Authorize;
        break;
      case EVENTS.PaymentMethodRequired:
        headline = strs.PaymentMethodRequiredHeadline;
        text = strs.PaymentMethodRequiredText;
        color = Colors.blue;
        illustration = illustrationPaymentMethodRequired;
        route = "/profile/card/edit";
        label = strs.AddPaymentCard;
        break;
      case EVENTS.PaymentSuccessful:
        headline = strs.PaymentSuccessfulHeadline;
        text = strs.PaymentSuccessfulText;
        color = Colors.green;
        illustration = illustrationPaymentSuccessful;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      case EVENTS.MedalReceived:
        headline = strs.MedalReceivedHeadline;
        text = strs.MedalReceivedText({ name: notification?.data?.from_name });
        color = Colors.pink;
        illustration = illustrationMedalReceived;
        label = this.props.strs.back;
        route = "/overview/notifications";
        break;
      case EVENTS.FreeText:
        headline = notification?.data?.title;
        text = notification?.data?.text;
        color = Colors.blue;
        illustration = illustrationPriceDropRequested; // TODO(20 des. 2021) ~ Helge: Custom illustration here
        route = notification?.data?.meta?.path || null;
        label = notification?.data?.meta?.label || this.props.strs.view;
        break;
      case EVENTS.ClaimApprovedMoney:
        headline = strs.ClaimApprovedMoneyHeadline({ store });
        text = strs.ClaimApprovedMoneyText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.pink;
        illustration = illustrationPriceDropApproved;
        route = notification?.data?.path || null;
        label = this.props.strs.viewMyClaim;
        break;
      case EVENTS.ClaimApprovedGiftcard:
        headline = strs.ClaimApprovedGiftcardHeadline({ store });
        text = strs.ClaimApprovedGiftcardText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.green;
        illustration = illustrationPriceDropApprovedGiftcard;
        route = notification?.data?.path || null;
        label = strs.getMyGiftcard;
        break;
      case EVENTS.ClaimApprovedStoreCredit:
        headline = strs.ClaimApprovedStoreCreditHeadline;
        text = strs.ClaimApprovedStoreCreditText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.pink;
        illustration = illustrationPriceDropApproved;
        route = notification?.data?.path || null;
        label = this.props.strs.viewMyClaim;
        break;
      case EVENTS.ClaimApprovedInvoiceReduced:
        headline = strs.ClaimApprovedInvoiceReducedHeadline;
        text = strs.ClaimApprovedInvoiceReducedText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.pink;
        illustration = illustrationPriceDropApproved;
        route = notification?.data?.path || null;
        label = this.props.strs.viewMyClaim;
        break;
      case EVENTS.ClaimApprovedCallRequired:
        headline = strs.ClaimApprovedCallRequiredHeadline;
        text = strs.ClaimApprovedCallRequiredText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.pink;
        illustration = illustrationPriceDropApprovedPhone;
        route = `tel:${notification.data.phone_number}` || null;
        label = strs.ClaimApprovedCallRequiredBtnTitle({ store });
        break;
      case EVENTS.ClaimApprovedPickupRequired:
        headline = strs.ClaimApprovedPickupRequiredHeadline;
        text = strs.ClaimApprovedPickupRequiredText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.pink;
        illustration = illustrationPriceDropApproved;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      case EVENTS.ClaimApprovedPartner:
        headline = strs.ClaimApprovedPartnerHeadline;
        text = strs.ClaimApprovedPartnerText({
          store,
          amount: amountFormatted,
          product,
        });
        color = Colors.green;
        illustration = illustrationPriceDropApprovedGiftcard;
        route = notification?.data?.path || null;
        label = strs.getMyGiftcard;
        break;
      case EVENTS.ClaimSent:
        headline = strs.ClaimSentHeadline({ store });
        text = strs.ClaimSentText({ store, amount: amountFormatted, product });
        color = Colors.purple;
        illustration = illustrationPriceDropRequested;
        route = notification?.data?.path || null;
        label = strs.viewPurchase;
        break;
      case EVENTS.ClaimRejected:
        headline = strs.ClaimRejectedHeadline;
        text = strs.ClaimRejectedText({ store, product });
        color = Colors.orange;
        illustration = illustrationPriceDropRejected;
        route = notification?.data?.path || null;
        label = strs.checkOutScore;
        break;
      case EVENTS.ClaimRejectedCompetitorGiftcard:
        headline = strs.ClaimRejectedCompetitorGiftcardHeadline;
        text = strs.ClaimRejectedCompetitorGiftcardText({
          store,
          product,
          amount: amountFormatted,
          partner,
        });
        color = Colors.green;
        illustration = illustrationPriceDropRejectedGiftCard;
        route = notification?.data?.path || null;
        label = strs.claimMyGiftcard;
        break;
      case EVENTS.GiftcardReminder:
        headline = strs.GiftcardReminderHeadline;
        text = strs.GiftcardReminderText({ store });
        color = Colors.green;
        illustration = illustrationPriceDropApprovedGiftcard;
        route = notification?.data?.path || null;
        label = strs.ViewGiftCard;
        break;
      case EVENTS.GiftcardExpiryReminder:
        headline = strs.GiftcardExpiryReminderHeadline;
        text = strs.GiftcardExpiryReminderText;
        color = Colors.green;
        illustration = illustrationPriceDropApprovedGiftcard;
        route = notification?.data?.path || null;
        label = strs.ViewGiftCard;
        break;
      case EVENTS.ReceiptItemReturned:
        headline = strs.ReceiptItemReturnedHeadline;
        text = strs.ReceiptItemReturnedText({ product, store });
        color = Colors.orange;
        illustration = illustrationReceiptItemReturned;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      case EVENTS.ReceiptExpired:
        headline = strs.ReceiptExpiredHeadline;
        text = strs.ReceiptExpiredText({ product, store });
        color = Colors.orange;
        illustration = illustrationReceiptHasExpired;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      case EVENTS.NoReceiptsFound:
        headline = strs.NoReceiptsFoundHeadline;
        text = strs.NoReceiptsFoundText;
        color = Colors.purple;
        illustration = illustrationNoReceiptFound;
        route = notification?.data?.path || null;
        label = strs.Next;
        break;
      case EVENTS.ReceiptsRegistered:
        headline = strs.ReceiptsRegisteredHeadline;
        text = strs.ReceiptsRegisteredText;
        color = Colors.purple;
        illustration = illustrationReceiptsRegistered;
        route = notification?.data?.path || null;
        label = strs.ViewReceipts;
        break;
      case EVENTS.ParcelArrivingToday:
        headline = strs.ParcelArrivingTodayHeadline;
        text = strs.ParcelArrivingTodaytext({ store });
        color = Colors.blue;
        illustration = illustrationParcelDeliver;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      case EVENTS.ParcelDelivered:
        headline = strs.ParcelDeliveredHeadline;
        text = strs.ParcelDeliveredText({ store });
        color = Colors.green;
        illustration = illustrationReceiptItemReturned;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      case EVENTS.ParcelFound:
        headline = strs.ParcelFoundHeadline;
        text = strs.ParcelFoundText({ store });
        color = Colors.blue;
        illustration = illustrationParcelFound;
        route = notification?.data?.path || null;
        label = strs.view;
        break;
      default:
        return null;
    }

    return { headline, text, color, illustration, route, label };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notification?.id !== this.props.notification?.id) {
      this.props.markNotificationSeen(this.props.notification?.id);
    }
  }

  render() {
    const notification = this.props.notification;
    if (!notification) return null;

    const path = this.props.redirect ? this.props.redirect : "/overview/notifications";

    if (notification.data.event_type === "OneOffTrustedPartner") {
      return (
        <div className={"viewNotification viewNotification--multiple-buttons green"}>
          <MainContentLayout>
            <HeaderLayout left={{ type: "cancelLeft", path }} />

            <div className={"content"}>
              <h1 className="headline">{this.props.trustedPartnerStrs.title}</h1>
              <div className="text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.trustedPartnerStrs.text,
                  }}
                />
              </div>
              <div className="buttonsWrapper">
                <Hide on={"desktop"}>
                  <ControlsLayout>
                    <Button
                      type="transparent"
                      className={""}
                      label={this.props.trustedPartnerStrs.btnScore}
                      onClick={() => this.props.history.push(routes.discoveries.path)}
                    />
                    <Button
                      type="transparent"
                      className={""}
                      label={this.props.trustedPartnerStrs.btnTerms}
                      onClick={() => this.props.history.push(routes.terms.path + "?redirect=" + routes.overview.path)}
                    />
                  </ControlsLayout>
                </Hide>
                <Hide on={"mobile"}>
                  <Button
                    type="transparent"
                    className={""}
                    label={this.props.trustedPartnerStrs.btnScore}
                    onClick={() => this.props.history.push(routes.discoveries.path)}
                  />
                  <Button
                    type="transparent"
                    className={""}
                    label={this.props.trustedPartnerStrs.btnTerms}
                    onClick={() => this.props.history.push(routes.terms.path + "?redirect=" + routes.overview.path)}
                  />
                </Hide>
              </div>
            </div>

            <div className="illustration">
              <img alt="" src={illustrationPriceDropApprovedGiftcard} />
            </div>
          </MainContentLayout>
        </div>
      );
    }

    const content = this.getContent();
    if (!content) return null;

    const { headline, text, illustration, color, route, label } = content;
    const preferredStores = notification.data.preferred_stores;

    const hasPreferredStores = preferredStores?.length > 0;
    const shouldShowPreferredStores =
      [EVENTS.ClaimRejectedCompetitorGiftcard, EVENTS.ClaimRejected].includes(notification.data.event_type) &&
      hasPreferredStores;

    return (
      <div
        className={className("viewNotification", color, {
          "viewNotification--scroll": shouldShowPreferredStores,
        })}
      >
        <MainContentLayout>
          <HeaderLayout left={{ type: "cancelLeft", path }} />

          <div
            className={className("content", {
              "content--with--stores": shouldShowPreferredStores,
            })}
          >
            <h1 className="headline">{headline}</h1>
            <div className="text">{text}</div>
            {
              // notification?.
              shouldShowPreferredStores ? (
                <div style={{ marginTop: 32, display: "grid" }}>
                  <h1 className="headline">{this.props.strs.StoresListHeadline}</h1>
                  <div className="text">{this.props.strs.StoresListText}</div>
                  <div className="grid">
                    {preferredStores.map((rating) => {
                      return <RatingCard rating={rating} key={rating?.id} />;
                    })}
                  </div>
                </div>
              ) : null
            }

            {route && (
              <div style={{ paddingBottom: "25px" }}>
                <Button
                  type="transparent"
                  label={label}
                  to={route}
                  className={shouldShowPreferredStores ? "inputButton--notification" : ""}
                />
              </div>
            )}
          </div>

          <div className="illustration">
            <img
              alt=""
              src={illustration}
              style={{
                marginBottom: notification?.data?.event_type === EVENTS.MedalReceived ? 100 : null,
              }}
            />
          </div>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.location.pathname.match(/notifications\/(.+)/)?.[1];
  const notification = selectorNotificationsById(state, id);
  return {
    redirect: getQuery()?.redirect,
    purchase: selectorPurchaseById(state, notification?.data?.purchase_id),
    notification,
    strs: selectorLanguageStrings(state, "notifications"),
    trustedPartnerStrs: selectorLanguageStrings(state, "trustedPartner"),
    commonStrs: selectorLanguageStrings(state, "referral"),
  };
};

const mapDispatchToProps = { markNotificationSeen };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewNotification));
