import React from "react";
import ICON_LINKEDIN from "../../components/icons/linkedin";
import "./styles.scss";

const teamMemberCard = ({ name, role, linkedIn, description, image }) => {
  return (
    <div className="teamMemberCard">
      <div className="teamMemberCard__wrapper">
        <img src={image} alt="Tjommi" className="teamMemberCard--img" />
        <div className="teamMemberCard__body__wrapper">
          <div className="teamMemberCard__body--header">
            <div>
              <div className="teamMemberCard__body--header--name">{name}</div>
              <div className="teamMemberCard__body--header--name teamMemberCard__body--header--name--role">{role}</div>
            </div>
            <div className="teamMemberCard_linkedin">
              <a href={linkedIn}>
                <ICON_LINKEDIN />
              </a>
            </div>
          </div>
          <div className="teamMemberCard__body--description">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default teamMemberCard;
