import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  selectorLanguageStrings,
  selectorNotificationsByPurchaseId,
  selectorPurchaseItemById,
} from "../../state/selectors";
import TotalSavings from "../../components/totalSavings";
import { formatCurrency, formatDate } from "../../utils/formatter";
import { MainContentLayout } from "../../components/layouts/mainContent";
import { HeaderLayout } from "../../components/layouts/header";
import Outline from "../../components/layouts/outline";
import NotificationItem from "../../components/notificationItem";
import DetailLine from "../../components/detailLine";
import SpinnerCircle from "../../components/spinnerCircle";
import Hide from "../../components/hide/hide";
import image1 from "./assets/1.png";
import image2 from "./assets/2.png";
import image3 from "./assets/3.png";
import "./styles.scss";
import ArrowLeft from "../../components/icons/arrowMiniLeft";

class viewPurchase extends React.Component {
  render() {
    const purchase = this.props.purchase;
    if (!purchase) return null;

    // status
    let status = null;
    const isActive = purchase.daysLeft > 0;
    const hasRefund = purchase.refunded > 0;
    if (isActive && hasRefund) status = 2;
    else if (!isActive && hasRefund) status = 3;
    else if (isActive) status = 1;
    else status = 4;

    // taxonomy
    const strs = this.props.strs;
    const quantityStr = purchase.quantity > 1 ? purchase.quantity + "x " : "";
    let image = null;

    let purchasePriceStr = formatCurrency(purchase.purchasePrice, purchase.currency);
    let newPriceStr = null;
    let diffStr = null;
    let findersFeeStr = null;
    let totalSavingsStr = null;
    let totalSavingActive = false;

    // TODO(04 okt. 2021) ~ Helge: Redo this piece of shit code, dont show spinner if we have a value.
    switch (status) {
      case 1:
        image = image1;
        newPriceStr = (
          <>
            {strs.searchingLabel} <SpinnerCircle />
          </>
        );
        diffStr = (
          <>
            {strs.searchingLabel} <SpinnerCircle />
          </>
        );
        findersFeeStr = (
          <>
            {strs.searchingLabel} <SpinnerCircle />
          </>
        );
        totalSavingsStr = (
          <>
            {strs.searchingLabel} <SpinnerCircle />
          </>
        );
        break;
      case 2:
        totalSavingActive = true;
        image = image2;
        newPriceStr = (
          <>
            {formatCurrency(purchase.newPrice, purchase.currency)} <SpinnerCircle />
          </>
        );
        diffStr = (
          <>
            {formatCurrency(purchase.diff, purchase.currency)} <SpinnerCircle />
          </>
        );
        findersFeeStr = (
          <>
            {formatCurrency(purchase.commission, purchase.currency)}
            <SpinnerCircle />
          </>
        );
        totalSavingsStr = (
          <>
            {formatCurrency(purchase.totalSavings, purchase.currency)}
            <SpinnerCircle />
          </>
        );
        break;
      case 3:
        image = image2;
        totalSavingActive = true;
        newPriceStr = formatCurrency(purchase.newPrice, purchase.currency);
        diffStr = formatCurrency(purchase.diff, purchase.currency);
        findersFeeStr = formatCurrency(purchase.commission, purchase.currency);
        totalSavingsStr = formatCurrency(purchase.totalSavings, purchase.currency);
        break;
      case 4:
        image = image3;
        newPriceStr = "-";
        diffStr = "-";
        findersFeeStr = "-";
        totalSavingsStr = "-";
        break;
      default:
        return null;
    }

    return (
      <div className="viewPurchase">
        <MainContentLayout center={true}>
          <HeaderLayout
            left={
              <div className="headerLayout--leftArrow" onClick={() => this.props.history.goBack()}>
                <ArrowLeft color="black" />
              </div>
            }
          />

          <Hide on="desktop">
            <div className={classNames("heroImage", `heroImage--state${status}`)}>
              <img alt="" src={image} />
            </div>
          </Hide>
          <div className="label">{strs.purchaseLabel}</div>
          <h1 className="headline">{quantityStr + purchase.product}</h1>
          {purchase.refunded > 0 && (
            <Outline>
              <TotalSavings amount={purchase.totalSavings} />
            </Outline>
          )}
          <Outline label={strs.statusLabel}>
            <div className="detailLine">{isActive ? strs.statusActive : strs.statusExpired}</div>
            <DetailLine label={strs.detailPurchasePrice} value={purchasePriceStr} />
            <DetailLine label={strs.detailNewPrice} value={newPriceStr} />
            <DetailLine label={strs.detailDifference} value={diffStr} />
            <DetailLine label={strs.detailCommission} value={findersFeeStr} />
            <DetailLine
              valueColor={totalSavingActive ? "#E22E61" : "black"}
              label={strs.detailTotalSavings}
              value={totalSavingsStr}
            />
          </Outline>
          {this.props.notifications.length > 0 && (
            <>
              <Outline label={strs.notificationsLabel}>
                {this.props.notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    redirect={`/overview/active/${this.props.purchase.id}`}
                  />
                ))}
              </Outline>
            </>
          )}
          <Outline label={strs.detailsLabel}>
            <DetailLine label={strs.detailPurchaseDate} value={formatDate(this.props.purchase.date, "date-year")} />
            <DetailLine
              label={strs.detailExpirationDate}
              value={formatDate(this.props.purchase.expireDate, "date-year")}
            />
            <DetailLine
              label={strs.detailExpirationDaysRemaining}
              value={formatDate(this.props.purchase.expireDate, "expires")}
            />
          </Outline>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const option = ownProps.location.pathname.match(/active|refunds|expired/i)?.[0] || "active";
  const id = parseInt(ownProps.location.pathname.match(/(\d+)/)?.[0]) || null;
  const purchase = selectorPurchaseItemById(state, id);
  return {
    option,
    purchase,
    notifications: selectorNotificationsByPurchaseId(state, purchase?.purchaseId),
    strs: selectorLanguageStrings(state, "purchase"),
  };
};

export default withRouter(connect(mapStateToProps)(viewPurchase));
