import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../state/selectors";
import routes from "../../../utils/routes";
import ConnectedEmailModal from "../../../components/modals/connectedEmailModal/ConnectedEmailModal";
import DisconnectedEmailModal from "../../../components/modals/disconnectedEmailModal/DisconnectedEmailModal";
import getQuery from "../../../utils/getQuery";

const ViewProfileConfirmation = ({ history, location }) => {
  const [open, setOpen] = useState(true);

  // handle error if email connection failed
  if (getQuery()?.status === "error") {
    this.props.history.push(routes.profileEmails.path + "?status=error");
    return null;
  }

  const handleCloseModal = () => {
    setOpen(false);
    history.push(routes.profileEmails.path);
  };

  const type = location.pathname.match(/emailDisconnected|emailConnected/gi)?.[0];
  switch (type) {
    case "emailConnected":
      return <ConnectedEmailModal visible={open} closeBtn={handleCloseModal} />;
    case "emailDisconnected":
    default:
      return <DisconnectedEmailModal visible={open} closeBtn={handleCloseModal} />;
  }
};

const mapStateToProps = (state) => ({
  strs: selectorLanguageStrings(state, "profileConfirmation"),
});

export default withRouter(connect(mapStateToProps)(ViewProfileConfirmation));
