import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { confirmCardSetup, setToastMessage, updateMyUser } from "../../../state/actionCreators";
import { selectorLanguageStrings } from "../../../state/selectors";
import Button from "../../../components/inputs/button";
import { ControlsLayout } from "../../../components/layouts/controls";
import { HeaderLayout } from "../../../components/layouts/header";
import { MainContentLayout } from "../../../components/layouts/mainContent";
import Outline from "../../../components/layouts/outline";
import DetailLine from "../../../components/detailLine";
import StripeClient from "../../../services/stripeClient";
import { log } from "../../../services/log";
import routes from "../../../utils/routes";
import "./styles.scss";

const STRIPE_CARD_ELEMENT_ID = "stripe-card-element";

class ViewProfileCard extends React.Component {
  state = {
    spinner: false,
    edit: false,
  };

  cardElement = null;

  componentDidMount() {
    this.loadStripe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.edit === false && this.state.edit === true) this.loadStripe();
  }

  loadStripe() {
    return StripeClient.get().then((stripe) => {
      const elm = document.querySelector("#" + STRIPE_CARD_ELEMENT_ID);
      if (!elm) return;
      const elements = stripe.elements();
      this.cardElement = elements.create("card");
      this.cardElement.mount("#" + STRIPE_CARD_ELEMENT_ID);
      this.cardElement.focus();
    });
  }

  handleSaveClick = (paymentMethodString) => () => {
    this.setState({ spinner: true });
    const paymentMethod =
      paymentMethodString === "cardElement" ? { card: this.cardElement } : this.props.paymentMethod.payment_method_id;
    this.props
      .confirmCardSetup(paymentMethod, null)
      .then(() => {
        this.setState({ spinner: false, edit: false });
        this.props.setToastMessage(this.props.strs.cardSuccessfullyAdded);
        this.props.history.push(routes.profileCard.path);
      })
      .catch((error) => {
        this.props.setToastMessage(error?.message || "Something went wrong, try again or contact hello@tjommi.app.");
        this.setState({ spinner: false });
        log(error, this.props.user);
      });
  };

  handleEditClick = () => this.setState({ edit: !this.state.edit });

  renderCard = () => {
    return (
      <>
        <HeaderLayout left={{ type: "arrowLeft", path: routes.profileRefunds.path }} />
        <h1>{this.props.paymentMethod && !this.state.edit ? this.props.strs.editTitle : this.props.strs.title}</h1>
        <div className="text">{this.props.strs.text}</div>
        {!this.props.paymentMethod || this.state.edit ? (
          <>
            <div id={STRIPE_CARD_ELEMENT_ID} className="cardInput"></div>
            <ControlsLayout>
              <Button
                type="pink"
                label={this.props.strs.buttonSave}
                icon={this.state.spinner && "spinner"}
                disabled={this.state.spinner}
                onClick={this.handleSaveClick("cardElement")}
              />
            </ControlsLayout>
          </>
        ) : (
          <Outline>
            <DetailLine
              slim
              pencil
              flip
              label={(this.props.paymentMethod?.brand || "").toUpperCase() + " XXXX" + this.props.paymentMethod.last4}
              onClick={this.handleEditClick}
            />
          </Outline>
        )}
      </>
    );
  };

  renderVerifyCard = () => {
    return (
      <>
        <HeaderLayout left={{ type: "arrowLeft", path: routes.profile.path }} />
        <h1>{this.props.strs.title}</h1>
        <div className="text">{this.props.strs.consent}</div>
        <ControlsLayout>
          <Button
            type="pink"
            label={this.props.strs.buttonConfirmCard}
            icon={this.state.spinner && "spinner"}
            disabled={this.state.spinner}
            onClick={this.handleSaveClick("paymentMethod")}
          />
        </ControlsLayout>
      </>
    );
  };

  render() {
    return (
      <div className="viewProfileCard ph-no-capture">
        <MainContentLayout center small>
          <Switch>
            <Route path={routes.profileCard.subroutes.verify.path} render={this.renderVerifyCard} />
            <Route path={routes.profileCard.path} render={this.renderCard} />
          </Switch>
        </MainContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.globalState.myUser,
  paymentMethod: state.globalState.myUser?.paymentMethod,
  strs: selectorLanguageStrings(state, "profileCard"),
});

const mapDispatchToProps = { updateMyUser, confirmCardSetup, setToastMessage };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewProfileCard));
