import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectorLanguageStrings } from "../../../../state/selectors";
import Button from "../../../../components/inputs/button";
import illustrationPriceDropRequested from "../../../notification/assets/price-drop-requested.png";
import "./styles.scss";
import routes from "../../../../utils/routes";
import GenericModal from "../../../../components/genericModal";

const ChatSuccess = ({ strs, history }) => {
  return (
    <GenericModal
      isVisible={true}
      closeBtn={() => history.push(routes.profile.path)}
      backgroundImage={illustrationPriceDropRequested}
      backgroundColor={"#896EEB"}
      title={strs.headline}
      text={strs.text}
      button={<Button type="transparent" label={strs.button} to={routes.profile.path} />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    strs: selectorLanguageStrings(state, "supportSuccess"),
  };
};

export default withRouter(connect(mapStateToProps)(ChatSuccess));
