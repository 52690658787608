import React from "react";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import routes from "../../../utils/routes";
import IconLogo from "../../icons/logo";
import IconBurgerMenu from "../../icons/burgerMenu";
import IconMuseum from "../../icons/museum";
import IconLocationHome from "../../icons/locationHome";
import IconForwardArrow from "../../icons/forwardArrow";
import disableBodyScrollForMobile from "../../../utils/disableBodyScrollForMobile";
import IconCancel from "../../icons/cancelLight";
import { FACEBOOK_URL, GET_APP_URL } from "../../../utils/configs";
import AppStoreBanners from "../../appStoreBanners";
import ICON_ARROW_OUTWARD from "../../../components/layouts/footer/assets/arrow_outward.png";

import Button from "../../inputs/newButton";
import "./styles.scss";
import { selectorUserOnBoard } from "../../../state/selectors";
import { connect } from "react-redux";

class NewMainMenu extends React.Component {
  state = {
    visible: false,
    scrolled: false,
    pathname: "",
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
    const { pathname } = this.props.location;
    this.setState({ pathname });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.props.ignoreScroll) return;
    this.setState({ scrolled: window.scrollY > 20 });
  };

  handleItemClick = (path) => {
    this.setState({ visible: false });
    this.props.history.push(path);
  };

  handleToggleClick = () => {
    const visible = !this.state.visible;
    disableBodyScrollForMobile(visible);
    this.setState({ visible });
  };

  render() {
    return (
      <div
        className={classNames({
          "navbarTopMenu": true,
          "navbarTopMenu--scrolled": this.state.scrolled && !this.props.dark,
          "navbarTopMenu--dark": this.props.dark ? true : false,
          "navbarTopMenu--visible": this.state.visible ? true : false,
        })}
      >
        <div
          className={classNames({
            "navbarTopMenu_burgerMenu": true,
            "navbarTopMenu_burgerMenu--white":
              this.state.pathname === routes.merchant.path || this.state.pathname === routes.howitworks.path
                ? true
                : false,
          })}
          onClick={this.handleToggleClick}
        >
          {this.state.visible ? <IconCancel size={40} /> : <IconBurgerMenu />}
        </div>

        <div className="navbarTopMenu_left">
          <Link
            className={classNames({
              "navbarTopMenu-item navbarTopMenu_text": true,
              "navbarTopMenu-item navbarTopMenu_text--white": this.props.dark ? true : false,
            })}
            to={routes.index.path}
          >
            Home
            <div
              className={classNames({
                "navbarTopMenu-item__icon": true,
                "navbarTopMenu-item__icon--active": this.state.pathname === routes.index.path ? true : false,
              })}
            >
              <IconForwardArrow width={20} height={20} color={this.props.dark ? "ffffff" : "222222"} />
            </div>
          </Link>
          {/* <Link
            className={classNames({
              "navbarTopMenu-item navbarTopMenu_text": true,
              "navbarTopMenu-item navbarTopMenu_text--white": this.props.dark ? true : false,
            })}
            to={routes.about.path}
          >
            About us
            <div
              className={classNames({
                "navbarTopMenu-item__icon": true,
                "navbarTopMenu-item__icon--active": this.state.pathname === routes.about.path ? true : false,
              })}
            >
              <IconForwardArrow width={20} height={20} />
            </div>
          </Link> */}
          {/* <Link
            className={classNames({
              "navbarTopMenu-item navbarTopMenu_text": true,
              "navbarTopMenu-item navbarTopMenu_text--white": this.props.dark ? true : false,
            })}
            to={routes.ratings.path}
          >
            Score
            <div
              className={classNames({
                "navbarTopMenu-item__icon": true,
                "navbarTopMenu-item__icon--active": this.state.pathname === routes.ratings.path ? true : false,
              })}
            >
              <IconForwardArrow width={20} height={20} />
            </div>
          </Link> */}
          <Link
            className={classNames({
              "navbarTopMenu-item navbarTopMenu_text": true,
              "navbarTopMenu-item navbarTopMenu_text--white": this.props.dark ? true : false,
            })}
            to={routes.howitworks.path}
          >
            How it works
            <div
              className={classNames({
                "navbarTopMenu-item__icon": true,
                "navbarTopMenu-item__icon--active": this.state.pathname === routes.howitworks.path ? true : false,
              })}
            >
              <IconForwardArrow width={20} height={20} color={this.props.dark ? "#ffffff" : "#222222"} />
            </div>
          </Link>
        </div>
        <div
          className={classNames({
            "navbarTopMenu_middle": true,
            "navbarTopMenu_middle--visible": this.state.visible ? true : false,
          })}
        >
          <Link className="item" to={routes.index.path}>
            <IconLogo color={this.props.dark ? "white" : "#222222"} />
          </Link>
        </div>
        <div className="navbarTopMenu_right">
          <Button
            onClick={() => {
              this.handleItemClick(
                this.state.pathname !== routes.merchant.path ? routes.merchant.path : routes.index.path
              );
            }}
            label={this.state.pathname === routes.merchant.path ? "Consumer" : "Merchant"}
            backgroundColor={this.state.pathname === routes.merchant.path ? "#FA779D" : "#4ADAB0"}
            textColor="#000000"
            icon={this.state.pathname === routes.merchant.path ? <IconLocationHome /> : <IconMuseum />}
            leftIcon
          />
          <Button
            label={"Sign in"}
            light
            onClick={() => {
              this.props.isOnBoard
                ? this.handleItemClick(routes.overview.path)
                : this.handleItemClick(routes.onboarding.path);
            }}
          />
          <Button
            textColor={this.props.dark ? "#222222" : "#ffffff"}
            backgroundColor={this.props.dark ? "#ffffff" : "#222222"}
            label={"Get the app"}
            onClick={() => {
              window.open(GET_APP_URL);
            }}
          />
        </div>
        <div
          className={classNames({
            "navbarTopMenu_merchant": true,
            "navbarTopMenu_merchant--visible": this.state.visible ? true : false,
          })}
        >
          <Button
            onClick={() => {
              this.handleItemClick(
                this.state.pathname !== routes.merchant.path ? routes.merchant.path : routes.index.path
              );
            }}
            backgroundColor={this.state.pathname === routes.merchant.path ? "#FA779D" : "#4ADAB0"}
            textColor="#000000"
            icon={this.state.pathname === routes.merchant.path ? <IconLocationHome /> : <IconMuseum />}
            leftIcon
            circle
          />
        </div>
        <div
          className={classNames({
            "navbarTopMenu_burgerMenu__content": true,
            "navbarTopMenu_burgerMenu__content--visible": this.state.visible,
          })}
        >
          <div>
            <div className="navbarTopMenu_burgerMenu__content-item">
              <Link
                className={classNames({
                  "navbarTopMenu_text": true,
                  "navbarTopMenu_burgerMenu__content_text": true,
                  "navbarTopMenu_burgerMenu__content_text--active":
                    this.state.pathname === routes.index.path ? true : false,
                })}
                to={routes.index.path}
                onClick={() => {
                  this.handleToggleClick();
                }}
              >
                Home
              </Link>
            </div>
            {/* <div className="navbarTopMenu_burgerMenu__content-item">
              <Link
                className={classNames({
                  "navbarTopMenu_text": true,
                  "navbarTopMenu_burgerMenu__content_text": true,
                  "navbarTopMenu_burgerMenu__content_text--active":
                    this.state.pathname === routes.about.path ? true : false,
                })}
                to={routes.about.path}
                onClick={() => {
                  this.handleToggleClick();
                }}
              >
                About us
              </Link>
            </div> */}
            <div className="navbarTopMenu_burgerMenu__content-item">
              <Link
                className={classNames({
                  "navbarTopMenu_text": true,
                  "navbarTopMenu_burgerMenu__content_text": true,
                  "navbarTopMenu_burgerMenu__content_text--active":
                    this.state.pathname === routes.howitworks.path ? true : false,
                })}
                onClick={() => {
                  this.handleToggleClick();
                }}
                to={routes.howitworks.path}
              >
                How
              </Link>
            </div>
            <div className="navbarTopMenu_burgerMenu__content-item">
              <Link
                className={classNames({
                  "navbarTopMenu_text": true,
                  "navbarTopMenu_burgerMenu__content_text": true,
                  "navbarTopMenu_burgerMenu__content_text--active":
                    this.state.pathname === routes.merchant.path ? true : false,
                })}
                onClick={() => {
                  this.handleToggleClick();
                }}
                to={routes.merchant.path}
              >
                Merchant
              </Link>
            </div>
          </div>
          {/* Footer */}
          <div>
            <div className="footerPage_section_wrapper">
              <div className="footerPage_section_middle">
                <div className="footerPage_section">
                  <div className="footerPage_section_middle_getApp">
                    <p className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title--black">
                      Get the app
                    </p>

                    <AppStoreBanners outline={true} />
                  </div>
                </div>
              </div>
              <div className="footerPage_section_right">
                <div className="footerPage_section_middle_getApp">
                  <p className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title--black">
                    Join us here.
                  </p>
                  <div className="footerPage_section_social_link_wrapper">
                    <div
                      className="footerPage_section_social_link footerPage_section_middle_getApp_title
                footerPage_section_middle_getApp_title_social"
                    >
                      <a
                        href={FACEBOOK_URL}
                        className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title_social"
                      >
                        Linkedin
                      </a>
                      <div className="footerPage_section_social_link_img">
                        <img src={ICON_ARROW_OUTWARD} alt="TjommiLogo" className="footerPage_section_social_link_img" />
                      </div>
                    </div>
                    <div
                      className="footerPage_section_social_link footerPage_section_middle_getApp_title
                footerPage_section_middle_getApp_title_social"
                    >
                      <a
                        href={FACEBOOK_URL}
                        className="footerPage_section_middle_getApp_title footerPage_section_middle_getApp_title_social"
                      >
                        Facebook
                      </a>
                      <div
                        className="
                  footerPage_section_middle_getApp_title
                  footerPage_section_social_link_img"
                      >
                        <img src={ICON_ARROW_OUTWARD} alt="TjommiLogo" className="footerPage_section_social_link_img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="navbarTopMenu_burgerMenu__content__hr" />
            <div className="navbarTopMenu_burgerMenu__content__copyright">2022 Tjommi —All rights reserved.</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isOnBoard: selectorUserOnBoard(state),
});

export default connect(mapStateToProps)(withRouter(NewMainMenu));
