import React from "react";
import { withRouter } from "react-router-dom";
import ArrowLeft from "../../icons/arrowMiniLeft";
import StoryLogo from "../../icons/storyLogo";
import LanguageDropdown from "../../inputs/languageDropdown";
import Button from "../../icons/Button";
import "./styles.scss";
import classNames from "classnames";
import routes from "../../../utils/routes";

class HeaderLayoutClass extends React.Component {
  handleLeftArrowClick = (path) => () => this.props.history.push(path);

  goToAdmin = () => {
    this.props.history.push(routes.admin.path);
  };

  getContent(obj) {
    let logo = <StoryLogo />;
    switch (obj?.type) {
      case "arrowLeft":
        return (
          <div className="headerLayout--leftArrow" onClick={this.handleLeftArrowClick(obj.path)}>
            <ArrowLeft color="black" /> {obj?.label}
          </div>
        );
      case "cancelLeft":
        return (
          <div className="headerLayout--leftArrow" onClick={this.handleLeftArrowClick(obj.path)}>
            <Button /> {obj?.label}
          </div>
        );
      case "cancelRight":
        return (
          <div className="headerLayout--leftArrow" onClick={this.handleLeftArrowClick(obj.path)}>
            <Button color="#000" /> {obj?.label}
          </div>
        );
      case "languageDropdown":
        return <LanguageDropdown />;
      case "text1":
        return (
          <div className="headerLayout--iconWithText">
            <div onClick={this.goToAdmin}>{logo}</div>
            {this.props.title}
          </div>
        );
      case "text2":
        return <div className="headerLayout--iconWithText">{this.props.title}</div>;
      case "text3":
        return <div className="headerLayout--iconWithText">{this.props.title}</div>;
      case "text4":
        return <div className="headerLayout--iconWithText">{this.props.title}</div>;
      case "text5":
        return <div className="headerLayout--iconWithText">{this.props.title}</div>;
      case "text6":
        return <div className="headerLayout--iconWithText">{this.props.title}</div>;
      default:
        return obj;
    }
  }

  render() {
    const roundBackArrow = this.props.roundBackArrow;
    return (
      <div className={`headerLayout`}>
        <div
          className={classNames({
            "headerLayout--left": true,
            "headerLayout--roundBackArrow": roundBackArrow === true,
          })}
        >
          {this.getContent(this.props.left)}
        </div>
        <div className="headerLayout--right">{this.getContent(this.props.right)}</div>
      </div>
    );
  }
}

export const HeaderLayout = withRouter(HeaderLayoutClass);
