import React from "react";
import { connect } from "react-redux";
import { selectorLanguageStrings, selectorPurchaseItemsByDate } from "../../state/selectors";
import ListingPage from "../listingPage";
import iconClock from "../overview/screen/assets/clock.svg";
import Outline from "../../components/layouts/outline";
import ListItem from "../../components/listItem";
import routes from "../../utils/routes";
import EmptyState from "../emptyState";
import HOURGLASS_IMAGE from "./assets/expiredNoResult.png";
import { getPurchases } from "../../state/actionCreators";

const Expired = ({ expiredPurchases, getPurchases, strs }) => {
  return (
    <ListingPage
      headline={strs.expired.title}
      backPath={routes.overview.subroutes.refunds.path}
      items={expiredPurchases}
      onRefresh={getPurchases}
      renderItem={(date) => (
        <Outline label={date.label} key={date.label}>
          {date.items.map((item) => (
            <ListItem key={item.id} image={iconClock} text={item.product} route={`/overview/expired/${item.id}`} />
          ))}
        </Outline>
      )}
      emptyState={<EmptyState image={HOURGLASS_IMAGE} text={strs.expired.expiredEmptyText} />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    expiredPurchases: selectorPurchaseItemsByDate(state, "expired"),
    strs: selectorLanguageStrings(state, "overview"),
  };
};

const mapDispatchToProps = { getPurchases };

export default connect(mapStateToProps, mapDispatchToProps)(Expired);
