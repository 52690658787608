import React, { useState } from "react";
import "./styles.scss";
import classNames from "classnames";
import ICON_ADD_CIRCLE from "../../components/icons/addCircle";
import ICON_REMOVE_CIRCLE from "../../components/icons/removeCircle";

const Accordion = ({ itemList, title, light, small, onSectionPress }) => {
  const [activePanel, setActivePanel] = useState(null);
  return (
    <div className="accordion">
      <div
        className={classNames({
          "accordion__title": true,
          "accordion__title--white": light ? true : false,
          "accordion__title--small": small ? true : false,
        })}
      >
        {title}
      </div>

      <div className="accordion__list__item-wrapper">
        {itemList.map((ele, index) => {
          return (
            <div key={index}>
              <div className="accordion__list__item-hr" />

              <div
                className={classNames({
                  "accordion__list__item__header": true,
                  "accordion__list__item__header__text": true,
                  "accordion__list__item__header--white": light ? true : false,
                })}
                onClick={() => {
                  if (activePanel != null && activePanel === index) {
                    setActivePanel(null);
                  } else {
                    setActivePanel(index);
                  }

                  if (onSectionPress) {
                    onSectionPress(index);
                  }
                }}
              >
                {ele.title}

                <div>
                  {activePanel === index ? (
                    <ICON_REMOVE_CIRCLE color={light ? "#FFFFFF" : "#222222"} />
                  ) : (
                    <ICON_ADD_CIRCLE color={light ? "#FFFFFF" : "#222222"} />
                  )}
                </div>
              </div>
              <div
                className={classNames({
                  "accordion__list__item__panel": true,
                  "accordion__list__item__panel--block": activePanel === index ? true : false,
                  "accordion__list__item__panel--small": small ? true : false,
                })}
              >
                {ele.subTitle}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;
