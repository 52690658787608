const Icon = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F9F9F9" fillOpacity="0.08" />
    <path
      d="M27.71 26.2902C27.8974 26.479 28.0025 26.7342 28.0025 27.0002C28.0025 27.2661 27.8974 27.5214 27.71 27.7102C27.5205 27.8961 27.2655 28.0002 27 28.0002C26.7345 28.0002 26.4796 27.8961 26.29 27.7102L20 21.4202L13.71 27.7102C13.5205 27.8961 13.2655 28.0002 13 28.0002C12.7345 28.0002 12.4796 27.8961 12.29 27.7102C12.1027 27.5214 11.9976 27.2661 11.9976 27.0002C11.9976 26.7342 12.1027 26.479 12.29 26.2902L18.58 20.0002L12.29 13.7102C12.103 13.5216 11.9983 13.2666 11.9988 13.001C11.9994 12.7354 12.1051 12.4809 12.2929 12.2931C12.4807 12.1053 12.7353 11.9995 13.0009 11.999C13.2665 11.9984 13.5215 12.1031 13.71 12.2902L20 18.5802L26.29 12.2902C26.3831 12.1963 26.4939 12.1217 26.6159 12.0708C26.7378 12.0198 26.8687 11.9934 27.0009 11.9932C27.1331 11.9929 27.2641 12.0187 27.3862 12.0692C27.5084 12.1197 27.6195 12.1938 27.7129 12.2873C27.8064 12.3807 27.8805 12.4918 27.931 12.614C27.9815 12.7362 28.0073 12.8671 28.007 12.9993C28.0068 13.1315 27.9804 13.2624 27.9294 13.3844C27.8785 13.5063 27.8039 13.6171 27.71 13.7102L21.42 20.0002L27.71 26.2902Z"
      fill={color || "white"}
    />
  </svg>
);

export default Icon;
