import React from "react";
import classNames from "classnames";
import "./styles.scss";

const TabNavigator = ({ onClick, activeTab, TabButtonList }) => {
  return (
    <div className="ViewTabNavigator">
      <div className="ViewTabNavigator__wrapper">
        {TabButtonList.map((ele) => {
          return (
            <div
              key={ele.id}
              onClick={() => onClick(ele)}
              className={classNames({
                "ViewTabNavigator__label": true,
                "ViewTabNavigator__label--selected": activeTab === ele.id ? true : false,
              })}
            >
              {ele.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabNavigator;
