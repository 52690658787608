import React from "react";
import { connect } from "react-redux";
import { setLanguage } from "../../../state/actionCreators";
import { selectorLanguage, selectorLanguageStrings } from "../../../state/selectors";
import Dropdown from "../../dropdown";
import { LANGUAGES } from "../../../utils/lang";
import DANISH_FLAG_ICON from "./da.png";
import NORWEGIAN_FLAG_ICON from "./no.png";
import ENGLISH_FLAG_ICON from "./en.png";
import "./styles.scss";

const LanguageDropdown = ({ language, setLanguage, strs }) => {
  return (
    <Dropdown
      className="languageDropdown"
      activeItem={(item) => item.langCode === language}
      onSelect={(item) => setLanguage(item.langCode)}
      hideIcon={true}
      items={[
        {
          label: (
            <>
              <img src={NORWEGIAN_FLAG_ICON} alt={strs.nb} /> {strs.nb}
            </>
          ),
          langCode: LANGUAGES.NB,
          nolink: true,
        },
        {
          label: (
            <>
              <img src={DANISH_FLAG_ICON} alt={strs.da} /> {strs.da}
            </>
          ),
          langCode: LANGUAGES.DA,
          nolink: true,
        },
        {
          label: (
            <>
              <img src={ENGLISH_FLAG_ICON} alt={strs.en} /> {strs.en}
            </>
          ),
          langCode: LANGUAGES.EN,
          nolink: true,
        },
      ]}
    />
  );
};

const mapStateToProps = (state) => ({
  language: selectorLanguage(state),
  strs: selectorLanguageStrings(state, "language"),
});

const mapDispatchToProps = { setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
