import React from "react";
import "./styles.scss";

const DateInput = ({ value, handleChange, name }) => {
  return (
    <input
      className="dateInput"
      name={name}
      type="date"
      onChange={handleChange}
      value={value}
      max={new Date().toISOString().split("T")[0]}
    />
  );
};

export default DateInput;
