import React, { useState, useEffect } from "react";
import MainMenu from "../../components/menus/mainMenu";
import SectionText from "../../components//newSectionText";
import TeamMemberCard from "../../components/teamMemberCard";
import Footer from "../../components/layouts/footer";
import SessionCard from "../../components/sectionCard";

import HenrikJohannessenPhoto from "./assets/2023/co-founders/henrikJohannessen.png";
import HelgeSverrePhoto from "./assets/2023/co-founders/heldge.png";
import JesperBrokPhoto from "./assets/2023/co-founders/jesperBrok.png";
import BenjaminPhoto from "./assets/2023/co-founders/benjamin.png";

import AllanPhoto from "./assets/2023/directors/allan.png";
import LarsJepsenPhoto from "./assets/2023/directors/larsJepsen.png";
import JesperPhoto from "./assets/2023/directors/jesperJarlbaek.png";
import LarsPhoto from "./assets/2023/directors/lars.png";
import TomPhoto from "./assets/2023/directors/tom.png";
import ChadPhoto from "./assets/2023/directors/chad.png";
import ClausPhoto from "./assets/2023/directors/claus.png";
import PeterHigginPhoto from "./assets/2023/directors/peterHiggins.png";
import classNames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import "./styles.scss";

const ViewTeamPage = () => {
  useEffect(() => {
    window.location.href = "/";
  }, []);

  const directorsList1 = [
    {
      name: "Jesper Jarlbæk",
      role: "Chairman",
      image: JesperPhoto,
      description: `Jesper is a successful Business Angel investor with multiple exits and the recipient of the "Business Angel of the Year" title from DVCA. He co-founded and served as Chairman of the Danish Business Angels association, DanBAN, which has been recognized as the best performing business angel network by the European Business Angel Network five times. Jesper is also Chairman of CataCap Private Equity fund, which has been named the best performing PE fund in Denmark twice, and sits on the board of two listed companies while serving as Chairman of a licensed stockbroker company.`,
      linkedIn: "https://www.linkedin.com/in/jarlbaek",
    },
    {
      name: "Lars Jepsen",
      role: "Vice Chariman",
      image: LarsJepsenPhoto,
      description: `Lars is a globally experienced business owner with a demonstrated history of working in the management consulting industry as a Strategy Execution specialist, facilitator and advisor.  He is skilled in Executive and Personal Development, Business Planning, Career Development, and HR Consulting. Lars has an M.Sc. (Econ) in International Economics and Business from Copenhagen Business School.`,
      linkedIn: "https://www.linkedin.com/in/larsjepsen/",
    },
    {
      name: "Lars Fløe Nielsen",
      role: "Member",
      image: LarsPhoto,
      description: `A programmer by trade, Lars was one of the founding members of Sitecore, and held many roles during his time there, from sales and marketing, to strategy building and leading teams of engineers. Lars is a chair, vice chair, or board member at nemlig.com, Whiteaway Group, Seaborg Technologies, Contractbook, and Tjommi. He is Jury Chairman of the EY Entrepreneur of the Year Denmark, and co-founder of Nordic Makers, a group of eight top Nordic Angel Investors.`,
      linkedIn: "https://www.linkedin.com/in/larsfloenielsen/",
    },
    {
      name: "Allan Sønderskov Darre",
      role: "Member",
      image: AllanPhoto,
      description: `As a business angel, board member and entrepreneur, Allan’s philosophy is to challenge conventional thinking by building tech solutions in the space of opportunity between markets.
        Allan founded Freetailer in 2004, creating a new marketspace in the sharing economy, strengthening the connection between retailers and customers. Freetailer listed on the stock exchange in 2018. Allan has invested carefully in more than 41 startups. His modus is to prime startups locally and to support them to execute globally.`,
      linkedIn: "https://www.linkedin.com/in/allandarre/",
    },
  ];

  const directorsList2 = [
    {
      name: "Tom Hambrett",
      role: "Member",
      image: TomPhoto,
      description: `Tom is a partner and General Counsel at Revolut, responsible for the group's legal and regulatory strategy globally. He joined in 2017, after working at Herbert Smith Freehills, where he advised on corporate and securities law. Tom holds a BBA and LLB from Macquarie University in Australia.`,
      linkedIn: "https://www.linkedin.com/in/jarlbaek",
    },
    {
      name: "Peter O’higgins",
      role: "Advisor",
      image: PeterHigginPhoto,
      description: `Peter worked at JP Morgan for 12 years in the Equity Derivatives Risk technology field as a business analyst, before being promoted to Executive Director for the same program. After his time at JP Morgan, he joined Revolut as CFO and was instrumental in steering the company through challenging regulatory environments across multiple markets while overseeing significant growth.`,
      linkedIn: "https://www.linkedin.com/in/larsjepsen/",
    },
    {
      name: "Chad West",
      role: "Advisor",
      image: ChadPhoto,
      description: `Chad West is a seasoned marketer who was the first Marketing employee at Revolut, where he eventually became Chief Marketing Officer and played a pivotal role in driving the company's growth to its first 1 million users. Prior to Revolut, Chad spent a couple of years at Rocket Internet in the marketing department. After leaving Revolut, he joined Dija as CMO, and the company was later acquired by Gopuff. Currently, Chad serves as the CMO at Argent, a fast-growing startup in the crypto and web3 space.`,
      linkedIn: "https://www.linkedin.com/in/larsfloenielsen/",
    },
    {
      name: "Claus Nielsen",
      role: "Advisor",
      image: ClausPhoto,
      description: `Claus is an experienced top management leader with a strong track record in international business management, commercial development, tech & digital scale, and organizational transformations. He has held key roles at Bestseller group, Fitness World, and Red Bull, where he demonstrated his passion for developing effective business models, building sustainable businesses, products, and brands. Claus is results-driven and committed to accelerating performance, developing people and organizations, and delivering tangible outcomes.`,
      linkedIn: "https://www.linkedin.com/in/allandarre/",
    },
  ];

  const coFoundersList = [
    {
      name: "Henrik Johannessen",
      role: "Co-founder & CEO",
      image: HenrikJohannessenPhoto,
      description:
        "Henrik started his entreprenurial career with DUCO, a luxury accessories brand which he started at the age of 18. He studied Economics & Leadership at Bi Norwegian Business school, then started work as a Jr advisor at an investment company focused on real estate. Henrik founded Tjommi as a student, going full-time upon graduation. After moving from Copenhagen to London, Henrik is focused turning Tjommi into the dominant player in the global ecommerce post-purchase market.",
      linkedIn: "https://www.linkedin.com/in/tjommihenrik/",
    },
    {
      name: "Helge Sverre Liseth",
      role: "Co-founder & CTO",
      image: HelgeSverrePhoto,
      description:
        "Helge is an experinced developer with over 10 years of experience. He worked as Partner and CTO at Norway’s leading tech agency. Making soloutions for Vipps, TV2 and NCE Finance Innovation. Helge co-founded and was a partner at Coded, which builds different Saas soloutions across industries.",
      linkedIn: "https://www.linkedin.com/in/helgesverre/",
    },
    {
      name: "Jesper Brok",
      role: " Co-founder & CMO",
      image: JesperBrokPhoto,
      description:
        "Jesper is the youngest dane to list a company that he founded on the NASDAQ. That company was Happy Helper, where we was Co-founder and CMO. He has also held positions at Takeout and Rocket Internet, where he worked in Global Ops for Foodora. Jester was also interim CMO for Clerk.io. Jesper is a private investor, and a former professional handball player.",
      linkedIn: "https://www.linkedin.com/in/jesperbrok/",
    },
    {
      name: "Benjamin Walch",
      role: "COO",
      image: BenjaminPhoto,
      description:
        "Hands-on and execution driven, Benjamin gained global experience working in Denmark, the Philippines, India and Japan. Ben is data-driven in assessing opportunities and decision making, while being attentive toward the human aspect that KPIs can’t represent. Creating real impact, with good people for services&products that he believes in is what gets Ben out of bed in the morning. Ben worked for 3 years as a strategy consultant at Accenture, spent 1 year at Rocket Internet, and was Co-founder and COO at the Designers Trust.",
      linkedIn: "https://www.linkedin.com/in/benjaminwalch/",
    },
  ];

  const [visibleDirectorsList1, setVisibleDirectorsList1] = useState(false);
  const [visibleDirectorsList2, setVisibleDirectorsList2] = useState(false);
  const [visibleCoFoundersList, setVisibleCoFoundersList] = useState(false);

  const VisibilitySensorChange = (isVisible, sectionName) => {
    if (isVisible) {
      if (sectionName === "directorsList1") {
        setVisibleDirectorsList1(true);
      }
      if (sectionName === "directorsList2") {
        setVisibleDirectorsList2(true);
      }
      if (sectionName === "coFoundersList") {
        setVisibleCoFoundersList(true);
      }
    } else {
      if (sectionName === "directorsList1") {
        setVisibleDirectorsList1(false);
      }
      if (sectionName === "directorsList2") {
        setVisibleDirectorsList2(false);
      }
      if (sectionName === "coFoundersList") {
        setVisibleCoFoundersList(false);
      }
    }
  };

  return (
    <div className="ViewTeamPage">
      <MainMenu />
      <div className="ViewTeamPage-header-wrapper">
        <div className="ViewTeamPage-header__title">
          Our <br />
          leadership <br />
          team
        </div>
      </div>

      {/* SECTION 1 */}
      <div className="ViewTeamPage-section">
        <div className="ViewTeamPage-section-content__membersHeader">
          <div className="ViewTeamPage-section-content__membersHeader__title">Board of directors</div>
          <div className="ViewTeamPage-section-content__membersHeader__title--sub">
            Best in class board of directors with unicorn founders & Revolut executives
          </div>
        </div>
      </div>

      {/* SECTION 2 */}
      <div className="ViewTeamPage-section">
        <VisibilitySensor onChange={(e) => VisibilitySensorChange(e, "directorsList1")} partialVisibility={true}>
          <div className="ViewTeamPage-section-content__teamMembers__wrapper">
            {directorsList1.map((ele, index) => {
              return (
                <div
                  key={index}
                  className={classNames({
                    "ViewTeamPage-section-content__teamMembers__appear": true,
                    "ViewTeamPage-section-content__teamMembers__appear__inview": visibleDirectorsList1,
                  })}
                >
                  <TeamMemberCard
                    image={ele.image}
                    name={ele.name}
                    role={ele.role}
                    linkedIn={ele.linkedIn}
                    description={ele.description}
                  />
                </div>
              );
            })}
          </div>
        </VisibilitySensor>
      </div>

      {/* SECTION 2 */}
      <div className="ViewTeamPage-section">
        <VisibilitySensor onChange={(e) => VisibilitySensorChange(e, "directorsList2")} partialVisibility={true}>
          <div className="ViewTeamPage-section-content__teamMembers__wrapper ViewTeamPage-section-content__teamMembers__wrapper--padding">
            {directorsList2.map((ele, index) => {
              return (
                <div
                  key={index}
                  className={classNames({
                    "ViewTeamPage-section-content__teamMembers__appear": true,
                    "ViewTeamPage-section-content__teamMembers__appear__inview": visibleDirectorsList2,
                  })}
                >
                  <TeamMemberCard
                    image={ele.image}
                    name={ele.name}
                    role={ele.role}
                    linkedIn={ele.linkedIn}
                    description={ele.description}
                  />
                </div>
              );
            })}
          </div>
        </VisibilitySensor>
      </div>

      {/* SECTION 3 */}
      <div className="ViewTeamPage-section--black ViewTeamPage-section-content__membersHeader--extraTop">
        <div className="ViewTeamPage-section-content__membersHeader">
          <div className="ViewTeamPage-section-content__membersHeader__title ViewTeamPage-section-content__membersHeader__title--white">
            Executive leadership
          </div>
          <div className="ViewTeamPage-section-content__membersHeader__title--sub">
            Best in class execution team with proven track record as founders.
          </div>
        </div>
      </div>

      {/* SECTION 4 */}
      <div className="ViewTeamPage-section--black">
        <VisibilitySensor onChange={(e) => VisibilitySensorChange(e, "coFoundersList")} partialVisibility={true}>
          <div className="ViewTeamPage-section-content__teamMembers__wrapper">
            {coFoundersList.map((ele, index) => {
              return (
                <div
                  key={index}
                  className={classNames({
                    "ViewTeamPage-section-content__teamMembers__appear": true,
                    "ViewTeamPage-section-content__teamMembers__appear__inview": visibleCoFoundersList,
                  })}
                >
                  <TeamMemberCard
                    image={ele.image}
                    name={ele.name}
                    role={ele.role}
                    linkedIn={ele.linkedIn}
                    description={ele.description}
                  />
                </div>
              );
            })}
          </div>
        </VisibilitySensor>
      </div>

      {/* SECTION 5 */}
      <div className="ViewTeamPage-section--black">
        <div className="ViewTeamPage-section-content__sessionCards">
          <div className="ViewTeamPage-section-content__sessionCards-wrapper">
            <SessionCard newsSubscribeMode backgroundColor="#FFFFFF" />
          </div>
          <div className="ViewTeamPage-section-content__sessionCards-wrapper">
            <SessionCard refundType backgroundColor="#CBC3B9" dark bottomContentPosition="end" />
          </div>
        </div>
      </div>

      {/* SECTION 6 */}
      <div className="ViewTeamPage-section--black">
        <div className="ViewTeamPage-section-content__appstoreBanner">
          <SectionText
            specialSub={" Such a beautiful word."}
            sub={"Refund."}
            title={"Loved by 200,000  shoppers."}
            white
            appStoreBanners
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ViewTeamPage;
